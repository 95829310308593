import React from "react";
import styled from "styled-components";
import { __ } from "../../lib/translate";
import Modal from "../Modal";
import { Link, Error } from "./MainComponents";
import SubmitButton from "../SubmitButton";

export const ConfirmSubscription = ({ show, onClose, onConfirm, loadingState, error }) => {
  return (
    <Modal align="top" onClose={onClose} opened={show}>
      <Wrap>
        <Text>{__("vivacom_purchase_confirmation")}</Text>
        <Error>{error ? error : ""}</Error>
        <SubmitButton onClick={onConfirm} style={{ width: "100%" }} loading={loadingState}>
        {__("vivacom_confirm")}
        </SubmitButton>
        <Link onClick={onClose}>{__("vivacom_cancel")}</Link>
      </Wrap>
    </Modal>
  );
}

const Wrap = styled.div`
  max-width: 360px;
  background: #fff;
  border-radius: 10px;
  padding: 40px;
  @media (max-width: 768px) {
    padding: 25px;
  }
`

const Text = styled.div`
  font-size: 15px;
  line-height: 1.3;
  margin-bottom: 27px;
  margin-top: 10px;
  @media (max-width: 768px) {
    margin-bottom: 25px;
  }
`
