// @flow

import React, { useState, useEffect, useRef } from "react";
import Componentify from "react-componentify";

import Modal, { OvalModalWrap, CloseModalIcon } from "../Modal";
import * as Style from "./styledComponents";
import Button from "../ButtonDefault";
import InputText from "../InputText";

import apiMethod from "../../api/apiMethod";
import { spanClassTagConverter, brTagConverter } from "../../lib/componentifyConverters";
import { __ } from "../../lib/translate";
import { errorKeys } from "../../lib/errors";

const RenameModal = ({ data, modalContainer }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [newName, setFolderName] = useState(HFN.metaName(data) || "");
  const [isLoading, setIsLoading] = useState(false);
  const [showOverwrite, setShowOverwrite] = useState(false);

  const inputTextRef = useRef(null);

  useEffect(() => {
    var renameInput = inputTextRef.current;

    if (data.isfolder) {
      createSelection(renameInput, 0, renameInput.value.length);
    }	else {
      createSelection(renameInput, 0, renameInput.value.lastIndexOf('.'));
    }
    renameInput.focus();
  }, []);

  const handleClose = () => {
    setIsOpen(false);
    setFolderName("");
  };

  const onNameChange = ({ target }) => {
    setFolderName(target.value);
  };

  const onEnter = (e: any) => {
    if (e.keyCode === 13) {
      onRenameButtonClick();
    }
  };

  const onRenameButtonClick = () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    const params = { toname: newName.trim() };

    if (newName.trim().length < 1) {
      HFN.message(__("New name is required."), "errror");
      setIsLoading(false);
      setFolderName("");
      return;
    }

    const regex = new RegExp('[><"/]+');
    if (regex.test(newName)) {
      HFN.message(__("share_folder_modal_error_folder_name_special_chars"), "error");
      setIsLoading(false);
      return;
    }
    if (data.name == newName.trim()) {
      handleClose();
      return;
    }

    if (!showOverwrite && !data.isfolder && HFN.fileExists(data.parentfolderid, params.toname)) {
      setIsLoading(false);
      setShowOverwrite(true);
    } else {
      let method = "";

      if (data.isfolder) {
        method = "renamefolder";
        params.folderid = data.folderid;
      } else {
        method = "renamefile";
        params.fileid = data.fileid;
      }

      var prep = callback => {
        callback();
      };
      if (data.encrypted && !data.ismount) {
        prep = callback => {
          pCloudCrypto.asyncEncryptMeta(
            { name: params.toname, parentfolderid: data.parentfolderid },
            function(enc) {
              params.toname = enc;
              callback();
            }
          );
        };
      }

      prep(() => {
        apiMethod(
          method,
          params,
          ret => {
            HFN.message((data.isfolder ? __("Folder") : __("File")) + " " + __("renamed."));
            HFN.updateMetadata(ret.metadata);
            document.dispatchEvent(new CustomEvent("more-options-click", { detail: { action: "rename", data: ret.metadata } }));
            handleClose();
          },
          {
            errorCallback: ({ result, error }) => {
              if (errorKeys[result]) {
                HFN.message(__(errorKeys[result]), "error");
              } else {
                handleClose();
                HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
                throw new Error(error);
              }
              setIsLoading(false);
            }
          }
        );
      });
    }
  };

  const renderOverwriteBody = () => {
    const fileMessage = __(
      "file_name_override_message",
      `A file named "<span class='ellipsis'>%item1%</span>" already exists in "<span class='ellipsis'>%item2%</span>".<br/>Would you like to overwrite it?`,
      {
        item1: newName,
        item2: HFN.metaName(HFN.data.fflookup["d" + data.parentfolderid])
      }
    );

    return (
      <React.Fragment>
        <Style.OverwriteMessage>
          <Componentify text={fileMessage} converters={[spanClassTagConverter, brTagConverter]} />
        </Style.OverwriteMessage>
        <Style.Footer>
          <Button
            color="lightgray4"
            style={{
              marginRight: "5px"
            }}
            onClick={() => setShowOverwrite(false)}
          >
            {__("Back")}
          </Button>
          <Button
            color="red"
            style={{
              marginLeft: "5px"
            }}
            disabledColor="#dddddd"
            onClick={onRenameButtonClick}
          >
            {__("Overwrite")}
          </Button>
        </Style.Footer>
      </React.Fragment>
    );
  };

  const renderRenameBody = () => {
    return (
      <React.Fragment>
        <Style.Label>{__("Name", "Name")}</Style.Label>
        <InputText
          innerRef={inputTextRef}
          name="newName"
          placeholder={__("Name")}
          onChange={onNameChange}
          value={newName}
          autoFocus
          onKeyDown={onEnter}
        />
        <Style.Footer>
          <Button
            color="lightgray4"
            style={{
              marginRight: "5px"
            }}
            onClick={handleClose}
          >
            {__("Cancel")}
          </Button>
          <Button
            color="cyan"
            style={{
              marginLeft: "5px"
            }}
            disabledColor="#dddddd"
            onClick={onRenameButtonClick}
          >
            {__("Rename")}
          </Button>
        </Style.Footer>
      </React.Fragment>
    );
  };

  return (
    <Modal align="top" animate onClose={handleClose} opened={isOpen} container={modalContainer}>
      <OvalModalWrap>
        <CloseModalIcon onClick={handleClose} />
        <Style.Container>
          <Style.Header>{__("Rename")}</Style.Header>
          {showOverwrite ? renderOverwriteBody() : renderRenameBody()}
        </Style.Container>
      </OvalModalWrap>
    </Modal>
  );
};

export default RenameModal;
