import React from "react";
import { connect } from "react-redux";
import store from "../lib/state/store";
import apiMethod from "../api/apiMethod";
import {
  NewPrivacyPolicyModal,
  ThankYou,
  BottomPrivacyWarning
} from "../components/NewPrivacyPolicyModal";

class NewPrivacyPollicy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openedModal: true,
      showWarning: false,
      showLoading: false,
      showThankyou: false
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openThankyou = this.openThankyou.bind(this);
    this.closeThankyou = this.closeThankyou.bind(this);
    this.acceptPrivacyPolicy = this.acceptPrivacyPolicy.bind(this);
  }

  openModal() {
    this.setState({
      openedModal: true,
      showThankyou: false,
      showWarning: false,
      showLoading: false
    });
  }

  closeModal() {
    this.setState({ openedModal: false });
    if (!this.props.agreedwithpp) {
      this.setState({ showWarning: true });
    }
  }

  openThankyou() {
    this.setState({
      openedModal: false,
      showThankyou: true,
      showWarning: false
    });
  }

  closeThankyou() {
    this.setState({
      openedModal: false,
      showThankyou: false,
      showWarning: false
    });
  }

  acceptPrivacyPolicy() {
    this.setState({ showLoading: true });
    const { auth } = this.props;
    apiMethod(
      "agreewithprivacypolicy",
      { auth },
      () => {
        this.setState({ showLoading: false });
        this.openThankyou();
      },
      {}
    );
  }

  render() {
    if (this.state.openedModal) {
      return (
        <NewPrivacyPolicyModal
          store={this.props.store}
          acceptPrivacyPolicy={this.acceptPrivacyPolicy}
          toggleThankyou={this.toggleThankyou}
          openModal={this.openModal}
          closeModal={this.closeModal}
          loading={this.state.showLoading}
          openedModal={this.state.openedModal}
        />
      );
    }

    if (this.state.showWarning) {
      return <BottomPrivacyWarning openModal={this.openModal} />;
    }

    if (this.state.showThankyou) {
      return (
        <ThankYou
          closeThankyou={this.closeThankyou}
          showThankyou={this.state.showThankyou}
        />
      );
    }

    return null;
  }
}

export default connect(state => {
  console.log("STATE:", state);
  return {
    logged: state.pCloudUser.logged,
    auth: state.pCloudUser.token,
    agreedwithpp: state.pCloudUser.userinfo.agreedwithpp
  };
})(NewPrivacyPollicy);
