// @flow
import apiMethod from "../../api/apiMethod";

import store from "../../lib/state/store";
import { modifyContentItem } from "../../lib/state/reducers/contentSlice";

export const parseLinkData = ({
  code,
  linkid,
  link,
  shortlink = false,
  type,
  metadata,
  haspassword = false,
  expires = false,
  enableduploadforchosenusers = false,
  enableduploadforeveryone = false,
  disableupload = true,
  created,
  traffic,
  downloads,
  downloadenabled
}) => ({
  code: code,
  linkId: linkid,
  linkUrl: link,
  shortLinkUrl: shortlink,
  type: type,
  linkName: metadata.name,
  hasPassword: haspassword,
  expirationDate: expires,
  created: created,
  downloads: downloads,
  traffic: traffic,
  uploadSettings: {
    isUploadDisabled: disableupload,
    canEveryoneUpload: enableduploadforeveryone,
    isRestrictedUpload: enableduploadforchosenusers
  },
  canDownload: downloadenabled,
  emailsWithUploadAccess: []
});

export const getEmailsWithAccessApiCall = (token, linkId, onSuccess, onError) => {
  apiMethod(
    "publink/listemailswithaccess",
    {
      auth: token,
      linkid: linkId
    },
    ({ list }) => {
      onSuccess(list);
    },
    {
      forceFresh: true,
      cacheTime: 0,
      errorCallback: ({ error }) => {
        onError();
        throw new Error(error);
      }
    }
  );
};

export const changePublinkApiCall = (params, onSuccess, onError) => {
  apiMethod(
    "changepublink",
    params,
    (res) => {
      onSuccess(res);

      const item = res;
      item.id = res.code;
      item.name = res.metadata.name;
      item.icon = res.metadata.icon;
      if (res.metadata.isfolder && res.metadata.folderid == 0) {
        item.icon = HFN.ICO.VIRTUALFOLDER;
      }
      item.hash = res.metadata.hash;
      item.thumb = res.metadata.thumb;
      item.fileid = res.metadata.fileid || '';
      item.issharedlink = true;

      store.dispatch(modifyContentItem(item));
    },
    {
      errorCallback: (res) => {
        onError(res);
      },
    }
  );
};
