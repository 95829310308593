import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { createPortal } from "react-dom";
import { Tooltip } from "react-tooltip";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";

import useOutsideClicker from "@pcloud/web-utilities/dist/hooks/useOutsideClicker";
import { isUploadDisabledRedesign } from "../../../UploadManager/helpers";
import { __ } from "../../../../lib/translate";
import { ONBOARDING_ID_UPLOAD_BUTTON } from "../../../HighlightOnboardingComponent/constants";
import HighlightOnboardingComponent from "../../../HighlightOnboardingComponent";
import UploadButtonContent from "../../../HighlightOnboardingComponent/Contents/UploadManager/UploadButtonContent";
import { addItem } from "../../../../lib/state/reducers/onboardingSlice";
import useWindowDimensions from "../../../../lib/hooks/useWindowDimensions";
import { INVITATION_PAGES, MOBILE_WIDTH_BREAKPOINT } from "../../../../config/constants";
import HoverMenu from "../../UI/HoverMenu";
import { getHashPage } from "../../../../lib";

const UploadButton = ({ short = false }) => {
    const { bodyWidth } = useWindowDimensions();
    const isMobile = bodyWidth < MOBILE_WIDTH_BREAKPOINT;

    const [page, setPage] = useState(getHashPage());
    const [isOpen, setIsOpen] = useState(false);
    const [isOptionsModalOpen, setIsOptionsModalOpen] = useState(false);
    const [turnOnAnimation, setTurnOnAnimation] = useState(false);
    const itemData = useSelector(({ content }) => content.itemData);
    const dispatch = useDispatch();
    const isUploadDisabled = isUploadDisabledRedesign();
    // Do not delete - userLang is used for language change render
    const userLang = useSelector(({ pCloudUser }) => pCloudUser.userinfo.language);

    useEffect(() => {
        window.addEventListener("hashchange", handleHashChange);

        return () => {
            window.removeEventListener("hashchange", handleHashChange);
        };
    }, [page]);

    const handleHashChange = () => {
        setPage(getHashPage());
    };

    useEffect(() => {
        setTurnOnAnimation(true);

        if (!HFN.config.isDlink() && !isUploadDisabled) {
            dispatch(addItem(ONBOARDING_ID_UPLOAD_BUTTON));
        }
    }, [isUploadDisabled]);

    const uploadModalRef = useRef(null);
    const fileInputRef = useRef(null);
    const folderInputRef = useRef(null);

    const handleFileChange = (event) => {
        HFN.uploadControl.onUpload({
            items: event.target.files,
            isDrop: false
        });
        setIsOptionsModalOpen(false);
    };

    const handleRemoteUpload = () => {
        if (typeof gtag === "function") {
            gtag("event", "upload_options_click", {
                action: "file from url",
                location: "add button"
            });
        }
        HFN.uploadControl.onUpload({ isRemoteUpload: true });
        setIsOptionsModalOpen(false);
    };

    const triggerFileInput = () => {
        if (typeof gtag === "function") {
            gtag("event", "upload_options_click", {
                action: "file upload",
                location: "add button"
            });
        }

        fileInputRef.current.click();
        fileInputRef.current.value = "";
        setIsOptionsModalOpen(false);
    };

    const triggerFolderCreateInput = () => {
        if (typeof gtag === "function") {
            gtag("event", "upload_options_click", {
                action: "new folder",
                location: "add button"
            });
        }

        if (!HFN.config.auth) {
            HFN.initLoginRegModal("loginorsignup", function() {}, {
                subTitle: __("shared_link_login_prompt")
            });
            return;
        } else {
            HFN.newFolder(itemData.folderid);
        }
        setIsOptionsModalOpen(false);
    };

    const triggerFolderInput = () => {
        if (typeof gtag === "function") {
            gtag("event", "upload_options_click", {
                action: "folder upload",
                location: "add button"
            });
        }

        folderInputRef.current.click();
        folderInputRef.current.value = "";
        setIsOptionsModalOpen(false);
    };

    useOutsideClicker(uploadModalRef, () => {
        setIsOptionsModalOpen(false);
    });

    const onOpen = () => {
        setIsOpen(true);
    };

    const onClose = () => {
        setIsOpen(false);
    };

    const renderButton = () => {
        const isShort = short || isMobile;
        return (
            <Button
                disabled={isUploadDisabled}
                $short={isShort}
                $isMobile={isMobile}
            >
                {isShort && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <i className="mediumIcon fa-regular fa-plus" />
                    </motion.div>
                )}
                {!isShort && (
                    <>
                        {__("Add")}
                        <i className="smallIcon fa-regular fa-chevron-down" />
                    </>
                )}
            </Button>
        );
    };

    const getDropDownList = () => {
        const returnData = [
            {
                label: ["New Folder", "New Folder"],
                params: [],
                iconComponent: (
                    <ModalSelectOptionIcon>
                        <i className="smallIcon fa-light fa-folder-plus" />
                    </ModalSelectOptionIcon>
                ),
                onClick: triggerFolderCreateInput
            },
            {
                divider: true
            },
            {
                label: ["Files", "Files"],
                params: [],
                iconComponent: (
                    <ModalSelectOptionIcon>
                        <i className="smallIcon fa-light fa-file-arrow-up" />
                    </ModalSelectOptionIcon>
                ),
                onClick: triggerFileInput
            }
        ];

        if (!HFN.config.isMobile()) {
            returnData.push({
                label: ["Folder", "Files"],
                params: [],
                iconComponent: (
                    <ModalSelectOptionIcon>
                        <i className="smallIcon fa-light fa-folder-arrow-up" />
                    </ModalSelectOptionIcon>
                ),
                onClick: triggerFolderInput
            });
        }

        if (
            !HFN.config.isMobile() &&
            !HFN.config.isDlink() &&
            !HFN.inCrypto()
        ) {
            returnData.push({
                label: ["upload_manager_remote_upload_option", "File from URL"],
                params: [],
                iconComponent: (
                    <ModalSelectOptionIcon>
                        <i className="smallIcon fa-light fa-file" />
                    </ModalSelectOptionIcon>
                ),
                onClick: handleRemoteUpload
            });
        }

        return returnData;
    };

    const renderDropDownButton = () => {
        return (
            <ButtonWrapper
                data-tooltip-id={isMobile ? null : "upload_button_tooltip"}
            >
                {!isOpen && (
                    <Tooltip
                        className="cv-tooltip"
                        style={{ zIndex: 2000 }}
                        id="upload_button_tooltip"
                        noArrow
                        place="bottom"
                        offset={12}
                    >
                        {isUploadDisabled
                            ? __(
                                  "tooltip_upload_button_disabled",
                                  "Upload is not available on this page"
                              )
                            : __(
                                  "tooltip_upload_button",
                                  "Upload or create new item"
                              )}
                    </Tooltip>
                )}
                <HighlightOnboardingComponent
                    id={ONBOARDING_ID_UPLOAD_BUTTON}
                    content={[<UploadButtonContent />]}
                    temporaryHide={isOptionsModalOpen}
                    notShowAfterClose={true}
                    pulseAnimation={true}
                    pulseCircle={false}
                    showOverlay={true}
                    place={"bottom-right"}
                    borderRadius={"9999px"}
                    delay={500}
                >
                    {turnOnAnimation && (
                        <AnimatePresence>{renderButton()}</AnimatePresence>
                    )}
                    {!turnOnAnimation && renderButton()}
                </HighlightOnboardingComponent>
            </ButtonWrapper>
        );
    };

    const renderContainer = () => {
        const list = getDropDownList();
        return (
            <Container id="uploadButton" $isMobile={isMobile}>
                {!isUploadDisabled && (
                    <HoverMenu
                        triggerOnClick={true}
                        align={"end"}
                        menuButtonClassName={"no-margin no-padding"}
                        direction={isMobile ? "top" : "bottom"}
                        arrow={false}
                        portal={false}
                        menuComponent={renderDropDownButton()}
                        menuItems={list}
                        iconSetup={{
                            open: null,
                            close: null
                        }}
                        onOpen={onOpen}
                        onClose={onClose}
                    />
                )}
                {isUploadDisabled && renderDropDownButton()}
                <InputFile
                    type="file"
                    multiple
                    onChange={handleFileChange}
                    ref={fileInputRef}
                />
                <InputFile
                    type="file"
                    webkitdirectory="true"
                    onChange={handleFileChange}
                    ref={folderInputRef}
                />
            </Container>
        );
    };

    if (isMobile) {
        if (
            INVITATION_PAGES.includes(page) ||
            [
                'filemanager',
                'trash',
                'audio',
            ].includes(page) &&
            itemData.hasOwnProperty("items") &&
            !Object.keys(itemData.items).length
        ) {
            return null;
        }

        const mobileContainerId = "mobile-upload-button-container";
        let container = document.getElementById(mobileContainerId);
        if (!container) {
            container = HFN.bottomFixedControls.getContainer();
            container = container.querySelector("#" + mobileContainerId);
        }

        return <>{createPortal(renderContainer(), container)}</>;
    }

    return <>{renderContainer()}</>;
};

export default UploadButton;

const Container = styled.div`
    position: relative;

    & .hover-menu-button {
        padding: 0;
    }

    ${({ $isMobile }) =>
        $isMobile &&
        css`
            & .szh-menu {
                transform: translate(2px, -12px);
            }

            &,
            & * {
                -webkit-tap-highlight-color: transparent;
            }
        `}
`;

const ButtonWrapper = styled.div`
    float: left;
    display: inline-block;
`;

export const Button = styled.button`
    cursor: pointer;
    height: 40px;
    min-width: 120px;
    background: var(--button-primary-background-default);
    display: flex;
    padding: var(--spacing-sm);
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: var(--radius-full);
    position: relative;
    white-space: nowrap;
    padding: 0px 48px;

    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: var(--button-primary-label-default);
    transition: all .3s linear;

    & i {
        position: absolute;
        top: 13px;
        right: 13px;
    }

    ${({ disabled }) =>
        disabled &&
        css`
            background: #999999;
            opacity: 0.5;
            pointer-events: none;
        `}

    ${({ $short }) =>
        $short &&
        css`
            min-width: 40px;
            width: 40px;
            padding: 0;

            & i {
                position: absolute;
                top: 11px;
                right: 12px;
            }
        `}

    ${({ $isMobile }) =>
        $isMobile &&
        css`
            width: 48px;
            height: 48px;
            box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.16);

            & i {
                font-size: 28px;
                line-height: 28px;
                position: absolute;
                top: 10px;
                right: 12px;
            }
        `}
`;

const ModalSelectOptionIcon = styled.span`
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    & i {
        color: var(--color-icon-menu);
    }
`;

const InputFile = styled.input`
    display: none;
`;
