import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { __ } from "../../lib/translate";
import { Wrapper, Content, Button } from "./styles";
import useWindowDimensions from "../../lib/hooks/useWindowDimensions";
import { MOBILE_WIDTH_BREAKPOINT } from "../../config/constants";
import CryptoImage from "../../../root/img/crypto/crypto-buy-image.svg";
import { useSelector } from "react-redux";
import hashManager from "../../lib/hashManager";

const CryptoBusiness = () => {
    const { bodyWidth } = useWindowDimensions();
    const isMobile = bodyWidth < MOBILE_WIDTH_BREAKPOINT;
    const [showMemberMessage, setShowMemberMessage] = useState(false);
    const user = useSelector(({ pCloudUser }) => pCloudUser.userinfo);
    const token = useSelector(({ pCloudUser }) => pCloudUser.token);

    const showCryptoUpdateBtn =
        user.account?.owner && !user.cryptov2isactive;

    useEffect(() => {
        if (user.account && !user.account.owner) {
            HFN.apiMethod("account_info", { auth: token }, function(
                res
            ) {
                let text;
                if (!res.account.owner.cryptosetup) {
                    text = "contact_owner_to_set_up_message";
                } else {
                    text = "no_crypto_shares_yet";
                }
                setShowMemberMessage(__(text));
            });
        }
    }, [user]);

    const renderInfoSection = () => (
        <InfoSection $isMobile={isMobile}>
            <InfoSectionDescription>
                <div>{__("Protect files with unbreakable encryption")}</div>
                <div>{__("Share encrypted folders with other members")}</div>
                <div>
                    {__("Control who can view, edit and manage shared folders")}
                </div>
                <div>{__("Access securely from every device")}</div>
                <div>
                    {__(
                        "crypto_buy_message_3",
                        "Ensure zero-knowledge privacy for everything confidential."
                    )}
                </div>
            </InfoSectionDescription>
        </InfoSection>
    );

    return (
        <WrapperCustom $isMobile={isMobile}>
            <ContentCustom>
                {!isMobile && (
                    <Title>{__("Share files at full security")}</Title>
                )}
                <InfoSectionMessage $isMobile={isMobile}>
                    {__(
                        "Collaborate with your team, while keeping important files confidential in the Crypto folder. With pCloud Crypto's client-side encryption, you encrypt files before they leave your device. You are the only one who holds the key to your online privacy!"
                    )}
                </InfoSectionMessage>
                {isMobile && renderInfoSection()}
                {showMemberMessage && (
                    <NoteWrapper $isMobile={isMobile}>
                        {showMemberMessage}
                    </NoteWrapper>
                )}
                <CryptoImageWrapper>
                    <CryptoImage />
                </CryptoImageWrapper>
                <Content $maxWidth={560}>
                    {!isMobile && renderInfoSection()}
                    {!showMemberMessage && (
                        <ActionWrapper>
                            {!showCryptoUpdateBtn && (
                                <Button
                                    onClick={() => {
                                        hashManager.pushState(
                                            { page: "cryptoset" },
                                            2
                                        );
                                    }}
                                >
                                    {__("branding-intro-button1-premium")}
                                </Button>
                            )}
                            {showCryptoUpdateBtn && (
                                <Button
                                    onClick={() => {
                                        gaAll("event", "CryptoUpdate", "click");
                                        hashManager.pushState(
                                            { page: "cryptoupdate" },
                                            2
                                        );
                                    }}
                                >
                                    {__("Update")}
                                </Button>
                            )}
                        </ActionWrapper>
                    )}
                </Content>
            </ContentCustom>
        </WrapperCustom>
    );
};

export default CryptoBusiness;

const WrapperCustom = styled(Wrapper)`
    margin-top: ${({ $isMobile }) => ($isMobile ? "0" : "44px")};
`;

const ContentCustom = styled(Content)`
    gap: 24px;
    max-width: 560px;
`;

const Title = styled.div`
    color: var(--text-primary, #282c2f);
    font-size: 32px;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: 0.32px;
    text-align: center;
`;

const CryptoImageWrapper = styled.div`
    width: 100%;
    svg {
        width: 100%;
        height: auto;
    }
`;

const NoteWrapper = styled.div`
    display: flex;
    width: 100%;
    border-radius: 8px;
    padding: 16px 8px;
    box-sizing: border-box;
    background: var(--color-functional-positive100);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: ${({ $isMobile }) => ($isMobile ? "left" : "center")};

    color: var(--text-primary);
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
`;

const InfoSection = styled(Content)`
    max-width: 560px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: ${({ $isMobile }) => ($isMobile ? "left" : "center")};
    gap: 12px;
`;

const InfoSectionDescription = styled.div`
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 8px;

    & > div {
        position: relative;
        color: var(--text-primary);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        padding-left: 20px;
        box-sizing: border-box;

        &:after {
            font-family: "Font Awesome 6 Pro";
            font-weight: 400;
            font-size: 16px;
            content: "\\f00c";
            color: var(--text-positive);
            position: absolute;
            left: 0px;
            top: 0px;
            pointer-events: none;
        }
    }
`;

const InfoSectionMessage = styled.div`
    color: var(--text-primary);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: ${({ $isMobile }) => ($isMobile ? "left" : "center")};
`;

const ActionWrapper = styled.div`
    width: 100%;
    max-width: 340px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
`;
