import React from 'react';
import { Provider } from "react-redux";

import store from "../lib/state/store";
import componentMount from './componentMount';
import Popup from './Popup';
import ModalSignin from "@pcloud/web-utilities/dist/authentication/components/UserAuthentication2/ModalSignin";

const ModalSigninComponent = componentMount(props => (
  <Provider store={store}><ModalSignin {...props} /></Provider>
));

export default function authModal(view, onSuccess, props, modalOptions) {
  if (!onSuccess) {
    throw Error("onSuccess is required.");
  }

  props = props || {};
  modalOptions = modalOptions || {};

  if (!('onForgotPassword' in props)) {
    throw Error("onForgotPassword is required.");
  }

  var cont = $('<div class="g-modal-body"></div>');
  var unmount = false;

  props.onSuccess = token => {
    Popup.close();
    onSuccess(token);
  };

  if (view) {
    props.initialView = view;
  }

  Popup.open(cont, {
    onDomReady: function(wrapper) {
      unmount = ModalSigninComponent(wrapper.find(".g-modal-body")[0], props);

      if (modalOptions.onReady) {
        modalOptions.onReady();
      }
    },
    closeCallback: function() {
      unmount && unmount();

      if (modalOptions.onClose) {
        modalOptions.onClose();
      }
    },
    hasClose: true,
    hasTitle: true,
    className: "auth-modal oval-modal"
  });
}
