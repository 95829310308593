// @flow

import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";

import Modal, { OvalModalWrap, CloseModalIcon } from "../../Modal";
import Button from "../../ButtonDefault";
import InputText from "../../InputText";
import * as Style from "../../Modals/styledComponents";
import InputStyledRadio from "../../InputStyledRadio";
import InputSelect from "../../InputSelect";

import FileIcon from "../../FileIcon";
import { ICONS } from "../../../lib/icons";
import ArrowGrey from "../../../../root/img/svg/arrow-grey.svg";

import apiMethod from "../../../api/apiMethod";
import { __ } from "../../../lib/translate";
import { errorKeys } from "../../../lib/errors";
import useWindowDimensions from "../../../lib/hooks/useWindowDimensions";

type Props = {
    data: Array<any>,
    items: Array<any>
};

const AddToPlaylistModal = ({ data, items }: Props) => {
    const [isOpened, setIsOpened] = useState(true);
    const [loading, setLoading] = useState(false);
    const [shouldCreatePlaylist, setShouldCreatePlaylist] = useState(
        PLAYLIST_SELECTED
    );
    const [newPlaylistName, setPlaylistName] = useState("");

    const [playlistIsToName, setPlaylistToName] = useState([]);
    const [playlistSelected, setPlaylistSelected] = useState(-1);

    const inputTextRef = useRef(null);
    const { bodyWidth } = useWindowDimensions();

    const PLAYLIST_SELECTED = 0;
    const PLAYLIST_NEW = 1;

    useEffect(() => {
        if (!playlistIsToName.length) {
            setShouldCreatePlaylist(PLAYLIST_NEW);
        } else {
            setShouldCreatePlaylist(PLAYLIST_SELECTED);
        }
    }, [playlistIsToName, playlistSelected]); //[playlistIsToName, playlistSelected]

    useEffect(() => {
        apiMethod("collection_list", {}, (ret) => {
            const col = ret.collections;
            console.log("playlist to name col", col);

            let playlistIsToNameH = col.filter((item) => {
                console.log("inside playlist", item);

                return !item.system;
            });

            setPlaylistToName(playlistIsToNameH);
            playlistIsToNameH.length &&
                setPlaylistSelected(playlistIsToNameH[0].id);
        });
    }, []); //[]

    const onCloseClick = () => {
        if (loading) {
            return;
        }
        setIsOpened(false);
    };

    const onInputChange = (e) => {
        console.log("e.target.id", e.target.id);
        setShouldCreatePlaylist(parseInt(e.target.id));

        setPlaylistName("");
    };

    const onAddToPlaylistClick = () => {
        if (loading) {
            return;
        }
        setLoading(true);

        let linkitems = function(collectionid) {
            let params = {
                    collectionid: collectionid,
                    fileids: ""
                },
                fids = [];

            for (let p in items) {
                fids.push(items[p].fileid);
            }
            params.fileids = fids.join(",");

            apiMethod(
                "collection_linkfiles",
                params,
                () => {
                    HFN.refreshPlaylist(collectionid);
                    HFN.message(__("files added"));
                    setIsOpened(false);
                },
                {
                    errorCallback: ({ result, error }) => {
                        if (errorKeys[result]) {
                            HFN.message(__(errorKeys[result]), "error");
                        } else {
                            HFN.message(
                                __(
                                    "something_went_wrong_refresh_and_try_again"
                                ),
                                "error"
                            );
                            setLoading(false);
                            throw new Error(error);
                        }
                        setLoading(false);
                    }
                },
                { type: "POST" }
            );
        };

        if (shouldCreatePlaylist == PLAYLIST_NEW) {
            apiMethod(
                "collection_create",
                { name: newPlaylistName },
                (ret) => {
                    setTimeout(function () {
                        linkitems(ret.collection.id);
                    }, 1000);
                },
                {
                    errorCallback: ({ result, error }) => {
                        if (errorKeys[result]) {
                            HFN.message(__(errorKeys[result]), "error");
                        } else {
                            HFN.message(
                                __(
                                    "something_went_wrong_refresh_and_try_again"
                                ),
                                "error"
                            );
                            setLoading(false);
                            throw new Error(error);
                        }
                        setLoading(false);
                    }
                }
            );
        } else {
            linkitems(playlistSelected);
        }
    };

    const onNameChange = ({ target }) => {
        setPlaylistName(target.value);

        setShouldCreatePlaylist(PLAYLIST_NEW);
    };

    type selectMap = {
        value: number | string,
        text: string,
        disabled?: boolean
    };

    const getPlaylistOptionsArray = (
        playlistIsToName,
        optionRowMap: selectMap
    ) => {
        let result = [];
        console.log("inside getplay", playlistIsToName);

        optionRowMap && result.push(optionRowMap);
        for (let index in playlistIsToName) {
            result.push({
                text: playlistIsToName[index].name,
                value: playlistIsToName[index].id
            });
        }
        return result;
    };

    const renderSongItem = (data, index) => {
        const { name, icon } = data;

        return (
            <Style.Row key={index}>
                <FileIcon item={icon} type={ICONS.SVG_LIST} />
                <Text>{HFN.strFit(name, 40)}</Text>
            </Style.Row>
        );
    };

    const renderContent = () => {
        return (
            <React.Fragment>
                <Body>
                    <Header>{__("Add to Playlist")}</Header>
                    <Style.Container className={"add-to-playlist"} style={{ paddingBottom: "10px" }}>
                        <Style.Label>{__("Songs")}</Style.Label>
                        <ItemsList>{data.map(renderSongItem)}</ItemsList>
                    </Style.Container>
                    <Style.Container className={"add-to-playlist"} style={{ padding: "5px 30px 0px 30px" }}>
                        <Style.Label>{__("Choose Playlist")}</Style.Label>
                        <BoxPlaylist
                            id={PLAYLIST_SELECTED}
                            className="first"
                            selected={!shouldCreatePlaylist}
                        >
                            <Style.WrapperElements>
                                <InputStyledRadio
                                    size="small"
                                    id="0"
                                    name="chooseplaylist"
                                    checked={!shouldCreatePlaylist}
                                    onChange={(e: any) => {
                                        onInputChange(e);
                                    }}
                                    disabled={!playlistIsToName.length}
                                />
                            </Style.WrapperElements>
                            {playlistIsToName.length ? (
                                <SelectBox className="selectBox">
                                    <InputSelect
                                        style={{ paddingRight: "35px" }}
                                        name="playlist"
                                        placeholder={__("My playlist")}
                                        disabled={shouldCreatePlaylist}
                                        options={getPlaylistOptionsArray(
                                            playlistIsToName
                                        )}
                                        value={playlistSelected}
                                        onChange={(e) =>
                                            setPlaylistSelected(e.target.value)
                                        }
                                    />
                                </SelectBox>
                            ) : (
                                <DisabledText>
                                    {__("No Playlists")}
                                </DisabledText>
                            )}
                        </BoxPlaylist>
                        <BoxPlaylist
                            id={PLAYLIST_NEW}
                            className="last"
                            selected={!shouldCreatePlaylist}
                        >
                            <Style.WrapperElements>
                                <InputStyledRadio
                                    style={{ paddingLeft: "27px" }}
                                    size="small"
                                    id="1"
                                    name="createplaylist"
                                    checked={shouldCreatePlaylist}
                                    onChange={(e: any) => {
                                        onInputChange(e);
                                    }}
                                />
                            </Style.WrapperElements>
                            <SelectBox className="selectBox">
                                <InputText
                                    innerRef={inputTextRef}
                                    name="newPlaylistName"
                                    placeholder={__("Name")}
                                    onChange={onNameChange}
                                    onFocus={() => {
                                        setShouldCreatePlaylist(PLAYLIST_NEW);
                                    }}
                                    value={newPlaylistName}
                                />
                            </SelectBox>
                        </BoxPlaylist>
                    </Style.Container>
                </Body>
                <Style.Footer>
                    <Button
                        style={{ marginRight: "5px" }}
                        color="lightgray4"
                        disabled={loading}
                        onClick={onCloseClick}
                    >
                        {__("Cancel")}
                    </Button>
                    <Button
                        style={{ marginLeft: "5px" }}
                        color="cyan"
                        disabled={loading}
                        loading={loading}
                        onClick={onAddToPlaylistClick}
                    >
                        {__("Add to Playlist")}
                    </Button>
                </Style.Footer>
            </React.Fragment>
        );
    };

    const modalStyle = {};
    if (bodyWidth < 600) {
        modalStyle.width = '95vw';
    }

    return (
        <Modal align="top" opened={isOpened} onClose={onCloseClick}>
            <OvalModalWrap
                style={modalStyle}
            >
                <CloseModalIcon onClick={onCloseClick} />
                <Style.Content>{renderContent()}</Style.Content>
            </OvalModalWrap>
        </Modal>
    );
};

export default AddToPlaylistModal;

const Text = styled.div`
    max-width: 330px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

const Body = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
`;

const Header = styled(Style.Header)`
    margin-bottom: 0;
`;

const ItemsList = styled(Style.Box)`
    line-height: 24px;
    padding: 10px;
    font-size: 15px;
    font-weight: normal;
    text-align: left;
    padding: 8px;
    justify-content: flex-start;
    flex-direction: column;
    max-height: 115px;
    overflow: auto;
    overflow-x: hidden;

    & > div:only-child {
        padding-bottom: 0px;
    }

    /* width */
    &::-webkit-scrollbar {
        width: 5px;
        border-radius: 3px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #eaeaea;
        border-radius: 3px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #aaa;
        border-radius: 3px;
        transition: background 200ms ease-in-out;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`;

const BoxPlaylist = styled.label`
    display: flex;
    font-size: 15px;
    font-weight: normal;
    cursor: pointer;
    padding-bottom: 10px;
`;

const SelectBox = styled.div`
    position: relative;
    display: flex;
    flex: 1;
`;

const DisabledText = styled.span`
    color: #ddd;
`;
