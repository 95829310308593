// @flow

import React, { PureComponent } from "react";
import { findDOMNode } from "react-dom";
import { connect } from "react-redux";
import styled from "styled-components";
import { hiDPI } from "polished";
import $ from "jquery";
import { Tooltip as ReactTooltip } from "react-tooltip";

import InputDiv from "./InputDiv";
import BrandingLogo from "./BrandingLogo";
import BrandingCover from "./BrandingCover";
import ActionModal from "./ActionModal";
import { CloseButton, Loader } from "../TwoFactorAuth/SharedComponents";
import Button from "../../components/ButtonDefault";

import type { BrandingConf, PubLink } from "../../types/publiclinks";
import { IMAGE_TYPE } from "@pcloud/web-utilities/dist/config/constants";
import apiMethod from "../../api/apiMethod";
import { __ } from "../../lib/translate";
import { calcRelativeOffset } from "../../lib/utils";

type Props = {
  token: string,
  brandingType: "custom" | "default",
  isActiveCL: boolean,
  isActiveGL: boolean,
  isPremium: boolean,
  isBusiness: boolean,
  link?: PubLink,
  onClose: () => void,
  updateGeneralBrandingStatus: () => void,
  updateCustomBrandingStatus: string => void
};

type State = {
  isLoadingSave: boolean,
  isLoadingCustomBranding: boolean,
  isLoadingGeneralBranding: boolean,
  hasDefaultBranding: boolean,
  hasCustomBranding: boolean,
  title: string,
  description: string,
  logoSrc: string,
  coverPhotoSrc: string,
  coverOffsetY: number,
  logoFile: any,
  coverFile: any,
  logoFileForDefault: any,
  coverFileForDefault: any,
  showPreview: boolean,
  repositionStarted: boolean,
  canSave: boolean,
  backgroundColor: string | Array<string>,
  applyDefaultBrandingModal: boolean,
  clearDefaultBrandingModal: boolean,
  clearCustomBrandingModal: boolean,
  exitBeforeSaveBrandingModal: boolean,
  clearWarningBrandingModal: boolean
};

const IMAGE_TYPE_TO_FIELD_NAME = {};
IMAGE_TYPE_TO_FIELD_NAME[IMAGE_TYPE.LOGO] = "logo";
IMAGE_TYPE_TO_FIELD_NAME[IMAGE_TYPE.COVER] = "cover";

const pixelRatio = window.devicePixelRatio;

const getLogoSrc = (data: BrandingConf): string =>
  (pixelRatio >= 1.5 ? data.logoSrc2x : data.logoSrc1x) || "";
const getCoverPhotoSrc = (data: BrandingConf): string =>
  (pixelRatio >= 1.5 ? data.coverImageBig : data.coverImageSmall) || "";

class BrandingSetupHeader extends PureComponent<Props, State> {
  static defaultProps = {
    token: "",
    brandingType: "",
    isActiveCL: false,
    isActiveGL: false,
    isPremium: false,
    isBusiness: false,
    onClose: () => {},
    updateGeneralBrandingStatus: () => {},
    updateCustomBrandingStatus: () => {}
  };

  constructor(props: Props) {
    super(props);

    (this: any).onPreviewClick = (this: any).onPreviewClick.bind(this);
    (this: any).onResetClick = (this: any).onResetClick.bind(this);
    (this: any).onClearClick = (this: any).onClearClick.bind(this);
    (this: any).onSaveClick = (this: any).onSaveClick.bind(this);
    (this: any).onCloseClick = (this: any).onCloseClick.bind(this);
    (this: any).onImageUpload = (this: any).onImageUpload.bind(this);
    (this: any).onImageRemove = (this: any).onImageRemove.bind(this);
    (this: any).onColorSelected = (this: any).onColorSelected.bind(this);
    (this: any).onRepositionCoverClick = (this: any).onRepositionCoverClick.bind(
      this
    );
    (this: any).onDoneCoverReposition = (this: any).onDoneCoverReposition.bind(
      this
    );
    (this: any).getCoverOffset = (this: any).getCoverOffset.bind(this);

    (this: any).onTitleChange = (this: any).onTitleChange.bind(this);
    (this: any).onDescriptionChange = (this: any).onDescriptionChange.bind(
      this
    );
    (this: any).closeActionModal = (this: any).closeActionModal.bind(this);
    (this: any).getInitialCustomData = (this: any).getInitialCustomData.bind(
      this
    );

    const { isActiveCL = false, isActiveGL = false } = props;

    this.state = {
      isLoadingSave: false,
      isLoadingCustomBranding: false,
      isLoadingGeneralBranding: false,
      hasDefaultBranding: isActiveGL,
      hasCustomBranding: isActiveCL,
      title: "",
      description: "",
      logoSrc: "",
      coverPhotoSrc: "",
      coverOffsetY: 0,
      logoFile: null,
      coverFile: null,
      logoFileForDefault: null,
      coverFileForDefault: null,
      showPreview: false,
      repositionStarted: false,
      canSave: false,
      backgroundColor: "",
      applyDefaultBrandingModal: false,
      clearDefaultBrandingModal: false,
      clearCustomBrandingModal: false,
      exitBeforeSaveBrandingModal: false,
      clearWarningBrandingModal: false
    };

    (this: any).emptyData = {
      title: "",
      description: "",
      coverOffsetY: 0,
      backgroundColor: "",
      logoSrc: "",
      coverPhotoSrc: ""
    };
    (this: any).initialDefaultData = Object.assign({}, (this: any).emptyData);
    (this: any).initialCustomData = Object.assign({}, (this: any).emptyData);
    (this: any).previewImage = null;
    (this: any).fileInput = null;

    (this: any).contentWrapper = React.createRef();
  }

  componentDidMount() {
    const { brandingType } = this.props;
    const { hasCustomBranding } = this.state;

    this.fetchGeneralLinkBranding();
    if (brandingType === "custom" && hasCustomBranding) {
      this.fetchCustomLinkBranding();
    }
  }

  fetchGeneralLinkBranding() {
    const { brandingType } = this.props;
    const { hasCustomBranding, hasDefaultBranding } = this.state;

    this.setState({ isLoadingGeneralBranding: true });

    apiMethod(
      "getdefaultpublinkbranding",
      { auth: HFN.config.auth },
      ({ branding, isactive }) => {
        console.log("GeneralData:", { branding });
        const { header, description } = branding;

        // We have different structures coming for default branding and this one for custom branding
        // Should be fixed
        const imageData: any = {
          logoSrc1x: branding.logo1x ? HFN.prepUrl(branding.logo1x) : "",
          logoSrc2x: branding.logo2x ? HFN.prepUrl(branding.logo2x) : "",
          coverImageSmall: branding.coversmall
            ? HFN.prepUrl(branding.coversmall)
            : "",
          coverImageBig: branding.coverbig ? HFN.prepUrl(branding.coverbig) : ""
        };
        const background = branding.covergradient
          ? branding.covergradient.split(",")
          : branding.covercolor;

        const offsetY = branding.coveroffsety
          ? calcRelativeOffset(
              (this: any).contentWrapper.offsetWidth,
              branding.coverwidth,
              branding.coveroffsety
            )
          : 0;

        (this: any).initialDefaultData = {
          title: header || "",
          description: description || "",
          coverOffsetY: offsetY,
          backgroundColor: background || "",
          logoSrc: getLogoSrc(imageData),
          coverPhotoSrc: getCoverPhotoSrc(imageData)
        };

        if (
          (brandingType === "default" || !hasCustomBranding) &&
          hasDefaultBranding
        ) {
          this.setState({
            title: header || "",
            description: description || "",
            coverOffsetY: offsetY,
            backgroundColor: background || "",
            logoSrc: getLogoSrc(imageData),
            coverPhotoSrc: getCoverPhotoSrc(imageData),
            isLoadingGeneralBranding: false,
            hasDefaultBranding: isactive
          });
        } else {
          this.setState({ isLoadingGeneralBranding: false });
        }
      },
      {
        errorCallback: ({ error }) => {
          this.setState({ isLoadingGeneralBranding: false });
          console.log("Error:", error);
          HFN.message(
            __("something_went_wrong_refresh_and_try_again"),
            "error"
          );
          throw new Error(error);
        }
      }
    );
  }

  fetchCustomLinkBranding() {
    if (!this.props.link) {
      console.error("link prop is missing");
      return;
    }
    const linkId = this.props.link.linkid;

    this.setState({ isLoadingCustomBranding: true });

    apiMethod(
      "getpublinkbranding",
      { auth: HFN.config.auth, linkid: linkId },
      ({ branding }) => {
        console.log("CustomData:", { branding });
        const { header, description } = branding;

        // We have different structures coming for default branding and this one for custom branding
        // Should be fixed
        const imageData: any = {
          logoSrc1x: branding.logo1x ? HFN.prepUrl(branding.logo1x) : "",
          logoSrc2x: branding.logo2x ? HFN.prepUrl(branding.logo2x) : "",
          coverImageSmall: branding.coversmall
            ? HFN.prepUrl(branding.coversmall)
            : "",
          coverImageBig: branding.coverbig ? HFN.prepUrl(branding.coverbig) : ""
        };

        const background = branding.covergradient
          ? branding.covergradient.split(",")
          : branding.covercolor;

        const offsetY = branding.coveroffsety
          ? calcRelativeOffset(
              (this: any).contentWrapper.offsetWidth,
              branding.coverwidth,
              branding.coveroffsety
            )
          : 0;

        (this: any).initialCustomData = {
          title: header || "",
          description: description || "",
          coverOffsetY: offsetY,
          backgroundColor: background,
          logoSrc: getLogoSrc(imageData),
          coverPhotoSrc: getCoverPhotoSrc(imageData)
        };

        this.setState({
          title: header || "",
          description: description || "",
          coverOffsetY: offsetY,
          backgroundColor: background,
          logoSrc: getLogoSrc(imageData),
          coverPhotoSrc: getCoverPhotoSrc(imageData),
          isLoadingCustomBranding: false
        });
      },
      {
        errorCallback: ({ error }) => {
          this.setState({ isLoadingCustomBranding: false });
          console.log("Error:", error);
          HFN.message(
            __("something_went_wrong_refresh_and_try_again"),
            "error"
          );
          throw new Error(error);
        }
      }
    );
  }

  clearInitialData(type: "default" | "custom") {
    if (type === "default") {
      (this: any).initialDefaultData = Object.assign({}, (this: any).emptyData);
    } else {
      (this: any).initialCustomData = Object.assign({}, (this: any).emptyData);
    }
  }

  setBranding(params, action) {
    const {
      brandingType,
      updateGeneralBrandingStatus,
      updateCustomBrandingStatus
    } = this.props;

    const { logoFile, coverFile } = this.state;
    const method =
      brandingType === "default" || action === "accept"
        ? "setdefaultpublinkbranding"
        : "setcustompublinkbranding";
    const data = new FormData();

    if (logoFile) {
      data.append(IMAGE_TYPE_TO_FIELD_NAME[IMAGE_TYPE.LOGO], logoFile);
    }

    if (coverFile) {
      data.append(IMAGE_TYPE_TO_FIELD_NAME[IMAGE_TYPE.COVER], coverFile);
    }

    console.log("setBranding PARAMS>>>", { method, params, data });

    const xhr = $.ajax({
      url: HFN.apiMethodUrl(null, method, params),
      type: "POST",
      data: logoFile || coverFile ? data : null,
      processData: false,
      contentType: false,
      success: ret => {
        if (ret.result === 0) {
          const {
            title,
            description,
            coverOffsetY,
            backgroundColor,
            logoSrc,
            coverPhotoSrc
          } = this.state;

          if (brandingType === "default") {
            this.setState({
              isLoadingSave: false,
              canSave: false,
              hasDefaultBranding: true
            });

            (this: any).initialDefaultData = {
              title,
              description,
              coverOffsetY,
              backgroundColor,
              logoSrc,
              coverPhotoSrc
            };
            updateGeneralBrandingStatus(true);
          } else if (brandingType === "custom") {
            this.setState({
              isLoadingSave: false,
              canSave: false,
              hasCustomBranding: true
            });

            (this: any).initialCustomData = {
              title,
              description,
              coverOffsetY,
              backgroundColor,
              logoSrc,
              coverPhotoSrc
            };
            updateCustomBrandingStatus({ linkid: params.linkid, custombranding: true });
          }

          HFN.message(__("branding_settings_saved"));
        }
      },
      error: ret => {
        this.setState({ isLoadingSave: false });
        console.log("ERROR", ret);
      },
      xhr: () => {
        const xhr = new window.XMLHttpRequest();
        if (xhr.upload) {
          xhr.upload.onprogress = e => {
            console.log("progress", e);
          };
        }
        return xhr;
      }
    });
  }

  disableBranding(type: "default" | "custom", callback = () => {}) {
    const {
      token,
      updateGeneralBrandingStatus,
      updateCustomBrandingStatus
    } = this.props;
    const { clearDefaultBrandingModal, clearCustomBrandingModal } = this.state;
    const params: Object = { auth: token };
    let method = "";

    if (clearDefaultBrandingModal || clearCustomBrandingModal) {
      this.setState({
        clearDefaultBrandingModal: false,
        clearCustomBrandingModal: false
      });
    }

    if (type === "default") {
      method = "disabledefaultpublinkbranding";
    } else if (type === "custom") {
      if (!this.props.link) {
        console.error("link prop is missing");
        return;
      }
      params.linkid = this.props.link.linkid;
      method = "removepublinkbranding";
    }
    console.log("disableBranding params >>>", { params, method, type });

    apiMethod(
      method,
      params,
      ret => {
        callback();
        HFN.message(__("branding_settings_saved"));

        this.clearInitialData(type);
        this.setState({ isLoadingSave: false, canSave: false });

        if (type === "default") {
          updateGeneralBrandingStatus(false);
        } else if (type === "custom") {
          updateCustomBrandingStatus({ linkid: params.linkid, custombranding: false });
        }
      },
      {
        errorCallback: ({ error }) => {
          callback();
          console.log("Error:", error);
          this.setState({ isLoadingSave: false, canSave: false });
          HFN.message(
            __("something_went_wrong_refresh_and_try_again"),
            "error"
          );
          throw new Error(error);
        }
      }
    );
  }

  applyCustomForDefault() {
    const {
      token,
      brandingType,
      isPremium,
      updateGeneralBrandingStatus,
      updateCustomBrandingStatus
    } = this.props;

    const {
      title,
      description,
      logoSrc,
      coverPhotoSrc,
      backgroundColor,
      logoFileForDefault,
      coverFileForDefault,
      coverOffsetY,
      hasCustomBranding,
      hasDefaultBranding
    } = this.state;
    const params = { auth: token };
    const data = new FormData();

    if (logoFileForDefault) {
      data.append(
        IMAGE_TYPE_TO_FIELD_NAME[IMAGE_TYPE.LOGO],
        logoFileForDefault
      );
    }

    if (coverFileForDefault) {
      data.append(
        IMAGE_TYPE_TO_FIELD_NAME[IMAGE_TYPE.COVER],
        coverFileForDefault
      );
    }

    if (title) {
      params.header = title;
    }

    if (description) {
      params.description = description;
    }

    if (
      logoSrc &&
      !logoFileForDefault &&
      !hasCustomBranding &&
      hasDefaultBranding
    ) {
      params.usedefaultlogo = true;
    }

    if (
      coverPhotoSrc &&
      !coverFileForDefault &&
      !hasCustomBranding &&
      hasDefaultBranding
    ) {
      params.usedefaultcover = true;
    }

    if (backgroundColor) {
      if (Array.isArray(backgroundColor)) {
        params.covergradient = backgroundColor.join(",");
      } else {
        params.covercolor = backgroundColor;
      }
      params.deletecover = true;
    }

    if (coverOffsetY) {
      params.coveroffsety = Math.abs(coverOffsetY);
    }

    const xhr = $.ajax({
      url: HFN.apiMethodUrl(null, "setdefaultpublinkbranding", params),
      type: "POST",
      data: logoFileForDefault || logoFileForDefault ? data : null,
      processData: false,
      contentType: false,
      success: ret => {
        if (ret.result === 0) {
          console.log("Default branding saved!");
          this.disableBranding("custom", this.onCloseClick);
        }
      },
      error: ret => {
        console.log("ERROR", ret);
      },
      xhr: () => {
        const xhr = new window.XMLHttpRequest();
        if (xhr.upload) {
          xhr.upload.onprogress = e => {
            console.log("progress", e);
          };
        }
        return xhr;
      }
    });
  }

  disableClearButton() {
    const { brandingType } = this.props;
    const { showPreview, canSave, hasDefaultBranding } = this.state;

    const emptyDataJSON = JSON.stringify((this: any).emptyData);
    const isCustomDataChanged =
      JSON.stringify((this: any).initialCustomData) !==
      this.getStateBrandingData();

    const isEmptyStateData =
      JSON.stringify(this.getStateBrandingData()) === emptyDataJSON;

    const canClear =
      (brandingType === "default" && !isEmptyStateData) ||
      (brandingType === "custom" &&
        isCustomDataChanged &&
        !hasDefaultBranding &&
        !isEmptyStateData);

    return showPreview || !canClear;
  }

  disableResetButton() {
    const { brandingType } = this.props;
    const { showPreview, canSave, hasDefaultBranding } = this.state;
    const stateData = this.getStateBrandingData();
    const isBrandigsDataEqual =
      JSON.stringify(stateData) ===
      JSON.stringify((this: any).initialDefaultData);

    const canReset = hasDefaultBranding && !isBrandigsDataEqual;

    return showPreview || !canReset;
  }

  getCoverOffset(offsetY) {
    this.setState({ coverOffsetY: offsetY });
  }

  onRepositionCoverClick() {
    this.setState({ repositionStarted: true, showPreview: true });
  }

  onDoneCoverReposition() {
    this.setState({
      repositionStarted: false,
      showPreview: false,
      canSave: true
    });
  }

  onPreviewClick() {
    this.setState(state => {
      return { showPreview: !state.showPreview };
    });
  }

  onResetClick() {
    if (!this.disableResetButton()) {
      this.getInitialDefaultData();
    }
  }

  onClearClick() {
    const { brandingType } = this.props;
    const { hasCustomBranding, hasDefaultBranding } = this.state;

    if (!this.disableClearButton()) {
      let canSave = true;

      if (!hasCustomBranding && brandingType === "custom") {
        canSave = false;
      }

      if (!hasDefaultBranding && brandingType === "default") {
        canSave = false;
      }

      this.setState({
        title: "",
        description: "",
        logoFile: null,
        coverFile: null,
        logoSrc: "",
        coverPhotoSrc: "",
        backgroundColor: "",
        coverOffsetY: 0,
        canSave,
        clearWarningBrandingModal : false
      });
    }
  }

  onSaveClick(action: "accept" | "decline") {
    const {
      logoFile,
      coverFile,
      title,
      description,
      canSave,
      logoSrc,
      coverPhotoSrc,
      coverOffsetY,
      backgroundColor,
      showPreview
    } = this.state;

    const { token, brandingType, isPremium } = this.props;
    const { hasCustomBranding, hasDefaultBranding } = this.state;

    if (canSave && !showPreview) {
      this.setState({ isLoadingSave: true });
      const params: Object = {};

      if (title) {
        params.header = title;
      }

      if (description) {
        params.description = description;
      }

      if (logoSrc && !logoFile && !hasCustomBranding && hasDefaultBranding) {
        params.usedefaultlogo = true;
      } else if (logoSrc && !logoFile) {
        params.keeplogo = true;
      }

      if (
        coverPhotoSrc &&
        !coverFile &&
        !hasCustomBranding &&
        hasDefaultBranding
      ) {
        params.usedefaultcover = true;
      } else if (coverPhotoSrc && !coverFile) {
        params.keepcover = true;
      }

      if (backgroundColor) {
        if (Array.isArray(backgroundColor)) {
          params.covergradient = backgroundColor.join(",");
        } else {
          params.covercolor = backgroundColor;
        }
        params.deletecover = true;
      }

      if (coverOffsetY && coverPhotoSrc) {
        params.coveroffsety = Math.abs(coverOffsetY);
        params.coverwidth = this.contentWrapper.offsetWidth;
      }

      console.log("onSave PARAMS>>>", JSON.stringify(params));

      if (Object.keys(params).length === 0 && !logoSrc && !coverPhotoSrc) {
        if (hasDefaultBranding && brandingType === "default") {
          this.setState({
            clearDefaultBrandingModal: true
          });
        } else if (brandingType === "custom") {
          if (hasCustomBranding) {
            this.setState({
              clearCustomBrandingModal: true
            });
          } else {
            this.setState({ isLoadingSave: false, canSave: false });
            HFN.message(__("general_branding_disable_error", "It's not possible to disable General branding for a single link."), "error");
          }
        }
      } else {
        params.auth = token;

        if (!logoSrc) {
          params.deletelogo = true;
        }

        if (!coverPhotoSrc) {
          params.deletecover = true;
        }

        if (this.props.link) {
          params.linkid = this.props.link.linkid;
        }

        const stateData = this.getStateBrandingData();
        const isBrandigsDataEqual =
          JSON.stringify(stateData) ===
          JSON.stringify((this: any).initialDefaultData);

        if (brandingType === "custom" && isBrandigsDataEqual) {
          this.disableBranding("custom");
        } else {
          this.setBranding(params, action);
        }
      }
    }
  }

  onCloseClick() {
    const { onClose, brandingType, isPremium, isBusiness } = this.props;
    const { applyDefaultBrandingModal, hasDefaultBranding, canSave } = this.state;
    const isDataEmpty =
      JSON.stringify((this: any).initialCustomData) ===
      JSON.stringify((this: any).emptyData);

    if (
      !hasDefaultBranding &&
      !applyDefaultBrandingModal &&
      brandingType === "custom" &&
      (isPremium || isBusiness) &&
      !isDataEmpty &&
      !localStorage.getItem("applyDefaultBrandingModal")
    ) {
      localStorage.setItem("applyDefaultBrandingModal", "1");
      this.setState({
        applyDefaultBrandingModal: true
      });
    } else {
      this.setState({
        applyDefaultBrandingModal: false,
        exitBeforeSaveBrandingModal: canSave
      });
      if (!canSave) {
        onClose();
      }
    }
  }

  getStateBrandingData() {
    const {
      title,
      description,
      coverOffsetY,
      backgroundColor,
      logoSrc,
      coverPhotoSrc
    } = this.state;

    return {
      title,
      description,
      coverOffsetY,
      backgroundColor,
      logoSrc,
      coverPhotoSrc
    };
  }

  getInitialCustomData() {
    const {
      title,
      description,
      coverOffsetY,
      backgroundColor,
      logoSrc,
      coverPhotoSrc
    } = (this: any).initialCustomData;

    this.setState({
      title,
      description,
      logoFile: null,
      coverFile: null,
      logoSrc,
      coverPhotoSrc,
      backgroundColor,
      coverOffsetY,
      canSave: true
    });
  }

  getInitialDefaultData() {
    const {
      title,
      description,
      coverOffsetY,
      backgroundColor,
      logoSrc,
      coverPhotoSrc
    } = (this: any).initialDefaultData;

    const isBrandigsDataEqual =
      JSON.stringify((this: any).initialCustomData) ===
      JSON.stringify((this: any).initialDefaultData);

    this.setState({
      title,
      description,
      logoFile: null,
      coverFile: null,
      logoSrc,
      coverPhotoSrc,
      backgroundColor,
      coverOffsetY,
      canSave: !isBrandigsDataEqual
    });

    this.resetCoverPhotoPosition();
  }

  resetCoverPhotoPosition() {
    const coverPhoto = document.getElementsByClassName("cover-image")[0];
    if (coverPhoto) {
      coverPhoto.style.removeProperty('top');
      coverPhoto.style.removeProperty('left');
    }
  }

  onImageUpload(type: string, file: {}, callback: () => void) {
    const reader = new FileReader();

    reader.onload = () => {
      if (type === IMAGE_TYPE.LOGO) {
        const img = new Image();
        img.onload = () => {
            this.setState({
              logoFile: file,
              logoSrc: reader.result
            });
        };
        img.src = reader.result;
      } else if (type === IMAGE_TYPE.COVER) {
        const img = new Image();
        img.onload = () => {
            this.setState({
              coverFile: file,
              coverPhotoSrc: reader.result,
              backgroundColor: "",
              coverOffsetY: 0
            });
        };

        img.src = reader.result;
      }

      if (typeof callback === "function") {
        callback();
      }
    };

    if (file) {
      this.setState({ canSave: true });
      reader.readAsDataURL(file); //reads the data as a URL
    }
  }

  onImageRemove(type: IMAGE_TYPE.LOGO | IMAGE_TYPE.COVER) {
    if (type === IMAGE_TYPE.LOGO) {
      this.setState({
        logoFile: null,
        logoSrc: "",
        canSave: true
      });
    } else if (type === IMAGE_TYPE.COVER) {
      this.setState({
        coverFile: null,
        coverPhotoSrc: "",
        canSave: true
      });
    }
  }

  onColorSelected(color: string | Array<string>) {
    if (color === "ffffff") {
      color = "";
    }
    this.setState({
      backgroundColor: color,
      coverFile: null,
      coverPhotoSrc: "",
      canSave: true
    });
  }

  onTitleChange(value: string) {
    const { brandingType } = this.props;
    let canSave = false;

    if (brandingType === "default") {
      canSave = (this: any).initialDefaultData.title !== value;
    } else if (brandingType === "custom") {
      canSave = (this: any).initialCustomData.title !== value;
    }

    this.setState({ title: value, canSave });
  }

  onDescriptionChange(value: string) {
    const { brandingType } = this.props;
    let canSave = false;

    if (brandingType === "default") {
      canSave = (this: any).initialDefaultData.description !== value;
    } else if (brandingType === "custom") {
      canSave = (this: any).initialCustomData.description !== value;
    }

    this.setState({ description: value, canSave });
  }

  closeActionModal(resetType?: string, callback: () => void = () => {}) {
    if (resetType === "default") {
      const {
        title,
        description,
        coverOffsetY,
        backgroundColor,
        logoSrc,
        coverPhotoSrc
      } = (this: any).initialDefaultData;

      this.setState(
        {
          title,
          description,
          logoFile: null,
          coverFile: null,
          logoSrc,
          coverPhotoSrc,
          backgroundColor,
          coverOffsetY,
          canSave: true,
          isLoadingSave: false,
          applyDefaultBrandingModal: false,
          clearCustomBrandingModal: false,
          clearDefaultBrandingModal: false,
          exitBeforeSaveBrandingModal: false,
          clearWarningBrandingModal: false
        },
        callback
      );
    } else if (resetType === "custom") {
      const {
        title,
        description,
        coverOffsetY,
        backgroundColor,
        logoSrc,
        coverPhotoSrc
      } = (this: any).initialCustomData;

      this.setState(
        {
          title,
          description,
          logoFile: null,
          coverFile: null,
          logoSrc,
          coverPhotoSrc,
          backgroundColor,
          coverOffsetY,
          canSave: true,
          isLoadingSave: false,
          applyDefaultBrandingModal: false,
          clearCustomBrandingModal: false,
          clearDefaultBrandingModal: false,
          exitBeforeSaveBrandingModal: false,
          clearWarningBrandingModal: false
        },
        callback
      );
    } else {
      this.setState(
        {
          isLoadingSave: false,
          applyDefaultBrandingModal: false,
          clearCustomBrandingModal: false,
          clearDefaultBrandingModal: false,
          exitBeforeSaveBrandingModal: false,
          clearWarningBrandingModal: false
        },
        callback
      );
    }
  }

  renderSetupLine() {
    const { brandingType } = this.props;
    const {
      showPreview,
      repositionStarted,
      canSave,
      isLoadingSave,
      hasDefaultBranding
    } = this.state;

    const disableClearButton = this.disableClearButton();
    const isMobile = HFN.config.isMobile();

    return (
      <Header>
        <Row>
          <Col className="flex">
            {
              <LineTitle>
                {brandingType === "default"
                  ? __("general_branding", "General Branding")
                  : __("custom_branding", "Custom Branding")}
              </LineTitle>
            }
          </Col>
          <Col className="preview">
            {!repositionStarted && (
              <Button
                color="lightgray2"
                size="medium"
                onClick={this.onPreviewClick}
              >
                {showPreview
                  ? __("branding_hide_preview", "Hide Preview")
                  : __("branding_show_preview", "Show Preview")}
              </Button>
            )}
          </Col>
          <Col className="flex save-reset">
            <ReactTooltip
                className="cv-tooltip"
                noArrow
                multiline
                offset={12}
                id="branding-save-reset"
                place="bottom"
            >
              {__("branding_custom_reset_tooltip")}
            </ReactTooltip>
            {brandingType === "custom" && hasDefaultBranding ? (
              <span>
                <Button
                  color="gray"
                  disabled={this.disableResetButton()}
                  size="medium"
                  onClick={this.onResetClick}
                  data-tooltip-id="branding-save-reset"
                >
                  {!isMobile && __("branding_reset_button")}
                  {isMobile && <i className="fa-regular fa-clock-rotate-left"></i>}
                </Button>
              </span>
            ) : (
              <Button
                color="gray"
                disabled={disableClearButton}
                size="medium"
                onClick={() => {
                  this.setState({clearWarningBrandingModal : !disableClearButton})
                }}
                data-tooltip-id="branding-save-reset"
              >
                {!isMobile && __("Clear")}
                {isMobile && <i className="fa-regular fa-clock-rotate-left"></i>}
              </Button>
            )}
            <Button
              color="cyan"
              disabled={showPreview || !canSave}
              loading={isLoadingSave}
              size="medium"
              onClick={this.onSaveClick}
            >
              {!isMobile && __("Save")}
              {isMobile && <i className="fa-regular fa-floppy-disk"></i>}
            </Button>
            <CloseButtonModal onClick={this.onCloseClick} />
          </Col>
        </Row>
      </Header>
    );
  }

  renderContent() {
    const {
      title,
      description,
      showPreview,
      repositionStarted,
      logoSrc,
      coverPhotoSrc,
      coverOffsetY,
      backgroundColor,
      isLoadingCustomBranding,
      isLoadingGeneralBranding
    } = this.state;

    if (
      showPreview &&
      !title &&
      !description &&
      !logoSrc &&
      !coverPhotoSrc &&
      !backgroundColor
    ) {
      return null;
    }

    return (
      <Wrapper ref={ref => (this.contentWrapper = ref)}>
        {!isLoadingCustomBranding && !isLoadingGeneralBranding ? (
          <React.Fragment>
            <BrandingCover
              color={backgroundColor}
              imageSrc={coverPhotoSrc}
              positionTop={coverOffsetY}
              isEditable={!showPreview}
              isRepositionStarted={repositionStarted}
              showGradinent={!!title || !!description}
              getCoverOffset={this.getCoverOffset}
              onStartReposition={this.onRepositionCoverClick}
              onDoneCoverReposition={this.onDoneCoverReposition}
              onImageUpload={this.onImageUpload}
              onImageRemove={this.onImageRemove}
              onColorSelected={this.onColorSelected}
            />
            {!repositionStarted ? (
              <ContentWrapper>
                <BrandingLogo
                  isEditable={!showPreview}
                  imageSrc={logoSrc}
                  onImageUpload={this.onImageUpload}
                  onImageRemove={this.onImageRemove}
                />
                <TextWrapper
                  hasPositionAbsolute={!logoSrc && coverPhotoSrc && showPreview}
                >
                  <InputDiv
                    style={{ fontSize: "19px", fontWeight: "bold" }}
                    name="title"
                    value={title}
                    emptyText={__(
                      "general_branding_headline_placeholder",
                      "Add a headline"
                    )}
                    maxLength="50"
                    isEditable={!showPreview}
                    changeColor={!!backgroundColor || !!coverPhotoSrc}
                    onChange={this.onTitleChange}
                  />
                  <InputDiv
                    style={{ fontSize: "17px" }}
                    name="description"
                    value={description}
                    emptyText={__(
                      "general_branding_description_placeholder",
                      "Add a description"
                    )}
                    maxLength="140"
                    isEditable={!showPreview}
                    changeColor={!!backgroundColor || !!coverPhotoSrc}
                    onChange={this.onDescriptionChange}
                  />
                </TextWrapper>
              </ContentWrapper>
            ) : null}
          </React.Fragment>
        ) : (
          <Loader />
        )}
      </Wrapper>
    );
  }

  render() {
    const { onClose } = this.props;
    const {
      exitBeforeSaveBrandingModal,
      clearWarningBrandingModal,
      applyDefaultBrandingModal,
      clearDefaultBrandingModal,
      clearCustomBrandingModal
    } = this.state;

    let actionModalText = "";
    let actionButtonOption1 = { text: "", onClick: () => {} };
    let actionButtonOption2 = { text: "", onClick: () => {} };
    let showTopImage = true;

    if (exitBeforeSaveBrandingModal) {
      actionModalText = (
          <div>
            <h2>{__("exit_before_save_branding_modal_title", "Discard changes")}</h2>
            <div>{__("exit_before_save_branding_modal_text", "You will lose all current changes to your branding if you proceed.")}</div>
          </div>
      );
      actionButtonOption1 = {
        text: __("Cancel"),
        onClick: () => {
          this.setState({exitBeforeSaveBrandingModal : false});
        }
      };
      actionButtonOption2 = {
        text: __("Discard"),
        onClick: () => this.closeActionModal("", onClose)
      };
      showTopImage = false;
    } else if (clearWarningBrandingModal) {
      actionModalText = (
          <div>
            <h2>{__("clear_warning_branding_modal_title", "Clear changes")}</h2>
            <div>{__("clear_warning_branding_modal_text", "You will undo all changes to your branding.")}</div>
          </div>
      );
      actionButtonOption1 = {
        text: __("Cancel"),
        onClick: () => {
          this.setState({clearWarningBrandingModal : false});
        }
      };
      actionButtonOption2 = {
        text: __("Clear changes"),
        onClick: this.onClearClick
      };
      showTopImage = false;
    } else if (applyDefaultBrandingModal) {
      actionModalText = __("branding_apply_general_message");
      actionButtonOption1 = {
        text: __("No"),
        onClick: () => this.closeActionModal("", onClose)
      };
      actionButtonOption2 = {
        text: __("Yes"),
        onClick: () => this.applyCustomForDefault()
      };
    } else if (clearDefaultBrandingModal) {
      actionModalText = (
        <div>
          <div>{__("disable_branding_message")}</div>
          <div>{__("Do you want to continue?")}</div>
        </div>
      );
      actionButtonOption1 = {
        text: __("No"),
        onClick: () => this.closeActionModal("default")
      };
      actionButtonOption2 = {
        text: __("Yes"),
        onClick: () => this.disableBranding("default")
      };
    } else if (clearCustomBrandingModal) {
      actionModalText = (
        <div>
          <div>{__("disable_custom_branding_message2")}</div>
          <div>{__("Do you want to continue?")}</div>
        </div>
      );
      actionButtonOption1 = {
        text: __("No"),
        onClick: () => this.closeActionModal("custom")
      };
      actionButtonOption2 = {
        text: __("Yes"),
        onClick: () => this.disableBranding("custom")
      };
    }

    return (
      <React.Fragment>
        <HeaderWrapper />
        {this.renderSetupLine()}
        {this.renderContent()}
        <ActionModal
          opened={
            applyDefaultBrandingModal ||
            clearDefaultBrandingModal ||
            clearCustomBrandingModal ||
            exitBeforeSaveBrandingModal ||
            clearWarningBrandingModal
          }
          showTopImage={showTopImage}
          text={actionModalText}
          actionButtonOption1={actionButtonOption1}
          actionButtonOption2={actionButtonOption2}
        />
      </React.Fragment>
    );
  }
}

export default connect(
  ({ pCloudUser: { token, userinfo: { premium, business } } = {} }) => {
    return { token, isPremium: premium, isBusiness: business };
  }
)(BrandingSetupHeader);

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top: 1px solid #eaeaea;
  border-bottom: 0;
  width: 100%;
  height: 300px;
  box-sizing: border-box;
  background-repeat: repeat;
  background-image: url(${require("../../../root/img/branding-settings/defeult-bg.png")});
  overflow: hidden;
`;

// Header
const HeaderWrapper = styled.div`
  width: 100%;
  height: 60px;
`;

const Header = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  box-sizing: border-box;
  overflow: hidden;
`;

const Row = styled.div`
  width: 1180px;
  margin: auto;
  height: 60px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    margin: auto;
    width: auto;
    min-width: 70px;
  }

  @media only screen and (max-width: 1240px) {
    width: inherit;
    min-width: 100%;
  }

  @media only screen and (max-width: 600px) {
    & > div:first-child {
      display: none;
    }
   }
`;

const Col = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;

  &.flex {
    flex: 1;
    width: 100%;
  }

  & i {
    color: #fff;
  }

  &.preview {
    margin: 0 10px;

    @media only screen and (max-width: 600px) {
      margin: 0;
    }
    
    a {
      @media only screen and (max-width: 600px) {
        padding: 0 10px;
      }
    }
  }

  &.save-reset {
    justify-content: flex-end;

    a {
      margin: 0 0 0 10px;
      @media only screen and (max-width: 600px) {
        padding: 0 10px;
        min-width: auto;
      }
    }
  }
`;

const LineTitle = styled.div`
  font-size: 15px;
  font-weight: bold;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

// Logo, Title and Descripton
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 100;
`;
const TextWrapper = styled.div`
  width: 100%;
  position: ${({ hasPositionAbsolute }) =>
    hasPositionAbsolute ? "absolute" : "relative"};
  bottom: ${({ hasPositionAbsolute }) => (hasPositionAbsolute ? "28px" : "0")};
`;
// Cover photo
const CoverPhoto = styled.div`
  position: absolute;
  display: none;
  width: 100%;
  height: 300px;
  background-repeat: repeat;
  background-image: url(${require("../../../root/img/branding-settings/defeult-bg.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/branding-settings/defeult-bg@2x.png")});
  }
`;

const CloseButtonModal = styled(CloseButton)`
  position: absolute;
  top: 17px;
  margin: 0;
  margin-right: 15px;

  @media only screen and (max-width: 1315px) {
    position: relative;
    margin: 0;
    margin-left: 15px;
    top: 0px;
  }
`;
