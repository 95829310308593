import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { setGridHeaderHeight } from "../../../../lib/state/reducers/contentSlice";
import { useDispatch } from "react-redux";
import Alert from "../../../Alert";

const CryptoTmpPassword = () => {
  const contentRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!contentRef.current) {
      return;
    }
    const parent = contentRef.current;

    const handleDynamicWidth = () => {
      const height = parent.offsetHeight + 10;

      if (CryptoTmpPasswordHeight !== height) {
        CryptoTmpPasswordHeight = height;
        dispatch(setGridHeaderHeight(height));
      }
    };

    handleDynamicWidth();
    const observer = new ResizeObserver(handleDynamicWidth);
    observer.observe(parent);

    return () => {
      observer.unobserve(parent);
    };
  }, [contentRef.current]);

  return (
    <Wrapper ref={contentRef}>
      <Alert
        theme={"warning"}
        multiline={true}
        hasIcon={true}
        hasCloseButton={true}
        onClose={function() {
          HFN.config.showTempCryptoPassMessage = false;
        }}
        componentifyText={__("crypto_change_crypto_pass_alert")}
      />
    </Wrapper>
  );
};

export default CryptoTmpPassword;

export let CryptoTmpPasswordHeight = 100;

const Wrapper = styled.div``;
