// @flow

import pCloudUser from "@pcloud/web-utilities/dist/lib/state/reducers/pcloud/userSlice";
import pCloudNotifications from "@pcloud/web-utilities/dist/lib/state/reducers/pcloud/notificationSlice";
import audioPlayer from "./audioPlayerSlice";
import onboarding from "./onboardingSlice";
import publiclink from "./publiclinkSlice";
import bookmarks from "./bookmarksSlice";
import galleryPreview from "./galleryPreviewSlice";
import fileRequests from "./fileRequestsSlice";
import sharedFolders from "./sharedFoldersSlice";
import sharedLinks from "./sharedLinksSlice";
import business from "./businessSlice";
import content from "./contentSlice";

export default {
  pCloudUser,
  pCloudNotifications,
  audioPlayer,
  onboarding,
  publiclink,
  bookmarks,
  galleryPreview,
  fileRequests,
  sharedLinks,
  sharedFolders,
  business,
  content,
};
