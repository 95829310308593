import React from "react";
import styled from "styled-components";

import Button from "./ButtonDefault";
import InputCheckbox from "../components/InputCheckbox";
import { TSpan } from "./intl";
import { __ } from "../lib/translate";
import { setcookie, rcookie } from "../lib/utils";

import Portal from "../components/Portal";
import Modal, {
	ModalTop,
	ModalBody,
	ModalButtonArea,
	ModalWrap,
	OvalModalWrap,
	OvalModalClose,
	OvalModalBody
} from "./Modal";


var styles = {
	buttonMargin: {
		margin: "15px"
	},
	buttonModalMinWidth: {
		minWidth: "125px"
	},
	vatNumberWrapper: {
		fontSize: "15px",
		marginTop: "20px",
		color: "#8b8b8b"
	}
};


class CookieConsentModal extends React.Component<{ children: React.Element<any> }, { opened: boolean }> {
	render() {
		const { hasGa, hasFbq, opened, close, onChangeCookieGa, onChangeCookieFb, updateConsentInfo /*, isCheckedGa, isCheckedFb*/ } = this.props;
		//console.log('==========', isCheckedGa, isCheckedFb);

		return (
			<Wrapper className="cookie-consent-modal-wrapper">
				<form onSubmit={this.updateInvoiceInfo}>
					<Modal align="top" opened={opened} onClose={close}>
						<ModalTop onClose={close}>
							<TSpan id="cookie_preference_center">Cookie Preference Center</TSpan>
						</ModalTop>
						<ModalBody style={{ minWidth: "280px" }}>
							<Section>
								<SectionTitle>{__("necessary_cookies","Necessary Cookies")}</SectionTitle>
								<SectionContent>{__("necessary_cookies_example", "These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site may not work then.")}</SectionContent>
							</Section>
							<Section>
								<SectionTitle>
									<InputCheckbox
										name={"ga-cookies"}
										onChange={onChangeCookieGa}
										checked={hasGa}
										color="lightGreen"
									/>
									<Title>{__("performance_cookies","Performance Cookies")}</Title>
								</SectionTitle>
								<SectionContent>{__("performance_cookies_example","These cookies allow us to count visits and traffic sources, so we can measure and improve the performance of our site. They help us know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies, we will not know when you have visited our site.")}</SectionContent>
							</Section>
							<Section>
								<SectionTitle>
									<InputCheckbox
										name={"fbq-cookies"}
										onChange={onChangeCookieFb}
										checked={hasFbq}
										color="lightGreen"
									/>
									<Title>{__("social_sharing_cookies","Social Sharing Cookies")}</Title>
								</SectionTitle>

								<SectionContent>{__("social_sharing_cookies_example","We use some social sharing plugins, to allow you to share certain pages of our website on social media. These plugins place cookies so that you can correctly view how many times a page has been shared.")}</SectionContent>
							</Section>
						</ModalBody>
						<ModalButtonArea>
							<Button
								style={{
									...styles.buttonMargin,
									...styles.buttonModalMinWidth
								}}
								color="black"
								onClick={close}
								inline
							>
								<TSpan id="Cancel" style={{color: "#fff"}}>Cancel</TSpan>
							</Button>
							<Button
								style={{
									...styles.buttonMargin,
									...styles.buttonModalMinWidth
								}}
								color="green"
								onClick={updateConsentInfo}
								inline
							>
								<TSpan id="Save" style={{color: "#fff"}}>Save</TSpan>
							</Button>
						</ModalButtonArea>
					</Modal>
				</form>
			</Wrapper>
		);
	}
}

export default CookieConsentModal;

const Wrapper = styled.div`
	display: inline-block;
`;

const Section = styled.div`
	position: relative;
	border-bottom: 1px solid rgba(17,17,17,0.1);
	padding: 0 0 20px 0;
	margin-bottom: 12px;
	max-width: 430px;
	font-family: "Roboto", sans-serif;
	font-size: 15px;

	&:last-child {
		border-bottom: none;
	}
`;

const SectionTitle = styled.div`
	font-weight: bold;
	padding: 8px 0 22px;
	display: inline-block;
	width: 100%;

	& div:first-child {
		float: right;
		margin-left: 10px;
	}
`;

const Title = styled.div`
	overflow: hidden;
	line-height: 30px;
`;

const SectionContent = styled.div`
	font-family: Roboto, Arial, Helvetica, sans-serif;
`;
