import React, { useEffect, useState } from "react";
import { __ } from "../../../lib/translate";
import styled from "styled-components";
import Alert from "../../../components/Alert";
import { useSelector } from "react-redux";

const AlertCryptoV1 = () => {
    const [alert, setAlert] = useState(false);
    const user = useSelector(({ pCloudUser }) => pCloudUser.userinfo);
    const token = useSelector(({ pCloudUser }) => pCloudUser.token);

    useEffect(() => {
        if (HFN.config.isBusiness() && user.account.owner) {
            HFN.apiMethod("account_info", { auth: token }, function(
                ret
            ) {
                if (!ret.account.cryptov2activated) {
                    setAlert(
                        __("crypto_update_owner_alert", "", {
                            update: "#page=cryptoupdate",
                            learnmore: "#page=cryptobusiness"
                        })
                    );
                }
            });
        }
    }, [user]);

    return (
        <>
            {alert && (
                <AlertWrapper>
                    <Alert
                        theme={"warning"}
                        multiline={true}
                        centerText={true}
                        componentifyText={alert}
                    />
                </AlertWrapper>
            )}
        </>
    );
};

export default AlertCryptoV1;

const AlertWrapper = styled.div`
    width: 100%;
    padding-right: var(--spacing-md);
    box-sizing: border-box;
    @media (max-width: 1023px) {
        padding-left: var(--spacing-md);
    }
`;
