// @flow

import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";

import Modal, { OvalModalWrap, CloseModalIcon } from "../Modal";
import Button from "../ButtonDefault";
import * as Style from "./styledComponents";

import apiMethod from "../../api/apiMethod";
import { __ } from "../../lib/translate";
import { errorKeys } from "../../lib/errors";

type Props = {
  share: any,
  callback: () => void
};

const DeclineShareModal = ({ share = {}, callback = () => {} }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const onEnter = useCallback(
    (e: any) => {
      if (e.keyCode === 13) {
        declineShare();
      }
    },
    [declineShare]
  );

  useEffect(() => {
    if (isOpen) {
      console.log("Add keyup event DeclineShareModal");
      document.addEventListener("keyup", onEnter);
    } else {
      console.log("Remove keyup event DeclineShareModal");
      document.removeEventListener("keyup", onEnter);
    }
  }, [isOpen, onEnter]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const declineShare = useCallback(() => {
    const { sharerequestid, code } = share;
    const params = {};

    if (isLoading) {
      return;
    }

    if (sharerequestid) {
      params.sharerequestid = sharerequestid;
    } else if (code) {
      params.code = code;
    } else {
      HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
      throw new Error("No sharerequestid and share code");
    }

    setIsLoading(true);

    apiMethod(
      "declineshare",
      params,
      res => {
        HFN.message(__("Share is declined."));
        setIsLoading(false);
        handleClose();
        // triggerIncomingRequests(".requests", true);
        if (callback) {
          callback();
        }
      },
      {
        errorCallback: ({ result, error }) => {
          setIsLoading(false);
          if (errorKeys[result]) {
            HFN.message(__(errorKeys[result]), "error");
          } else {
            handleClose();
            HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
            throw new Error(error);
          }
        }
      }
    );
  });

  const { sharename } = share;

  return (
    <Modal align="top" animate onClose={handleClose} opened={isOpen}>
      <OvalModalWrap>
        <CloseModalIcon onClick={handleClose} />
        <Style.Container>
          <Style.Header>{__("Decline Share")}</Style.Header>
          <Style.Label>{__("Folder")}</Style.Label>
          <BoxTeam>
            <Icon src={HFN.createIconSrc(HFN.ICO.FOLDER_SHARED, HFN.ICONS.SVG_LIST)} alt="icon" />
            {sharename}
          </BoxTeam>
          <Style.Footer>
            <Button
              color="lightgray4"
              style={{
                marginRight: "5px"
              }}
              onClick={handleClose}
            >
              {__("Cancel")}
            </Button>
            <Button
              loading={isLoading}
              color="red"
              style={{
                marginLeft: "5px"
              }}
              disabledColor="#dddddd"
              onClick={declineShare}
            >
              {__("Decline")}
            </Button>
          </Style.Footer>
        </Style.Container>
      </OvalModalWrap>
    </Modal>
  );
};

export default DeclineShareModal;

const BoxTeam = styled(Style.Box)`
  height: auto;
  padding: 10px;
  font-size: 15px;
  align-items: center;
  justify-content: flex-start;
`;

const Icon = styled.img`
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  vertical-align: middle;
`;
