import { createSlice } from "@reduxjs/toolkit";
import { setAutoFreeze } from 'immer';
import { parseLinkData } from "../../../containers/ShareLinkSettings/utils";

setAutoFreeze(false);

const initialState = {
    loadedLinkData: false,
    loadedSharedLinks: false,
    sharedLinks: [],
    linkData: {
        code: "",
        linId: "",
        linkUrl: "",
        shortLinkUrl: "",
        linkName: "",
        hasPassword: false,
        expirationDate: false,
        created: "",
        downloads: 0,
        traffic: 0,
        uploadSettings: {
            isUploadDisabled: true,
            canEveryoneUpload: false,
            isRestrictedUpload: false
        },
        canDownload: false,
        emailsWithUploadAccess: []
    },
    isUploadSerringsDropdownOpen: false
};

export const sharedLinksSlice = createSlice({
    name: "sharedLinks",
    initialState: initialState,
    reducers: {
        loadSharedLinks: (state, action) => {
            state.loadedSharedLinks = true;
            state.sharedLinks = action.payload;
        },
        addSharedLink: (state, action) => {
            state.sharedLinks = addLink(state, action.payload);
        },
        deleteSharedLink: (state, action) => {
            state.sharedLinks = deleteLink(state, action.payload);
        },
        loadSharedLinkData: (state, action) => {
            state.loadedLinkData = true;
            state.linkData = parseLinkData(action.payload);
        },
        setUploadSettings: (state, action) => {
            state.linkData.uploadSettings = action.payload;
        },
        setDownloadSettings: (state, action) => {
            state.linkData.canDownload = action.payload;
        },
        setHasPassword: (state, action) => {
            state.linkData.hasPassword = action.payload;
        },
        setExpires: (state, action) => {
            state.linkData.expirationDate = action.payload;
        },
        setEmailsWithUploadAccess: (state, action) => {
            state.linkData.emailsWithUploadAccess = action.payload;
        },
        setShortLinkUrl: (state, action) => {
            state.linkData.shortLinkUrl = action.payload;
        }
    }
});

const addLink = (state, data) => {
    const { sharedLinks } = state;
    sharedLinks.push(data);
    return { ...sharedLinks };
};

const deleteLink = (state, data) => {
    const { sharedLinks } = state;
    const filtredSharedLinks = sharedLinks.filter(
        (item) => item.id !== data.id
    );
    return { ...filtredSharedLinks };
};

export const {
    loadSharedLinks,
    addSharedLink,
    deleteSharedLink,
    loadSharedLinkData,
    setUploadSettings,
    setDownloadSettings,
    setHasPassword,
    setExpires,
    setEmailsWithUploadAccess,
    setShortLinkUrl
} = sharedLinksSlice.actions;

export default sharedLinksSlice.reducer;
