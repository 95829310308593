import React, { useState } from "react";
import Componentify from "react-componentify";

import Modal, { OvalModalWrap, OvalModalCloseV2 } from "../Modal";
import Button from "../ButtonDefault";
import { __ } from "../../lib/translate";
import { boldConverter } from "../../lib/componentifyConverters";
import useUploadContext from './hooks/useUploadContext';
import { Content, Body, ButtonWrapper, Text, Title } from "./sharedStyles";

const UploadCancelModal = (props) => {
  const {
    show = false,
    handleNo = null,
    handleYes = null,
  } = props;

  return (
    <Modal align="top" animate shouldCloseOnOverlayClick={false} onClose={handleNo} opened={show}>
      <OvalModalWrap>
        <OvalModalCloseV2 onClick={handleNo} />
        <Content>
          <React.Fragment>
            <Body>
              <Title>{__('upload_manager_cancel_title', 'Cancel upload')}</Title>
              <Text>
                <Componentify
                  text={__(
                    "upload_manager_cancel_description",
                    "All active uploads will be canceled. How do you want to proceed?"
                  )}
                  converters={[boldConverter]}
                />
              </Text>
            </Body>
            <ButtonWrapper>
              <Button inline color="lightgray4" onClick={handleYes}>
                {__('Cancel', 'Cancel')}
              </Button>
              <Button inline color="cyan" onClick={handleNo}>
                {__('upload_manager_close_cancel_action', 'Keep uploading')}
              </Button>
            </ButtonWrapper>
          </React.Fragment>
        </Content>
      </OvalModalWrap>
    </Modal>
  );
};

export default UploadCancelModal;