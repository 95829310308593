// @flow

import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { __ } from "../../lib/translate";
import * as Style from "./preferences.style";
import hashManager from "../../lib/hashManager";

const EncryptionSetupSetting = () => {
  const encryptionSetup = useSelector(({ pCloudUser }) => pCloudUser.userinfo.cryptosetup);
  const encryptionExpire = useSelector(({ pCloudUser }) => pCloudUser.userinfo.cryptoexpires);

  const onSetupEncryptionClick = () => {
    hashManager.pushState({ page: "crypto" }, 2);
  }

  if (encryptionSetup) {
    return null;
  }

  return (
    <Style.Row>
      <div>
        <Style.Title>{__("Encryption")}</Style.Title>
        <Style.Description>{__("The safest place, where your files are hidden from anyone else!")}</Style.Description>
      </div>
      <Style.Button onClick={onSetupEncryptionClick}>{__("tfa_setup_set_up_btn", "Set up")}</Style.Button>
    </Style.Row>
  )
};

export default EncryptionSetupSetting;
