import { createSlice } from "@reduxjs/toolkit";
import { setAutoFreeze } from 'immer';

setAutoFreeze(false);

const initialState = {
    mediaItems: {},
    optsData: {
        code: "",
        candownload: false,
        linkpassword: ""
    }
};

export const galleryPreviewSlice = createSlice({
    name: "galleryPreview",
    initialState: initialState,
    reducers: {
        fetchMediaSuccess: (state, action) => {
            state.mediaItems[action.payload.id] = action.payload;
        },
        deleteMediaItem: (state, action) => {
            delete state.mediaItems[action.payload];
        },
        setGalleryOptions: (state, action) => {
            state.optsData = { ...state.optsData, ...action.payload };
        },
        setHasPassword: (state, action) => {
            state.optsData = {
                ...state.optsData,
                linkpassword: action.payload
            };
        },
        setHasCode: (state, action) => {
            state.optsData = { ...state.optsData, code: action.payload };
        },
        setCanDownload: (state, action) => {
            state.optsData = { ...state.optsData, candownload: action.payload };
        }
    }
});

export const {
    fetchMediaSuccess,
    deleteMediaItem,
    setGalleryOptions,
    setHasPassword,
    setHasCode,
    setCanDownload
} = galleryPreviewSlice.actions;

export default galleryPreviewSlice.reducer;
