//@flow

import React from "react";
import styled from "styled-components";

import { COLORS } from "@pcloud/web-utilities/dist/config/constants";
import { ColorType } from "../../types/dataTypes.components";

type TabType = {
  tabId: string | number,
  tabTitle: string,
  clickAction: (tabId: string | number) => void
};

type TabsProps = {
  color: ColorType,
  tabs: Array<TabType>,
  activeTab: string | number
};

const PaymentTabs = ({ color, tabs, activeTab }: TabsProps) => {
  const tabsMap = {};
  const tabWidth = 100 / tabs.length;

  const renderTabs = () => {
    const tabsComponents = tabs.map(({ tabId, tabTitle, clickAction, disabled }, index) => {
      tabsMap[tabId] = index;
      return (
        <Tab
          width={tabWidth}
          active={tabId === activeTab}
          onClick={() => (!disabled ? clickAction(tabId) : null)}
          key={tabId}
          disabled={disabled}
        >
          {tabTitle}
        </Tab>
      );
    });

    return tabsComponents;
  };

  return (
    <TabsWrapper>
      {renderTabs()}
      <ActiveLine color={color} width={tabWidth} tab={tabsMap[activeTab]} />
    </TabsWrapper>
  );
};

export default PaymentTabs;

const TabsWrapper = styled.div`
  border-bottom: 1px solid #eee;
  margin: 0 auto;
  margin-bottom: 20px;
  position: relative;
`;

const Tab = styled.div`
  width: ${props => props.width || 50}%;
  display: inline-block;
  padding: 0px 0px 12px 0px;
  text-align: center;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  color: #222;
  text-transform: uppercase;
  cursor: ${props =>( props.disabled ? "default" : "pointer")};
  transition: all 0.25s ease-in-out;
  position: relative;

  ${props =>
    props.active
      ? `
        font-weight: bold;
        cursor: default;
      `
      : ""};
  ${props =>
    props.disabled
      ? `
      &::after {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        display: inline-block;
        width: 100%;
        height: 100%;
        background: rgba(255,255,255, 0.8);
      }
    `
      : ``};

  @media (max-width: 768px) {
    padding: 0px 0px 20px 0px;
  }
`;

const ActiveLine = styled.div`
  width: ${props => props.width || 50}%;
  position: absolute;
  bottom: -1px;
  height: 2px;
  background: ${props => (props.color ? COLORS[props.color].mainColor : "#17bed0")};
  transition: left 0.25s ease-in-out;
  left: ${props => props.tab * props.width}%;
`;
