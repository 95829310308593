import PropTypes from 'prop-types';
import React from "react";
import styled, { keyframes } from "styled-components";
import { COLORS } from '@pcloud/web-utilities/dist/config/constants';
import LoadingIcon from "../../root/img/loading_button.svg";

const SubmitButton = ({ children, active, loading, tooltip, className = "", ...rest }) => {
  let classes = ["butt", "submitbut"];
  let tooltipObj = { "data-toggle": "remove-tooltip" };

  if (!active) {
    classes.push("inactive-butt");
    if (rest.onClick) {
      rest.onClick = () => {};
    }
  }

  if (tooltip) {
    tooltipObj.title = tooltip;
    tooltipObj["data-toggle"] = "tooltip";
    //tooltipObj['data-container'] = '.upload-link-wrap';
  }

  const _loadingImage = <SpinnginContainer className="loading-image animate"><LoadingIcon width="20px" height="20px"/></SpinnginContainer>;

  return (
    <ButtonWrap className={className + " butt-wrap"}>
      <Button
        type="submit"
        className={classes.join(" ")}
        {...tooltipObj}
        {...rest}
      >
        {loading ? _loadingImage : children}
      </Button>
    </ButtonWrap>
  );
};

SubmitButton.propTypes = {
  active: PropTypes.bool,
  loading: PropTypes.bool
};

SubmitButton.defaultProps = {
  active: true,
  loading: false
};

export default SubmitButton;

const ButtonWrap = styled.div`
  width: 100%;
`;

const spin = keyframes`
  100% { transform: rotate(360deg) };
`;

const SpinnginContainer = styled.div`
  -webkit-animation: ${spin} 0.5s linear infinite;
  animation: ${spin} 0.5s linear infinite;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin: 0 auto;
`;

const Button = styled.button`
  width: 100%;
  min-width: ${({ minWidth }) => minWidth || 0};
  height: ${({ minHeight }) => minHeight || "38px"} !important;
  line-height: ${({ minHeight }) => minHeight || "38px"} !important;
  border-radius: ${({ borderRadius }) => borderRadius || "8px"};
  color: ${(props) => {
    if (props.disabledTextColor && props.disabled) {
      return props.disabledTextColor;
    }

    if (props.color && COLORS[props.color]) {
      return COLORS[props.color].text;
    }

    return COLORS["cyan"].text;
  }};
  text-decoration: none;
  text-align: center;
  display: block;
  font-weight: 600;
  font-size: ${({ fontSize }) => fontSize || "13px"};
  position: relative;
  width: 100%;
  padding: 0 10px;
  /* padding: 0; */
  margin: 0 auto;
  box-sizing: border-box;
  background: ${(props) => {
    if (props.disabledColor && props.disabled) {
      return props.disabledColor;
    }

    if (props.color && COLORS[props.color]) {
      if (COLORS[props.color].secondColor) {
        return 'linear-gradient(to bottom, ' + COLORS[props.color].mainColor + ', ' + COLORS[props.color].secondColor + ')';
      }
      return COLORS[props.color].mainColor;
    } else {
      return COLORS["cyan"].mainColor;
    }
  }};

  overflow: hidden;
  cursor: pointer;
  
  &:disabled {
    cursor: auto;
    
    &:after {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      display: inline-block;
      width: 100%;
      height: 100%;
      background-color: #fff;
      opacity: ${({ disabledColor }) => disabledColor ? 0 : 0.5};
    }
  }
`;
