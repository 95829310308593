// @flow

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Tooltip } from "react-tooltip";

import { myUrlLocation } from "@pcloud/web-utilities/dist/config";

import apiMethod from "../../api/apiMethod";
import { __ } from "../../lib/translate";
import { formatDt } from "../../lib/utils";
import Button from "../ButtonDefault";
import Modal, { OvalModalBody, OvalModalClose, OvalModalCloseV2, OvalModalWrap } from "../Modal";
import * as Style from "./preferences.style";
import { updateBillingInfo } from "../../lib/state/reducers/businessSlice";

const showErrorAlert = (...args) => {
  HFN.message(...args);
};

const getSourceName = (provider: string, instrument: string): string => {
  const providerInstrumentToSource = {
    Braintree: { paypalaccount: "PayPal", creditcard: "Credit Card" },
    SafeCharge: { creditcard: "Credit Card", apm: "Local Payment" }
  };
  const providerToSource = {
    Stripe: "Credit Card"
  };

  if (providerInstrumentToSource[provider] && providerInstrumentToSource[provider][instrument]) {
    return providerInstrumentToSource[provider][instrument];
  } else if (providerToSource[provider]) {
    return providerToSource[provider];
  }

  return provider;
};

const PaymentHistoryInfo = () => {
  const isBusiness = useSelector(({ pCloudUser }) => pCloudUser.userinfo.business);
  const userSettings = useSelector(({ pCloudUser }) => pCloudUser.userSettings || {});
  const { payments: billingHistory = [], billingInfo = [] } = userSettings;
  const [loading, setLoading] = useState(false);
  const [hasCompleteInfo, setHasCompleteInfo] = useState(false);
  const [showMoreInfo, setShowMoreInfo] = useState(-1);
  const dispatch = useDispatch();

  useEffect(() => {
    const billingKeysCompany = ["billingAddress", "country", "company"];
    const billingKeysPerson = ["billingAddress", "country", "names"];
    const hasCompleteInfo =
      billingKeysCompany.every(key => !!billingInfo[key]) ||
      billingKeysPerson.every(key => !!billingInfo[key]);

    setHasCompleteInfo(hasCompleteInfo);
  }, [billingInfo]);

  const issueInvoice = (payment: PaymentData) => {
    const { paymentId } = payment;

    if (loading) {
      return;
    }

    setLoading(true);

    apiMethod("issueinvoice", {
        paymentid: paymentId
      }, ({ invoicecode: invoiceCode }) => {
        const findPaymentById = paymentId => currentPayment => currentPayment.paymentId === paymentId;
        const invpayments = billingHistory.filter(findPaymentById(paymentId));
        const invpayment = invpayments[0];

        invpayment.invoiceCode = invoiceCode;
        dispatch(updateBillingInfo([...billingHistory]));
        setLoading(false);
      }, {
        errorCallback: ret => {
          showErrorAlert(ret.error, "error");
          setLoading(false);
        }
      }
    );
  };

  const issueReceipt = (payment: PaymentData) => {
    const { paymentId } = payment;

    if (loading) {
      return;
    }

    setLoading(true);

    apiMethod("issuereceipt", {
        paymentid: paymentId
      }, ({ receiptcode: receiptCode }) => {
        const findPaymentById = paymentId => currentPayment => currentPayment.paymentId === paymentId;
        // imp. syntax
        const invpayments = billingHistory.filter(findPaymentById(paymentId));
        const invpayment = invpayments[0];

        invpayment.receiptCode = receiptCode;
        dispatch(updateBillingInfo([...billingHistory]));
        setLoading(false);
      }, {
        errorCallback: ret => {
          showErrorAlert(ret.error, "error");
          setLoading(false);
        }
      }
    );
  };

  const renderGetInvoiceButton = (payment, showFullText) => {
    if (!hasCompleteInfo) {
      return (
        <>
          <Style.Link disabled={1} inline data-tooltip-id="no_invoice_info_for_payment">
            {__(showFullText ? "ph_get_invoice_full" : "ph_get_invoice")}
          </Style.Link>
          <Tooltip
            id="no_invoice_info_for_payment"
            className="cv-tooltip"
            noArrow={true}
          >
            {__(
              "no_invoice_info_for_payment",
              "Please fill all missing fields to be able to generate an invoice."
            )}
          </Tooltip>
        </>

      );
    } else if (payment.invoiceCode) {
      return (
        <Style.Link
          href={myUrlLocation("invoice/show?code=" + payment.invoiceCode)}
          target="_blank"
          size="medium"
          inline
        >
          {__(showFullText ? "ph_show_invoice_full" : "ph_show_invoice")}
        </Style.Link>
      );
    } else if (payment.canCreateInvoice) {
      return (
        <Style.Link
          size="medium"
          inline
          onClick={() => issueInvoice(payment)}
        >
          {__(showFullText ? "ph_get_invoice_full" : "ph_get_invoice")}
        </Style.Link>
      );
    } else {
      return (
        <>
          <Style.Link disabled={1} inline data-tooltip-id="no_invoice_for_payment">
            {__(showFullText ? "ph_get_invoice_full" : "ph_get_invoice")}
          </Style.Link>
          <Tooltip
            id="no_invoice_for_payment"
            className="cv-tooltip"
            noArrow={true}
          >
            {__(
              "no_invoice_for_payment",
              "Unfortunately, you can no longer issue an invoice for this payment."
            )}
          </Tooltip>
        </>
      );
    }
  };

  const hasBillingHistory = billingHistory.length;
  const billingHistorySorted = [...billingHistory];
  //sort billing history by date
  hasBillingHistory &&
  billingHistorySorted.sort(function(a, b) {
      return new Date(b.paymentDate).getTime() - new Date(a.paymentDate).getTime();
    });

  return (
    <div>
      {hasBillingHistory ? (
        <div>
          <Style.Row>
            <Style.Title>{__("ph_billing_history", "Payment History:")}</Style.Title>
          </Style.Row>
          <Style.TableWrapper isBusiness={isBusiness}>
            <thead>
              <Style.HeaderRow>
                <Style.HeaderCol style={{ width: "23.33%" }}> {__("ph_date", "Date")}</Style.HeaderCol>
                <Style.HeaderCol className="desktop-only" style={{ width: "23.33%" }}> {__("ph_type", "Type")}</Style.HeaderCol>
                <Style.HeaderCol style={{ width: "23.33%" }}> {__("ph_price", "Price")}</Style.HeaderCol>
                <Style.HeaderCol className="desktop-only" style={{ width: "10%" }}> {__("ph_getshow", "Invoice")}</Style.HeaderCol>
                <Style.HeaderCol className="desktop-only" align="right" style={{ width: "10%" }}> {__("ph_receipt", "Receipt")}</Style.HeaderCol>
                <Style.HeaderCol className="mobile-only" align="right" style={{ width: "10%" }}> {__("More")}</Style.HeaderCol>
              </Style.HeaderRow>
            </thead>
            <tbody>
              {billingHistorySorted.map((payment, index) => {
                return (
                  <Style.BodyRow key={payment.paymentId}>
                    <Style.BodyCol>{formatDt(payment.paymentDate)}</Style.BodyCol>
                    <Style.BodyCol className="desktop-only">{getSourceName(payment.billingProvider, payment.instrument)}</Style.BodyCol>
                    <Style.BodyCol>{payment.amount} {payment.currency}</Style.BodyCol>
                    <Style.BodyCol className="desktop-only">{renderGetInvoiceButton(payment)}</Style.BodyCol>
                    <Style.BodyCol className="desktop-only" align="right">
                      {payment.receiptCode ? (
                        <Style.Link
                          href={myUrlLocation("receipt/show?code=" + payment.receiptCode)}
                          target="_blank"
                          inline
                        >
                          {__("ph_show_receipt", "Show")}
                        </Style.Link>
                      ) : (
                        <Style.Link
                          inline
                          onClick={() => issueReceipt(payment)}
                        >
                          {__("ph_get_receipt", "Get")}
                        </Style.Link>
                      )}
                    </Style.BodyCol>
                    <Style.BodyCol className="mobile-only" align="right" onClick={() => setShowMoreInfo(index)}>
                      <i style={{fontSize: "18px"}} class="fa-light fa-file-invoice" />
                    </Style.BodyCol>
                  </Style.BodyRow>
                );
              })}
            </tbody>
          </Style.TableWrapper>
        </div>
      ) : (
        null
      )}
      <Modal align="top" opened={showMoreInfo !== -1} onClose={() => setShowMoreInfo(-1)}>
        <MoreInfoModalWrapper>
          <OvalModalCloseV2 onClick={() => setShowMoreInfo(-1)}/>
            {showMoreInfo !== -1 ? (
              <>
                <ModalTitle>{__("billing_information", "Billing information")}</ModalTitle>
                <Container isBusiness={isBusiness}>
                  <Row className="billing-history">
                    <Col>{__("ph_date", "Date")}</Col>
                    <Col>{formatDt(billingHistory[showMoreInfo].paymentDate)}</Col>
                  </Row>
                  <Row className="billing-history">
                    <Col>{__("ph_type", "Type")}</Col>
                    <Col>{getSourceName(billingHistory[showMoreInfo].billingProvider, billingHistory[showMoreInfo].instrument)}</Col>
                  </Row>
                  <Row className="billing-history">
                    <Col>{__("ph_price", "Price")}</Col>
                    <Col>{billingHistory[showMoreInfo].amount} {billingHistory[showMoreInfo].currency}</Col>
                  </Row>
                </Container>
                <ButtonWrapper>
                  {renderGetInvoiceButton(billingHistory[showMoreInfo], true)}
                  {billingHistory[showMoreInfo].receiptCode ? (
                    <Style.Link
                      href={myUrlLocation("receipt/show?code=" + billingHistory[showMoreInfo].receiptCode)}
                      target="_blank"
                      size="medium"
                      inline
                    >
                      {__("ph_show_receipt_full", "Show Receipt")}
                    </Style.Link>
                  ) : (
                    <Style.Link
                      size="medium"
                      inline
                      onClick={() => issueReceipt(billingHistory[showMoreInfo])}
                    >
                      {__("ph_get_receipt_full", "Get Receipt")}
                    </Style.Link>
                  )}
                </ButtonWrapper>
              </>
            ) : null}

        </MoreInfoModalWrapper>
      </Modal>
    </div>
  );
};

export default PaymentHistoryInfo;

const Container = styled.ul`
  margin: 0;
  padding: 0;
  background: ${props => (props.isBusiness ? "#fff" : "#fafafa")};
`;

const Row = styled.li`
  font-size: 12px;
  line-height: 1.1;
  border-bottom: 1px solid #e9e9e9;
  list-style-type: none;
  display: flex;
  justify-content: space-between;

  &.header-history {
    font-weight: 700;
    padding: 0;
    background: #f4f4f4;
  }

  &.billing-history {
    padding: 10px 0px;
  }

  /* &.billing-history div,
  &.header-history div {
    width: 20%;
  } */

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: 768px) {
    &.billing-history {
      padding: 4px 20px;
    }

    &.header-history {
      padding: 0 20px;
    }
  }
`;

const Col = styled.div`
  width: 20%;
  display: inline-block;
  padding: 0 0 0 10px;
  font-size: 12px;
  line-height: 33px;
  text-align: left;
  box-sizing: border-box;

  &.billing-more {
    text-align: right;
    display: none;
  }

  &.row-heading {
    font-weight: 700;
    width: 30%;
  }
  &.row-heading:first-letter {
    text-transform: uppercase;
  }

  @media (max-width: 768px) {
    padding-left: 0;

    &, &.row-heading {
      width: calc(50% - 20px);
    }

    &.billing-type,
    &.billing-invoice,
    &.billing-receipt {
      display: none;
    }

    &.billing-more {
      width: 40px;
      display: block;
    }
  }
`;

const MoreInfoModalWrapper = styled(OvalModalWrap)`
  width: calc(100vw - 40px);
  padding: 20px 0px;
  box-sizing: border-box;
  max-width: 540px;

  & ${MoreInfoModalWrapper} {
    & ${Row} {
      &.billing-history {
        padding: 4px 20px;
      }

      &.header-history {
        padding: 0 20px;
      }

      & ${Col} {
        &:first-child {
          font-weight: bold;
        }

        &:last-child {
          text-align: right;
        }

        @media (max-width: 768px) {
          padding-left: 0;

          &, &.row-heading {
            width: calc(50% - 20px);
          }

          &.billing-type,
          &.billing-invoice,
          &.billing-receipt {
            display: none;
          }

          &.billing-more {
            width: 40px;
            display: block;
          }
        }
      }
    }
  }
`;

const ModalTitle = styled.div`
  font-size: var(--font-size-18);
  font-weight: 700;
  line-height: 20px;
  margin-bottom: var(--spacing-md);
  color: var(--color-base-black);
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  margin-top: var(--spacing-md);
  gap: var(--spacing-sm);
  box-sizing: border-box;
  flex-wrap: wrap;

  a {
    display: inline-block;
    color: #17bed0 !important;
    padding: var(--spacing-sm) var(--spacing-lg) !important;
    border: 1px solid var(--color-primary-500);
    border-radius: 8px;
    line-height: 1;
    font-size: var(--font-size-14);
    width: 100%;
    background-color: var(--color-base-white);
    text-decoration: none !important;
    font-weight: normal;
    line-height: 1.2;
    text-align: center;
    border-radius: 4px;

    &[disabled] {
      border: none;
      background-color: #f2f2f2;
      border: none;
      color: #888 !important;
      opacity: 1;
    }
  }
`;
