// @flow
import React, { useState } from "react";
import styled from "styled-components";

import { useSelector } from "react-redux";
import apiMethod from "../../api/apiMethod";
import { __ } from "../../lib/translate";

import Button from "../ButtonDefault";
import { Row, Col } from "../tables/light";
import PasswordStrength from "../PasswordStrength";
import { MIN_CRYPTO_PASS_STRENGTH } from "@pcloud/web-utilities/dist/config/constants";

type Props = {
  userId: ?integer,
  teamId: ?integer,
  onPassChanged: () => void
};

const fieldsDefaultValue = { password: "", repeatPassword: "", hint: "" };

const SetNewPassBusiness = ({ userId, teamId, onPassChanged }: Props) => {
  const token = useSelector(({ pCloudUser }) => pCloudUser.token);
  const [fields, setFields] = useState(fieldsDefaultValue);
  const [loading, setLoading] = useState(false);

  const onFieldChange = function(field, value) {
    const newFields = { ...fields };
    newFields[field] = value;
    setFields(newFields);
  };

  const onSetNewPassword = e => {
    e.preventDefault();
    const { password, repeatPassword, hint } = fields;

    if (loading || !password.length) {
      return;
    }

    const strength = pCloudCrypto.passwordStrength(password);

    if (password.length < 8 || strength < MIN_CRYPTO_PASS_STRENGTH) {
      HFN.message(__("Password is too weak"), "error");
      return;
    }

    if (password != repeatPassword) {
      HFN.message(__("both passwords must be exact match."), "error");
      return;
    }

    const hintContainsPass = hint.trim().indexOf(password.trim()) != -1;
    if (hintContainsPass) {
      HFN.message(__("Your Hint must not contain the Passphrase."), "error");
      return;
    }

    setLoading(true);

    pCrypt.encryptPrivateKeyWithNewPass(password, 0, ({ privatekey, signature }) => {
      const params = { auth: token, hint: hint, privatekey: privatekey, signature: signature };
      if (userId) {
        params.userid = userId;
      } else if (teamId || teamId === 0) {
        params.teamid = teamId;
      } else {
        throw Error("Invalid useage neither userId nor teamId provided");
      }

      const methodName = userId ? "account_changeusercryptoprivate" : "account_changeteamcryptotempprivate";

      apiMethod(
        methodName,
        params,
        res => {
          if (res.result == 0) {
            HFN.message(__("crypto_pass_changed"));
            onPassChanged();
          }
          setLoading(false);
        },
        {
          type: "post",
          errorCallback: error => {
            console.log(error);
            HFN.message("Saving key failed", "error");
            setLoading(false);
          }
        }
      );
    });
  };
  console.log("fields.password.length", fields.password.length)
  return (
    <Wrap className="clearfix" onSubmit={onSetNewPassword}>
      <Row className="top-border">
        <Col className="label">{__("new_crypto_pass_label")}</Col>
        <Col className="input">
          <input
            type="password"
            name="cryptpass"
            onChange={e => onFieldChange("password", e.target.value)}
            value={fields.password}
            autoComplete="off"
          />
          <PassStrengWrap>
            <PasswordStrength password={fields.password} />
          </PassStrengWrap>
        </Col>
      </Row>
      <Row>
        <Col className="label">{__("Repeat Crypto Pass")}</Col>
        <Col className="input">
          <input
            type="password"
            name="cryptopassrepeat"
            onChange={e => onFieldChange("repeatPassword", e.target.value)}
            value={fields.repeatPassword}
          />
        </Col>
      </Row>
      <Row className="bottom-border">
        <Col className="label">
          {__("Hint")} ({__("Optional")})
        </Col>
        <Col className="input">
          <input
            type="text"
            name="hint"
            onChange={e => onFieldChange("hint", e.target.value)}
            value={fields.hint}
          />
        </Col>
      </Row>
      <ButtonWrap>
        <Button
          size="big"
          borderRadius="3"
          color="green"
          onClick={onSetNewPassword}
          loading={loading}
          disabled={!fields.password.length}
          type="submit"
        >
          {__("button_change_crypto_pass")}
        </Button>
      </ButtonWrap>
    </Wrap>
  );
};

export default SetNewPassBusiness;

const Wrap = styled.form`
  width: 720px;
`;

const ButtonWrap = styled.div`
  float: right;
  width: 240px;
  margin-top: 15px;
  margin-right: 11px;
`;

const PassStrengWrap = styled.div`
  position: relative;
  top: 3px;
  left: 2px;
}
`;
