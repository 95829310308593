import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { addItem } from "../lib/state/reducers/onboardingSlice";
import { ONBOARDING_ID_CHATBOT } from "./HighlightOnboardingComponent/constants";
import { useDispatch, useSelector } from "react-redux";
import HighlightOnboardingComponent from "./HighlightOnboardingComponent";
import ChatBotContent from "./HighlightOnboardingComponent/Contents/ChatBotContent";
import ChatBotIcon from "../../root/img/chatbot.svg";
import { Pulsate } from "./Modals/styledComponents";
import { getHashPage } from "../lib";
import useWindowDimensions from "../lib/hooks/useWindowDimensions";
import { MOBILE_WIDTH_BREAKPOINT } from "../config/constants";

const ChatBot = ({ iframeSrc }) => {
    const { bodyWidth } = useWindowDimensions();
    const isMobile = bodyWidth < MOBILE_WIDTH_BREAKPOINT;
    const dispatch = useDispatch();
    const wrapperRef = useRef(null);
    const iframeRef = useRef(null);
    const [isInitiate, setIsInitiate] = useState(false);
    const [page, setPage] = useState(getHashPage());
    const [isOpen, setIsOpen] = useState(false);
    const [isLoadingIframe, setIsLoadingIframe] = useState(true);
    const itemData = useSelector(({ content }) => content.itemData);

    useEffect(() => {
        window.addEventListener("hashchange", handleHashChange);

        return () => {
            window.removeEventListener("hashchange", handleHashChange);
        };
    }, [page]);

    const handleHashChange = () => {
        setPage(getHashPage());
    };

    const handleBtnClick = () => {
        if (!isInitiate) {
            setIsInitiate(true);
        }
        setIsOpen(!isOpen);
    };

    const onLoad = (e) => {
        setIsLoadingIframe(false);
    };


    if (
        isMobile &&
        [
            'filemanager',
            'trash',
            'audio',
        ].includes(page) &&
        itemData.hasOwnProperty("items") &&
        !Object.keys(itemData.items).length
    ) {
        return null;
    }

    return (
        <Wrapper>
            <HighlightOnboardingComponent
                id={ONBOARDING_ID_CHATBOT}
                content={[<ChatBotContent />]}
                notShowAfterClose={true}
                showOverlay={true}
                place={"top-left"}
                delay={1000}
            >
                {isInitiate && (
                    <Content $isOpen={isOpen}>
                        <IframeLink
                            src={iframeSrc}
                            ref={iframeRef}
                            $show={!isLoadingIframe}
                            onLoad={onLoad}
                            frameBorder="0"
                        />
                        {isLoadingIframe && <Loader />}
                        {isOpen && !isLoadingIframe && <CloseButton onClick={() => setIsOpen(false)}><i className="fa-regular fa-close" /> </CloseButton>}
                    </Content>
                )}
                <Button
                    id="chatbase-bubble-button"
                    ref={wrapperRef}
                    onClick={handleBtnClick}
                >
                    <ChatBotIcon />
                </Button>
            </HighlightOnboardingComponent>
        </Wrapper>
    );
};

export default ChatBot;

const Wrapper = styled.div`
    position: relative;
    z-index: 5;
`;

const Content = styled.div`
    display: ${({ $isOpen }) => ($isOpen ? "flex" : "none")};
    width: 450px;
    position: absolute;
    bottom: 0;
    right: 60px;
    left: unset;
    top: unset;
    border: none;
    box-shadow: rgba(150, 150, 150, 0.2) 0px 10px 30px 0px,
        rgba(150, 150, 150, 0.2) 0px 0px 0px 1px;
    height: 80dvh;
    max-height: 800px;
    border-radius: var(--radius-md);
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: white;
    z-index: 2147483646;

    @media (max-width: 600px) or (max-height: 500px) {
        position: fixed;
        top: 0;
        left: 0;
        right: unset;
        bottom: unset;
        width: 100vw;
        height: 100dvh;
        border-radius: 0;
    }
`;

const CloseButton = styled.div`
    display: none;
    @media (max-width: 600px) or (max-height: 500px) {
        position: fixed;
        top: 16px;
        right: 56px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        
        & i {
            color: #334144;
            font-size: 22px;
        }
    }
`;

const Loader = styled.div`
    width: 50px;
    height: 50px;
    border: 4px solid #20bed6;
    border-radius: 30px;
    animation: ${Pulsate} 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
`;

const Button = styled.div`
    width: 48px;
    height: 48px;
    border-radius: var(--radius-full);
    background: #20bec6;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    & svg {
        transform: translateY(-2px);
    }
`;

const IframeLink = styled.iframe`
    display: ${({ $show }) => ($show ? "block" : "none")};
    border: none;
    width: 100%;
    height: 100%;
    background-color: #fff;
    overflow: hidden;
`;
