// @flow
import apiConfig from "../api/config";
import { METHODS } from "@pcloud/web-utilities/dist/api/config.api";
import store from "../lib/state/store";
import { getLocationFromUrl } from "../lib/utils";
import hashManager from "../lib/hashManager";

export function prepUrl(ret) {
  const prot = "https";

  if (ret.hosts && ret.hosts[0]) {
    return prot + "://" + ret.hosts[0] + ret.path;
  }
}

export function downloadLinkFile(params) {
  HFN.apiMethod(
    "getfilelink",
    params,
    function (ret) {
      console.log("sending to: ", HFN.prepUrl(ret));

      window.open(HFN.prepUrl(ret));
    },
    { async: false }
  );
}

export function apiMethodUrl(server: string, method: string, params: any) {
  const state = store.getState();
  let currentServer = server || apiConfig.apiServer;
  let qarr = [];
  let n;

  if (METHODS[method] && METHODS[method].location) {
    const api = getLocationFromUrl() && getLocationFromUrl().api
    currentServer = api || apiConfig.defaultApiServer;
  }

  if (!params.auth && METHODS[method] && METHODS[method].reqauth)
    if (state.pCloudUser.logged) {
      params.auth = state.pCloudUser.token;
    } else {
      hashManager.pushState({ page: "login" });
      return false;
    }
  for (n in params) qarr.push(encodeURIComponent(n) + "=" + encodeURIComponent(params[n]));
  return apiConfig.apiScheme + "://" + currentServer + "/" + method + "?" + qarr.join("&");
}

export function apiMethodParams(params) {
  let qarr = [];
  let n;

  for (n in params) qarr.push(encodeURIComponent(n) + "=" + encodeURIComponent(params[n]));
  return qarr.join("&");
}
