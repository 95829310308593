import React, { useEffect, useState, useRef } from 'react'
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from "react-redux";
import {
  stepsMap
} from "../journey/misc";
import { customerJourneySteps } from './leftMenuOptions';
import useOutsideClicker from "@pcloud/web-utilities/dist/hooks/useOutsideClicker";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { renderQuota } from "@pcloud/web-utilities/dist/plans/utils.plans";
import { ONBOARDING_ID_UNLOCK_SPACE } from "../HighlightOnboardingComponent/constants";
import CustomerJourneyContent from "../HighlightOnboardingComponent/Contents/CustomerJourneyContent";
import HighlightOnboardingComponent from "../HighlightOnboardingComponent";
import { addItem } from "../../lib/state/reducers/onboardingSlice";
import useWindowDimensions from "../../lib/hooks/useWindowDimensions";
import { MOBILE_WIDTH_BREAKPOINT } from "../../config/constants";
import { setLeftMenuContent } from "../../lib/state/reducers/contentSlice";
import hashManager from "../../lib/hashManager";
import { PAGES_EMAIL_REDIRECTS } from "../../containers/EmailRedirectPagesContainer/constants";

const CustomerJourney = ({ closeMobileMenu }) => {
  const { bodyWidth } = useWindowDimensions();
  const isMobile = bodyWidth < MOBILE_WIDTH_BREAKPOINT;
  const dispatch = useDispatch();
  const leftMenuContent = useSelector(({ content }) => content.leftMenuContent) || undefined;
  const userInfo = useSelector(({ pCloudUser }) => pCloudUser.userinfo);
  const { quota, freequota } = userInfo;
  const lockedQuota = renderQuota(Math.max(0, freequota - quota))
  const unlockedQuota = renderQuota(quota);
  const containerRef = useRef(null);
  const { toggled, isProfileOpened, activeItem, activeItemIndex } = leftMenuContent;
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    if (
      bodyWidth >= MOBILE_WIDTH_BREAKPOINT &&
      !HFN.config.isDlink() &&
      !PAGES_EMAIL_REDIRECTS.includes(hashManager.getState('page')) &&
      !(
        typeof userInfo.journey === 'undefined' ||
        Object.values(userInfo.journey.steps).every(value => value === true) ||
        (
          new Date(userInfo.registered).getTime() <
          new Date("2017-10-30 11:00:00 GMT")
        ) ||
        userInfo.premium || userInfo.business || userInfo.vivapcloud ||
        (!userInfo.premium && userInfo.freequota === userInfo.quota)
      )
    ) {
      dispatch(addItem(ONBOARDING_ID_UNLOCK_SPACE));
    }
  }, []);

  useOutsideClicker(containerRef, () => {
    setOpened(false);
  });

  if (typeof userInfo.journey === 'undefined') {
    return null;
  } else if (Object.values(userInfo.journey.steps).every(value => value === true)) {
    return null;
  } else if (
    new Date(userInfo.registered).getTime() <
    new Date("2017-10-30 11:00:00 GMT")
  ) {
    return null;
  } else if (userInfo.premium || userInfo.business || userInfo.vivapcloud) {
    return null;
  } else if (!userInfo.premium && userInfo.freequota === userInfo.quota) {
    return null;
  }

  const showMoreInfo = () => {
    dispatch(setLeftMenuContent({ ...leftMenuContent, activeItem: "", activeItemIndex: -1 }));
    hashManager.pushState({ page: 'unlockspace' });
    closeMobileMenu();
    setOpened(false);
  }

  const renderMainInfo = (isInMenu) => {
    return (<>
      <Title $opened={isInMenu}>{__("customer_journey_title", "Unlock Space")}</Title>
      <UnlockedSpace $opened={isInMenu}>
        {__("Unlocked")}: {unlockedQuota} - {__("Locked")}: {lockedQuota}
      </UnlockedSpace>

      {!isInMenu && (
        <FontAwesomeIconHolder>
          <HighlightOnboardingComponent
            id={ONBOARDING_ID_UNLOCK_SPACE}
            content={[<CustomerJourneyContent />]}
            temporaryHide={opened || isProfileOpened || toggled}
            showOverlay={true}
            notShowAfterClose={true}
            place={"top-right"}
            delay={500}
          >
            <FontAwesomeIconToggle className="smallIcon darkColorIcon fa-regular fa-chevron-up" onClick={() => setOpened((prev) => !prev)} $opened={isInMenu} />
          </HighlightOnboardingComponent>
        </FontAwesomeIconHolder>
      )}

      {isInMenu && (
        <FontAwesomeIconHolder>
          <FontAwesomeIconToggle className="smallIcon darkColorIcon fa-regular fa-chevron-up" onClick={() => setOpened((prev) => !prev)} $opened={isInMenu} />
        </FontAwesomeIconHolder>
      )}
    </>)
  }

  return (
    <>
      <MenuContainer ref={containerRef} $opened={opened}>
        {renderMainInfo(true)}
        {Object.entries(customerJourneySteps).map(([key, value], index) => {
          return (
            <MenuItem key={key}>
              <MenuItemSize $completed={userInfo.journey.steps[key]}>1 GB</MenuItemSize>
              <MenuItemText>{__(`${value}`)}</MenuItemText>
            </MenuItem>
          )
        })}
        <MoreInfoLink onClick={showMoreInfo}>{__("customer_journey_more_info_label", "More info")}</MoreInfoLink>
      </MenuContainer>
      {(toggled && !isMobile) ?
        <FontAwesomeIconPrize className="baseIcon fa-solid fa-gift" onClick={() => setOpened(true)}
          data-tooltip-id="left-menu-item-tooltip" data-tooltip-content={__("customer_journey_title", "Unlock Space")} /> :
        <Container onClick={() => setOpened(true)} onPointerDown={(e) => e.preventDefault()}>
          {renderMainInfo(false)}
        </Container>}
    </>
  )
}

export default CustomerJourney;

const Container = styled.div`
  height: 48px;
  padding: 0 8px 8px var(--spacing-md);
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
`;

const Title = styled.h2`
  color: var(--text-primary);
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Inter;
  font-size:  ${({ $opened }) => $opened ? 16 : 14}px;
  font-weight: ${({ $opened }) => $opened ? 500 : 400};
  line-height: ${({ $opened }) => $opened ? 22 : 20}px;
  margin: 0;
`;

const UnlockedSpace = styled.p`
  color: ${({ $opened }) => $opened ? 'var(--text-link)' : 'var(--text-secondary)'};
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Roboto;
  font-size: ${({ $opened }) => $opened ? 14 : 11}px;
  font-weight: 400;
  line-height: ${({ $opened }) => $opened ? 20 : 16}px;
  margin: 0 0 ${({ $opened }) => $opened ? 'var(--spacing-md)' : 0} 0;
`;

const FontAwesomeIconHolder = styled.div`
  position: absolute;
  top: ${({ $opened }) => $opened ? 30 : 6}px;
  right: ${({ $opened }) => $opened ? 10 : 0}px;
  cursor: pointer;
`;
const FontAwesomeIconToggle = styled.i`
  ${({ $opened }) => $opened && css`
    transform: rotate(180deg);
  `};

  cursor: pointer;
  padding: var(--spaces-1);
`;

const MenuContainer = styled.div`
  left: var(--spacing-md);
  bottom: var(--spacing-xs);
  width: auto;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 1001;
  background-color: var(--color-base-white);
  padding: ${({ $opened }) => $opened ? 'var(--spacing-lg) var(--spacing-md) var(--spacing-md) var(--spacing-md)' : 0};
  border-radius: var(--radius-minimum);
  border: 0.5px rgba(0, 0, 0, 0.25);
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  height: ${({ $opened }) => $opened ? '426' : '0'}px;
  transition: height 0.5s ease;
  box-sizing: border-box;
`

const MenuItem = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const MenuItemSize = styled.div`
  width: 48px;
  height: 32px;
  border-radius: var(--radius-rounded);
  background: ${({ $completed }) => $completed ? 'var(--surface-selected)' : 'var(--surface-primary)'};
  margin-right: var(--spacing-md);
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ $completed }) => $completed ? 'var(--text-link)' : 'var(--text-primary)'};
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

const MenuItemText = styled.div`
  color: var(--text-primary);
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  white-space: nowrap;
`;

const MoreInfoLink = styled.button`
  background-color: transparent;
  height: 40px;
  color: var(--Primary-500, #17BED0);
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.1px;
  padding: 12px;
  width: max-content;
  border: none;
  margin-left: auto;
  cursor: pointer;
`

const FontAwesomeIconPrize = styled.i`
  padding: 12px 0;
  text-align: center;
  display: block;
  cursor: pointer;
  outline: none;
  background: linear-gradient(180deg, #156A73 0%, #001C1F 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const StyledReactTooltip = styled(ReactTooltip)`
  background-color: var(--color-grey-800);
  z-index: 1000;
  color: var(--color-base-white);
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Roboto;
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
`