// @flow

import React from "react";
import { connect } from "react-redux";
import Componentify from "react-componentify";
import styled from "styled-components";
import { hiDPI } from "polished";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { TSpan, HtmlTString } from "../components/intl";
import BoxContainer from "../components/BoxContainer";
import IconStats from "../components/IconStats";
import Button from "../components/ButtonDefault";
import SecondaryButton from "../components/SecondaryButton";
import InputText from "../components/InputText";
import apiMethod from "../api/apiMethod";
import { toBeautyMonthYear, validateEmail, loadFbApi } from "../lib/utils";
import { URLMY } from "@pcloud/web-utilities/dist/config";
import { boldConverter } from "../lib/componentifyConverters";
import InfoIcon from "../../root/img/svg/info-icon.svg"

import type { Userinfo } from "../types/user";

type InviteFriendsProps = {
	token: string,
	userinfo: Userinfo
};

const terms = {
	free_quota_increase_up_to_10GB:
		"Free quota can be increased up to a maximum of 10 GB.",
	count_all_invited:
		"Users invited before free quota has reached it's maximum are counted for the challenge.",
	user_can_win_only_once: "One user can win only once.",
	reset_stats_every_month:
		"Stats are reset every month and are starting from 0.",
	only_users_with_verified_emails_count:
		"Only invited users with verified emails count as a successful invite.",
	random_picked_winner: "The winner is picked on a random basis.",
	every_invite_increases_chance_by_1:
		"Every successful invite increases your chance to win by 1.",
	preventing_abuse_same_ip_address:
		"For preventing abuse we will not be counting more that 10 people who are both invited and registered from the same IP address.",
	// eligable_to_affiliate:
	// 	"<b>You are eligible to an affiliate commission if a person you refer buys from our products. <a href='https://partner.pcloud.com/login/register' target='_blank'>Register here!</b>"
};

const showErrorAlert = (...args) => {
	HFN.message(...args);
};

class InviteFriendsContainer extends React.Component<InviteFriendsProps> {
	constructor(props: InviteFriendsProps) {
		super(props);
		this.state = {
			invites: {},
			inviteLink: "",
			contactsLoaded: false,
			contacts: [],
			qEngine: {},
			combo: {},
			isLoadedFbApi: null
		};
		this.inputEmails = React.createRef();

		this.validateFields = this.validateFields.bind(this);
		this.sendInvitations = this.sendInvitations.bind(this);
	}

	componentDidMount() {
		this.getInvitesInfo();
		//this.getInviteLink();
		this.getContactList();
		const self = this;
		loadFbApi().then(function(result){
			self.setState({isLoadedFbApi: result})
		});
	}

	input(name, value) {
		this.setState({ [name]: value });
	}

	sendInvitations() {
		const { combo, qEngine } = this;

		let vals = combo.getFullVals(true);
		let emails = [];
		let fb = [];
		let fbNames = [];
		let message = $("textarea").val();
		let manuallyAdded = [];

		for (let n = 0; n < vals.length; ++n) {
			if (vals[n].data && vals[n].data.source == 2) {
				fb.push({ id: vals[n].data.value, obj: vals[n].obj });
				fbNames.push(vals[n].data.name);
			} else if (vals[n].data && vals[n].data.source == 3) {
				emails.push({ email: vals[n].data.value, obj: vals[n].obj });
			} else if (validateEmail(vals[n].val)) {
				emails.push({ email: vals[n].val, obj: vals[n].obj });
				if (!vals[n].data && manuallyAdded.indexOf(vals[n].val) == -1) {
					qEngine.add([{ name: vals[n].val, value: vals[n].val, source: 1 }]);
					manuallyAdded.push(vals[n].val);
				}
			} else {
				vals[n].obj
					.addClass("error")
					.find(".combo-res")
					.tooltip("destroy")
					.attr("title", __("This is not a valid contact or an email."));
			}
		}

		if (!emails.length && !fb.length) {
			HFN.message(__("Please, enter a contact or a valid e-mail."), "error");
			return;
		}

		let self = this;

		let xhr = [],
			callbacksAtOnce = [],
			success = false;
		for (let n = 0; n < emails.length; ++n) {
			(function(n) {
				xhr.push(
					apiMethod(
						"sendinvitelink",
						{ auth: self.props.token, mail: emails[n].email },
						ret => {
							callbacksAtOnce.push(function() {
								emails[n].obj.remove();
							});
							success = true;
						},
						{
							errorCallback: ret => {
								showErrorAlert(ret.error, "error");
								callbacksAtOnce.push(function() {
									emails[n].obj
										.addClass("error")
										.find(".combo-res")
										.tooltip("destroy")
										.attr("title", __(ret.error))
										.tooltip(/*{ container: '.tomails' }*/);
								});
							}
						}
					)
				);
			})(n);
		}

		$.when.apply($, xhr).then(function() {
			for (let n = 0; n < callbacksAtOnce.length; ++n) {
				callbacksAtOnce[n]();
			}

			if (success) {
				HFN.message(__("Link sent!"));
			}

			combo.onRemove();
		});
	}

	getContactList() {
		apiMethod(
			"contactlist",
			{ auth: this.props.token },
			ret => {
				this.setState({ contactsLoaded: true, contacts: ret.contacts });
			},
			{
				errorCallback: ret => {
					showErrorAlert(ret.error, "error");
				}
			}
		);
	}

	getInvitesInfo() {
		apiMethod(
			"getinvitesinfo",
			{ auth: this.props.token },
			ret => {
				this.setState({
					invites: ret
				});
			},
			{
				errorCallback: ret => {
					showErrorAlert(ret.error, "error");
				}
			}
		);
	}

	getInviteLink() {
		apiMethod(
			"invite",
			{ auth: this.props.token },
			ret => {
				this.setState({
					inviteLink: ret
				});
			},
			{
				errorCallback: ret => {
					showErrorAlert(ret.error, "error");
				}
			}
		);
	}

	validateFields(field) {
		if (field && field.trim()) {
			return true;
		}
		return false;
	}

	copyToClipboard(shareUrl) {
		let copysupported = document.queryCommandSupported("copy"),
			is_opera = navigator.userAgent.toLowerCase().indexOf("opera") == -1;

		if (is_opera) copysupported = true;
		if (copysupported) {
			const el = document.createElement("textarea");
			el.value = shareUrl;
			document.body.appendChild(el);

			el.select();
			document.execCommand("copy");

			document.body.removeChild(el);

			HFN.message(__("Link copied."));
		}
		if (!copysupported) {
			HFN.message(
				__("browser_doesnt_support_operation", "Your browser doesn't support this operation. Please copy manually")
			);
		}
	}

	shareFacebook(shareUrl) {
		const {isLoadedFbApi} = this.state;

		// Dynamically gather and set the FB share data. 
		var FBDesc      = 'pCloud is the place where you can save photos, videos and documents. Access them anywhere you go, on any device.';
		var FBTitle     = 'Join pCloud today and get 1 GB bonus!';
		var FBLink      = shareUrl;
		var FBPic       = (URLMY + "img/referral-fb.png").replace(/^\/+/i, "https://")

		// Open FB share popup
		isLoadedFbApi && FB.ui({
			method: 'share_open_graph',
			mobile_iframe: true,
			action_type: 'og.shares',
			action_properties: JSON.stringify({
				object: {
					'og:type': 'object',
					'og:url': FBLink,
					'og:title': FBTitle,
					'og:description': FBDesc,
					'og:image': FBPic,
					'og:image:width': 1200,
					'og:image:height': 628
				}
			})
		},
		function (response) {
		})
	}

	shareTwitter(shareUrl) {
		window.open(
			"https://twitter.com/intent/tweet?url=" +
				encodeURIComponent(shareUrl) +
				"&text=" +
				encodeURIComponent("Join pCloud today and get 1 GB bonus!"),
			"twitter-share",
			"width=550,height=450"
		);
		return false;
	}

	prepareSendInput(contacts) {
		if (this.inputPrepared || !this.inputEmails.current) {
			return;
		}
		this.inputPrepared = true;
		let filteredContacts = HFN.filterMailContacts(contacts);
		let qEngine;
		let combo;

		let tokenizer = function(name) {
			let ret = Bloodhound.tokenizers.whitespace(name.replace(/\(?\)?/g, ""));
			if (ret.indexOf(name.replace(/\(?\)?/g, "")) == -1)
				ret.push(name.replace(/\(?\)?/g, ""));
			return ret;
		};

		qEngine = new Bloodhound({
			name: "name",
			datumTokenizer: function(r) {
				let ret = Bloodhound.tokenizers.whitespace(
					r.name.replace(/\(?\)?/g, "")
				);
				if (ret.indexOf(r.name.replace(/\(?\)?/g, "")) == -1)
					ret.push(r.name.replace(/\(?\)?/g, ""));
				return ret;
			},
			queryTokenizer: Bloodhound.tokenizers.whitespace,
			local: filteredContacts
		});
		this.qEngine = qEngine;

		let orig_get = qEngine.get;
		qEngine.get = function(query, cb) {
			return orig_get.apply(qEngine, [
				query,
				function(suggestions) {
					if (!suggestions) return cb(suggestions);
					suggestions.forEach(function(s) {
						let tokens = tokenizer(s.name.toLowerCase()),
							score = 0;
						for (let n = 0; n < tokens.length; ++n) {
							if (tokens[n].indexOf(query) == 0) score++;
						}
						s.exact_match = score;
					});
					suggestions.sort(function(a, b) {
						return a.exact_match > b.exact_match
							? -1
							: a.exact_match < b.exact_match ? 1 : 0;
					});
					cb(suggestions);
				}
			]);
		};

		qEngine.initialize();

		let comboOptions = {
			place: this.inputEmails.current,
			name: "emails",
			//width: "100%",
			sourceKey: "name",
			source: qEngine,
			placeholder: __("add_emails", "Add email addresses"),
			maxNotFoundSymbols: 5,
			splitBy: [9, 32, 13, 188],
			getShowVal: function(r) {
				return r;
			},

			fancyResultBox: function(box, data) {
				let ent;

				if (data && data.data) {
					ent = data.data;
					if (ent.source == 2)
						box.prepend(
							'<img src="/img/fb-tag.png" width="16" height="16" class="inf">'
						);
					else if (ent.source == 3)
						box.prepend(
							'<img src="/img/gm-tag.png" width="16" height="16" class="inf">'
						);
					else if (ent.source == 1)
						box.prepend(
							'<img src="/img/or-mail-small.png" width="16" height="16" class="inf">'
						);
				} else if (box.data("val")) {
					if (validateEmail(box.data("val")))
						box.prepend(
							'<img src="/img/or-mail-small.png" width="16" height="16" class="inf">'
						);
				}
			},

			imgCloseFile: "/img/close-label.png",

			extraMulticompleteOpts: {
				suggestAt: this.inputEmails.current,
				boxRender: function(data) {
					let box = $("<div>"),
						img = $('<img width="32" height="32">');

					box
						.addClass("clearfix")
						.append($('<div class="iconw"></div>').append(img))
						.append(
							$('<div class="textpart"></div>')
								.append($('<span class="title"></span>').text(data.name))
								.append($('<span class="explain"></span>'))
						);

					if (data.source == "import") {
						if (data.value == "Facebook") {
							img.attr("src", "/img/fab.png");
						} else if (data.value == "Gmail") {
							img.attr("src", "/img/gmail.png");
						}
						box.find(".explain").text(
							__("Import contacts from %source%", false, {
								source: data.value
							})
						);
					} else if (data.source == 3) {
						img.attr("src", "/img/gmail.png");
						box.find(".explain").text(data.value);
					} else if (data.source == 2) {
						img.attr(
							"src",
							"https://graph.facebook.com/" +
								data.value +
								"/picture?type=square"
						);
						box
							.find(".iconw")
							.append('<img src="/img/fb-over.png" class="smicon">');
						box.find(".explain").text(__("Facebook Message"));
					} else if (data.source == 1) {
						img.attr("src", "/img/or-mail.png");
						box.find(".explain").text(__("Send Email"));
					}

					return box;
				}
			}
		};

		combo = new combo2(comboOptions);
		this.combo = combo;
	}

	render() {
		const {
			emails,
			invites,
			inviteLink,
			contactsLoaded,
			contacts,
			isLoadedFbApi
		} = this.state;

		const invitationStats = invites.invites;
		const lastWinner = invites.winner;

		const { emailverified } = this.props.userinfo;

		if (emailverified && !inviteLink) {
			this.getInviteLink();
		}

		if (contactsLoaded && inviteLink && emailverified) {
			this.prepareSendInput(contacts);
		}

		return (
			<ChallengeWrapper>
				<Title>{__("enter_challenge", "Enter the Challenge!")}</Title>
				<Content>
					<span>
						{__(
							"invite_friends_win_500gb_lifetime",
							"Invite as many friends you can and win 500GB space for a lifetime."
						)}
					</span>
					<TooltipWrapper>
						<InfoIcon data-tooltip-id="invitefriends-info-tooltip" width="16px" height="16px"/>
						<ReactTooltip
							className="cv-tooltip"
							id="invitefriends-info-tooltip"
							multiline
							noArrow
							offset={12}
							opacity={1}
						>
							{__("invite_friends_birthday_tooltip", "Both users should have verified emails")}
						</ReactTooltip>
					</TooltipWrapper>
				</Content>

				<VideoInvite>
					<object
						width="100%"
						height="200px"
						data="img/invite-friends-video.html"
					/>
				</VideoInvite>

				{emailverified ? (
					<div>
						<WrapperDivWithMargin>
							<SubTitle>{__("Send Invite", "Send Invitation")}</SubTitle>
							{contactsLoaded && inviteLink ? (
								<BoxContainer
									color="gray"
									border="gray"
									className="challenge-boxes"
								>
									<Wrapper>
										<Note>
											<Componentify text={__("invite_friends_note")} converters={[boldConverter]}/>
										</Note>
										<div className="send-invitations">
											<div className="tomails" ref={this.inputEmails}/>
											<Button
												inline
												className="sendbut"
												onClick={this.sendInvitations}
											>
												{__("Send", "Send")}
											</Button>
										</div>
										<div className="share-link">
											{__("or_share_invitation_link", "Or share invitation link")}
										</div>
										<SecondaryButton
											type="link"
											onClick={this.copyToClipboard.bind(this, inviteLink.url)}
										>
											{__("copy_invite_link", "Copy invite link")}
										</SecondaryButton>
										<SecondaryButton
											type="fb"
											onClick={this.shareFacebook.bind(this, inviteLink.url)}
										>
											{__("Share on Facebook", "Share on Facebook")}
										</SecondaryButton>
										<SecondaryButton
											type="twitter"
											onClick={this.shareTwitter.bind(this, inviteLink.url)}
										>
											{__("Share on Twitter", "Share on Twitter")}
										</SecondaryButton>
										
									</Wrapper>
								</BoxContainer>
							) : (
								<LoadingInvitationLink>
									<img src="/ZCDNURL/img/loading.gif" />
								</LoadingInvitationLink>
							)}
						</WrapperDivWithMargin>

						{lastWinner ? (
							<WrapperDivWithMargin>
								<SubTitle>
									{__("last_winner", "Last winner")}
									<WinnerPeriod>
										{" "}
										({toBeautyMonthYear(lastWinner.dt)}){" "}
									</WinnerPeriod>
								</SubTitle>
								<BoxContainer
									color="gray"
									border="gray"
									className="challenge-boxes"
								>
									<WinnerStatsWrapper>
										<WinnerStats>{lastWinner.email}</WinnerStats>
										<WinnerStats>
											with <b>{lastWinner.totalinvites}</b> invites
										</WinnerStats>
										<WinnerStats>
											<TSpan id="won_500gb_space">Won 500 GB space</TSpan>
										</WinnerStats>
									</WinnerStatsWrapper>
								</BoxContainer>
							</WrapperDivWithMargin>
						) : (
							""
						)}

						{invitationStats ? (
							<WrapperDivWithMargin>
								<SubTitle>{__("invitation_stats", "Invitation stats")}</SubTitle>
								<BoxContainer
									color="gray"
									border="gray"
									className="challenge-boxes"
								>
									<InvitationStatsWrapper>
										<IconStats
											title={__(
												"total_invites_this_month",
												"Total invites this month"
											)}
											value={invitationStats.thismonth}
											icon="allinvites"
										/>
										<IconStats
											title={__("you_have_invited", "You have invited")}
											value={invitationStats.total}
											icon="myinvites"
										/>
										<IconStats
											title={__("your_chance_to_win", "Your chance to win")}
											value={invitationStats.chance > 0 ? "1:" + invitationStats.chance : 0}
											icon="chance"
										/>
									</InvitationStatsWrapper>
								</BoxContainer>
							</WrapperDivWithMargin>
						) : (
							""
						)}
					</div>
				) : (
					<ErrorBox>{__("to_send_links_must_verify_your_email", "In order to send invite links to you friends, you must first verify your e-mail address.")}</ErrorBox>
				)}

				<SubTitle>{__("terms", "Terms")}</SubTitle>
				<BoxContainer color="gray" border="gray" className="challenge-boxes">
					<TermRowWrapper>
						{Object.keys(terms).map((key, i) => {
							const term = terms[key];

							return (
								<TermRow key={key}>
									{i + 1}. <span dangerouslySetInnerHTML={{ __html: __(key, term) }} />
								</TermRow>
							);
						})}
					</TermRowWrapper>
				</BoxContainer>
			</ChallengeWrapper>
		);
	}
}

export default connect(state => ({ userinfo: state.pCloudUser.userinfo, token: state.pCloudUser.token }))(
	InviteFriendsContainer
);

const WrapperDivWithMargin = styled.div`
	margin-bottom: 50px;
`;

const LoadingInvitationLink = styled.div`
	text-align: center;
`;

const VideoInvite = styled.div`
	margin: 40px auto;
`;

const ChallengeWrapper = styled.div`
	height: 100%;
	overflow-y: auto;
	& > .challenge-boxes {
		margin-bottom: 40px;
	}
`;

const FontStyleTitle = styled.div`
	line-height: 25px;
	font-weight: bold;
	color: #000;
`;

const Title = styled(FontStyleTitle)`
	font-size: 21px;
	text-align: center;
	margin-top: 15px;
`;

const SubTitle = styled(FontStyleTitle)`
	font-size: 19px;
	margin: 10px 0;
`;

const Content = styled(FontStyleTitle)`
	font-size: 19px;
	text-align: center;
	font-weight: normal;
`;

const WinnerPeriod = styled.span`
	font-weight: normal;
`;

const Wrapper = styled.div`
	text-align: center;
	padding: 10px 0;

	& > div {
		margin-right: 4px;
	}

	& > div.share-link {
		font-size: 13px;
		line-height: 20px;
		color: #999;
		margin: 15px 0;
	}

	& > div.send-invitations {
		.tomails {
			display: inline-block;
			min-width: 550px;
			width: auto;

			@media (max-width: 800px) {
				width: auto!important;
				min-width: auto;
			}

			.combo-contain {
				padding: 5px;
				box-shadow: none;
				border-radius: 5px;
				box-sizing: border-box;

				@media (max-width: 800px) {
					width: auto!important;
				}

				input {
					height: 28px;
					padding: 0 10px;
					font-size: 14px;
					box-sizing: border-box;

					@media (max-width: 800px) {
						width: auto!important;
					}
				}

				.combo-res {
					height: 18px;
					line-height: 18px;
					background: #f2f2f2;
					border-radius: 1px;
					margin: 0 5px 2px 0;

					.inf {
						margin: -1px 5px 0 0;
					}
				}
			}
			.combo-contain.focu,
			.combo-contain.focu input {
				background: #fff !important;
			}
		}
		a {
			min-width: 100px;
			margin-left: 5px;
			vertical-align: top;
		}
	}
`;

const TermRowWrapper = styled.ul`
	margin: 11px;
	padding-left: 0;
`;

const TermRow = styled.li`
	font-size: 12px;
	line-height: 15px;
	list-style-type: none;

	a {
		color: #17bed0;
		text-decoration: none;
	}
`;

const WinnerStatsWrapper = styled.div`
	text-align: center;

	& > div:first-child:before {
		display: inline-block;
		content: "";
		height: 24px;
		width: 24px;
		background-size: 24px 24px;
		vertical-align: middle;
		margin-right: 15px;

		background-image: url(${require("../../root/img/invite-friend/winner.png")});
		${hiDPI(1.5)} {
			background-image: url(${require("../../root/img/invite-friend/winner@2x.png")});
		}
	}
`;

const StatsBase = styled.div`
	max-width: 230px;
	width: 100%;
	display: inline-block;
	text-align: center;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
`;

const WinnerStats = styled(StatsBase)`
	font-size: 15px;
	line-height: 25px;
`;

const InvitationStatsWrapper = styled.div`
	text-align: center;
`;

const ErrorBox = styled.div`
	color: red;
	font-size: 16px;
	background: rgba(255, 0, 0, 0.05);
	border: 1px solid red;
	padding: 20px;
	text-align: center;
	margin: 50px 0;
`;

const Note = styled.div`
	font-size: 13px;
	margin-bottom: 20px;
	text-align: left;
	margin-left: 15px;
`;

const TooltipWrapper = styled.div`
  margin-left: 8px;
  display: inline-block;
  height: 16px;
  width: 16px;
  position: relative;
  top: 1px;


  .info-tooltip {
    padding: 15px 20px;
    max-width: 250px;
    border-radius: 6px;
    font-size: 12px;
    line-height: 1.35;
    font-weight: 400;
    text-align: center;
  }
`;
