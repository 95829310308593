// @flow

import React from "react";
// import "../../styles/comparePlansModal.css"
import styled, { keyframes } from "styled-components";
import { hiDPI } from "polished";

import Button, { ButtonCentered } from "./ButtonDefault";
import Modal from "./Modal";
import { URLSITE } from "@pcloud/web-utilities/dist/config";

type State = { isOpen: boolean };

class ModalWrapper extends React.Component<State> {
  constructor() {
    super();

    this.state = {
      isOpen: false
    };

    (this: any)._open = this._open.bind(this);
    (this: any)._close = this._close.bind(this);
  }

  _open() {
    this.setState({ isOpen: true });
  }

  _close() {
    this.setState({ isOpen: false });
  }

  componentDidMount() {
    this._open();
  }

  render() {
    return (
      <Modal align="top" opened={this.state.isOpen} onClose={this._close}>
        <ModalWrap>
          <DriveUpdateModal onClose={this._close} />
          <CloseButton onClick={this._close} />
        </ModalWrap>
      </Modal>
    );
  }
}

export default ModalWrapper;

function DriveUpdateModal({ onClose, onButtonClick }) {
  return (
    <Container>
      <WarningIcon/>
      <Header>MANDATORY pCloud Drive Update</Header>
      <Message>
        <p>In order to continue using pCloud Drive, you need to download and install the latest version of the app.<br />
        pCloud Drive's latest update includes crucial stability improvements that are required for the normal operation of the app and a renewed SSL certificate. <br />
        </p>
        <br />
        <a href={URLSITE + "download-free-online-cloud-file-storage.html"}><b>Please download and install it.</b></a>
      </Message>
    </Container>
  );
}

const ModalWrap = styled.div`
  position: relative;
`;

const Container = styled.div`
  text-align: center;
  border-radius: 15px;
  padding: 10px;
  background: #fefefe;
  width: 620px;
  font-family: "Roboto", sans-serif;
  color: #333;
`

const WarningIcon = styled.div`
  display: inline-block;
  margin: 25px;
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(${require("../../root/img/updatedrive/w.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img/updatedrive/w@2x.png")});
  }
`

const Header = styled.div`
  font-size: 17px;
  font-weight: bold;
  margin: 0px 30px 8px 30px;
`;

const Message = styled.div`
  font-size: 15px;
  margin: 0px 70px 25px 70px;
  line-height: 1.2;
  & a{
    color: #17bed0;
  }
`

const CloseButton = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  margin: 12px;
  background-position: center;
  cursor: pointer;
  background-size: contain;
  background-image: url(${require("../../root/img/updatedrive/close.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img/updatedrive/close@2x.png")});
  }

  &:hover {
    opacity: 0.8;
  }
`;
