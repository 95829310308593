import React, { useEffect } from "react";
import { __ } from "../../../../../../lib/translate";
import { Wrapper, Title, SubTitle, AppContainer } from "./styles";
import { detectOS } from "@pcloud/web-utilities/dist/lib/utils";
import DownloadAppsButtons from "../../../DownloadApp/DownloadAppsButtons";
import QRCodeTooltip from "../../../DownloadApp/QRCodeTooltip";

const MobileEmptyContainer = () => {
    const os = detectOS(false, true);

    useEffect(() => {
        localStorage.setItem("backupsLayersSeen", true);
    }, []);

    const title = __(
        "backups_mobile_title",
        "Effortlessly back up your phone’s gallery"
    );
    const subTitle = __(
        "backups_mobile_subtitle",
        "Keep your memories safe: Use pCloud’s Automatic Upload. Easily back up and access your photos from one secure place."
    );
    return (
        <Wrapper>
            <QRCodeTooltip />
            <Title>{title}</Title>
            <SubTitle>{subTitle}</SubTitle>
            <AppContainer>
                <DownloadAppsButtons device={os} />
            </AppContainer>
        </Wrapper>
    );
};

export default MobileEmptyContainer;
