// @flow

import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { __ } from "../../../lib/translate";

import MinusIcon from "@pcloud/web-utilities/dist/resources/images/svg/minus-icon.svg";
import PlusIcon from "@pcloud/web-utilities/dist/resources/images/svg/plus-icon.svg";
import { B_USERS } from "@pcloud/web-utilities/dist/config/constants";

type Props = {
  numOfUsers: number,
  min: number,
  max: number,
  disabled: Boolean,
  onChange: () => void
};

const UsersInput = ({
  numOfUsers = B_USERS.MIN,
  min = B_USERS.MIN,
  max = B_USERS.MAX,
  disabled = false,
  onChange = () => {}
}: Props) => {
  const [disabledMin, setDisabledMin] = useState(false);
  const [disabledMax, setDisabledMax] = useState(false);

  useEffect(() => {
    setDisabledMin(disabled || numOfUsers <= min);
    setDisabledMax(disabled || numOfUsers >= max);
  }, [disabled, max, min, numOfUsers]);

  const onChangeInput = e => {
    let value = e.target.value;

    if (value !== "" && value >= 0 && value > max) {
      value = max;
    }

    onChange(value ? parseInt(value) : value);
    console.log("On change value", value);
  };

  const validateUsersNumber = e => {
    const key = e.which || e.keyCode || e.charCode;
    const pressedCharacter = String.fromCharCode(key);
    let value = e.target.value.replace(/\s+/g, "");

    value = value.trim();
    if (!/[0-9 ]/.test(pressedCharacter)) {
      e.preventDefault();
    }
  };

  const onBtnUpClick = () => {
    if (disabled) {
      return;
    }
    const oldValue = parseFloat(numOfUsers || 0);
    let newVal = 0;
    if (oldValue >= max) {
      newVal = oldValue;
    } else {
      newVal = oldValue + 1;
    }
    onChange(parseInt(newVal));
  };

  const onBtnDownClick = () => {
    if (disabled) {
      return;
    }
    const oldValue = parseFloat(numOfUsers || 0);
    let newVal = 0;
    if (oldValue <= min) {
      newVal = oldValue;
    } else {
      newVal = oldValue - 1;
    }
    onChange(parseInt(newVal));
  };

  return (
    <Wrapper>
      <Title>{__("Users")}:</Title>
      <ButtonWrapper>
        <Button disabled={disabledMin} onClick={onBtnDownClick}>
          <MinusIcon width="14px" fill={disabledMin ? "#dadadc" : "#ff3434"} />
        </Button>
        <InputUsers
          type="number"
          min={min}
          max={max}
          value={numOfUsers}
          onInput={onChangeInput}
          onKeyPress={validateUsersNumber}
          onPaste={validateUsersNumber}
          autoFocus
          disabled={disabled}
        />
        <Button disabled={disabledMax} onClick={onBtnUpClick}>
          <PlusIcon width="14px" fill={disabledMax ? "#dadadc" : "#86c600"} />
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default UsersInput;

const Wrapper = styled.div``;

const Title = styled.div`
  font-size: 13px;
  font-weight: normal;
  color: #999;
  margin-bottom: 7px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 240px;
  padding: 5px;
  margin: 0 auto;
  border-radius: 20px;
  border: solid 1px #eee;
  background-color: #f2f2f2;
  box-sizing: border-box;
  
  @media (max-width: 500px) {
    width: 100%;
  }
`;

const InputUsers = styled.input`
  max-width: 100px;
  font-size: 17px;
  width: 50px;
  font-weight: bold;
  border: none;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.08);
  background-color: transparent;
  outline: none;
  text-align: center;
  /* Firefox */
  -moz-appearance: textfield;
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => (props.disabled ? "default" : "pointer")};
  width: 30px;
  height: 30px;
  border-radius: 18px;
  box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  box-sizing: border-box;
`;
