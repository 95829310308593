// @flow

import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";

import Modal, { OvalModalWrap, CloseModalIcon } from "../Modal";
import Button from "../ButtonDefault";
import FileIcon from "../FileIcon";
import { Loader } from "../../containers/TwoFactorAuth/SharedComponents";
import * as Style from "./styledComponents";

import { __ } from "../../lib/translate";
import { getIconType } from "../../lib/utils";
import { ICONS } from "../../lib/icons";

import store from "../../lib/state/store";
import { deleteContentItem } from "../../lib/state/reducers/contentSlice";

const DeleteLinkModal = ({ list }: { list: Array<any> }) => {
  const [isOpened, setIsOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(true);

  useEffect(() => {
    setLoadingModal(false);
    setIsOpened(true);
  }, []);

  const onEnter = useCallback(
    (e: any) => {
      if (e.keyCode === 13) {
        onDeleteClick();
      }
    },
    [onDeleteClick]
  );

  useEffect(() => {
    if (isOpened) {
      document.addEventListener("keyup", onEnter);
    } else {
      document.removeEventListener("keyup", onEnter);
    }
  }, [isOpened, onEnter]);

  const onCloseClick = () => {
    if (isLoading) {
      return;
    }
    setIsOpened(false);
  };

  const onDeleteClick = useCallback(() => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    const linksBatch = new batchApiCall({
      errorCallback: function(ret) {
        HFN.message(ret.error, "error");
        setIsLoading(false);
      }
    });

    let n;
    let dtype = "publink";

    for (n = 0; n < list.length; ++n) {
      if (list[n].uploadlinkid) {
        linksBatch.addCall("deleteuploadlink", { uploadlinkid: list[n].uploadlinkid });
        dtype = "puplink";
      } else linksBatch.addCall("deletepublink", { linkid: list[n].linkid });
    }

    linksBatch.execute(function() {
      if (dtype == "publink") {
        HFN.cache.expire(HFN.cache.cacheid("publinks", "all"));
        HFN.cache.expireMatch("api-listpublinks");
        triggerOpenPublinks(".publinks");
      } else {
        HFN.cache.expire(HFN.cache.cacheid("puplinks", "all"));
        HFN.cache.expireMatch('api-listuploadlinks');
        triggerOpenPuplinks(".puplinks");
      }
      for (n = 0; n < list.length; ++n) {
        store.dispatch(deleteContentItem(list[n]));
      }
      HFN.message(__("Link(s) Removed."));
      setIsOpened(false);
    });
  });

  const renderItem = (data, index) => {
    const { name, metadata } = data;
    const itemData = metadata || data;
    const { encrypted } = itemData;
    const iconName = getIconType(itemData);

    return (
      <Style.Row key={index}>
        <FileIcon item={iconName} type={ICONS.SVG_LIST} metadata={itemData} />
        <Title>{encrypted ? HFN.metaName(itemData) : name}</Title>
      </Style.Row>
    );
  };

  const renderContent = () => {
    return (
      <Style.Container>
        <Style.Header>{__("Remove link", "Remove link")}</Style.Header>
        <Style.Label>{__("remove_link_title", "Do you want to remove the link to:")}</Style.Label>
        <ItemsList>{list.map(renderItem)}</ItemsList>
        <Style.Footer>
          <Button
            color="lightgray4"
            style={{
              marginRight: "5px"
            }}
            onClick={onCloseClick}
          >
            {__("Cancel")}
          </Button>
          <Button
            loading={isLoading}
            disabled={isLoading}
            color="red"
            style={{
              marginLeft: "5px"
            }}
            onClick={onDeleteClick}
          >
            {__("Remove", "Remove")}
          </Button>
        </Style.Footer>
      </Style.Container>
    );
  };

  return (
    <Modal align="top" opened={isOpened} onClose={onCloseClick}>
      <OvalModalWrap>
        <CloseModalIcon onClick={onCloseClick} />
        <Content>{loadingModal ? <Loader /> : renderContent()}</Content>
      </OvalModalWrap>
    </Modal>
  );
};

export default DeleteLinkModal;

const Content = styled.div``;

const Title = styled.div`
  font-size: 15px;
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1;
  text-align: left;
`;

const ItemsList = styled(Style.Box)`
  line-height: 24px;
  padding: 10px;
  font-size: 15px;
  font-weight: normal;
  text-align: left;
  padding: 8px;
  justify-content: flex-start;
  flex-direction: column;
  max-height: 115px;
  overflow: auto;
  overflow-x: hidden;

  & > div:only-child {
    padding-bottom: 0px;
  }

  /* width */
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #eaeaea;
    border-radius: 3px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 3px;
    transition: background 200ms ease-in-out;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
