//@flow

import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { SwitchTransition, CSSTransition } from "react-transition-group";

import {
  SettingsWrapper,
  SettingsHeader,
  SettingsTitle,
  SettingsDescription,
  SettingOptionsContainer,
  InputWrapper,
  ToggleLabel,
  Error,
  FadeAnimationContainer
} from "./SharedStyledComponents";
import TopBar from "./TopBar";
import InputCheckbox from "../../components/InputCheckbox";
import Button from "../../components/ButtonDefault";
import { changePublinkApiCall } from "./utils";

import { __ } from "../../lib/translate";
import { setHasPassword } from "../../lib/state/reducers/sharedLinksSlice";

type Props = {
  allSettingsBack: any,
  bodyHeight: number,
  parentRef: any
};

const defaultTopOffest = 50;

const PasswordProtectionSetting = ({ allSettingsBack, bodyHeight, parentRef, currentSettingId }: Props) => {
  const linkData = useSelector(({ sharedLinks }) => sharedLinks.linkData);
  const isPremiumUser = useSelector(({ pCloudUser }) => pCloudUser.userinfo.premium);
  const isBusinessUser = useSelector(({ pCloudUser }) => pCloudUser.userinfo.business);
  const { linkId, hasPassword } = linkData;

  const [toggleState, setToggleState] = useState(hasPassword);
  const [loading, setLoading] = useState(false);
  const [changesInited, setChangesInited] = useState(false);
  const [passwordValue, setPasswordValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useDispatch();
  const passwordInput = useRef(null);

  const buttonTexts = {
    save: __("Save"),
    change: __("Change")
  };

  const buttonText = hasPassword && toggleState ? buttonTexts.change : buttonTexts.save;

  const handleToggleChange = () => {
    if (loading) {
      return;
    }

    const newToggleState = !toggleState;

    newToggleState && passwordInput.current.focus();
    setToggleState(newToggleState);
    setPasswordValue("");
    setErrorMessage("");

    if (hasPassword != newToggleState) {
      setChangesInited(true);
    } else {
      setChangesInited(false);
    }
  };

  const handlePasswordChange = event => {
    const value = event.target.value;
    setPasswordValue(value);

    if (value) {
      setChangesInited(true);
    } else {
      setChangesInited(false);
    }
  };

  const handleSubmit = () => {
    if (!changesInited) {
      return;
    }

    if (toggleState && passwordValue.length == 0) {
      setErrorMessage(__("password_required", "Password is requried."));
    } else if (toggleState && passwordValue.length < 6) {
      setErrorMessage(__("password_too_short", "Password is too short."));
    } else {
      errorMessage && setErrorMessage("");
      setLoading(true);
      changePublink();
    }
  };

  const changePublink = () => {
    const params = {
      linkid: linkId,
      iconformat: "id"
    };

    if (passwordValue && toggleState) {
      params.linkpassword = passwordValue;
    } else if (!toggleState) {
      params.deletepassword = true;
    }

    changePublinkApiCall(
      params,
      () => {
        if (typeof gtag === "function") {
          gtag("event", "shared_link_settings_click", {
            action: "password setting change",
            category: "setting",
            type: "premium",
            user_premium: (isPremiumUser || isBusinessUser) ? 1 : 0,
            location: "settings modal",
            value: toggleState ? "on" : "off"
          })
        }
        setLoading(false);
        dispatch(setHasPassword(toggleState));
        setChangesInited(false);
        HFN.message(__("shared_links_settings_updated"), "success");
      },
      ({ error }) => {
        setLoading(false);
        setErrorMessage(error);
      }
    );
  };

  const renderHeader = () => {
    return (
      <SettingsHeader>
        <SettingsTitle>{__("link_settings_password")}</SettingsTitle>
        <SettingsDescription>{__("link_settings_password_info")}</SettingsDescription>
      </SettingsHeader>
    );
  };

  const renderBody = () => {
    return (
      <SettingOptionsContainer disabledFeature={!(isPremiumUser || isBusinessUser)}>
        <form>
          <InputWrapper>
            <InputCheckbox
              checked={toggleState}
              onChange={handleToggleChange}
              name="password-toggle"
              color="cyan"
              size="small"
            />
            <ToggleLabel>{__("link_settings_password")}</ToggleLabel>
          </InputWrapper>

          <PasswordInput
            type="password"
            value={passwordValue}
            onChange={handlePasswordChange}
            ref={passwordInput}
            placeholder={__("link_password_placeholder")}
            autocomplete="off"
            disabled={!toggleState}
          />
          <FadeAnimationContainer>
            <SwitchTransition>
              <CSSTransition
                key={errorMessage}
                addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
                classNames="fade"
              >
                {errorMessage ? (
                  <Error>{errorMessage}</Error>
                ) : (
                  <PasswordLabel>{__("link_password_requirements")}</PasswordLabel>
                )}
              </CSSTransition>
            </SwitchTransition>
          </FadeAnimationContainer>
          <ButtonWrapper>
            <Button
              loading={loading}
              disabled={!changesInited}
              borderRadius="3"
              color="cyan"
              onClick={handleSubmit}
            >
              <FadeAnimationContainer>
                <SwitchTransition>
                  <CSSTransition
                    key={buttonText}
                    addEndListener={(node, done) =>
                      node.addEventListener("transitionend", done, false)
                    }
                    classNames="fade"
                  >
                    <span>{buttonText}</span>
                  </CSSTransition>
                </SwitchTransition>
              </FadeAnimationContainer>
            </Button>
          </ButtonWrapper>
        </form>
      </SettingOptionsContainer>
    );
  };

  return (
    <SettingsWrapper
      bodyHeight={bodyHeight}
      ref={parentRef}
      topOffest={isPremiumUser || isBusinessUser ? defaultTopOffest : 90}
    >
      <div>
        <TopBar settingId={currentSettingId} allSettingsBack={allSettingsBack} showAlert={!(isPremiumUser || isBusinessUser)} />
        {renderHeader()}
        {renderBody()}
      </div>
    </SettingsWrapper>
  );
};

export default PasswordProtectionSetting;

const PasswordInput = styled.input`
  height: 36px;
  width: 100%;
  margin-top: 10px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  padding: 12px;
  border-radius: 3.1px;
  box-shadow: inset 0px 3px 0 0 rgba(0, 0, 0, 0.03);
  border: solid 1px #c1c1c1;
  background-color: #ffffff;
  box-sizing: border-box;
  transition: opacity 200ms ease-in-out;
  font-family: "Roboto", sans-serif;
`;

const PasswordLabel = styled.p`
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: -0.11px;
  text-align: left;
  color: #888888;
  margin: 12px 0px;
`;

const ButtonWrapper = styled.div`
  a[disabled] {
    background-color: #dddddd;
    color: #a2a2a2;
    opacity: 1;
  }
`;
