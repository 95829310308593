// @flow

import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Componentify from "react-componentify";

import { __ } from "../../../lib/translate";
import { currencyToSign, getSubsPrice, getPriceWithCurrency } from "../../../lib/utils";
import { getBusinessPlanName } from "../../../lib/plansInfo"

import { ButtonWithIcon } from "../../../components/ButtonDefault";
import { InputStyledRadioWithBox } from "../../../components/InputStyledRadio";
import UsersInput from "./UsersInput";

import { Container, Header, MinUsersNote } from "./StyledComponents";
import { B_USERS, SUBSC_PERIOD } from "@pcloud/web-utilities/dist/config/constants";
import { businessPrices } from "@pcloud/web-utilities/dist/plans/prices";
import { BUSINESS_PLANS_ID_MAP_FROM_API } from "@pcloud/web-utilities/dist/plans/constants.plans";
import { linkConverterV2, boldConverter } from "@pcloud/web-utilities/dist/lib/componentifyConverters";

type Props = {
  setPeriod: () => void,
  setPrice: () => void,
  setCurrency: () => void,
  setMembers: () => void,
  setEndTrial: () => void,
  onSuccess: () => void
};

const StartSubscriptionStep = ({
  setPeriod,
  setPrice,
  setCurrency,
  setMembers,
  setEndTrial,
  onSuccess = () => {}
}: Props) => {
  const billingInfo = useSelector(({ business }) => business.billingInfo);
  const accountInfo = useSelector(({ business }) => business.accountInfo.account);
  const memberInfoAccount = accountInfo.memberinfo;
  const paymentSource = useSelector(({ pCloudUser }) => pCloudUser.paymentSource);
  const { currency: paymentSourceCurrency = "USD" } = paymentSource;
  const { memberinfo = {}, billing = {} } = billingInfo;
  const { period = SUBSC_PERIOD.YEARLY, memberprice = {}, businessplanid: planId, currency: billingCurrency } = billing;
  const currency = billingCurrency || paymentSourceCurrency;
  const curSign = currencyToSign(currency);
  const currencyCode = currency;
  const { minimum: minUsers, maximum: maxUsers = B_USERS.MAX, maximumupgrade: maxUpgrade = B_USERS.MAX, current: currentUsers} = memberinfo;
  const { current: currentAccountUsers} = memberInfoAccount;
  const planid = BUSINESS_PLANS_ID_MAP_FROM_API[planId];
  const { yearly = businessPrices[planid][currency].year, monthly = businessPrices[planid][currency].month } = memberprice;

  const [selectedUsers, setSelectedUsers] = useState(maxUsers || maxUpgrade);
  const [selectedRadioId, setSelectedRadioId] = useState(period);
  const [annualPrice, setAnnualPrice] = useState(0);
  const [monthlyPrice, setMonthlyPrice] = useState(0);
  const [inRangeUsers, setInRangeUsers] = useState(false);
  const [canProcced, setCanProceed] = useState(false);


  const minUsersForDowngrade = currentAccountUsers > minUsers ? currentAccountUsers : minUsers;

  const updatePrices = useCallback(
    users => {
      const amountForAnnual = getSubsPrice(users, SUBSC_PERIOD.YEARLY, parseFloat(yearly));
      const amountForMonthly = getSubsPrice(users, SUBSC_PERIOD.MONTHLY, parseFloat(monthly));

      setAnnualPrice(amountForAnnual);
      setMonthlyPrice(amountForMonthly);
    },
    [monthly, yearly]
  );

  useEffect(() => {
    const inRange = selectedUsers >= minUsersForDowngrade && selectedUsers <= maxUpgrade;
    setInRangeUsers(inRange);
    updatePrices(selectedUsers);
    setMembers(selectedUsers);

    const usersChanged = selectedUsers >= minUsersForDowngrade;
    setCanProceed(usersChanged);
  }, [selectedUsers, setMembers, updatePrices, minUsers, minUsersForDowngrade, maxUpgrade]);

  const getDisclaimerText = () => {
    let disclaimerText = __("business_switch");

    if (planId === 1) {
      return disclaimerText.replace("%PLANNAME%", "<b>" + __("plan_name_business_pro") + "</b>");
    } else if (planId === 2) {
      return disclaimerText.replace("%PLANNAME%", "<b>" + __("plan_name_business") + "</b>");
    }

    return disclaimerText.replace("%PLANNAME%", "");
  }

  const onSubmit = () => {
    if (!selectedUsers || !inRangeUsers || !canProcced) {
      return;
    }
    setCurrency(currency);
    setPeriod(selectedRadioId);
    if (selectedRadioId == SUBSC_PERIOD.YEARLY) {
      setPrice(annualPrice);
    } else if (selectedRadioId == SUBSC_PERIOD.MONTHLY) {
      setPrice(monthlyPrice);
    }
    setEndTrial(true);
    onSuccess();
  };

  const onChangeRadio = e => {
    setSelectedRadioId(parseInt(e.target.id));
  };

  const renderMinUsersNote = () => {
    let text = "";

    if (selectedUsers < minUsers) {
      text = __("busines_min_users", "Minimum 3 users required");
    } else if (selectedUsers < minUsersForDowngrade) {
      text = __("business_subscription_downgrade", "You cannot start a Business subscription with less users than you currently have in the account.");
    }
    return <MinUsersNote show={text}>{text}</MinUsersNote>;
  }

  return (
    <Container key="startSubscStep">
      <Header>{__("b_billing_start_subscription", "Start Business Subscription", { "PLANNAME": getBusinessPlanName(planId) })}</Header>
      <UsersInput
        numOfUsers={selectedUsers}
        min={minUsersForDowngrade}
        max={maxUpgrade}
        onChange={setSelectedUsers}
      />
      {renderMinUsersNote()}
      <Line />
      <RadioButtonsWrapper isChecked={false}>
        <RadioWrapper>
          <RadioLabel>{__("Yearly Subscription")}</RadioLabel>
          <InputStyledRadioWithBox
            text={
              <PriceText>
                {getPriceWithCurrency(annualPrice, currencyCode)} / <span>{__("year")}</span>
              </PriceText>
            }
            id={SUBSC_PERIOD.YEARLY}
            size="big"
            name="radio-group"
            color="#86c600"
            checked={selectedRadioId == SUBSC_PERIOD.YEARLY}
            onChange={onChangeRadio}
          />
        </RadioWrapper>

        <RadioWrapper>
          <RadioLabel>{__("Monthly Subscription")}</RadioLabel>
          <InputStyledRadioWithBox
            text={
              <PriceText>
                {getPriceWithCurrency(monthlyPrice, currencyCode)} / <span>{__("month")}</span>
              </PriceText>
            }
            id={SUBSC_PERIOD.MONTHLY}
            name="radio-group"
            color="#86c600"
            checked={selectedRadioId == SUBSC_PERIOD.MONTHLY}
            onChange={onChangeRadio}
          />
        </RadioWrapper>
      </RadioButtonsWrapper>

      <ButtonWithIcon
        color="green"
        style={{ marginTop: "40px", fontSize: "14px" }}
        onClick={onSubmit}
        minWidth="360px"
        disabledColor="#dddddd"
        disabled={!selectedUsers || !inRangeUsers || !canProcced}
        icon="secure"
        text={__("b_billing_proceed_to_payment", "proceed to payment").toUpperCase()}
      />
      <Disclaimer>
        <Componentify
          text={getDisclaimerText()}
          converters={[linkConverterV2, boldConverter]}
        />
      </Disclaimer>
    </Container>
  );
};

export default StartSubscriptionStep;

const Line = styled.div`
  height: 1px;
  background-color: #eee;
  margin: 40px 0;
`;

const RadioButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;

  @media (max-width: 600px) {
    flex-direction: column;
    gap: 8px;
  }
`;

const RadioWrapper = styled.div`
  display: inline-block;
  text-align: left;
  width: 49%;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const RadioLabel = styled.div`
  font-size: 13px;
  font-weight: normal;
  color: #999;
  margin-bottom: 6px;
`;

const PriceText = styled.div`
  font-size: 17px;
  font-weight: bold;
  span {
    font-weight: normal;
  }
`;

const Disclaimer = styled.div`
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: center;
  color: #999999;
  margin-top: 20px;
  font-size: 12px;
  margin-top: 16px;

  a {
    text-decoration: none;
    color: #17bed0;
  }
`;
