import { createSlice } from "@reduxjs/toolkit";
import { setAutoFreeze } from 'immer';

setAutoFreeze(false);

const initialState = {
    loaded: false,
    linkData: {
        code: "",
        comment: "",
        name: "",
        uploadlinkid: 0,
        link: "",
        mail: "",
        linkName: "",
        hasPassword: false,
        expirationDate: false,
        created: "",
        expires: "",
        modified: "",
        maxspace: 0,
        metadata: {},
        files: 0,
        id: 0,
        space: 0
    }
};

export const fileRequestsSlice = createSlice({
    name: "fileRequests",
    initialState: initialState,
    reducers: {
        loadFileRequestData: (state, action) => {
            state.loaded = true;
            state.linkData = action.payload;
        },
        setMessageSettings: (state, action) => {
            state.linkData = { ...state.linkData, comment: action.payload };
        },
        setLimitSettings: (state, action) => {
            state.linkData = { ...state.linkData, hasLimit: action.payload };
        },
        setExpiresSettings: (state, action) => {
            state.linkData = { ...state.linkData, expires: action.payload };
        }
    }
});

export const {
    loadFileRequestData,
    setMessageSettings,
    setLimitSettings,
    setExpiresSettings
} = fileRequestsSlice.actions;

export default fileRequestsSlice.reducer;
