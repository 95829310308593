// @flow

import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { hiDPI } from "polished";

import type { JourneySteps } from "../../types/user";
import { __ } from "../../lib/translate";
import { getHash } from "../../lib/utils";
import { DEVICE_OS } from "@pcloud/web-utilities/dist/config/constants";

import UnlockSpaceStepImage from "./UnlockSpaceStepImage";
import UnlockSpaceArrow from "./UnlockSpaceArrow";
import Accordion from "../Accordion";

type Props = {
  journeySteps: JourneySteps,
  quota: number,
  usedQuota: number,
  freeQuota: number
};

const osToImgSize: {
  [key: ?(string | number)]: Array<Object>
} = {
  "1": [
    { width: 20, height: 16 },
    { width: 16, height: 20 },
    { width: 16, height: 20 },
    { width: 14, height: 22 },
    { width: 24, height: 16 },
    { width: 20, height: 14 }
  ],
  "3": [
    { width: 29, height: 29 },
    { width: 29, height: 29 },
    { width: 29, height: 29 },
    { width: 29, height: 29 },
    { width: 29, height: 29 },
    { width: 29, height: 29 }
  ]
};
const stepNumberToHeader = [
  "step_action_verify_email",
  "step_action_upload_file",
  "step_title_drive",
  "step_action_pcloud_app",
  "step_action_autoupload_on",
  "step_action_invite_friends"
];
const stepNumberToDescription = [
  ["verify_account_to_unlock_sharing", "verify_account_to_unlock_sharing_settings"],
  ["upload_file_description", "upload_file_settings"],
  ["access_and_work_without_taking_hdd_space", ""],
  ["files_in_your_pocket", ""],
  ["auto_upload_free_up_space", "files_in_your_pocket_settings"],
  ["invite_friends_get_1_gb", "invite_friends_get_1_gb_settings"]
];
const unlockOneGB = "unlock_1_gb";
const oneGBUnlocked = "1_gb_unlocked";

const os: ?(number | string) = getHash("os") ? getHash("os") : DEVICE_OS.Android;
const osSpec = osToImgSize[os];

const data = [0, 1, 2, 3, 4, 5];

class UnlockSpaceSteps extends React.Component<Props> {
  static defaultProps = {
    userinfo: {},
    step: 1
  };

  constructor(props: Props) {
    super(props);

    (this: any).renderStep = this.renderStep.bind(this);
  }

  isJourneyCurrentStepCompleted(step) {
    const { journeySteps/*, quota, freeQuota*/ } = this.props;
    const { verifymail, uploadfile, downloaddrive, downloadapp, autoupload, sentinvitation } = journeySteps;
    //const canInvitefriends = freeQuota === quota;
    const unlockJourneyStepsState = [verifymail, uploadfile, downloaddrive, downloadapp, autoupload, sentinvitation];

    return unlockJourneyStepsState[step];
  }

  renderStep(id, isOpen) {
    const stepMap = stepNumberToDescription[id];
    let isStepCompleted = this.isJourneyCurrentStepCompleted(id);

    return [
      <UnlockSpaceStepImage key={`stepImage${id}`} step={id} imgSize={osSpec[id]} deviceOs={(os)} />,
      <StepInfo key={`stepInfo${id}`} isOpen={isOpen}>
        <StepHeader>{__(stepNumberToHeader[id])}</StepHeader>
        <SubHeader>{isStepCompleted ? __(oneGBUnlocked) : __(unlockOneGB)}</SubHeader>
        {isOpen && <StepContent>{stepMap.map(elem => <div>{__(elem)}</div>)}</StepContent>}
      </StepInfo>,
      <UnlockSpaceArrow key={`stepArrow${id}`} isOpen={isOpen} isCompleted={isStepCompleted} />
    ];
  }

  render() {

    return (
      <Wrapp>
        <Header>
          {__(
            "complete_these_steps_unlock_space",
            "Complete these steps and unlock more space."
          )}
        </Header>
        <Accordion data={data} renderItem={this.renderStep} />
      </Wrapp>
    );
  }
}

export default connect(({ pCloudUser: { userinfo: { journey: { steps }, quota, usedquota, freequota } } }) => ({
  journeySteps: steps,
  quota,
  usedQuota: usedquota,
  freeQuota: freequota
}))(UnlockSpaceSteps);

const Wrapp = styled.div`
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  outline: none;
`;

const Header = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  line-height: 28px;
  padding: 25px 15px;
  text-align: center;
  border-bottom: 1px solid #ddd;
`;

const StepInfo = styled.div`
  padding: 9px 40px 9px 60px;

  position: relative;
  font-family: "Roboto", sans-serif;

  font-size: 16px;
  line-height: 20px;
  color: #000;

  background-color: ${props => props.isOpen ? "#fafafa" : "transparent"};
  border-top: ${props => props.isOpen ? "1px solid #ddd" : "1px solid transparent"};
  border-bottom: ${props => props.isOpen ? "1px solid #ddd" : "1px solid transparent"};
  margin: -1px 0;
`;

const StepHeader = styled.div`
  font-weight: bold;
`;

const SubHeader = styled.div`
  font-size: 14px;
  line-height: 17px;
  font-weight: normal;
  color: #8e8e8e;
  padding: 4px 0;
`;

const StepContent = styled.div`
  margin: 9px 0;

  & div:last-child {
    margin: 10px 0 0;
  }
`;
