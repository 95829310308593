// @flow

import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import Componentify from "react-componentify";

import Modal, { OvalModalWrap, OvalModalCloseV2 } from "../../Modal";
import Button from "../../ButtonDefault";
import * as Style from "../styledComponents";
import InputStyledRadio from "../../InputStyledRadio";

import apiMethod from "../../../api/apiMethod";
import { __ } from "../../../lib/translate";
import { strongConverter, brTagConverter } from "../../../lib/componentifyConverters";

type Props = {
  memberId: number
};

const DeactivateMemberModal = ({ memberId = 0 }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldDelete, setShouldDelete] = useState(0);
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const onEnter = useCallback(
    (e: any) => {
      if (e.keyCode === 13) {
        removeUser();
      }
    },
    [removeUser]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("keyup", onEnter);
    } else {
      document.removeEventListener("keyup", onEnter);
    }
  }, [isOpen, onEnter]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const onInputChange = e => {
    console.log("e.target.id", e.target.id);
    setShouldDelete(parseInt(e.target.id));
  };

  const removeUser = useCallback(() => {
    if (!showWarning && shouldDelete) {
      setShowWarning(true);
      return;
    }
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    const params = {
      userid: memberId
    };

    if (shouldDelete) {
      params.active = 0;
    } else {
      params.frozen = 1;
    }

    apiMethod(
      "account_usermodify",
      params,
      () => {
        PCB.refreshAll();
        if (shouldDelete) {
          HFN.message(__("User Deleted."));
        } else {
          HFN.message(__("User Deactivated."));
        }
        handleClose();
      },
      {
        errorCallback: ({ error, result }) => {
          setIsLoading(false);
          let errorMessage = __("something_went_wrong_refresh_and_try_again");

          if (result === 2076) {
            errorMessage = __("error_2076", "You do not have the permissions for this action.");
          }

          HFN.message(errorMessage, "error");
          throw new Error(error);
        }
      }
    );
  });

  const renderBody = () => {
    return (
      <React.Fragment>
        <BoxDeactivate id={0} className="first" selected={!shouldDelete}>
          <Style.WrapperElements>
            <InputStyledRadio
              size="small"
              id="0"
              name="deactivate"
              checked={!shouldDelete}
              onChange={(e: any) => {
                onInputChange(e);
              }}
            />
          </Style.WrapperElements>
          <Style.WrapperElements className="wrap">
            <Header>{__("modal_deactivate_title", "Deactivate user")}</Header>
            <Description>{__("modal_deactivate_explain")}</Description>
          </Style.WrapperElements>
        </BoxDeactivate>

        <BoxDeactivate id={1} className="last" selected={!shouldDelete}>
          <Style.WrapperElements>
            <InputStyledRadio
              size="small"
              id="1"
              name="delete"
              checked={shouldDelete}
              onChange={(e: any) => {
                onInputChange(e);
              }}
            />
          </Style.WrapperElements>
          <Style.WrapperElements className="wrap">
            <Header>{__("modal_delete_title", "Delete user")}</Header>
            <Description>{__("modal_delete_explain")}</Description>
          </Style.WrapperElements>
        </BoxDeactivate>
      </React.Fragment>
    );
  };

  const renderWarningMessage = () => {
    return (
      <WarningText>
        <Componentify
          text={__("Delete_business_user_warning")}
          converters={[strongConverter, brTagConverter]}
        />
      </WarningText>
    );
  };

  return (
    <Modal align="top" animate onClose={handleClose} opened={isOpen}>
      <OvalModalWrap>
        <OvalModalCloseV2 onClick={handleClose} />
        <Style.Container>
          <Style.Header>{__("modal_deactivate_title", "Deactivate user")}</Style.Header>
          {showWarning && shouldDelete ? renderWarningMessage() : renderBody()}
          <Style.Footer>
            <Button
              color="lightgray4"
              style={{
                marginRight: "5px"
              }}
              onClick={handleClose}
            >
              {__("Cancel")}
            </Button>
            <Button
              loading={isLoading}
              color="red"
              style={{
                marginLeft: "5px"
              }}
              disabledColor="#dddddd"
              onClick={removeUser}
            >
              {shouldDelete ? __("Delete") : __("Deactivate")}
            </Button>
          </Style.Footer>
        </Style.Container>
      </OvalModalWrap>
    </Modal>
  );
};

export default DeactivateMemberModal;

const BoxDeactivate = styled.label`
  display: flex;
  border-radius: 6px;
  border: 1px solid #eee;
  padding: 11px 11px;
  justify-content: space-between;
  background-color: #fafafa;
  font-size: 15px;
  font-weight: normal;
  min-height: 111px;
  height: 110px;
  box-sizing: border-box;

  &.first {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: ${props => (props.selected ? "#fafafa" : "#ffffff")};
  }

  &.last {
    background-color: ${props => (!props.selected ? "#fafafa" : "#ffffff")};
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;

const Header = styled.div`
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const Description = styled.div`
  font-size: 13px;
`;

const WarningText = styled.div`
  padding: 20px;
  box-sizing: border-box;
  border-radius: 5px;
  border: solid 1px rgba(247, 60, 60, 0.05);
  background-color: rgba(247, 60, 60, 0.07);
  font-size: 13px;
  font-weight: normal;
  color: #f73c3c;
`;
