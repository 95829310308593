import { createSlice } from "@reduxjs/toolkit";
import { setAutoFreeze } from 'immer';

setAutoFreeze(false);

const initialState = {
    loaded: false,
    folders: {},
    openFolderId: 0
};

export const publiclinkSlice = createSlice({
    name: "publiclink",
    initialState: initialState,
    reducers: {
        load: (state, action) => {
            state.loaded = true;
            state.folders = flattenMetadata(action.payload);
        },
        openFolder: (state, action) => {
            state.openFolderId = action.payload;
        },
        dispose: () => initialState
    }
});

function flattenMetadata(metadata, flat = {}) {
    if (metadata.isfolder) {
        flat[metadata.folderid] = metadata;

        if (metadata.isfolder && metadata.contents) {
            for (let i = 0; i < metadata.contents.length; ++i) {
                flattenMetadata(metadata.contents[i], flat);
            }
        }
    }

    return flat;
}

export const { load, openFolder, dispose } = publiclinkSlice.actions;

export default publiclinkSlice.reducer;
