import React, { useEffect, useState } from "react";
import Breadcrumb from "../../Templates/TopBar/Breadcrumb";
import styled from "styled-components";
import Notifications from "../../Templates/TopBar/Notifications";
import SearchBar from "../../Templates/TopBar/SearchBar";
import PCloudLogo from "../../../../../root/img/header/pcloud-logo.svg";
import MobileMenuBar from "../../Templates/TopBar/MobileMenuBar";
import { FlexWrapper } from "../Style";
import UpgradeItem from "../../Templates/TopBar/UpgradeItem";
import MoreAppsItem from "../../Templates/TopBar/MoreAppsItem";
import { AnimatePresence, motion } from "framer-motion";
import UploadButton from "../../Templates/TopBar/UploadButton";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { DEFAULT_MENU_ITEMS } from "../../../LeftMenu/constants";
import CryptoLockButton from "../../../CryptoLockButton";
import { getHashPage } from "../../../../lib";
import { disableSearchOnPages } from "./constants";
import { setLeftMenuContent } from "../../../../lib/state/reducers/contentSlice";
import hashManager from "../../../../lib/hashManager";

const MobileLayout = ({ searchBarExpanded, setSearchBarExpanded, page }) => {
    const breadcrumbData = useSelector(({ content }) => content.breadcrumb);
    const dispatch = useDispatch();
    const leftMenuContent =
        useSelector(({ content }) => content.leftMenuContent) || undefined;

    const handleLogoClick = () => {
        hashManager.pushState({ page: "filemanager" }, 2);
        dispatch(setLeftMenuContent({
            ...leftMenuContent,
            activeItem: DEFAULT_MENU_ITEMS.MY_PCLOUD,
            activeItemIndex: 0
        }));
    };

    const isCryptoPage =
        (page === "filemanager" &&
        breadcrumbData &&
        typeof breadcrumbData === 'object' &&
        breadcrumbData[Object.keys(breadcrumbData)[0]] &&
        breadcrumbData[Object.keys(breadcrumbData)[0]].encrypted) ||
        page === "crypto" ||
        page === "cryptoset" ||
        page === "cryptolocked";

    return (
        <MobileWrapper>
            <MobileFirstLine id={"topbar-first-line"}>
                <LeftSection>
                    <MobileMenuBar />
                    <LogoWrapper onClick={handleLogoClick}>
                        <PCloudLogo />
                    </LogoWrapper>
                </LeftSection>

                <RightSection className={"actions-section"}>
                    <UploadButton />

                    <div id="ribbon-container" />
                    <UpgradeItem />
                    <Notifications isMobile={true} />
                    <MoreAppsItem />
                </RightSection>
            </MobileFirstLine>
            <MobileSecondLine>
                <LeftSection>
                    <AnimatePresence>
                        {!searchBarExpanded && (
                            <BreadCrumbMotionWrapper
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.2 }}
                            >
                                <Breadcrumb />
                            </BreadCrumbMotionWrapper>
                        )}
                    </AnimatePresence>
                </LeftSection>

                <RightSection>
                    {isCryptoPage && (
                        <CryptoLockButton
                            value={page !== "cryptolocked"}
                            disableChange={page === "cryptolocked"}
                            onChange={() => {
                                if (page !== "cryptolocked") {
                                    setTimeout(() => {
                                        pCloudCrypto.lock();
                                        HFN.cache.expireMatch("listfolder");
                                        HFN.data.afterCryptoUnlock = hashManager.getState();
                                        hashManager.pushState({ page: "cryptolocked" }, 2);
                                    }, 500);
                                }
                            }}
                        />
                    )}
                    {!disableSearchOnPages.includes(page) && !isCryptoPage && (
                        <SearchBar
                            isMobile={false}
                            searchBarExpanded={searchBarExpanded}
                            setSearchBarExpanded={setSearchBarExpanded}
                        />
                    )}
                </RightSection>
            </MobileSecondLine>
        </MobileWrapper>
    );
};

export default MobileLayout;

const LeftSection = styled.div`
    display: flex;
    align-items: center;
    gap: var(--spacing-md);
    min-width: 0;
`;

const RightSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--spacing-lg);
    @media (max-width: 1023px) {
        gap: var(--spacing-sm);
        &.actions-section {
            margin-right: calc(var(--spacing-sm) * -1);

            & > div:not(#ribbon-container) {
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
`;

const BreadCrumbMotionWrapper = styled(motion.div)`
    min-width: 0;
    display: flex;
    align-items: center;
`;

const MobileWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;

    &,
    & * {
        -webkit-tap-highlight-color: transparent;
    }
`;
const MobileFirstLine = styled(FlexWrapper)`
    height: 64px;
    padding: var(--spacing-sm) var(--spacing-md);
    box-sizing: border-box;
    border-bottom: 1px solid var(--divider-primary);
`;
const MobileSecondLine = styled(FlexWrapper)`
    height: 72px;
    padding: var(--spacing-md) var(--spacing-md);
    box-sizing: border-box;
    width: auto;
`;
const LogoWrapper = styled.div`
    cursor: pointer;
    height: 28px;
    width: 43px;
`;
