// @flow

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled as MUIStyled } from '@mui/material/styles';

import { PreferencesContent, PreferencesAlign } from "./preferences.style";
import Account from "./tabs/Account";
import Security from "./tabs/Security";
import Encryption from "./tabs/Encryption";
import * as SETTINGS from "./settingsConstants";
import { __ } from "../../lib/translate";
import Notifications from "./tabs/Notifications";
import PaymentHistory from "./tabs/PaymentHistory";
import LinkedAccounts from "./tabs/LinkedAccounts";
import hashManager from "../../lib/hashManager";

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  borderBottom: "var(--border-size, 1px) solid var(--border-primary, #E4E5E7)",
  '& .MuiTabs-indicator': {
    backgroundColor: "var(--color-primary-500)",
  },
});

const StyledTab = MUIStyled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "var(--color-base-black)",
    fontFamily: 'Roboto',
    padding: "0 16px 8px 16px",

    '&.Mui-selected': {
      color: "var(--color-base-black)",
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&.Mui-focusVisible': {
      backgroundColor: "transparent",
    },
  }),
);

const SettingsContainer = (props) => {
  const businessAccount = useSelector(({ pCloudUser }) => pCloudUser.userinfo.account);
  const [tabs, setTabs] = useState([...SETTINGS.DEFAULT_TABS]);
  const [activeTab, setActiveTab] = useState(SETTINGS.ACCOUNT_SETTINGS);

  useEffect(() => {
    const newTabs = [...SETTINGS.DEFAULT_TABS];
    const urlSettingsTab = hashManager.getState("settings");

    if (!businessAccount) {
      newTabs.push({
        tabId: SETTINGS.PAYMENT_HISTORY_SETTINGS,
        tabTitle: __("payments_history","Payment History"),
        search: "tab-finance"
      })

      setTabs(newTabs);
    }

    if (urlSettingsTab) {
      const tabData = newTabs.find(({ search }) => search === urlSettingsTab);

      if (tabData) {
        setActiveTab(tabData.tabId);
      }
    }
  }, [])

  const onTabClick = (event, clickedTab) => {
    const tabData = tabs.find(({ tabId }) => tabId === clickedTab);
    const urlData = { page: hashManager.getState('page') };

    if (tabData && hashManager.getState("settings") != tabData.search) {
      urlData.settings = tabData.search;
      let urlDataString = "";

      for (let param in urlData) {
        urlDataString += urlDataString === '' ? '#' : '&';
        urlDataString += `${param}=${urlData[param]}`;
      }

      location.replace(urlDataString);
    }

    setActiveTab(clickedTab);
  }

  const renderTabContent = () => {
    if (activeTab === SETTINGS.ACCOUNT_SETTINGS) {
      return <Account {...props} settings={SETTINGS.SETTINGS_PER_TABS.settingsPerTab[SETTINGS.ACCOUNT_SETTINGS]} />;
    }

    if (activeTab === SETTINGS.LINKED_ACCOUNTS_SETTINGS) {
      return <LinkedAccounts {...props} settings={SETTINGS.SETTINGS_PER_TABS.settingsPerTab[SETTINGS.LINKED_ACCOUNTS_SETTINGS]} />;
    }

    if (activeTab === SETTINGS.SECURITY_SETTINGS) {
      return <Security {...props} settings={SETTINGS.SETTINGS_PER_TABS.settingsPerTab[SETTINGS.SECURITY_SETTINGS]} />;
    }

    if (activeTab === SETTINGS.ENCRYPTION_SETTINGS) {
      return <Encryption {...props} settings={SETTINGS.SETTINGS_PER_TABS.settingsPerTab[SETTINGS.ENCRYPTION_SETTINGS]} />;
    }

    if (activeTab === SETTINGS.NOTIFICATIONS_SETTINGS) {
      return <Notifications {...props} settings={SETTINGS.SETTINGS_PER_TABS.settingsPerTab[SETTINGS.NOTIFICATIONS_SETTINGS]} />;
    }

    if (activeTab === SETTINGS.PAYMENT_HISTORY_SETTINGS) {
      return <PaymentHistory {...props} settings={SETTINGS.SETTINGS_PER_TABS.settingsPerTab[SETTINGS.PAYMENT_HISTORY_SETTINGS]} />;
    }
  };

  return (
    <Wrapper>
      <PreferencesContent className="inner-animation animation-type-fade">
        <StyledTabs
          value={activeTab}
          onChange={onTabClick}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        
        >
          {tabs.map(({ tabId, tabTitle }) => (
            <StyledTab label={__(tabTitle)} />
          ))}
        </StyledTabs>
        <PreferencesAlign>
          <div>{renderTabContent()}</div>
        </PreferencesAlign>
      </PreferencesContent>
    </Wrapper>
  );
};

export default SettingsContainer;

const Wrapper = styled.div`
  display: flex;
  margin-top: 20px;
  max-width: 1140px;

  @media(max-width: 768px) {
    margin-top: 0;
    padding-bottom: 160px;
  }
`;
