//@flow

import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { hiDPI } from "polished";
import { CSSTransition } from "react-transition-group";

import { __ } from "../../lib/translate";
import { SubscriptionUpdate } from "../../lib/SubscriptionUpdate";
import { PlanIDType, ColorType } from "../../types/dataTypes.components";
import { ButtonWithIcon } from "@pcloud/web-utilities/dist/resources/components/ButtonDefault";
import useIsMount from "@pcloud/web-utilities/dist/hooks/useIsMount";
import { Error } from "./SharedStyledComponents";
import { B_USERS, PROVIDERS_IDS } from "@pcloud/web-utilities/dist/config/constants";
import { Loader } from "./PaymentLoader";

type PaypalBoxType = {
  auth: string,
  reason: string,
  planId: PlanIDType | Array<PlanIDType>,
  period: "month" | "year" | "lifetime",
  members: number,
  provider: "paypal" | "braintree",
  userPaymentSource: {
    email: string
  },
  afterPaymentActions: () => void,
  discount?: string,
  color?: ColorType,
  price?: number,
  currency?: string,
  endTrial?: boolean,
  isBusiness?: boolean,
  paypalButtonLabel?: string,
  onPaymentProcessing?: () => void,
  afterPaymentProcessing?: () => void,
  showLoading?: () => void,
  businessplanid?: 1 | 2
};

const PaypalBox = ({
  auth,
  reason = "modify",
  planId,
  period,
  providerId,
  discount,
  userPaymentSource,
  afterPaymentActions,
  color = "cyan",
  price,
  currency,
  members = B_USERS.MIN,
  endTrial = false,
  isBusiness = false,
  paypalButtonLabel = "",
  onPaymentProcessing = () => {},
  afterPaymentProcessing = () => {},
  showLoading = () => {},
  businessplanid
}: PaypalBoxType) => {
  const [paypalLoading, setPaypalLoading] = useState(true);
  const [paypalEmail, setPaypalEmail] = useState();
  const [errorMessage, setErrorMessage] = useState("");

  const payments = useRef(null);

  const isMount = useIsMount();

  useEffect(() => {
    const params = {
      provider: "braintree",
      period: period,
      userAuth: auth,
      products: planId,
      billingAddress: {},
      storage: 0,
      traffic: 0,
      isBusiness: isBusiness,
      members: members,
      endTrial: endTrial,
      businessplanid: businessplanid,
      toggleLoader: togglePaymentLoader,
      showPaymentError,
      showBadInput: () => {}
    };

    if (discount) {
      params.discountcode = discount;
    }

    payments.current = new SubscriptionUpdate(params);
  }, []);

  useEffect(() => {
    if (userPaymentSource && userPaymentSource.email) {
      setPaypalEmail(userPaymentSource.email);
      setPaypalLoading(false);
    }
  }, [userPaymentSource]);

  useEffect(() => {
    if (!paypalEmail && isMount) {
      setPaypalLoading(true);
      payments.current.getTokenBraintree({
        refresh: false,
        label: paypalButtonLabel,
        callback: afterPaymentActions,
        onReady: () => {
          setPaypalLoading(false);
        }
      });
    } else {
      setPaypalLoading(false);
    }
  }, [paypalEmail, isMount]);

  const handleLogout = () => {
    setPaypalEmail("");
  };

  const handleSubmitPaypal = () => {
    const reasons = {
      upgrade: 1,
      modify: 1
    };

    if (!reasons[reason]) {
      return;
    }

    togglePaymentLoader("show");

    const params = {
      userAuth: auth,
      period: period,
      endTrial: endTrial,
      members: members,
      businessplanid: businessplanid
    };

    const payments = new SubscriptionUpdate(params);

    payments.updatePaypalBusinessSubscription({ reason });
  };

  const handleSubmitBraintree = () => {
    const params = {
      provider: providerId === PROVIDERS_IDS.PAYPAL ? "paypal" : "braintree",
      period: period,
      userAuth: auth,
      products: planId,
      billingAddress: {},
      storage: 0,
      traffic: 0,
      members: members,
      isBusiness: isBusiness,
      endTrial: endTrial,
      businessplanid: businessplanid,
      toggleLoader: togglePaymentLoader,
      showPaymentError,
      showBadInput: () => {}
    };

    if (discount) {
      params.discountcode = discount;
    }

    const payments = new SubscriptionUpdate(params);

    togglePaymentLoader("show");

    if (paypalEmail) {
      payments.createBraintreePayment({
        refresh: false,
        callback: afterPaymentActions
      });
    } else {
      payments.getTokenBraintree({
        refresh: false,
        label: paypalButtonLabel,
        callback: afterPaymentActions
      });
    }
  };

  const onSubmit = () => {
    const isPaypal = providerId === PROVIDERS_IDS.PAYPAL;

    if (isBusiness && isPaypal) {
      handleSubmitPaypal();
    } else {
      handleSubmitBraintree();
    }
  };

  const togglePaymentLoader = (action: "show" | "hide") => {
    let isLoading = action === "show";
    setPaypalLoading(isLoading);
    showLoading(isLoading);
    isLoading ? onPaymentProcessing() : afterPaymentProcessing();
  };

  const showPaymentError = message => {
    setErrorMessage(message);
  };

  let buttonText = __("checkout_right_btn");

  if (price && currency) {
    buttonText = `${__("pay_button", "Pay")} ${price} ${currency}`;
  }

  return (
    <React.Fragment>
      <AnimationContainer>
        <CSSTransition
          in={paypalLoading}
          unmountOnExit
          timeout={300}
          classNames="fading"
          onEnter={() => {}}
          onExited={() => {}}
          appear
        >
          <LoaderWrapper>
            <Loader color={color} />
          </LoaderWrapper>
        </CSSTransition>
      </AnimationContainer>
      <Wrapper>
        {errorMessage ? <Error className="payment-error-message">{errorMessage}</Error> : null}
        <PaypalIcon />
        {paypalEmail ? (
          <ExistingEmail>
            <span>{paypalEmail}</span>
            <Logout onClick={handleLogout}>{__("header_logout")}</Logout>
          </ExistingEmail>
        ) : null}
      </Wrapper>
      <ButtonWrapper>
        {paypalEmail ? (
          <ButtonWithIcon
            size="big"
            color={color}
            onClick={onSubmit}
            disabled={false}
            height={42}
            loading={paypalLoading}
            text={buttonText}
            icon="secure"
          />
        ) : (
          <div id="paypal-button" />
        )}
      </ButtonWrapper>
      <SecureText>
        <span>{__("secure_payment")}</span>
      </SecureText>
    </React.Fragment>
  );
};

export default PaypalBox;

const Wrapper = styled.div`
  position: relative;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PaypalIcon = styled.div`
  width: 160px;
  height: 160px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  background-image: url(${require("../../../root/img/paypal-logo.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/paypal-logo@2x.png")});
  }
`;

const ButtonWrapper = styled.div`
  margin-top: -2px;
`;

const SecureText = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: center;
  color: #999999;
  margin-top: 26px;
`;

const SecureIcon = styled.div`
  display: inline-block;
  width: 21px;
  height: 21px;
  background-repeat: no-repeat;
  background-size: 21px 21px;
  background-position: center;
  background-image: url(${require("../../../root/img/secure.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/secure@2x.png")});
  }
  position: relative;
  top: 5px;
  margin-right: 10px;
`;

const ExistingEmail = styled.div`
  position: absolute;
  bottom: 15px;
  display: inline-block;
  color: #888;
  font-size: 12px;
  min-height: 12px;
  line-height: 12px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
`;

const Logout = styled.span`
  margin-left: 8px;
  color: #17bed0;
  font-weight: bold;
  cursor: pointer;
`;

const AnimationContainer = styled.div`
  .fading-enter,
  .fade-appear {
    opacity: 1;
  }
  .fading-exit {
    opacity: 0;
  }
  .fading-enter-active,
  .fade-appear-active {
    opacity: 1;
  }
  .fading-exit-active {
    opacity: 0;
  }
  .fading-enter-active,
  .fade-appear-active,
  .fading-exit-active {
    transition: all 400ms ease-in-out;
  }
`;

const LoaderWrapper = styled.div`
  position: absolute;
  min-height: 370px;
  width: 100%;
  left: 0px;
  background: #fff;
  z-index: 1000;
`;
