// @flow
import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { hiDPI } from "polished";

import apiMethod from "../../api/apiMethod";
import { __ } from "../../lib/translate"; // DONE
import { validateEmail } from "../../lib/utils"; // DONE
import { errorKeys } from "../../lib/errors";
import { TAB_INDEX, EDIT, VIEW } from "@pcloud/web-utilities/dist/config/constants";
import { getSharedContacts } from "../../lib/shareFolder-utils";

import { TDiv, TSpan } from "../intl";
import { Label } from "./StyleInviteFolder";
import InputTextarea from "../InputTextarea";
import Button from "../ButtonDefault";
import PermissionButton from "./PermissionButton";
import ProgressLoader from "../ProgressLoader";
import * as Style from "../Modals/styledComponents";
import { getShareFolderName, getShareMessage, getSharePermission } from "../../lib/state/reducers/sharedFoldersSlice";

type Invitations = {
  emails: Array<any>,
  busers: Array<any>,
  bteams: Array<any>
};

type Props = {
  folderId: number,
  folderNameShare: string,
  isCryptoShare: boolean,
  onTabClick: number => void,
  getUsersWithoutCrypto: (Array<any>) => void,
  getUsersFromOtherRegion: (Array<any>) => void,
  getUsersWithChangedPermission: (Array<any>) => void
};

const InviteCollaboratorsTab = ({
  folderId,
  folderNameShare,
  isCryptoShare,
  onTabClick,
  getUsersWithoutCrypto,
  getUsersFromOtherRegion,
  getUsersWithChangedPermission
}: Props) => {
  const token = useSelector(({ pCloudUser }) => pCloudUser.token);
  const isBusiness = useSelector(({ pCloudUser }) => pCloudUser.userinfo.business);
  const userId = useSelector(({ pCloudUser }) => pCloudUser.userinfo.userid);
  const myEmail = useSelector(({ pCloudUser }) => pCloudUser.userinfo.email);
  const isUserVerify = useSelector(({ pCloudUser }) => pCloudUser.userinfo.emailverified);
  const userContacts = useSelector(({ pCloudUser }) => pCloudUser.userContacts);
  const bUserContacts = useSelector(({ business }) => business.users);
  const bTeamsContacts = useSelector(({ business }) => business.teams);
  const folderShares = useSelector(({ sharedFolders: { folderShares } }) => folderShares);
  const [contacts, setContacts] = useState([]);
  const [textareaValue, setTextareaValue] = useState("");
  const [folderName, setFolderName] = useState(folderNameShare);
  const [progress, setProgress] = useState(0);
  const [showLoaderProgress, setShowLoaderProgress] = useState(false);
  const [invitations, setInvitations] = useState({ emails: [], busers: [], bteams: [] });
  const [permissionId, setPermissionId] = useState(EDIT);
  const [errorFolderName, setErrorFolderName] = useState("");
  const [combo, setCombo] = useState({});
  const [qEngine, setqEngine] = useState(null);
  const [inputPrepared, setInputPrepared] = useState(false);
  const inputFolderName = useRef(null);
  const inputTexarea = useRef(null);
  const inputUsers = useRef(null);
  const dispatch = useDispatch();

  (token: string);
  (isBusiness: boolean);
  (userId: number);
  (myEmail: string);
  (isUserVerify: boolean);
  (userContacts: Array<any>);
  (bUserContacts: Array<any>);
  (bTeamsContacts: Array<any>);
  (contacts: Array<any>);
  (textareaValue: string);
  (folderName: string);
  (progress: number);
  (showLoaderProgress: boolean);
  (permissionId: number);
  (invitations: Invitations);
  (errorFolderName: string);

  const prepareSendInput = useCallback(
    contacts => {
      console.log("Prepare send input >>>>>>>>>>>", contacts);
      if (inputPrepared || !inputUsers.current) {
        return;
      }

      setInputPrepared(true);

      const tokenizer = name => {
        const ret = Bloodhound.tokenizers.whitespace(name.replace(/\(?\)?/g, ""));

        if (ret.indexOf(name.replace(/\(?\)?/g, "")) == -1) {
          ret.push(name.replace(/\(?\)?/g, ""));
        }

        return ret;
      };

      let qEngine = new Bloodhound({
        name: "name",
        datumTokenizer: r => {
          const ret = Bloodhound.tokenizers.whitespace(r.name.replace(/\(?\)?/g, ""));

          if (ret.indexOf(r.name.replace(/\(?\)?/g, "")) == -1) {
            ret.push(r.name.replace(/\(?\)?/g, ""));
          }
          if (validateEmail(r.value) && ret.indexOf(r.value.replace(/\(?\)?/g, "")) == -1) {
            ret.push(r.value.replace(/\(?\)?/g, ""));
          }
          if (r.userobj) {
            ret.push(r.userobj.email);
          }

          return ret;
        },
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        local: contacts
      });

      const orig_get = qEngine.get;
      qEngine.get = (query, cb) => {
        return orig_get.apply(qEngine, [
          query,
          suggestions => {
            if (!suggestions) {
              return cb(suggestions);
            }

            suggestions.forEach(s => {
              const tokens = tokenizer(s.name.toLowerCase());
              let score = 0;

              for (let n = 0; n < tokens.length; ++n) {
                if (tokens[n].indexOf(query) == 0) {
                  score++;
                }
              }
              s.exact_match = score;
            });
            suggestions.sort((a, b) => {
              return a.exact_match > b.exact_match ? -1 : a.exact_match < b.exact_match ? 1 : 0;
            });
            cb(suggestions);
          }
        ]);
      };

      qEngine.initialize();

      const comboOptions = {
        place: inputUsers.current,
        name: "emails",
        width: 518,
        innerWidth: 518 - 120,
        sourceKey: "name",
        focusOnInit: false,
        // onlySuggested: true,
        source: qEngine,
        placeholder: isBusiness ? __("Team, Name or Email") : __("Name or Email"),
        autocomplete: "off",
        maxNotFoundSymbols: 5,
        suggestOnFocus: false,
        getShowVal: r => {
          if (validateEmail(r)) return HFN.strFitMail(r, 40);
          return HFN.strFit(r, 40);
        },

        onRemoveResultBox: (box, data) => {
          console.log("bb", box);
          box.find(".combo-res").tooltip("destroy");
        },
        fancyResultBox: (box, data) => {
          let ent;

          console.log("fancyResultBox: box.data()", box.data());

          if (data && data.data) {
            ent = data.data;
            if (ent.source == 2) {
              box.prepend('<img src="/img/fb-tag.png" width="16" height="16" class="inf">');
            } else if (ent.source == 3) {
              box.prepend('<img src="/img/gm-tag.png" width="16" height="16" class="inf">');
              box
                .parent()
                .tooltip("destroy")
                .attr("title", ent.value)
                .tooltip({ container: "div[class^=Modal__ModalHolder]" });
            } else if (ent.source == 1) {
              box.prepend(
                '<img src="/img/or-mail-small.png" width="16" height="16" class="inf">'
              );
            } else if (ent.source == 1001) {
              box.prepend(
                '<img src="' +
                  (ent.userobj.avatar ? HFN.prepUrl(ent.userobj.avatar) : "/img/b/default-avatar.png") +
                  '" width="16" height="16" class="inf">'
              );
              box
                .parent()
                .tooltip("destroy")
                .attr("title", ent.userobj.email)
                .tooltip({ container: "div[class^=Modal__ModalHolder]" });
            } else if (ent.source == 1002)
              box.prepend('<img src="/img/b/team.png" width="16" height="16" class="inf">');
          } else if (box.data("val")) {
            if (validateEmail(box.data("val"))) {
              box.prepend(
                '<img src="/img/or-mail-small.png" width="16" height="16" class="inf">'
              );
              box
                .parent()
                .tooltip("destroy")
                .attr("title", box.data("val"))
                .tooltip({ container: "div[class^=Modal__ModalHolder]" });
            }
          }
        },

        imgCloseFile: "",

        extraMulticompleteOpts: {
          suggestAt: inputUsers.current,
          boxRender: function(data) {
            const box = $("<div>");
            const img = $('<img width="32" height="32">');

            box
              .addClass("clearfix")
              .append($('<div class="iconw"></div>').append(img))
              .append(
                $('<div class="textpart"></div>')
                  .append($('<span class="title"></span>').text(data.name))
                  .append($('<span class="explain"></span>'))
              );

            if (data.source == "import") {
              if (data.value == "Facebook") {
                img.attr("src", "/img/fab.png");
                //box.addClass('fbimport');
              } else if (data.value == "Gmail") {
                img.attr("src", "/img/gmail.png");
                //box.addClass('gmailimport');
              }
              box
                .find(".explain")
                .text(__("Import contacts from %source%", false, { source: data.value }));
            } else if (data.source == 3) {
              img.attr("src", "/img/gmail.png");
              box.find(".explain").text(data.value);
            } else if (data.source == 2) {
              img.attr(
                "src",
                "https://graph.facebook.com/" + data.value + "/picture?type=square"
              );
              box.find(".iconw").append('<img src="/img/fb-over.png" class="smicon">');
              box.find(".explain").text(__("Facebook Message"));
            } else if (data.source == 1) {
              img.attr("src", "/img/or-mail.png");
              box.find(".explain").text(__("Send Email"));
            } else if (data.source == 1001) {
              img.attr("src", data.userobj.avatar ? HFN.prepUrl(data.userobj.avatar) : "/img/b/default-avatar.png");
              box.find(".explain").text(data.userobj.email);
            } else if (data.source == 1002) {
              img.attr("src", "/img/b/team.png");
              box
                .find(".explain")
                .text(__("%num% member(s)", false, { num: PCB.teamUserCount(data.teamobj) }));
            }

            return box;
          }
        }
      };
      setqEngine(qEngine);
      setCombo(new combo2(comboOptions));
    },
    [inputPrepared, isBusiness]
  );

  useEffect(() => {
    const contacts = getSharedContacts(userContacts, bUserContacts, bTeamsContacts, isCryptoShare);
    setContacts(contacts);
  }, [userContacts, bUserContacts, bTeamsContacts, isCryptoShare]);

  useEffect(() => {
    if (inputPrepared && qEngine) {
      qEngine.clear();
      qEngine.local = contacts;
      qEngine.initialize(true);
    } else {
      prepareSendInput(contacts);
    }
  }, [contacts, inputPrepared, isBusiness, prepareSendInput, qEngine]);

  const validateFodlerName = () => {
    const regex = new RegExp('[><"/]+');
    return !regex.test(folderName);
  };

  const onSendInvitetionsClick = () => {
    if (!isUserVerify) {
      HFN.message(__("Please verify your email address to perform this action."), "error");
      return;
    }

    if (isCryptoShare) {
      if (!inputFolderName.current.value) {
        setErrorFolderName(__("share_folder_modal_error_empty_folder_name", "Emptry Folder name"));
        return;
      }

      if (!validateFodlerName()) {
        setErrorFolderName(
          __(
            "share_folder_modal_error_folder_name_special_chars",
            "Folder name cannot contain special characters."
          )
        );
        return;
      }
    }

    sendInvitations();
  };

  const onTextareaChange = () => {
    setTextareaValue(inputTexarea.current.value);
  };

  const onFolderNameChange = () => {
    setFolderName(inputFolderName.current.value);
    setErrorFolderName("");
  };

  const isBusnessUserOrTeam = inputVal => {
    const index = contacts.findIndex(conatct => {
      const { teamobj, userobj } = conatct;

      if (teamobj) {
        return teamobj.name === inputVal;
      } else if (userobj) {
        if (userobj.id === userId) {
          return false;
        } else {
          return userobj.email === inputVal;
        }
      }
    });
    return index !== -1;
  };

  const isMe = value => {
    return myEmail === value;
  };

  const isDeactivatedOrDeteted = inputVal => {
    let result = false;

    contacts.forEach(conatct => {
      const { userobj } = conatct;
      if (userobj) {
        if (userobj.email === inputVal) {
          result = !userobj.active || userobj.frozen;
        }
      }
    });
    return result;
  };

  const sendInvitations = () => {
    let vals = combo.getFullVals(true);
    let message = inputTexarea.current.value;
    let manuallyAdded = [];
    let emails = [];
    let busers = [];
    let bteams = [];
    let perm = 0;
    let permBusiness = 0;

    if (vals && !vals.length) {
      HFN.message(__("error_enter_valid_email", "Please enter a valid Email"), "error");
      return;
    }

    let hasErrorEmail = false;

    for (let n = 0; n < vals.length; n++) {
      let error = "";
      let isGmail = false;
      let isBusinessUser = false;
      let isTeam = false;

      if (vals[n].data && [1, 3].indexOf(vals[n].data.source) != -1) {
        // gmails
        isGmail = true;
        // emails.push({ email: vals[n].data.value, obj: vals[n].obj });
      } else if (vals[n].data && vals[n].data.source == 1001) {
        // business user
        isBusinessUser = true;
        if (isMe(vals[n].data.userobj.email)) {
          error = __("You can't share a folder with yourself.");
        } else if (isDeactivatedOrDeteted(vals[n].data.userobj.email)) {
          error = __("The user is inactive and can't receive shares.");
        } else {
          // busers.push({ buser: vals[n].data, obj: vals[n].obj });
        }
      } else if (vals[n].data && vals[n].data.source == 1002) {
        // business team
        isTeam = true;
        // bteams.push({ bteam: vals[n].data, obj: vals[n].obj });
      } else if (validateEmail(vals[n].val)) {
        // email is valid
        if (isCryptoShare) {
          // is Crypto folder
          if (isBusnessUserOrTeam(vals[n].val)) {
            if (isMe(vals[n].val)) {
              // is my email
              error = __("You can't share a folder with yourself.");
            } else if (isDeactivatedOrDeteted(vals[n].val)) {
              // user is frozen or not active
              error = __("The user is inactive and can't receive shares.");
            }
          } else {
            // user is not in business account
            error = __("The user is outside your Business account.");
          }
        }
      } else {
        // not a valid email
        error = __("This is not a valid contact or an email.");
      }

      if (!hasErrorEmail && error !== "") {
        hasErrorEmail = true;
      }

      if (error !== "") {
        vals[n].obj
          .addClass("error")
          .find(".combo-res")
          .tooltip("destroy")
          .attr("title", __(error))
          .tooltip({ container: "div[class^=Modal__ModalHolder]" });
        continue;
      } else if (isGmail) {
        emails.push({ email: vals[n].data.value, obj: vals[n].obj });
      } else if (isBusinessUser) {
        if (
          !busers.some(el => (el.buser && el.buser.value) === (vals[n].data && vals[n].data.value))
        ) {
          busers.push({ buser: vals[n].data, obj: vals[n].obj });
        } else {
          continue;
        }
      } else if (isTeam) {
        if (
          !bteams.some(el => (el.bteam && el.bteam.value) === (vals[n].data && vals[n].data.value))
        ) {
          bteams.push({ bteam: vals[n].data, obj: vals[n].obj });
        } else {
          continue;
        }
      } else {
        //emails.push({ email: vals[n].val, obj: vals[n].obj });
        let emailAlreadyExistsInBA = bUserContacts.filter(({ email: id1 }) => id1 === vals[n].val);
        if (emailAlreadyExistsInBA.length) {
          busers.push({ buser: {userobj: emailAlreadyExistsInBA[0]}, obj: vals[n].obj });
        } else {
          emails.push({ email: vals[n].val, obj: vals[n].obj });
        }
        if (!vals[n].data && manuallyAdded.indexOf(vals[n].val) == -1) {
          qEngine.add([{ name: vals[n].val, value: vals[n].val, source: 1 }]);
          manuallyAdded.push(vals[n].val);
        }
      }
    }

    if (hasErrorEmail) return;

    if (!emails.length && !busers.length && !bteams.length) return;
    permBusiness = permissionId;
    perm = Math.min(7, permBusiness);

    let usersWithChangedPermission = [];
    const manuallyAddedEmailsPartOfBA = bUserContacts.filter(({ email: id1 }) => !emails.some(({ email: id2 }) => id2 !== id1));
    if (emails.length && !manuallyAddedEmailsPartOfBA.length && perm != 0 && permissionId != perm) {
      usersWithChangedPermission.push(emails);
    }

    setShowLoaderProgress(true);
    setInvitations({ emails, busers, bteams });
    console.log("{ emails, busers, bteams }", { emails, busers, bteams });

    dispatch(getSharePermission(permissionId));
     dispatch(getShareMessage(message));
    dispatch(getShareFolderName(folderName));

    let xhr = [];
    let callbacksAtOnce = [];
    let manualToAdd = [];
    let usersNoCryptoSetup = [];
    let usersFromOtherRegion = [];
    let hasError = false;
    let hasErrorFolderName = "";

    emails.forEach(item => {
      xhr.push(
        apiMethod(
          "sharefolder",
          {
            auth: token,
            folderid: folderId,
            mail: item.email,
            permissions: perm,
            message: message,
            strictmode: true
          },
          res => {
            console.log("SUCC>>>>>", res);
            callbacksAtOnce.push(() => {
              setProgress(prevProgress => ++prevProgress);
              item.obj.remove();
            });

            if (manuallyAdded.indexOf(item.email) != -1) {
              manualToAdd.push(item.email);
            }
          },
          {
            errorCallback: ret => {
              callbacksAtOnce.push(() => {
                setProgress(prevProgress => ++prevProgress);
                console.log("ERROR>>>", ret);
                if (ret.result === 2322) {
                  usersFromOtherRegion.push(item);
                } else if (ret.result === 2019) {
                  item.obj.remove();
                } else if (ret.result === 2024) {
                  // User already has access to this folder or subfolder of this folder.
                  if (
                      folderShares.find(({ tomail, cancreate, candelete, canmodify, canread }) => 
                          tomail === item.email && 
                          ((perm === EDIT && cancreate && candelete && canmodify && canread) || (perm === VIEW && !cancreate && !candelete && !canmodify && canread))
                      )
                    ) {
                    // User already has access to this folder with the same permissions level. 
                    // Let's show it as success, because it's really shared with this email.
                    item.obj.remove();
                  } else {
                    hasError = true;
                    item.obj
                      .addClass("error")
                      .find(".combo-res")
                      .tooltip("destroy")
                      .attr("title", __("error_2024", "You have already shared this folder or a subfolder to this user."))
                      .tooltip({ container: "div[class^=Modal__ModalHolder]" });
                    }
                } else if (ret.result === 2018) {
                  hasError = true;
                  item.obj
                    .addClass("error")
                    .find(".combo-res")
                    .tooltip("destroy")
                    .attr("title", __("This is not a valid contact or an email."))
                    .tooltip({ container: "div[class^=Modal__ModalHolder]" });
                } else if (ret.result === 2045) {
                  hasError = true;
                  HFN.message(
                    __(
                      "error_2045",
                      "You can only share your own files or folders. This directory has folders owned by someone else."
                    ),
                    "error"
                  );
                } else if (errorKeys[ret.result]) {
                  hasError = true;
                  HFN.message(errorKeys[ret.result],"error");
                } else {
                  hasError = true;
                  item.obj
                    .addClass("error")
                    .find(".combo-res")
                    .tooltip("destroy")
                    .attr("title", __(ret.error))
                    .tooltip({ container: "div[class^=Modal__ModalHolder]" });
                }
              });
            }
          }
        )
      );
    });

    const shareFolderBusiness = (item, metod, params) => {
      return apiMethod(
        metod,
        params,
        res => {
          callbacksAtOnce.push(() => {
            item.obj.remove();
            setProgress(prevProgress => ++prevProgress);
          });
        },
        {
          errorCallback: ret => {
            callbacksAtOnce.push(() => {
              setProgress(prevProgress => ++prevProgress);
              if (ret.result === 2019) {
                item.obj.remove();
              } else if (ret.result === 1079) {
                usersNoCryptoSetup.push(item);
              } else if (ret.result === 2322) {
                usersFromOtherRegion.push(item);
              } else if (ret.result === 2001) {
                hasError = true;
                hasErrorFolderName = true;
              } else if (ret.result === 2125) {
                hasError = true;
                HFN.message(__("error_2125", "This folder could not be shared."), "error");
              } else if (ret.result === 2045) {
                hasError = true;
                HFN.message(
                  __(
                    "error_2045",
                    "You can only share your own files or folders. This directory has folders owned by someone else."
                  ),
                  "error"
                );
              } else {
                hasError = true;
                item.obj
                  .addClass("error")
                  .find(".combo-res")
                  .tooltip("destroy")
                  .attr("title", __(ret.error))
                  .tooltip({ container: "div[class^=Modal__ModalHolder]" });
              }
            });
          }
        }
      );
    };

    let businessParams = {
      auth: token,
      folderid: folderId,
      permissions: permBusiness,
      message: message,
      strictmode: true
    };

    if (isCryptoShare) {
      businessParams.name = folderName;
    }

    busers.forEach(item => {
      const bUserParams = Object.assign({}, businessParams, { mail: item.buser.userobj.email });
      xhr.push(shareFolderBusiness(item, "sharefolder", bUserParams));
    });

    bteams.forEach(item => {
      const bTeamParams = Object.assign({}, businessParams, { teamid: item.bteam.teamobj.id });
      xhr.push(shareFolderBusiness(item, "account_teamshare", bTeamParams));
    });

    $.when.apply($, xhr).then(() => {
      for (let n = 0; n < callbacksAtOnce.length; ++n) {
        callbacksAtOnce[n]();
      }

      setTimeout(() => {
        if (!hasError) {
          if (usersNoCryptoSetup.length !== 0) {
            getUsersWithoutCrypto([...usersNoCryptoSetup]);
          } else if (usersFromOtherRegion.length !== 0) {
            getUsersFromOtherRegion([...usersFromOtherRegion]);
          } else if (usersWithChangedPermission.length !== 0) {
            getUsersWithChangedPermission([...usersWithChangedPermission]);
          } else {
            onTabClick(TAB_INDEX.MANAGE);
          }
        } else if (hasErrorFolderName) {
          setErrorFolderName(
            __(
              "share_folder_modal_error_folder_name_special_chars",
              "Folder name cannot contain special characters."
            )
          );
        }
        setShowLoaderProgress(false);
        setProgress(0);
      }, 1000);

      if (manualToAdd.length) {
        apiMethod(
          "contactload",
          { auth: token, source: 1, mails: manualToAdd.join(",") },
          () => {}
        );
        manualToAdd = [];
      }
    });
  };

  const renderProgressLoader = () => {
    const { emails, busers, bteams } = invitations;
    const total = emails.length + busers.length + bteams.length;

    return showLoaderProgress ? (
      <ProgressLoader
        current={progress}
        message={__("share_folder_modal_sending_invitation", "Sharing...")}
        total={total}
      />
    ) : null;
  };

  const renderFolderNameInput = () => {
    return isCryptoShare ? (
      <React.Fragment>
        <Label id="share_folder_folder_name">Folder name:</Label>
        <FolderNameInput
          ref={inputFolderName}
          type="text"
          name="folderName"
          value={folderName}
          placeholder={__("share_folder_modal_folder_name_placeholder", "Enter folder name")}
          onChange={onFolderNameChange}
          autoComplete="off"
          error={errorFolderName}
        />
        <FolderNameDesc id="" error={errorFolderName}>
          {errorFolderName
            ? __(errorFolderName)
            : __(
                "share_folder_modal_folder_name_description",
                "The folder name you enter will appear for the recipients and will be used for notifications."
              )}
        </FolderNameDesc>
      </React.Fragment>
    ) : null;
  };

  return (
    <Wrapper showLoaderProgress={showLoaderProgress}>
      {renderProgressLoader()}
      <React.Fragment>
        {renderFolderNameInput()}
        <Label id="share_folder_modal_send_to">{__("Send to:")}</Label>
        <InputEmailWrapper>
          <div className="tomails" ref={inputUsers}/>
          <PermissionButton
            className="email-perm"
            shouldShowTooltip
            canReshare={isBusiness}
            getPermission={setPermissionId}
          />
        </InputEmailWrapper>
        <InputTextarea
          className="textarea"
          innerRef={inputTexarea}
          name="message"
          value={textareaValue}
          placeholder={__("Your message (optional)")}
          autoComplete="off"
          onChange={onTextareaChange}
        />
        <Style.Footer>
          <Button
            color="cyan"
            size="big"
            disabled={errorFolderName}
            onClick={onSendInvitetionsClick}
          >
            {__("Share")}
          </Button>
        </Style.Footer>
      </React.Fragment>
    </Wrapper>
  );
};

export default InviteCollaboratorsTab;

const Wrapper = styled.div`
  height: ${props => (props.showLoaderProgress ? "350px" : "auto")};
  min-height: 197px;

  .tomails {
    display: inline-block;
    min-width: 360px;
    width: 100%;
    font-size: 13px;

    @media (max-width: 800px) {
      min-width: auto;
    }

    .combo-contain {
      padding: 7px 10px 7px 10px;
      border: none;
      box-shadow: none;
      box-sizing: border-box;
      background: none;

      @media (max-width: 800px) {
        width: 100%!important;
      }
      
      .combo-contain-inner, input, .combo-wrap {
        @media (max-width: 800px) {
          width: 100%!important;
        }
      }

      input {
        height: 28px;
        font-size: 14px;
        box-sizing: border-box;
      }

      input::placeholder {
        font-size: 13px;
      }

      .combo-res {
        height: 18px;
        line-height: 18px;
        margin: 0 5px 2px 0;
        border-radius: 5px;
        border: solid 1px #d0f0fb;
        background-color: #f4fdff;

        img.rem, div.rem {
          height: 9px;
          width: 9px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          background-image: url(${require("../../../root/img/share-icons/blue-close.png")});
          ${hiDPI(1.5)} {
            background-image: url(${require("../../../root/img/share-icons/blue-close@2x.png")});
          }
        }

        .inf {
          margin: -1px 5px 0 0;
        }
      }

      .error {
        img.rem, div.rem {
          height: 9px;
          width: 9px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          background-image: url(${require("../../../root/img/share-icons/red-close.png")});
          ${hiDPI(1.5)} {
            background-image: url(${require("../../../root/img/share-icons/red-close@2x.png")});
          }
        }
      }
    }
    .combo-contain.focu,
    .combo-contain.focu input {
      background: #fff !important;
    }
  }

  .textarea {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #cacaca;
    resize: none;
    border-top: none;
    outline: none;
    padding: 10px;
    font-size: 13px;
    height: 63px;
    border: solid 1px #a1a1a1;
    border-top: none;
    background-color: #fff;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`;

const FolderNameDesc = styled(TDiv)`
  font-size: 11px;
  color: ${props => (props.error ? "#fc344c" : "#999999")};
  padding-top: 10px;
  padding-bottom: 30px;
  text-align: left;
`;

const FolderNameInput = styled.input`
  width: 100%;
  font-size: 13px;
  height: 40px;
  border-radius: 5px;
  border: ${props => (props.error ? "solid 1px #fc344c" : "solid 1px #a1a1a1")};
  outline: none;
  box-sizing: border-box;
  padding: 0 10px;
`;

const InputEmailWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 42px;
  box-sizing: border-box;
  overflow: hidden;
  border: solid 1px #a1a1a1;
  background-color: #fff;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const Footer = styled.div`
  display: flex;
  margin-top: 20px;
`;
