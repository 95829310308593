// @flow

import React, { useState } from "react";
import styled from "styled-components";
import Componentify from "react-componentify";

import Modal, { OvalModalWrap, CloseModalIcon } from "../Modal";
import Button from "../ButtonDefault";
import * as Style from "./styledComponents";

import { __ } from "../../lib/translate";
import { formatSize } from "../../lib/utils";
import { URLSITE } from "@pcloud/web-utilities/dist/config";
import { boldConverter } from "../../lib/componentifyConverters";

type Props = {
  quota: number
};

const RemoteuploadExceededModal = ({ quota }: Props) => {
  const [isOpened, setIsOpened] = useState(true);

  const onCloseClick = () => {
    setIsOpened(false);
  };

  const renderContent = () => {
    return (
      <React.Fragment>
        <Body>
          <Header>{__("Exceeded monthly usage")}</Header>
          <Style.Container style={{ paddingBottom: "10px" }}>
            <Style.ElementBold>
              {__("remote_upload_limit", "You have reached your Remote Upload traffic limit")}
            </Style.ElementBold>
            <Style.Message>
              <Componentify
                text={__(
                  "remoupload_exceeded",
                  "You've used your <b>%size%</b> GB Remote Upload traffic limit. Receive unlimited Remote Upload traffic - get Premium plan or higher.",
                  { size: formatSize(quota) }
                )}
                converters={[boldConverter]}
              />
            </Style.Message>
          </Style.Container>
        </Body>
        <Style.Footer>
          <Button style={{ marginRight: "5px" }} color="lightgray4" onClick={onCloseClick}>
            {__("Cancel")}
          </Button>
          <Button
            style={{ marginLeft: "5px" }}
            color="cyan"
            href={URLSITE + "cloud-storage-pricing-plans.html"}
          >
            {__("Become Premium")}
          </Button>
        </Style.Footer>
      </React.Fragment>
    );
  };

  return (
    <Modal align="top" opened={isOpened} onClose={onCloseClick}>
      <OvalModalWrap>
        <CloseModalIcon onClick={onCloseClick} />
        <Style.Content>{renderContent()}</Style.Content>
      </OvalModalWrap>
    </Modal>
  );
};

export default RemoteuploadExceededModal;

const Body = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const Header = styled(Style.Header)`
  margin-bottom: 0;
`;
