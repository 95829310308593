import React from "react";
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import styled from "styled-components";
import apiMethod from "../api/apiMethod";
import { __ } from "../lib/translate";
import { detectIsMobile, isUSLocation } from "../lib/utils";
import { LoginStep } from "../components/vivacompage/LoginStep";
import { PhoneNumberStep } from "../components/vivacompage/PhoneNumberStep";
import { VerificationCodeStep } from "../components/vivacompage/VerificationCodeStep";
import { SuccessStep } from "../components/vivacompage/SuccessStep";
import { ConfirmSubscription } from "../components/vivacompage/ConfirmSubscription";
import { myUrl, siteUrl } from "@pcloud/web-utilities/dist/config";
import { modify } from "@pcloud/web-utilities/dist/lib/state/reducers/pcloud/userSlice";
import { setGlobalLocationById } from "@pcloud/web-utilities/dist/api/utils.api";
import { US_LOCATIONID } from "@pcloud/web-utilities/dist/config/constants";

const errorCodes = {
  1106: "Please provide 'phone number'.",
  1107:  __("vivacom_error1107"),
  1022: "Please provide 'code'.",
  2003: __("vivacom_error2003"),
  2012:	__("vivacom_error2012"),
  2305: __("vivacom_error2305"),
  5000: "Internal error. Try again later."
}

class VivacomRegistration extends React.Component {

  static propTypes = { 
    onAuthAction: PropTypes.func.isRequired, 
    logoutUser: PropTypes.func.isRequired, 
    resetPassword: PropTypes.func.isRequired,
    logged: PropTypes.bool.isRequired,
    auth: PropTypes.string,
    premium: PropTypes.bool,
    vivacancelled: PropTypes.bool,
    msisdn: PropTypes.string,
    planid: PropTypes.number,
    plan: PropTypes.string,
    premiumlifetime: PropTypes.bool,
    business: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      phoneNumber: "",
      code: "",
      error: "",
      msisdn: "",
      sessionID: "",
      customerCode: "",
      planName: "",
      premiumUser: false,
      loadingState: false,
      showModal: false
    }

    this.onLoginSuccess = this.onLoginSuccess.bind(this);
    this.phoneNumberChange = this.phoneNumberChange.bind(this);
    this.codeChange = this.codeChange.bind(this);
    this.sendVerificationCode = this.sendVerificationCode.bind(this);
    this.assignMsisdn = this.assignMsisdn.bind(this);
    this.onLogout = this.onLogout.bind(this);
    this.subscribeForVivacom = this.subscribeForVivacom.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.setStateOnLogin = this.setStateOnLogin.bind(this);
  }

  getPlanName() {
    const { plan, planid, business, premiumlifetime } = this.props
    let planNameKey = "plan_name_";
    
    if (planid != "undefined") {
      planNameKey += planid;
    }

    if (premiumlifetime) {
      planNameKey += "_lifetime";
    } else if (business) {
      planNameKey = "plan_name_business";
    } else if (planid === "undefined" && !business) {
      planNameKey += "_unknown";
    }

    return __(planNameKey);
  }

  setStateOnLogin() {
    const { logged, premium, msisdn, vivapcloud, planid } = this.props;
    const planName = this.getPlanName();

    this.setState({ planName });

    if (logged && !isUSLocation()) {
      this.setState({ step: 1 });
    } else if (logged && premium && ((vivapcloud && planid == 12) || (!vivapcloud && planid !== 12))) {
      this.setState({ premiumUser: true });
    } else if (((logged && !premium) ||  (premium && !vivapcloud && planid == 12)) && msisdn) {
      this.setState({ step: 4 });
    } else if ((logged && !premium) || (premium && !vivapcloud && planid == 12)) {
      this.setState({ step: 2 });
    }
  }

  componentDidMount() {
    this.setStateOnLogin();
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  openModal() {
    this.setState({ showModal: true });
  }

  phoneNumberChange(value) {
    this.setState({ phoneNumber: value, error: "" })
  }

  codeChange(value) {
    this.setState({ code: value, error: "" })
  }

  onLoginSuccess(ret) {
    this.props.onAuthAction(ret);
    this.setStateOnLogin();
  }

  onLogout() {
    const {
      logoutUser
    } = this.props;

    logoutUser(() => {
      this.setState({ premiumUser: false, logged: false, planName: "" });
      setGlobalLocationById(US_LOCATIONID);
    });

  }

  sendVerificationCode() {
    const { phoneNumber } = this.state;
    const { auth } = this.props;
    const msisdn = "359" + phoneNumber.replace(/\s+/g, '').replace(/^(((\+)|0{1,2})359)|0?/, "");
    this.setState({ msisdn: msisdn, loadingState: true })
    if (msisdn.length < 12) {
      this.setState({ error: errorCodes["1107"], loadingState: false });
      return;
    }

    apiMethod(
			"viva_sendverificationcode",
			{ auth: auth, msisdn: msisdn },
			ret => {
				this.setState({ sessionID: ret.sessionid, customerCode: ret.customercode, step: 3, loadingState: false, error: "" });
			},
			{
				errorCallback: ret => {
          console.log("!!!SEND VERIFICATION CODE ERROR!!!", ret)
          const error = ret.result;
					this.setState({ error: errorCodes[error], loadingState: false });
				}
			}
		);
  }

  assignMsisdn() {
    const { msisdn, sessionID, customerCode, code } = this.state;
    const { auth, modify } = this.props;
    this.setState({ loadingState: true });
    if (code.length < 4) {
      this.setState({ error: errorCodes["2012"], loadingState: false });
      return;
    }

    apiMethod(
			"viva_asignmsisdn",
			{ auth: auth, msisdn: msisdn, sessionid: sessionID, customercode: customerCode, code: code},
			ret => {
        const step = ret.paid ? 5 : 4;
        console.log("RET VIVA ASIGN MSISDN:", ret);
        modify(ret.userinfo);
        this.setState({ step: step, loadingState: false, error: "" });
			},
			{
				errorCallback: ret => {
          console.log("!!!SUBMIT CODE ERROR!!!", ret)
          const error = ret.result;
					this.setState({ error: errorCodes[error], loadingState: false });
				}
			}
		);
  }

  subscribeForVivacom() {
    const { auth } = this.props;
    this.setState({ loadingState: true });
    apiMethod(
			"payviva",
			{ auth: auth },
			ret => {
        const isMobile = detectIsMobile();
        isMobile ? window.open(siteUrl("download-free-online-cloud-file-storage.html"), "_self") : window.open(myUrl(""), "_self")
      },
			{
        timeout: 30000,
				errorCallback: ret => {
					this.setState({ error: ret.error, loadingState: false });
        },
        onXhrError: (xhr, status, error) => {
          this.setState({ error: __("something_went_wrong_refresh_and_try_again"), loadingState: false });
        },
			}
		);
  }

  renderStep() {
    const { phoneNumber, code, error, step, premiumUser, loadingState, planName } = this.state;
    const { resetPassword, planid, logged } = this.props

    switch(step) {
      case 1:
        return {
          description: __("vivacom_signin"),
          form: <LoginStep onRegSuccess={this.onLoginSuccess} onLoginSuccess={this.onLoginSuccess} premiumUser={premiumUser} logoutUser={this.onLogout} resetPassword={resetPassword} planName={planName} logged={logged}/>
        }
      case 2:
        return {
          description: __("vivacom_enter_number"),
          form: <PhoneNumberStep handleChange={this.phoneNumberChange} value={phoneNumber} error={error} submit={this.sendVerificationCode} loadingState={loadingState} />
        }
      case 3:
        return {
          description: __("vivacom_enter_code"),
          form: <VerificationCodeStep handleChange={this.codeChange} value={code} error={error} submit={this.assignMsisdn} loadingState={loadingState}/>
        }
      case 4:
        return {
          description: __("vivacom_success15_title"),
          form: <SuccessStep option={2} subscribe={this.openModal}/>
        }
      case 5:
        return {
          description: __("vivacom_success128_title"),
          form: <SuccessStep option={1} />
        }
    }
  }

  render() {
    const { showModal, loadingState, error } = this.state;
    let renderInformation = this.renderStep();
    return (
      <div>
        {showModal ? <ConfirmSubscription show={showModal} onClose={this.closeModal} onConfirm={this.subscribeForVivacom} loadingState={loadingState} error={error}/> : null}
        <Container>
          <FormDescription>{renderInformation.description}</FormDescription>
          <FormWrapper>
            {renderInformation.form}
          </FormWrapper>
        </Container>
      </div>
    );
  }
}

export default connect(({ pCloudUser }) => {
  let _plan = "";

  if (pCloudUser.logged) {
    const { plan, premium, business, lastsubscription, msisdn, vivapcloud, premiumlifetime } = pCloudUser.userinfo;

    return {
      logged: pCloudUser.logged,
      auth: pCloudUser.token,
      premium: premium || business,
      plan: _plan,
      planid: plan,
      msisdn: msisdn,
      vivapcloud: vivapcloud,
      business: business,
      premiumlifetime: premiumlifetime
    };
  }
  
  return {
    logged: false
  }

  }, 
  dispatch => bindActionCreators({
    modify
  }, dispatch)
)(VivacomRegistration);


const FormWrapper = styled.div`
  max-width: 340px;
  padding: 50px;
  background: #fff;
  border-radius: 15px;
  margin: 25px auto;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.08);
  -moz-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.08);
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.08);
  @media (max-width: 768px) {
    padding: 25px 15px;
    max-width: default;
  }
`

const Container = styled.div`
  max-width: 640px;
  margin: 0 auto;
  padding: 6px;
`

const FormDescription = styled.div`
  margin: 0px 0px 40px 0px;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  color: #222;
  text-align: center;
`
