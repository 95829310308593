import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { Menu, MenuItem } from "@szhsin/react-menu";
import { __ } from "../../lib/translate";

import { galleryFooterConfig } from "./config/FooterConfig";
import InputCheckbox from "../../components/InputCheckbox";
import InputStyledCheckbox from "../../components/InputStyledCheckbox";
import { CheckboxLabel } from "../../components/Modals/styledComponents";
import { SLIDESHOW_OPTIONS } from "./constants";
import { convertTimeFromMilliseconds } from "@pcloud/web-utilities/dist/lib/utils";

import ArrowDown from "../../../root/img/svg/gallery/arrow_drop_down.svg";
import ArrowUp from "../../../root/img/svg/gallery/arrow_drop_up.svg";
import CheckIcon from "../../../root/img/svg/gallery/check.svg";
import { MenuWrapper } from "./common.style";
import { detectIsMobile } from "../../lib/utils";
import Navigation from "./Navigation";

import HighlightOnboardingComponent from "../../components/HighlightOnboardingComponent";
import { EXIT_FULLSCREEN_VIDEO_FROM_ACTIVE_SLIDESHOW } from "../../components/HighlightOnboardingComponent/constants";
import ToggleSlideshowOnExitFSVideoContent from "../../components/HighlightOnboardingComponent/Contents/Gallery/ToggleSlideshowOnExitFSVideoContent";

import ZoomControls from "./ZoomControls";

type Props = {
  total: number,
  onGoToNextMedia: () => void,
  onGoToPrevMedia: () => void,
  zoomStep: number,
  zoom: number,
  setZoom: () => void,
  minZoom: number,
  maxZoom: number,
  slideshowActive: boolean,
  slideshowPaused: boolean,
  slideSeconds: number,
  setSlideSeconds: (slideSeconds: number) => void,
  shouldRepeat: boolean,
  setShouldRepeat: (shouldRepeat: boolean) => void,
  shownItemData: {
    place: number,
    metaData: Object,
    autoPlay: boolean
  },
  modalContainer: any,
  showErrorMediaPreview: { show: boolean, options: { showDownloadButton: boolean } },
  loading: boolean,
  onToggleSlideshow: () => void,
  panningStarted: boolean,
  transformWrapper: {}
};

const GalleryFooter = React.forwardRef(
  (
    {
      total,
      onGoToNextMedia,
      onGoToPrevMedia,
      zoomStep,
      zoom,
      setZoom,
      minZoom,
      maxZoom,
      slideshowActive = false,
      slideshowPaused = false,
      slideSeconds = 0,
      setSlideSeconds,
      shouldRepeat = false,
      setShouldRepeat,
      shownItemData,
      modalContainer,
      showErrorMediaPreview = { show: false, options: { showDownloadButton: true } },
      loading = true,
      onToggleSlideshow,
      panningStarted = false,
      transformWrapper
    }: Props,
    ref
  ) => {
    const isMobile = useMemo(() => !!detectIsMobile(), []);

    const checkboxInputRef = useRef(null);

    const config = useMemo(() => {
      const mediaType =
        shownItemData && shownItemData.metaData && shownItemData.metaData.category === HFN.CATEGORY.VIDEO ? "video" : "image";
      const deviceType = isMobile ? "mobile" : "desktop";
      const slideshowState = slideshowActive ? (slideshowPaused ? "slideshowPaused" : "slideshowPlaying") : "slideshowOff";
      return galleryFooterConfig[deviceType][slideshowState][mediaType];
    }, [slideshowActive, slideshowPaused, isMobile, shownItemData.metaData]);

    const onSlideshowSecondsClick = valueInMs => {
      if (slideSeconds !== valueInMs) {
        if (typeof gtag === "function") {
          gtag("event", "media_preview_click", {
            action: "slideshow_seconds_change",
            eventValue: valueInMs / 1000,
            category: shownItemData && shownItemData.metaData && shownItemData.metaData.category === HFN.CATEGORY.VIDEO ? "video" : "image"
          });
        }
      }
      
      setSlideSeconds(valueInMs);
    };

    const onSlideshowRepeatChange = e => {
      if (typeof gtag === "function") {
        gtag("event", "media_preview_click", {
          action: "slideshow_repeat_change",
          eventValue: e.target.checked,
          category: shownItemData && shownItemData.metaData && shownItemData.metaData.category === HFN.CATEGORY.VIDEO ? "video" : "image"
        });
      }

      setShouldRepeat(e.target.checked);
    }

    const onPrevClick = () => {
      // Get prev media.
      onGoToPrevMedia();
    };

    const onNextClick = () => {
      // Get next media.
      onGoToNextMedia();
    };

    const toggleSlideshow = e => {
      // Important to keep keyboard Space works as Pause/Play instead of turn off slideshow toggle.
      e.target.blur();
      onToggleSlideshow();
    };

    const renderMenuItems = () =>
      SLIDESHOW_OPTIONS.map((value, index) => (
        <MenuItem key={`${index}-${value}`} onClick={() => onSlideshowSecondsClick(value)} value={value}>
          <Row>
            {slideSeconds === value ? <CheckIcon width="20px" height="20px" /> : <div />}
            {convertTimeFromMilliseconds(value)}
          </Row>
        </MenuItem>
      ));

    return (
      <FooterWrapper
        className={`preview-footer ${isMobile ? "mobile-view" : ""} ${
          slideshowActive && !slideshowPaused ? "playing-slideshow" : ""
        }`}
      >
        {shownItemData && shownItemData.place !== -1 && config?.slideShow && total > 1 && (
          <SlideShowWrapper>
            <div>
              <HighlightOnboardingComponent
                id={EXIT_FULLSCREEN_VIDEO_FROM_ACTIVE_SLIDESHOW}
                content={[<ToggleSlideshowOnExitFSVideoContent />]}
                pulseAnimation
                pulseCircle={false}
                showOverlay={false}
                place="top-left"
                delay="1000"
                container={modalContainer}
              >
                <InputWrapper onClick={toggleSlideshow}>
                  <InputCheckbox
                    ref={checkboxInputRef}
                    checked={slideshowActive}
                    name="slideshow-toggle"
                    color="cyan"
                    colorDefault="lightGray"
                    size="extrasmall"
                  />
                  <ToggleLabel>{__("Slideshow", "Slideshow")}</ToggleLabel>
                </InputWrapper>
              </HighlightOnboardingComponent>
            </div>
            {slideshowActive && (
              <SlideShowOptions>
                <CheckboxWrapper>
                  <CheckboxLabelStyled for="repeat-slideshow">
                    <InputStyledCheckbox
                      id="repeat-slideshow"
                      name="repeat"
                      size="small"
                      checked={shouldRepeat}
                      onChange={onSlideshowRepeatChange}
                      text={__("Repeat", "Repeat")}
                    />
                  </CheckboxLabelStyled>
                </CheckboxWrapper>
                <MenuWrapperStyled>
                  <Menu
                    align="end"
                    direction="top"
                    menuButton={({ open }) => (
                      <ArrowButton>
                        <span className="arrow-button-text">{convertTimeFromMilliseconds(slideSeconds)}</span>
                        {open ? <ArrowUp width="20px" height="20px" /> : <ArrowDown width="20px" height="20px" />}
                      </ArrowButton>
                    )}
                    transition
                    instanceRef={ref}
                  >
                    {renderMenuItems()}
                  </Menu>
                </MenuWrapperStyled>
              </SlideShowOptions>
            )}
          </SlideShowWrapper>
        )}

        {config?.scale && !showErrorMediaPreview.show && !loading && (
          <ZoomControls
            transformWrapper={transformWrapper}
            zoom={zoom}
            setZoom={setZoom}
            zoomStep={zoomStep}
            minZoom={minZoom}
            maxZoom={maxZoom}
            panningStarted={panningStarted}
          />
        )}

        {shownItemData && shownItemData.place !== -1 && config?.showNavigation && (
          <Navigation
            style={{ order: "2", margin: "0 auto" }}
            visibility={!slideshowActive || slideshowPaused}
            currentIndex={shownItemData.place}
            total={total}
            hideArrows={config?.navigationArrows}
            handlePrevClick={onPrevClick}
            handleNextClick={onNextClick}
          />
        )}
      </FooterWrapper>
    );
  }
);

export default GalleryFooter;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  padding: 0 24px;
  height: 64px;
  color: #fff;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  &.playing-slideshow {
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 1;
  }

  @media (max-width: 768px) {
    padding: 0 16px;
    height: 56px;
  }
`;

const SlideShowWrapper = styled.div`
  display: flex;
  gap: 32px;
  order: 1;
  position: absolute;
`;

const SlideShowOptions = styled.div`
  display: flex;
  gap: 28px;
`;

const MenuWrapperStyled = styled(MenuWrapper)`
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Standard */
  & > div {
    padding: 4px 0;
  }
`;

const ToggleLabel = styled.div`
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Standard */
`;

const CheckboxLabelStyled = styled(CheckboxLabel)`
  display: flex;
  justify-content: center;
  align-items: center;

  label {
    padding: 0;
    margin: 3px;
  }

  & .text-wrapper {
    font-size: 14px;
    line-height: 14px;
    padding-left: 10px;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Standard */
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  gap: 8px;
  cursor: pointer;
  padding: 4px 0;
`;

const CheckboxWrapper = styled.div`
  display: flex;

  @media (max-width: 370px) {
    display: none;
  }

  .checkbox-checkmark {
    display: inline-block;
    margin: 0;

    input ~ span {
      background: transparent;
    }
    input[type="checkbox"]:checked ~ span {
      background-color: #17bed0;
      border: 1px solid #17bed0;
    }

    &:hover input ~ span {
      background-color: transparent;
    }

    span.input-checkbox {
      border-color: #fff;
    }
  }

  & > label > div {
    margin-left: 0;
  }
`;

const ArrowButton = styled.div`
  display: flex;
  color: #fff;
  cursor: pointer;
  flex-shrink: 0;
`;

const Row = styled.div`
  display: flex;
  gap: 8px;

  div {
    width: 20px;
    height: 20px;
  }
`;