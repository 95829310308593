import React, { useEffect, useState, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import ItemsInfoContainer from "./ItemsInfoContainer";
import useWindowDimensions from "../../lib/hooks/useWindowDimensions";
import { MOBILE_WIDTH_BREAKPOINT } from "../../config/constants";
import hashManager from "../../lib/hashManager";
import { setItemsInfoPanelData } from "../../lib/state/reducers/contentSlice";

type Props = {
  currentFolderData: {
    id: string,
    folderid: number,
    name: string,
    ismine: boolean,
    thumb: string,
    created: string,
    modified: string,
    comments: number,
    isshared: boolean,
    icon: number,
    parentfolderid: number
  }
};

const ItemsInfoPanel = ({
  containerId = "items-info"
}: Props) => {

  const dispatch = useDispatch();

  const itemsInfoPanelData = useSelector(({ content }) => content.itemsInfoPanelData);

  const [itemsInfoData, setItemsInfoData] = useState(itemsInfoPanelData);
  const [animateItemIconZoomInOut, setAnimateItemIconZoomInOut] = useState(false);
  const [onPopState, setOnPopState] = useState(null);
  const detailsParamAddedInHash = useRef()

  const isBusiness = useSelector(({ pCloudUser }) => pCloudUser.userinfo.business);

  const { bodyWidth } = useWindowDimensions();
  const isMobileView = useMemo(() => bodyWidth < MOBILE_WIDTH_BREAKPOINT, [bodyWidth]);

  useEffect(() => {
    hashManager.removeState("details");
    window.addEventListener("popstate", setOnPopState);
    return () => {
      window.removeEventListener("popstate", setOnPopState);
    }
  }, []);

  useEffect(() => {
    if (isMobileView && itemsInfoData.show && !hashManager.getState("details")) {
      hashManager.pushState({ details: "open" });
      detailsParamAddedInHash.current = true;
    } else if (detailsParamAddedInHash.current && hashManager.getState("details") && typeof history !== "undefined") {
      // We're not coming from back button or non-mobile view resize. Remove the previously added state on details open from the
			// browser history.
      detailsParamAddedInHash.current = false;
      skipCloseOnInitialLoad();
      history.back();
    }
  }, [itemsInfoData.show, isMobileView]);

  useEffect(() => {
    if (isMobileView && onPopState && !hashManager.getState('details')) {
      closeItemsInfo();
    }
  }, [onPopState]);

  useEffect(() => {
    const oldValue = itemsInfoData;
    const newValue = itemsInfoPanelData;

    if (newValue.fromOptionsMenu &&
        oldValue.show && newValue.show &&
        oldValue.item && newValue.item && oldValue.item.id === newValue.item.id &&
        oldValue.tab === newValue.tab && newValue.tab === "info"
        && !newValue.skipZoomAnimation) {
      setAnimateItemIconZoomInOut(true);
    } else if (animateItemIconZoomInOut) {
      setAnimateItemIconZoomInOut(false);
    }

    setItemsInfoData(itemsInfoPanelData);
  }, [itemsInfoPanelData]);

  const closeItemsInfo = () => {
    dispatch(setItemsInfoPanelData(
      {...itemsInfoPanelData, show: false}
    ));
  };

  const onItemsInfoTabChange = (tab) => {
    dispatch(setItemsInfoPanelData(
      {...itemsInfoPanelData, tab}
    ));
  }

  const skipCloseOnInitialLoad = () => {
    dispatch(setItemsInfoPanelData(
      {
        ...itemsInfoPanelData,
        skipCloseOnInitialLoad: true,
        skipZoomAnimation: true
      },
      // skipCloseOnInitialLoad -> true
      true
    ));
  }

  return (
    <ItemsInfoContainer 
      containerId={containerId}
      isOpened={itemsInfoData.show} 
      onCloseItemsInfo={closeItemsInfo}
      onOpenFolderPreview={() => skipCloseOnInitialLoad()}
      item={itemsInfoData.item}
      selectedFiles={itemsInfoData.selectedFiles}
      selectedFolders={itemsInfoData.selectedFolders}
      selectedTab={itemsInfoData.tab}
      onTabChange={onItemsInfoTabChange}
      autoFocusAddComment={itemsInfoData?.autoFocus}
      isMobileView={isMobileView}
      isBusiness={isBusiness}
      animateItemIconZoomInOut={animateItemIconZoomInOut}
      setAnimateItemIconZoomInOut={setAnimateItemIconZoomInOut}
    />
  );
  
};

export default ItemsInfoPanel;