// @flow

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import apiMethod from "../api/apiMethod";
import { __ } from "../lib/translate";
import { formatDt } from "../lib/utils";
import { myUrlLocation } from "@pcloud/web-utilities/dist/config";

import Button from "./ButtonDefault";
import Tooltip from "./Tooltip";
import { TDiv, TSpan } from "./intl";
import Modal, { OvalModalBody, OvalModalClose, OvalModalCloseV2, OvalModalWrap } from "./Modal";
import { updateBillingInfo } from "../lib/state/reducers/businessSlice";

var styles = {
  buttonModalMinWidth: {
    textDecoration: "underline",
    padding: "0",
    color: "#000",
    background: "none",
    fontWeight: "normal"
  }
};

const showErrorAlert = (...args) => {
  HFN.message(...args);
};

const getSourceName = (provider: string, instrument: string): string => {
  const providerInstrumentToSource = {
    Braintree: { paypalaccount: "PayPal", creditcard: "Credit Card" },
    SafeCharge: { creditcard: "Credit Card", apm: "Local Payment" }
  };
  const providerToSource = {
    Stripe: "Credit Card"
  };

  if (providerInstrumentToSource[provider] && providerInstrumentToSource[provider][instrument]) {
    return providerInstrumentToSource[provider][instrument];
  } else if (providerToSource[provider]) {
    return providerToSource[provider];
  }

  return provider;
};

const BillingHistory = () => {
  const isBusiness = useSelector(({ pCloudUser }) => pCloudUser.userinfo.business);
  const userSettings = useSelector(({ pCloudUser }) => pCloudUser.userSettings || {});
  const { billingInfo = [] } = Object.assign({}, userSettings);
  const billingHistory = [...userSettings.payments];
  const [loading, setLoading] = useState(false);
  const [hasCompleteInfo, setHasCompleteInfo] = useState(false);
  const [showMoreInfo, setShowMoreInfo] = useState(-1);
  const dispatch = useDispatch();

  useEffect(() => {
    const billingKeysCompany = ["billingAddress", "country", "company"];
    const billingKeysPerson = ["billingAddress", "country", "names"];
    const hasCompleteInfo =
      billingKeysCompany.every(key => !!billingInfo[key]) ||
      billingKeysPerson.every(key => !!billingInfo[key]);

    setHasCompleteInfo(hasCompleteInfo);
  }, [billingInfo]);

  const issueInvoice = (payment: PaymentData) => {
    const { paymentId } = payment;

    if (loading) {
      return;
    }

    setLoading(true);

    apiMethod(
      "issueinvoice",
      {
        paymentid: paymentId
      },
      ({ invoicecode: invoiceCode }) => {
        const findPaymentById = paymentId => currentPayment =>
          currentPayment.paymentId === paymentId;
        // imp. syntax
        const invpayments = billingHistory.filter(findPaymentById(paymentId));
        const invpayment = invpayments[0];
        console.log("invpaymnet   >>> ", invpayment, invoiceCode, billingHistory);

        invpayment.invoiceCode = invoiceCode;
        dispatch(updateBillingInfo([...billingHistory]));
        setLoading(false);
      },
      {
        errorCallback: ret => {
          showErrorAlert(ret.error, "error");
          setLoading(false);
        }
      }
    );
  };

  const issueReceipt = (payment: PaymentData) => {
    const { paymentId } = payment;

    if (loading) {
      return;
    }

    setLoading(true);

    apiMethod(
      "issuereceipt",
      {
        paymentid: paymentId
      },
      ({ receiptcode: receiptCode }) => {
        const findPaymentById = paymentId => currentPayment =>
          currentPayment.paymentId === paymentId;
        // imp. syntax
        const invpayments = billingHistory.filter(findPaymentById(paymentId));
        const invpayment = invpayments[0];
        console.log("invpaymnet   >>> ", invpayment, receiptCode, billingHistory);

        invpayment.receiptCode = receiptCode;
        dispatch(updateBillingInfo([...billingHistory]));
        setLoading(false);
      },
      {
        errorCallback: ret => {
          showErrorAlert(ret.error, "error");
          setLoading(false);
        }
      }
    );
  };

  const renderGetInvoiceButton = (payment, showFullText) => {
    if (!hasCompleteInfo) {
      return (
        <Tooltip
          title={__(
            "no_invoice_info_for_payment",
            "Please fill all missing fields to be able to generate an invoice."
          )}
        >
          <Button
            onClick={(e) => {e.preventDefault();}}
            style={styles.buttonModalMinWidth}
            color="gray"
            size="medium"
            disabled
            inline
          >
            {showFullText ? <TSpan id="">Get Invoice</TSpan> : <TSpan id="ph_get_invoice">Get</TSpan>}
          </Button>
        </Tooltip>
      );
    } else if (payment.invoiceCode) {
      return (
        <Button
          style={styles.buttonModalMinWidth}
          href={myUrlLocation("invoice/show?code=" + payment.invoiceCode)}
          target="_blank"
          size="medium"
          inline
        >
          {showFullText ? <TSpan id="">Show Invoice</TSpan> : <TSpan id="ph_show_invoice">Show</TSpan>}
        </Button>
      );
    } else if (payment.canCreateInvoice) {
      return (
        <Button
          style={styles.buttonModalMinWidth}
          size="medium"
          inline
          onClick={() => issueInvoice(payment)}
        >
          {showFullText ? <TSpan id="">Get Invoice</TSpan> : <TSpan id="ph_get_invoice">Get</TSpan>}
        </Button>
      );
    } else {
      return (
        <Tooltip
          title={__(
            "no_invoice_for_payment",
            "Unfortunately, you can no longer issue an invoice for this payment."
          )}
        >
          <Button
            onClick={(e) => {e.preventDefault();}}
            style={styles.buttonModalMinWidth}
            color="gray"
            size="medium"
            disabled
            inline
          >
            {showFullText ? <TSpan id="">Get Invoice</TSpan> : <TSpan id="ph_get_invoice">Get</TSpan>}
          </Button>
        </Tooltip>
      );
    }
  };

  const hasBillingHistory = billingHistory.length;

  //sort billing history by date
  hasBillingHistory &&
    billingHistory.sort(function(a, b) {
      return new Date(b.paymentDate).getTime() - new Date(a.paymentDate).getTime();
    });
  console.log("billingHistory", billingHistory);

  return (
    <div>
      {hasBillingHistory ? (
        <div>
          {!isBusiness ? <Title id="ph_billing_history">Payment History:</Title> : null}
          <Container isBusiness={isBusiness}>
            <Row className="header-history">
              <Col className="billing-date">
                <TSpan id="ph_date">Date</TSpan>
              </Col>
              <Col className="billing-type">
                <TSpan id="ph_type">Type</TSpan>
              </Col>
              <Col className="billing-price">
                <TSpan id="ph_price">Price</TSpan>
              </Col>
              <Col className="billing-invoice">
                <TSpan id="ph_getshow">Invoice</TSpan>
              </Col>
              <Col className="billing-receipt">
                <TSpan id="ph_receipt">Receipt</TSpan>
              </Col>
              <Col className="billing-more">
                <TSpan id="More">More</TSpan>
              </Col>
            </Row>
            
            {billingHistory.map((payment, index) => {
              return (
                <Row className="billing-history" key={payment.paymentId}>
                  <Col className="billing-date">{formatDt(payment.paymentDate)}</Col>
                  <Col className="billing-type">{getSourceName(payment.billingProvider, payment.instrument)}</Col>
                  <Col className="billing-price">
                    {payment.amount} {payment.currency}
                  </Col>
                  <Col className="billing-invoice">{renderGetInvoiceButton(payment)}</Col>
                  <Col className="billing-receipt">
                    {payment.receiptCode ? (
                      <Button
                        style={styles.buttonModalMinWidth}
                        href={myUrlLocation("receipt/show?code=" + payment.receiptCode)}
                        target="_blank"
                        size="medium"
                        inline
                      >
                        <TSpan id="ph_show_receipt">Show</TSpan>
                      </Button>
                    ) : (
                      <Button
                        style={styles.buttonModalMinWidth}
                        size="medium"
                        inline
                        onClick={() => issueReceipt(payment)}
                      >
                        <TSpan id="ph_get_receipt">Get</TSpan>
                      </Button>
                    )}
                  </Col>
                  <Col className="billing-more" onClick={() => setShowMoreInfo(index)}><i style={{fontSize: "18px"}} class="fa-light fa-file-invoice"></i></Col>
                </Row>
              );
            })}
          </Container>
        </div>
      ) : (
        null
      )}
      <Modal align="top" opened={showMoreInfo !== -1} onClose={() => setShowMoreInfo(-1)}>
        <MoreInfoModalWrapper>
          <OvalModalCloseV2 onClick={() => setShowMoreInfo(-1)}/>
            {showMoreInfo !== -1 ? (
              <>
                <ModalTitle><TSpan id="billing_information">Billing information</TSpan></ModalTitle>
                <Container isBusiness={isBusiness}>
                  <Row className="billing-history">
                    <Col><TSpan id="ph_date">Date</TSpan></Col>
                    <Col>{formatDt(billingHistory[showMoreInfo].paymentDate)}</Col>
                  </Row>
                  <Row className="billing-history">
                    <Col><TSpan id="ph_type">Type</TSpan></Col>
                    <Col>{getSourceName(billingHistory[showMoreInfo].billingProvider, billingHistory[showMoreInfo].instrument)}</Col>
                  </Row>
                  <Row className="billing-history">
                    <Col><TSpan id="ph_price">Price</TSpan></Col>
                    <Col>{billingHistory[showMoreInfo].amount} {billingHistory[showMoreInfo].currency}</Col>
                  </Row>

                </Container>
                <ButtonWrapper>
                  {renderGetInvoiceButton(billingHistory[showMoreInfo], true)}
                  {billingHistory[showMoreInfo].receiptCode ? (
                    <Button
                      href={myUrlLocation("receipt/show?code=" + billingHistory[showMoreInfo].receiptCode)}
                      target="_blank"
                      size="medium"
                      inline
                    >
                      <TSpan id="">Show Receipt</TSpan>
                    </Button>
                  ) : (
                    <Button
                      size="medium"
                      inline
                      onClick={() => issueReceipt(billingHistory[showMoreInfo])}
                    >
                      <TSpan id="">Get Receipt</TSpan>
                    </Button>
                  )}
                </ButtonWrapper>
              </>
            ) : null}

        </MoreInfoModalWrapper>
      </Modal>
    </div>
  );
};

export default BillingHistory;

const Title = styled(TDiv)`
  font-size: 15px;
  font-weight: bold;
  height: 31px;
  border-bottom: 2px solid #ddd;
  padding-top: 17px;
  padding-left: 2px;
  margin: 10px 0 0 0;
`;

const Container = styled.ul`
  margin: 0;
  padding: 0;
  background: ${props => (props.isBusiness ? "#fff" : "#fafafa")};
`;

const Row = styled.li`
  font-size: 12px;
  line-height: 1.1;
  border-bottom: 1px solid #e9e9e9;
  list-style-type: none;
  display: flex;
  justify-content: space-between;

  &.header-history {
    font-weight: 700;
    padding: 0;
    background: #f4f4f4;
  }

  &.billing-history {
    padding: 10px 0px;
  }

  /* &.billing-history div,
  &.header-history div {
    width: 20%;
  } */

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: 768px) {
    &.billing-history {
      padding: 4px 20px;
    }

    &.header-history {
      padding: 0 20px;
    }
  }
`;

const Col = styled.div`
  width: 20%;
  display: inline-block;
  padding: 0 0 0 10px;
  font-size: 12px;
  line-height: 33px;
  text-align: left;
  box-sizing: border-box;

  &.billing-more {
    text-align: right;
    display: none;
  }

  &.row-heading {
    font-weight: 700;
    width: 30%;
  }
  &.row-heading:first-letter {
    text-transform: uppercase;
  }

  @media (max-width: 768px) {
    padding-left: 0;

    &, &.row-heading {
      width: calc(50% - 20px);
    }

    &.billing-type,
    &.billing-invoice,
    &.billing-receipt {
      display: none;
    }

    &.billing-more {
      width: 40px;
      display: block;
    }
  }
`;

const Span = styled.span`
  display: block;
  overflow: hidden;
  position: relative;
  input,
  select {
    margin-bottom: 10px;
  }
  input[name="vat"] {
    margin: 10px 0 30px 0;
    width: auto;
    padding-left: 35px;
  }
  select {
    padding: 10px 5px;
  }
  textarea {
    min-height: 75px;
  }
`;

const MoreInfoModalWrapper = styled(OvalModalWrap)`
  width: calc(100vw - 40px);
  padding: 20px 0px;
  box-sizing: border-box;
  max-width: 540px;

  & ${MoreInfoModalWrapper} {
    & ${Row} {
      &.billing-history {
        padding: 4px 20px;
      }

      &.header-history {
        padding: 0 20px;
      }

      & ${Col} {
        &:first-child {
          font-weight: bold;
        }

        &:last-child {
          text-align: right;
        }

        @media (max-width: 768px) {
          padding-left: 0;

          &, &.row-heading {
            width: calc(50% - 20px);
          }

          &.billing-type,
          &.billing-invoice,
          &.billing-receipt {
            display: none;
          }

          &.billing-more {
            width: 40px;
            display: block;
          }
        }
      }
    }
  }
`;

const ModalTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 16px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  margin-top: 16px;
  gap: 8px;
  box-sizing: border-box;
  flex-wrap: wrap;

  a {
    color: #17bed0 !important;
    padding: 8px 24px !important;
    border: 1px solid #17bed0;
    line-height: 1;
    font-size: 14px;
    width: 100%;
    background-color: #fff;
    text-decoration: none !important;
    font-weight: normal;
    line-height: 1.2;

    &[disabled] {
      border: none;
      background-color: #f2f2f2;
      border: none;
      color: #888 !important;
      opacity: 1;
    }
  }
`;
