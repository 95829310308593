import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import styled from 'styled-components';
import { formatDtTime } from '../../lib/utils';
import useWindowDimensions from '../../lib/hooks/useWindowDimensions';
import { Tooltip } from "react-tooltip";


type Props = {
  item: {
    id: string,
    fileid: number,
    name: string,
    category: number,
    thumb: boolean,
    width: number,
    height: number,
    contenttype: string,
    size: number,
    created: string,
    modified: string,
    exifdatetime: number,
    hash: number,
    comments: number,
    isshared: boolean,
    ismine: boolean,
    icon: number,
    parentfolderid: number,
  } | {
    id: string,
    folderid: number,
    name: string,
    ismine: boolean,
    thumb: string,
    created: string,
    modified: string,
    comments: number,
    isshared: boolean,
    icon: number,
    parentfolderid: number
  }
}

const ItemsInfo = ({
  item = null
}: Props) => {
  const [fields, setFields] = useState([]);

  const { bodyWidth } = useWindowDimensions();
  const MOBILE_WIDTH_BREAKPOINT = 580;
  const isMobileWidthView = bodyWidth <= MOBILE_WIDTH_BREAKPOINT;

  const ITEM_INFO_VALUE_VIEW_LENGTH = 20;

  const shares = useSelector(({ sharedFolders }) => sharedFolders.sharedFolders.shares);
  const users = useSelector(({ business, pCloudUser }) => pCloudUser.userinfo.business ? business.users : []);
  const breadcrumb = useSelector(({ content }) => content.breadcrumb);
  const userInfo = useSelector(({ pCloudUser }) => pCloudUser.userinfo);

  useEffect(() => {
    if (!item) {
      return;
    }

    if (!item.isfolder) {
      setFileInfo(item);
    } else {
      setFolderInfo(item);
    }
  }, [item]);

  const setFileInfo = (file) => {
    const {
      contenttype,
      isshared,
      created,
      modified,
      size,
      filescnt,
      parentfolderid,
      category,
      width,
      height,
      isdeleted
    } = file;

    const fileInfo = [];

    fileInfo.push([
      __("Size"),
      HFN.formatSize(size) + (filescnt ? ", " + filescnt + " " + __("Files") : "")
    ]);

    fileInfo.push([__("Content type"), contenttype]);

    if (category == HFN.CATEGORY.IMAGE || category == HFN.CATEGORY.VIDEO) {
      if (width && height) {
        fileInfo.push([__("Width"), width + "px"]);
        fileInfo.push([__("Height"), height + "px"]);
      }
    }

    fileInfo.push([__("uploaded", "Uploaded"), formatDtTime(created)]);
    fileInfo.push([__("Modified", "Modified"), formatDtTime(modified)]);

    setParentFolderName(fileInfo, parentfolderid, isdeleted);

    fileInfo.push([__("label_shares", "Shared"), isshared ? __("Yes") : __("No")]);
    
    setOwner(fileInfo, file);

    setFields(fileInfo);
  };

  const setFolderInfo = (folder) => {
    const {
      isshared,
      created,
      modified,
      parentfolderid,
      isdeleted
    } = folder;

    const folderInfo = [];

    folderInfo.push([__("Created"), formatDtTime(created)]);
    folderInfo.push([__("Modified", "Modified"), formatDtTime(modified)]);

    setParentFolderName(folderInfo, parentfolderid, isdeleted);

    folderInfo.push([__("label_shares", "Shared"), isshared ? __("Yes") : __("No")]);

    setOwner(folderInfo, folder);

    setFields(folderInfo);
  };

  const setParentFolderName = (info, parentfolderid, isdeleted) => {
    let folderName = "";
    
    if (isdeleted) {
      folderName = __("Trash", "Trash");
    } else if ("d" + parentfolderid in HFN.data.fflookup) {
      folderName = HFN.metaName(HFN.data.fflookup["d" + parentfolderid]);
      
      if (folderName === "/") {
        folderName = __("My pCloud ", "My pCloud");
      }
    }

    if (folderName !== "") {
      info.push([
        __("parent_folder", "Parent Folder"),
        folderName
      ]);
    }
  };

  const getSharedFrom = (item) => {
    if (item.ismine) {
      return ""
    }

    const extractSharedFrom = (item) => {
      if (item.isfolder && item.ismount) {
        let sharedFrom = "";
        for (const share of shares.incoming) {
          if ((share.folder && share.folder.isbusiness_shared && share.folder.folderid === item.folderid) || (share.folderid && share.folderid === item.folderid)) {
            if (share.sharedfrom && share.sharedfrom[0]) {
              const userId = share.sharedfrom[0].fromuserid;
  
              for (const user of users) {
                if (user.id === userId) {
                  sharedFrom = user.firstname + " " + user.lastname;
                  break;
                }
              }
            }
  
            if (sharedFrom === "" && share.frommail !== "") {
              sharedFrom = share.frommail;
            }
  
            break;
          }
        }
        return sharedFrom;
      }

      return false;
    }

    let sharedFrom = extractSharedFrom(item);

    if (sharedFrom !== false) {
      return sharedFrom;
    }
    
    // Find the owner among the parent directories if they are shared.
    if (breadcrumb && item.parentfolderid && breadcrumb[("d" + item.parentfolderid)]) {
      const breadcrumbIndexes = Object.keys(breadcrumb);
      for (let n = breadcrumbIndexes.length; n--;) {
        const folder = breadcrumb[breadcrumbIndexes[n]];
        if (folder.isfolder) {
          sharedFrom = extractSharedFrom(folder);
          if (sharedFrom !== false) {
            return sharedFrom;
          }
        }
      }
    }

    return "";
  };

  const setOwner = (info, item) => {
    let sharedFrom = getSharedFrom(item);
    if (sharedFrom && sharedFrom !== "") {
      info.push([__("Owner"), sharedFrom, "merge-2-columns"]);
    } else if (item.ismine && userInfo) {
      let meAsOwner = ""
      for (const user of users) {
        if (user.id === userInfo.userid) {
          meAsOwner = user.firstname + " " + user.lastname;
          break;
        }
      }

      info.push([__("Owner"), (meAsOwner !== "" ? meAsOwner : userInfo.email) + " (" + __("i_am_owner", "Me") + ")", "merge-2-columns"]);
    } else {
      info.push([__("Is mine"), item.ismine ? __("Yes") : __("No"), "merge-2-columns"]);
    }
  }

  const renderRow = (item, index) => {
    return (
      <GridItemWrapper key={index + item[0]} className={item[2] ?? ``}>
        <GridItemLabel>{item[0]}</GridItemLabel>
        <GridItemValue>
          <span
            data-id={index + item[0]}
            data-tooltip-id="itme-info-value-tooltip"
            data-tooltip-content={HFN.strFit(item[1], 140)}
            data-tooltip-hidden={isMobileWidthView || item[1].length <= (item[2] && item[2] === "merge-2-columns" ? ((ITEM_INFO_VALUE_VIEW_LENGTH + 3) * 2) : ITEM_INFO_VALUE_VIEW_LENGTH)}
            data-tooltip-delay-show={500}
          >
            {item[1]}
          </span>
        </GridItemValue>
      </GridItemWrapper>
    );
  };

  return (
    <React.Fragment>
      <ItemInfoGridWrapper>
        <ScrollableItemInfoContainer>
          {item ? 
            <ItemInfoGrid>  
              {fields.map(renderRow)}
            </ItemInfoGrid>
          : null}

          {!item ?
            <InfoOnSelectedMultipleItems>
              <i className="bigIcon fa-light fa-circle-info" />
              {__("multiple_selected_items_label", "To view more detailed information about these items select only one.")}
            </InfoOnSelectedMultipleItems>
          : null}
        </ScrollableItemInfoContainer>
      </ItemInfoGridWrapper>

      <Tooltip id="itme-info-value-tooltip" className="cv-tooltip" noArrow={true} />
    </React.Fragment>
  );
};

export default ItemsInfo;

const ItemInfoGridWrapper = styled.div`
  display: flex;
  flex: 1 0 0;
  overflow: hidden;
  position: relative;
`;

const ScrollableItemInfoContainer = styled.div`
  --scrolling-mask-height: 16px;
  display: flex;
  padding: var(--spacing-md, 16px) var(--spacing-none, 0) var(--spacing-none, 0) var(--spacing-none, 0);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-md, 16px);
  flex: 1 0 0;
  align-self: stretch;
  overflow: auto;
  box-sizing: border-box;

  mask: linear-gradient(to bottom, transparent, #FFF var(--scrolling-mask-height), #FFF calc(100% - var(--scrolling-mask-height)), transparent) no-repeat 0 0 / 100% 100%;

  & > :last-child {
    padding-bottom: var(--scrolling-mask-height)
  }
`;

const ItemInfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(188px, 1fr));
  column-gap: var(--spacing-sm, 8px);
  row-gap: var(--spacing-md, 16px);
  width: 100%;

  @media (max-width: 580px) {
    grid-template-columns: 1fr;
  }
`;

const GridItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: var(--spacing-none, 0px) 24px;
  overflow: hidden;
  
  @media (min-width: 581px) {
    &.merge-2-columns {
      grid-column: span 2;
    }
  }
`;

const GridItemLabel = styled.div`
  color: var(--text-secondary, #8C959D);
  font-size: var(--small-font-size);
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

const GridItemValue = styled.div`
  color: var(--text-primary, #282C2F);
  font-size: var(--font-size-14);
  font-weight: 400;
  line-height: 20px;
  font-weight: normal;

  @media (min-width: 581px) {
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
  }
`;

const InfoOnSelectedMultipleItems = styled.div`
  display: flex;
  flex: 1;
  border: none;
  align-items: center;
  text-align: center;
  padding: var(--spacing-md, 16px) var(--spaces-6, 48px);
  gap: var(--spacing-md, 16px);
  flex-direction: column;
  justify-content: center;
  align-self: center;
  color: var(--text-secondary, #8C959D);
  font-size: var(--base-font-size, 14px);
  font-weight: 400;
  line-height: 20px;
`;