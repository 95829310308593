// @flow

import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Modal, { OvalModalWrap, CloseModalIcon } from "../../Modal";
import Button from "../../ButtonDefault";
import { Loader } from "../../../containers/TwoFactorAuth/SharedComponents";
import * as Style from "../styledComponents";

import { __ } from "../../../lib/translate";
import FileIcon from "../../FileIcon";
import { ICONS } from "../../../lib/icons";

type Props = {
  list: Array<any>
};

const TrashDeleteModal = ({ list }: Props) => {
  const [isOpened, setIsOpened] = useState(true);
  const [loading, setLoading] = useState(false);
  const [shouldRenderAttention, setShouldRenderAttention] = useState(false);

  const onCloseClick = () => {
    if (loading) {
      return;
    }
    setIsOpened(false);
  };

  const onDeleteClick = () => {
    setShouldRenderAttention(true);
  };

  const onDeleteForeverClick = () => {
    if (loading) {
      return;
    }
    setLoading(true);

    let error = "";
    let batch = new batchApiCall({
      sequential: false,
      continueOnError: true,
      maxConcurentCalls: 10,
      errorCallback: function(ret) {
        error = ret.error;
      }
    });

    let n;
    for (n = 0; n < list.length; ++n) {
      if (list[n].isfolder) {
        batch.addCall("trash_clear", { folderid: list[n].folderid });
      } else {
        batch.addCall("trash_clear", { fileid: list[n].fileid });
      }
    }

    batch.execute(function(res) {
      if (error) {
        HFN.message(
          __(
            "too_many_subfolders",
            "Unable to perform this action. The folder contains too many subfolders."
          )
        );
      } else {
        HFN.message(__("Item(s) deleted."));
      }
      HFN.refreshTrashFolder(list[0].parentfolderid);

      setIsOpened(false);
    });
  };

  const renderBookmarkItem = (data, index) => {
    const { name, icon } = data;

    return (
      <Style.Row key={index}>
        <FileIcon item={icon} type={ICONS.SVG_LIST} />
        <Text>{name}</Text>
      </Style.Row>
    );
  };

  const renderContentDelete = () => {
    return (
      <React.Fragment>
        <Body>
          <Header>{__("Delete Forever", "Delete Forever")}</Header>
          {!loading ? (
            <Style.Container style={{ paddingBottom: "10px" }}>
              <Style.Label>
                {__("trash_permanently_delete", "Items to permanently delete")}
              </Style.Label>
              <ItemsList>{list.map(renderBookmarkItem)}</ItemsList>
            </Style.Container>
          ) : (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          )}
        </Body>
        <Style.Footer>
          <Button
            style={{ marginRight: "5px" }}
            color="lightgray4"
            disabled={loading}
            onClick={onCloseClick}
          >
            {__("Cancel")}
          </Button>
          <Button
            style={{ marginLeft: "5px" }}
            color="red"
            disabled={loading}
            onClick={onDeleteClick}
          >
            {__("Delete Forever")}
          </Button>
        </Style.Footer>
      </React.Fragment>
    );
  };

  const renderContentAttention = () => {
    return (
      <React.Fragment>
        <Body>
          <Header>{__("Attention", "Attention")}</Header>
          <Style.Title>{__("trash_modal_empty_trash_attention")}</Style.Title>
        </Body>
        <Style.Footer>
          <Button
            style={{ marginRight: "5px" }}
            color="lightgray4"
            disabled={loading}
            onClick={onCloseClick}
          >
            {__("Cancel")}
          </Button>
          <Button
            style={{ marginLeft: "5px" }}
            color="red"
            disabled={loading}
            onClick={onDeleteForeverClick}
          >
            {__("Delete Forever")}
          </Button>
        </Style.Footer>
      </React.Fragment>
    );
  };

  return (
    <Modal align="top" opened={isOpened} onClose={onCloseClick}>
      <OvalModalWrap>
        <CloseModalIcon onClick={onCloseClick} />
        <Content>
          {shouldRenderAttention ? renderContentAttention() : renderContentDelete()}
        </Content>
      </OvalModalWrap>
    </Modal>
  );
};

export default TrashDeleteModal;

const Content = styled.div`
  padding: 30px;
  width: 480px;
  box-sizing: border-box;
  text-align: center;
  min-height: 220px;
  
  @media (max-width: 600px) {
    width: 95vw;
  }
`;

const Text = styled.div`
  max-width: 330px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Body = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const LoaderWrapper = styled.div`
  position: relative;
  min-height: 90px;
`;

const Header = styled(Style.Header)`
  margin-bottom: 0;
`;

const ItemsList = styled(Style.Box)`
  line-height: 24px;
  padding: 10px;
  font-size: 15px;
  font-weight: normal;
  text-align: left;
  padding: 8px;
  justify-content: flex-start;
  flex-direction: column;
  max-height: 115px;
  overflow: auto;
  overflow-x: hidden;

  & > div:only-child {
    padding-bottom: 0px;
  }

  /* width */
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #eaeaea;
    border-radius: 3px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 3px;
    transition: background 200ms ease-in-out;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
