// @flow

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Modal, { OvalModalWrap, OvalModalCloseV2 } from "../../Modal";
import Button from "../../ButtonDefault";
import * as Style from "../styledComponents";
import InputText from "../../InputText";

import apiMethod from "../../../api/apiMethod";
import { __ } from "../../../lib/translate";
import { editUser } from "../../../lib/state/reducers/businessSlice";

type Props = {
  fName: string,
  lName: string,
  currPosition: string
};

const EditUserModal = ({ userId = "", fName = "", lName = "", currPosition = "" }: Props) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [firstName, setFirstName] = useState(fName);
  const [lastName, setLastName] = useState(lName);
  const [position, setPosition] = useState(currPosition === "0" ? "" : currPosition) ;
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    setIsOpen(true);
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };

  const onEnter = (e: any) => {
    if (e.keyCode === 13) {
      onSave();
    }
  };

  const onFirstNameChange = ({ target }) => {
    setFirstName(target.value);
  };

  const onLastNameChange = ({ target }) => {
    setLastName(target.value);
  };

  const onPositionChange = ({ target }) => {
    setPosition(target.value);
  };

  const onSave = () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    if (!firstName.trim().length) {
      HFN.message(__("business_edit_user_fname_error"), "error");
      return;
    }

    if (!lastName.trim().length) {
      HFN.message(__("business_edit_user_lname_error"), "error");
      return;
    }

    const params = { firstname: firstName, lastname: lastName, position: position || 0, userid: userId };

    apiMethod(
      "account_usermodify",
      params,
      () => {
        dispatch(editUser({ id: userId, firstname: firstName, lastname: lastName, position: position }));

        HFN.message("Info saved.");
        PCB.refreshAll();

        handleClose();
      },
      {
        errorCallback: ({ result, error, field }) => {
          setIsLoading(false);
          let errorMessage = __("something_went_wrong_refresh_and_try_again");

          if (result === 2103) {
            if (field === "firstname") {
              errorMessage = __("business_edit_user_fname_error");
            } else if (field === "lastname") {
              errorMessage = __("business_edit_user_lname_error");
            } else if (field === "position") {
              errorMessage = __("business_edit_user_position_error");
            }
          } else if (result === 2076) {
            errorMessage = __("error_2076", "You do not have the permissions for this action.");
          }

          HFN.message(errorMessage, "error");
          throw new Error(error);
        }
      }
    );
  };

  return (
    <Modal align="top" animate onClose={handleClose} opened={isOpen}>
      <OvalModalWrap>
        <OvalModalCloseV2 onClick={handleClose} />
        <Style.Container>
          <Style.Header>{__("Edit User")}</Style.Header>
          <Style.Label>{__("First Name", "Firt Mame")}</Style.Label>
          <InputText
            name="fistName"
            placeholder={__("modal_add_first_name", "Add first name")}
            onChange={onFirstNameChange}
            value={firstName}
            autoFocus
            style={{ marginBottom: "20px" }}
            onKeyDown={onEnter}
          />
          <Style.Label>{__("Last Name", "Last name")}</Style.Label>
          <InputText
            name="lastName"
            placeholder={__("modal_add_last_name", "Add last name")}
            onChange={onLastNameChange}
            value={lastName}
            style={{ marginBottom: "20px" }}
            onKeyDown={onEnter}
          />
          <Style.Label>{__("Position", "Position")}</Style.Label>
          <InputText
            name="position"
            placeholder={__("modal_add_position", "Add a position")}
            onChange={onPositionChange}
            value={position}
            onKeyDown={onEnter}
          />
          <Style.Footer>
            <Button
              color="lightgray4"
              style={{
                marginRight: "5px"
              }}
              onClick={handleClose}
            >
              {__("Close")}
            </Button>
            <Button
              loading={isLoading}
              color="cyan"
              style={{
                marginLeft: "5px"
              }}
              disabledColor="#dddddd"
              onClick={onSave}
            >
              {__("Save")}
            </Button>
          </Style.Footer>
        </Style.Container>
      </OvalModalWrap>
    </Modal>
  );
};

export default EditUserModal;
