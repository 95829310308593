// @flow

import React, { useEffect } from "react";

import { PROVIDERS_NAMES } from "@pcloud/web-utilities/dist/config/constants";
import ChangePaymentContainer from "../../../components/ManageSubscription/ChangePaymentContainer";
import { Container } from "./StyledComponents";

type Props = {
  provider: string,
  onSuccess: () => void
};

const ChangeCardStep = ({ provider, onSuccess = () => {} }: Props) => {
  const updateCreditCardInfo = () => {
    if (provider === PROVIDERS_NAMES.SAFECHARGE) {
      if (typeof SFCardNumber !== "undefined") {
        SFCardNumber.destroy();
        SFCardNumber = undefined;
        if (typeof cardExpiry !== "undefined") {
          cardExpiry.destroy();
          cardExpiry = undefined;
        }
        if (typeof cardCvc !== "undefined") {
          cardCvc.destroy();
          cardCvc = undefined;
        }
      }
    }
    onSuccess();
  };

  return (
    <Container key="changeCardStep">
      <ChangePaymentContainer
        color="green"
        provider={provider}
        updateCreditCardInfo={updateCreditCardInfo}
      />
    </Container>
  );
};

export default ChangeCardStep;
