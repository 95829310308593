// @flow

import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";

import Modal, { OvalModalWrap, CloseModalIcon } from "../../Modal";
import Button from "../../ButtonDefault";
import * as Style from "../styledComponents";

import apiMethod from "../../../api/apiMethod";
import { __ } from "../../../lib/translate";

type Props = {
  onSuccess: () => void
};

const DeleteLogoModal = ({ onSuccess = () => {} }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };

  const onEnter = useCallback(
    (e: any) => {
      if (e.keyCode === 13) {
        deleteLogo();
      }
    },
    [deleteLogo]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("keyup", onEnter);
    } else {
      document.removeEventListener("keyup", onEnter);
    }
  }, [isOpen]);

  const deleteLogo = () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    apiMethod(
      "account_branding_set",
      { deletelogo: 1 },
      () => {
        HFN.message(__("Logo deleted."));
        onSuccess();
        handleClose();
      },
      {
        errorCallback: ({ error, result }) => {
          setIsLoading(false);

          let errorMessage = __("something_went_wrong_refresh_and_try_again");
          if (result === 2076) {
            errorMessage = __("error_2076", "You do not have the permissions for this action.");
          }

          HFN.message(errorMessage, "error");
          throw new Error(error);
        }
      }
    );
  };

  return (
    <Modal align="top" animate onClose={handleClose} opened={isOpen}>
      <OvalModalWrap>
        <CloseModalIcon onClick={handleClose} />
        <Style.Container>
          <HeaderLogo>{__("delete_branding_logo", "Delete Logo")}</HeaderLogo>
          <Style.Message>
            {__(
              "delete_logo_confirmation",
              "You are about to deletе the currently set logo. The default pCloud logo will be shown instead."
            )}
          </Style.Message>
          <Style.Footer>
            <Button
              color="lightgray4"
              style={{
                marginRight: "5px"
              }}
              onClick={handleClose}
            >
              {__("Cancel")}
            </Button>
            <Button
              loading={isLoading}
              color="red"
              style={{
                marginLeft: "5px"
              }}
              disabledColor="#dddddd"
              onClick={deleteLogo}
            >
              {__("Delete", "Delete")}
            </Button>
          </Style.Footer>
        </Style.Container>
      </OvalModalWrap>
    </Modal>
  );
};

export default DeleteLogoModal;

const HeaderLogo = styled(Style.Header)`
  margin-bottom: 30px;
`;
