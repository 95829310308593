// @flow

import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { hiDPI } from "polished";

import Modal, { OvalModalWrap, OvalModalCloseV2 } from "../../Modal";
import ManageMemberQuotaSlider from "../../../containers/Business/MemberQuotaManagement/ManageMemberQuotaSlider";
import Button from "../../ButtonDefault";
import InputTextarea from "../../InputTextarea";
import * as Style from "../styledComponents";

import apiMethod from "../../../api/apiMethod";
import { __ } from "../../../lib/translate";
import { validateEmail } from "../../../lib/utils";
import { getSharedContacts } from "../../../lib/shareFolder-utils";
import {
  BUSINESS_MIN_STORAGE_MEMBER,
  BUSINESS_MIN_USER_QUOTA,
  BUSINESS_QUOTA_STEP
} from "@pcloud/web-utilities/dist/config/constants";

const InviteUsersToAccountModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [storagePerUser, setStoragePerUser] = useState(BUSINESS_MIN_STORAGE_MEMBER);
  const [maxStorageValue, setMaxStorageValue] = useState(0);
  const myEmail = useSelector(({ pCloudUser }) => pCloudUser.userinfo.email);
  const isBusiness = useSelector(({ pCloudUser }) => pCloudUser.userinfo.business);
  const userContacts = useSelector(({ pCloudUser }) => pCloudUser.userContacts);
  const bUserContacts = useSelector(({ business }) => business.users);
  const accountInfo = useSelector(({ business }) => business.accountInfo);
  const { account: { owner = {} } = {} } = accountInfo;
  const { quota, usedquota } = owner;
  const freeOwnerQuota = quota - usedquota;

  const [contacts, setContacts] = useState([]);
  const [inputPrepared, setInputPrepared] = useState(false);
  const [vals, setVals] = useState(0);
  const [combo, setCombo] = useState({});
  const [qEngine, setqEngine] = useState(null);
  const [textareaValue, setTextareaValue] = useState("");

  const inputTexarea = useRef(null);
  const inputUsers = useRef(null);

  const prepareSendInput = useCallback(
    contacts => {
      console.log("Prepare send input >>>>>>>>>>>", contacts);
      if (inputPrepared || !inputUsers.current) {
        return;
      }

      setInputPrepared(true);
      const tokenizer = function(name) {
        const ret = Bloodhound.tokenizers.whitespace(name.replace(/\(?\)?/g, ""));
        if (ret.indexOf(name.replace(/\(?\)?/g, "")) == -1) ret.push(name.replace(/\(?\)?/g, ""));
        return ret;
      };

      let qEngine = new Bloodhound({
        name: "name",
        datumTokenizer: r => {
          const ret = Bloodhound.tokenizers.whitespace(r.name.replace(/\(?\)?/g, ""));

          if (ret.indexOf(r.name.replace(/\(?\)?/g, "")) == -1) {
            ret.push(r.name.replace(/\(?\)?/g, ""));
          }
          if (validateEmail(r.value) && ret.indexOf(r.value.replace(/\(?\)?/g, "")) == -1) {
            ret.push(r.value.replace(/\(?\)?/g, ""));
          }
          if (r.userobj) {
            ret.push(r.userobj.email);
          }

          return ret;
        },
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        local: contacts
      });

      const orig_get = qEngine.get;
      qEngine.get = (query, cb) => {
        return orig_get.apply(qEngine, [
          query,
          function(suggestions) {
            if (!suggestions) return cb(suggestions);
            suggestions.forEach(function(s) {
              const tokens = tokenizer(s.name.toLowerCase());
              let score = 0;

              for (let n = 0; n < tokens.length; ++n) {
                if (tokens[n].indexOf(query) == 0) score++;
              }
              s.exact_match = score;
            });
            suggestions.sort(function(a, b) {
              return a.exact_match > b.exact_match ? -1 : a.exact_match < b.exact_match ? 1 : 0;
            });
            cb(suggestions);
          }
        ]);
      };

      qEngine.initialize();

      const comboOptions = {
        place: inputUsers.current,
        name: "emails",
        width: 410,
        innerWidth: 410,
        sourceKey: "name",
        focusOnInit: false,
        // onlySuggested: true,
        source: qEngine,
        placeholder: __("Email"),
        autocomplete: "off",
        maxNotFoundSymbols: 5,
        suggestOnFocus: false,
        getShowVal: r => {
          if (validateEmail(r)) {
            return HFN.strFitMail(r, 40);
          }

          return HFN.strFit(r, 40);
        },
        onRemoveResultBox: (box, data) => {
          setVals(vals => --vals);
          console.log("bb", box);
          box.find(".combo-res").tooltip("destroy");
        },
        fancyResultBox: (box, data) => {
          let ent;

          setVals(vals => ++vals);

          if (data && data.data) {
            ent = data.data;
            if (ent.source == 2) {
              box.prepend('<img src="/img/fb-tag.png" width="16" height="16" class="inf">');
            } else if (ent.source == 3) {
              box.prepend('<img src="/img/gm-tag.png" width="16" height="16" class="inf">');
              box
                .parent()
                .tooltip("destroy")
                .attr("title", ent.value)
                .tooltip({ container: "body" });
            } else if (ent.source == 1) {
              box.prepend(
                '<img src="/img/or-mail-small.png" width="16" height="16" class="inf">'
              );
            } else if (ent.source == 1001) {
              box.prepend(
                '<img src="' +
                  HFN.prepUrl(ent.userobj.avatar) +
                  '" width="16" height="16" class="inf">'
              );
              box
                .parent()
                .tooltip("destroy")
                .attr("title", ent.userobj.email)
                .tooltip({ container: "body" });
            } else if (ent.source == 1002)
              box.prepend('<img src="/img/b/team.png" width="16" height="16" class="inf">');
          } else if (box.data("val")) {
            if (validateEmail(box.data("val"))) {
              box.prepend(
                '<img src="/img/or-mail-small.png" width="16" height="16" class="inf">'
              );
              box
                .parent()
                .tooltip("destroy")
                .attr("title", box.data("val"))
                .tooltip({ container: "body" });
            } else {
              box
                .parent()
                .parent()
                .tooltip("destroy")
                .addClass("error")
                .find(".combo-res")
                .attr("title", __("This is not a valid contact or an email."))
                .tooltip({ container: "body" });
            }
          }
        },

        imgCloseFile: "",

        extraMulticompleteOpts: {
          suggestAt: inputUsers.current,
          boxRender: data => {
            console.log("BOX>>>> render box", data);
            const box = $("<div>"),
              img = $('<img width="32" height="32">');
            box
              .addClass("clearfix")
              .append($('<div class="iconw"></div>').append(img))
              .append(
                $('<div class="textpart"></div>')
                  .append($('<span class="title"></span>').text(data.name))
                  .append($('<span class="explain"></span>'))
              );

            if (data.source == "import") {
              if (data.value == "Facebook") {
                img.attr("src", "/img/fab.png");
                //box.addClass('fbimport');
              } else if (data.value == "Gmail") {
                img.attr("src", "/img/gmail.png");
                //box.addClass('gmailimport');
              }
              box.find(".explain").text(
                __("Import contacts from %source%", false, {
                  source: data.value
                })
              );
            } else if (data.source == 3) {
              img.attr("src", "/img/gmail.png");
              box.find(".explain").text(data.value);
            } else if (data.source == 2) {
              img.attr(
                "src",
                "https://graph.facebook.com/" + data.value + "/picture?type=square"
              );
              box.find(".iconw").append('<img src="/img/fb-over.png" class="smicon">');
              box.find(".explain").text(__("Facebook Message"));
            } else if (data.source == 1) {
              img.attr("src", "/img/or-mail.png");
              box.find(".explain").text(__("Send Email"));
            } else if (data.source == 1001) {
              img.attr("src", HFN.prepUrl(data.userobj.avatar));
              box.find(".explain").text(data.userobj.email);
            } else if (data.source == 1002) {
              img.attr("src", "/img/b/team.png");
              box.find(".explain").text(
                __("%num% member(s)", false, {
                  num: PCB.teamUserCount(data.teamobj)
                })
              );
            }
            console.log("boxRender", box);
            return box;
          }
        }
      };
      setqEngine(qEngine);
      setCombo(new combo2(comboOptions));
    },
    [inputPrepared]
  );

  useEffect(() => {
    const contacts = getSharedContacts(userContacts, bUserContacts, [], false);
    setContacts(contacts);
  }, [userContacts, bUserContacts]);

  useEffect(() => {
    if (inputPrepared && qEngine) {
      qEngine.clear();
      qEngine.local = contacts;
      qEngine.initialize(true);
    } else {
      prepareSendInput(contacts);
    }
  }, [contacts, inputPrepared, isBusiness, prepareSendInput, qEngine]);

  useEffect(() => {
    const minQuota = freeOwnerQuota - BUSINESS_QUOTA_STEP;
    const availableQuota = Math.floor(freeOwnerQuota / BUSINESS_QUOTA_STEP) * BUSINESS_QUOTA_STEP;
    
    if (minQuota > BUSINESS_MIN_USER_QUOTA) {
      setMaxStorageValue(availableQuota - BUSINESS_MIN_USER_QUOTA);
    } else {
      setMaxStorageValue(BUSINESS_MIN_USER_QUOTA);
    }
  }, [freeOwnerQuota, usedquota]);

  const onTextareaChange = () => {
    setTextareaValue(inputTexarea.current.value);
  };

  const isMe = value => {
    return myEmail === value;
  };
  const isDeactivatedOrDeteted = inputVal => {
    let result = false;

    contacts.forEach(conatct => {
      const { userobj } = conatct;
      if (userobj) {
        if (userobj.email === inputVal) {
          result = !userobj.active || userobj.frozen;
        }
      }
    });
    return result;
  };

  const sendInvitations = () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    let vals = combo.getFullVals(true);
    let message = inputTexarea.current.value;
    let manuallyAdded = [];
    let emails = [];
    let busers = [];

    if (vals && !vals.length) {
      HFN.message(__("Enter valid e-mail."), "error");
      setIsLoading(false);
      return;
    }

    let hasErrorEmail = false;

    for (let n = 0; n < vals.length; n++) {
      let error = "";
      let isGmail = false;
      let isBusinessUser = false;

      if (vals[n].data && [1, 3].indexOf(vals[n].data.source) != -1) {
        // gmails
        isGmail = true;
      } else if (vals[n].data && vals[n].data.source == 1001) {
        // business user
        isBusinessUser = true;
        if (isMe(vals[n].data.userobj.email)) {
          error = __("You can't send email to yourself.");
        } else if (isDeactivatedOrDeteted(vals[n].data.userobj.email)) {
          error = __("The user is inactive and can't receive mails.");
        }
      } else if (!validateEmail(vals[n].val)) {
        // not a valid email
        error = __("This is not a valid contact or an email.");
      }

      if (!hasErrorEmail && error !== "") {
        hasErrorEmail = true;
      }

      if (error !== "") {
        vals[n].obj
          .addClass("error")
          .find(".combo-res")
          .tooltip("destroy")
          .attr("title", __(error))
          .tooltip({ container: "body" });
        continue;
      } else if (isGmail) {
        emails.push({ data: vals[n].data, obj: vals[n].obj });
      } else if (isBusinessUser) {
        if (
          !busers.some(el => (el.buser && el.buser.value) === (vals[n].data && vals[n].data.value))
        ) {
          busers.push({ data: vals[n].data.userobj, obj: vals[n].obj });
        } else {
          continue;
        }
      } else {
        emails.push({ data: { ...vals[n], source: 1 }, obj: vals[n].obj });
        if (!vals[n].data && manuallyAdded.indexOf(vals[n].val) == -1) {
          qEngine.add([{ name: vals[n].val, value: vals[n].val, source: 1 }]);
          manuallyAdded.push(vals[n].val);
        }
      }
    }

    if (hasErrorEmail) {
      setIsLoading(false);
      return;
    }

    if (!emails.length && !busers.length) {
      setIsLoading(false);
      return;
     }

    let allEmailsData = emails.concat(busers);
    let xhr = [];
    let successResult = [];
    let succInvitations = [];
    let errorResult = {};
    let manualToAdd = [];
    let hasError = false;
    const mailsArr = allEmailsData.map(({ data }) => data.value || data.email || data.val);
    const params = {
      quota: storagePerUser,
      message: message
    };

    if (mailsArr.length > 0) {
      params.mails = mailsArr.join(",");
    }

    xhr.push(
      apiMethod(
        "account_invitemail",
        params,
        ({ mails }) => {
          successResult = [...mails];
        },
        {
          errorCallback: ({ result, error }) => {
            if (!(result === 2019) && !(result === 2024)) {
              errorResult = { result, error };
              hasError = true;
            }
            setIsLoading(false);
          },
          showErrorMessage: false
        }
      )
    );

    $.when.apply($, xhr).then(() => {
      allEmailsData.map(({ data, obj }) => {
        const emailObj = data.value || data.email || data.val;
        successResult.map(({ result, mail, message }) => {
          if (emailObj === mail) {
            if (result === 0 || result === 2019 || result === 2024) {
              obj.remove();
              succInvitations.push(mail);
              if (manuallyAdded.indexOf(mail) != -1) {
                manualToAdd.push(mail);
              }
            } else if (result === 2018) {
              hasError = true;
              obj
                .addClass("error")
                .find(".combo-res")
                .tooltip("destroy")
                .attr("title", __("This is not a valid contact or an email."))
                .tooltip({ container: "body" });
            } else {
              hasError = true;
              obj
                .addClass("error")
                .find(".combo-res")
                .tooltip("destroy")
                .attr("title", __(message))
                .tooltip({ container: "body" });
            }
          }
        });

        if (hasError && errorResult.result) {
          if (errorResult.result === 1064) {
            hasError = true;
            obj
              .addClass("error")
              .find(".combo-res")
              .tooltip("destroy")
              .attr("title", __("This is not a valid contact or an email."))
              .tooltip({ container: "body" });
          } else {
            hasError = true;
            obj
              .addClass("error")
              .find(".combo-res")
              .tooltip("destroy")
              .attr("title", __(errorResult.error))
              .tooltip({ container: "body" });
          }
        }
      });

      if (succInvitations.length) {
        PCB.refreshAll();
        HFN.message(__("Invitation(s) sent."));
      }

      if (!hasError) {
        handleClose();
      }

      if (manualToAdd.length) {
        apiMethod("contactload", { source: 1, mails: manualToAdd.join(",") }, () => {});
        manualToAdd = [];
      }
    });
  };

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal align="top" animate onClose={handleClose} opened={isOpen}>
      <OvalModalWrap>
        <OvalModalCloseV2 onClick={handleClose} />
        <InviteWrapper>
          <Style.Header>{__("business_invite_user_header", "Add users")}</Style.Header>
          <Style.Label>{__("Account user", "Account user")}</Style.Label>
          <InputEmailWrapper>
            <div className="tomails" ref={inputUsers}/>
          </InputEmailWrapper>
          <Style.Label>{__("manage_business_storage_quota", "Storage quota")}</Style.Label>
          <ManageMemberQuotaSlider
            minValue={0}
            users={vals || 1}
            startValue={BUSINESS_MIN_USER_QUOTA}
            minQuota={BUSINESS_MIN_USER_QUOTA}
            minStartValue={BUSINESS_MIN_USER_QUOTA}
            maxValue={maxStorageValue}
            getStorageValue={setStoragePerUser}
          />
          <StorageDiscaimer>
            {__(
              "business_storage_discaimer",
              "Select the storage quota every user will receive, when they join the Business account. You can change the storage by 100 GB (min)."
            )}
          </StorageDiscaimer>
          <Style.Label>{__("Message")}</Style.Label>
          <InputTextarea
            style={{ width: "100%", height: "50px", padding: "10px", boxSizing: "border-box" }}
            className="textarea"
            innerRef={inputTexarea}
            name="message"
            value={textareaValue}
            placeholder={__("business_invite_user_message", "Add a message (optional)")}
            autoComplete="off"
            onChange={onTextareaChange}
          />
          <Style.Footer>
            <Button
              color="lightgray4"
              style={{
                marginRight: "5px"
              }}
              onClick={handleClose}
            >
              {__("Cancel")}
            </Button>
            <Button
              color="cyan"
              style={{
                marginLeft: "5px"
              }}
              disabledColor="#dddddd"
              loading={isLoading}
              onClick={sendInvitations}
            >
              {__("Add")}
            </Button>
          </Style.Footer>
        </InviteWrapper>
      </OvalModalWrap>
    </Modal>
  );
};

export default InviteUsersToAccountModal;

const InviteWrapper = styled(Style.Container)`
  text-align: left;

  .tomails {
    display: inline-block;
    width: 417px;
    min-height: 36px;
    height: auto;
    max-height: 80px;
    font-size: 13px;

    @media (max-width: 800px) {
      width: 100%!important;
      min-width: auto;
    }

    .combo-contain {
      height: 36px;
      padding: 5px;
      border: none;
      box-shadow: none;
      box-sizing: border-box;
      background: #fff !important;

      @media (max-width: 800px) {
        width: 100%!important;
      }
      
      .combo-contain-inner, input, .combo-wrap {
        @media (max-width: 800px) {
          width: 100%!important;
        }
      }

      .combo-wrap {
        height: 26px;
        margin: 3px;
        box-sizing: border-box;
      }

      input {
        height: 22px;
        line-height: 22px;
        margin-bottom: 0;
        font-size: 14px;
        width: 400px !important;
        box-sizing: border-box;
        background: #fff !important;
      }

      input::placeholder {
        font-size: 13px;
      }

      .combo-res {
        height: 18px;
        line-height: 18px;
        margin: 0 5px 2px 0;
        border-radius: 12px;
        border: solid 1px #d1f0fb;
        background-color: #f4fdff;
        padding: 3px 10px;

        img.rem, div.rem {
          height: 9px;
          width: 9px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          background-image: url(${require("../../../../root/img/share-icons/blue-close.png")});
          ${hiDPI(1.5)} {
            background-image: url(${require("../../../../root/img/share-icons/blue-close@2x.png")});
          }
        }

        .inf {
          margin: -1px 5px 0 0;
        }
      }

      .error {
        img.rem, div.rem {
          height: 9px;
          width: 9px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          background-image: url(${require("../../../../root/img/share-icons/red-close.png")});
          ${hiDPI(1.5)} {
            background-image: url(${require("../../../../root/img/share-icons/red-close@2x.png")});
          }
        }
      }
    }
    .combo-contain.focu,
    .combo-contain.focu input {
      background: #fff !important;
    }
  }

  .textarea {
    width: 100%;
    height: 50px;
    padding: 10px;
    outline: none;
    font-size: 13px;
    font-family: "Roboto", sans-serif;
    border-radius: 5px;
    border: solid 1px #a1a1a1;
    background-color: #ffffff;
  }
`;

const InputEmailWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 36px;
  box-sizing: border-box;
  box-shadow: inset 0px 3px 0 0 rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  margin-bottom: 17px;
  border: solid 1px #a1a1a1;
  background-color: #ffffff;
  overflow-y: scroll;
  overflow-x: hidden;
  
  @media (max-width: 500px) {
    flex-direction: column;
  }

  & ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const BoxTeam = styled(Style.Box)`
  height: auto;
  padding: 10px;
  font-size: 15px;
`;

const StorageSlider = styled.div`
  padding: 10px;
  background-color: #fafafa;
  font-size: 15px;
  border-radius: 6px;
  border: solid 1px #eeeeee;
  margin-bottom: 10px;
`;

const StorageDiscaimer = styled.div`
  font-size: 13px;
  opacity: 0.5;
  margin-bottom: 15px;
`;
