// @flow

import React from "react";
import Modal, { OvalModalWrap, OvalModalClose } from "./Modal";
import PromoHorizontal1 from "@pcloud/web-utilities/dist/resources/components/PromoHorizontal1";
import PromoHorizontal2 from "@pcloud/web-utilities/dist/resources/components/PromoHorizontal2";
import PromoOld from "@pcloud/web-utilities/dist/resources/components/PromoOld";

type State = { 
  isOpen: boolean,
};

type Props = { 
  title: string,
  titlecolor: string,
  description: string,
  descriptioncolor: string,
  btntext: string,
  btntextcolor: string,
  btnlink: string,
  btnbackground: string,
  disclaimer: string,
  disclaimercolor: string,
  img: string,
  img2x: string,
  img2xSize: string,
  imgSize: string,
  backgroundcolor: string,
  leftpartbackgroundcolor: string,
  label: string,
  lefttitletext: string,
  lefttitletextcolor: string,
  headertext: string,
  headertextcolor: string,
  channelid: number,
  timerStartTime?: string | number | boolean,
  timerEndTime?: string | number | boolean,
  timertextcolor?: string | boolean,
  timerbackgroundcolor?: string | boolean,
  useLocalTime?: boolean,
  newLayout?: boolean,
  newLayoutTest?: boolean,
  middletext: string,
  middletextcolor: string,
  bulletcolor: string,
  bullet1: string,
  bullet2: string,
  bullet3: string,
  bullettextcolor: string,
  sendAnalyticsEvent: () => void
};

export default class PromoModal extends React.Component<Props, State> {
  static defaultProps = {
    timerStartTime: false,
    timerEndTime: false,
    useLocalTime: false,
    newLayout: false
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    (this: any).open = this.open.bind(this);
    (this: any).close = this.close.bind(this);
  }

  componentDidMount() {
    this.open();
  }

  open() {
    const { label, channelid, sendAnalyticsEvent } = this.props;
    this.setState({ isOpen: true });    sendAnalyticsEvent({
      category: "Promo modal",
      action: "open",
      label: label,
      os: 4
    }, {
      channelid: channelid
    });
    // gaAll("event", "promo-offer-modal-" + channelid, "show");
    if (typeof ga4 == 'function') {
      ga4({
        eventname: "view_promotion",
        ecommerce: {
          items: [{
            promotion_name: label,
            creative_slot: "modal",
            item_id: channelid
          }]
        }
      })
    }
  }

  close() {
    const { label, channelid, sendAnalyticsEvent } = this.props;

    this.setState({ isOpen: false });
    sendAnalyticsEvent({
      category: "Promo modal",
      action: "close",
      label: label,
      os: 4
    }, {
      channelid: channelid
    });
  }

  render() {
    const { isOpen }  = this.state;
    const { newLayout, newLayoutTest } = this.props;

    let PromoComponent = newLayout ? PromoHorizontal1 : PromoOld;

    const modalStyle = {
      // padding: "10px",
      backgroundColor: "#" + this.props.backgroundcolor
    }

    if (newLayoutTest) {
      PromoComponent = PromoHorizontal2;
      // modalStyle.borderRadius = "23px";
    } else if (newLayout) {
      PromoComponent = PromoHorizontal1;
    } else {
      PromoComponent = PromoOld;
    }

    return (
      <Modal align="top" onClose={this.close} opened={isOpen}>
        <OvalModalWrap style={modalStyle}>
          <OvalModalClose onClick={this.close}/>
          <PromoComponent {...this.props} closeModal={this.close}/>
        </OvalModalWrap>
      </Modal>
    )
  }
}
