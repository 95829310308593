// @flow

import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";

import Modal, { OvalModalWrap, OvalModalCloseV2 } from "../../Modal";
import Button from "../../ButtonDefault";
import * as Style from "../styledComponents";

import apiMethod from "../../../api/apiMethod";
import { __ } from "../../../lib/translate";

const CancelInvitationModal = ({ email = "" }: { email: string }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const onEnter = useCallback((e: any) => {
    if (e.keyCode === 13) {
      cancelInvite();
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("keyup", onEnter);
    } else {
      document.removeEventListener("keyup", onEnter);

    }
  }, [isOpen]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const cancelInvite = () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    apiMethod(
      "account_invitecancel",
      { mail: email },
      () => {
        PCB.refreshAll();
        HFN.message(__("Invite Cancelled."));
        handleClose();
      },
      {
        errorCallback: ({ error, result }) => {
          setIsLoading(false);
          let errorMessage = __("something_went_wrong_refresh_and_try_again");

          if (result === 2076) {
            errorMessage = __("error_2076", "You do not have the permissions for this action.");
          }

          HFN.message(errorMessage, "error");
          throw new Error(error);
        }
      }
    );
  };

  return (
    <Modal align="top" animate onClose={handleClose} opened={isOpen}>
      <OvalModalWrap>
        <OvalModalCloseV2 onClick={handleClose} />
        <Style.Container>
          <Style.Header>{__("family_invitation_cancelled_title")}</Style.Header>
          <Style.Label>{__("form_email", "Email")}</Style.Label>
          <BoxTeam>{email}</BoxTeam>
          <Style.Footer>
            <Button
              color="lightgray4"
              style={{
                marginRight: "5px"
              }}
              onClick={handleClose}
            >
              {__("Close")}
            </Button>
            <Button
              loading={isLoading}
              color="red"
              style={{
                marginLeft: "5px"
              }}
              disabledColor="#dddddd"
              onClick={cancelInvite}
            >
              {__("Cancel Invite")}
            </Button>
          </Style.Footer>
        </Style.Container>
      </OvalModalWrap>
    </Modal>
  );
};

export default CancelInvitationModal;

const BoxTeam = styled(Style.Box)`
  height: auto;
  padding: 10px;
  font-size: 15px;
`;
