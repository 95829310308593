// @flow
import React, { PureComponent } from "react";
import styled from "styled-components";

import Button from "../../components/ButtonDefault";
import Modal, { OvalModalWrap, CloseModalIcon } from "../../components/Modal";
import * as Style from "../../components/Modals/styledComponents";

import { __ } from "../../lib/translate";

type Props = {
  email: string,
  isOpen: boolean,
  onCloseModal: () => void
};
type State = {};

class WarningInvitationModal extends PureComponent<Props, State> {
  static defaultProps = {
    email: "",
    isOpen: false,
    onCloseModal: () => {}
  };

  constructor(props: Props) {
    super(props);

    (this: any).onClose = this.onClose.bind(this);
    (this: any).onLogOut = this.onLogOut.bind(this);
  }

  onClose() {
    const { onCloseModal } = this.props;

    onCloseModal();
  }

  onLogOut() {
    const { onCloseModal } = this.props;

    onCloseModal();
    HFN.logout()
  }

  render() {
    const { isOpen, email } = this.props;

    return (
      <Modal align="top" opened={isOpen} onClose={this.onClose} shouldCloseOnOverlayClick={false}>
        <OvalModalWrap>
          <CloseModalIcon onClick={this.onClose} />
          <Content>
            <Style.Header>
              {__('Warning')}
            </Style.Header>
            <Text style={{ maxWidth: "450px" }}>
              <Message>
                {__("You are currently logged in as")} <b>{email}</b>.
              </Message>
              <Message>
                {__(
                    "Note that by joining pCloud Business with your existing account, you will:"
                )}
              </Message>
              <Message>
                <ul>
                  <li>{__("suspend all of your shares")}</li>
                  <li>
                    {__(
                        "keep your existing files, but users with certain permissions will be able to see them"
                    )}
                  </li>
                </ul>
              </Message>
              <Message>
                {__('If you want to join with a new account, click on "Logout".')}
              </Message>
            </Text>
            <Style.Footer>
              <Button
                  style={{ marginRight: "5px" }}
                  color="lightgray4"
                  onClick={this.onClose}
              >
                {__("Continue")}
              </Button>
              <Button
                  style={{ marginLeft: "5px" }}
                  color="red"
                  onClick={this.onLogOut}
              >
                {__("Logout")}
              </Button>
            </Style.Footer>
          </Content>
        </OvalModalWrap>
      </Modal>
    );
  }
}

export default WarningInvitationModal;

const Content = styled(Style.Content)`
  text-align: left;
`;

const Text = styled(Style.Text)`
  text-align: left;
`;

const Message = styled.div`
  
`;