// @flow

import React, { useState, useEffect, useRef } from "react";
import { findDOMNode } from "react-dom";
import { List } from "immutable";
import styled from "styled-components";

import Modal, { OvalModalWrap, CloseModalIcon } from "../Modal";
import Picker from "../Picker/Picker";
import PickerSelectedItems from "../Picker/PickerSelectedItems";
import * as Style from "./styledComponents";

import { __ } from "../../lib/translate";

type Props = {
  folder: any,
  fileList: Array<any>,
  opts: any,
  onCopy: () => void,
  modalContainer?: any
};

const CopyToCloudModal = ({
  folder = { folderid: 0, name: "/", contents: null, ismine: true, cancreate: true },
  fileList = [],
  opts = {},
  onCopy = () => {},
  modalContainer
}: Props) => {
  const [isOpen, setIsOpen] = useState(true);
  const [loading, setLoading] = useState(true);
  const [copyList, setCopyList] = useState(List(fileList));
  const iconRef = useRef(null);

  useEffect(() => {
    let list = List();
    fileList.forEach((item, index) => {
      list = list.set(index, {
        meta: item,
        error: ""
      });
    });
    setCopyList(list);
    setLoading(false);

    console.log("copylist", list);
  }, []); //[]

  const handleClose = () => {
    setIsOpen(false);
  };

  const onPick = ({ data }) => {
    const { itemId = -1 } = data;
    if (itemId == -1) {
      return;
    }

    setLoading(true);
    onCopy(itemId);
  };

  const onRemoveRow = index => {
    if (copyList.size === 1) {
      handleClose();
    } else {
      setCopyList(copyList.delete(index));
    }
  };

  return (
    <Modal align="top" animate onClose={handleClose} opened={isOpen} container={modalContainer}>
      <OvalModalWrap>
        <CloseModalIcon onClick={handleClose} />
        <Style.Container>
          <HeaderCopy>{__("Save to pCloud")}</HeaderCopy>
          {loading ? (
            <Style.LoaderWrapper>
              <Style.Loader />
            </Style.LoaderWrapper>
          ) : null}
          <Body key="body">
            <LabelCopy>{__("selected_items", "Items")}</LabelCopy>
            <PickerSelectedItems list={copyList} onRemoveItem={onRemoveRow} />
            <LabelCopy>{__("Save to")}</LabelCopy>
            <Picker
              folder={folder}
              opts={Object.assign(opts, { buttonText: "Save" })}
              onPick={onPick}
              onCancel={handleClose}
            />
          </Body>
        </Style.Container>
      </OvalModalWrap>
    </Modal>
  );
};

export default CopyToCloudModal;

const LabelCopy = styled(Style.Label)`
  height: 18px;
  margin-bottom: 5px;
`;

const HeaderCopy = styled(Style.Header)`
  margin-bottom: 40px;
`;

const LoaderWrapper = styled.div`
  position: absolute;
  min-height: 450px;
  width: 420px;
  z-index: 1000;
  background-color: #fff;
`;

const Body = styled.div``;
