import { createSlice } from "@reduxjs/toolkit";
import { setAutoFreeze } from 'immer';
import { getOnboardingSkipName } from "../../../components/HighlightOnboardingComponent/helpers";

setAutoFreeze(false);

const initialState = {
    queue: [],
    active: 0
};

export const onboardingSlice = createSlice({
    name: "onboarding",
    initialState: initialState,
    reducers: {
        addItem: (state, action) => {
            const onboardingskip = JSON.parse(
                localStorage.getItem(getOnboardingSkipName())
            );

            if (
                (!Array.isArray(onboardingskip) ||
                    !onboardingskip.includes(action.id)) &&
                !state.queue.includes(action.payload)
            ) {
                state.queue.push(action.payload);
            }
        },
        removeItem: (state, action) => {
            state.queue = state.queue.filter((item) => item !== action.payload);
        },
        setActive: (state, action) => {
            state.active = action.payload;
        }
    }
});

export const { addItem, removeItem, setActive } = onboardingSlice.actions;

export default onboardingSlice.reducer;
