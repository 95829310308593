import { createSlice } from "@reduxjs/toolkit";
import { setAutoFreeze } from 'immer';

setAutoFreeze(false);

const initialState = {
    loaded: false,
    sharedFolders: { shares: [], requests: [] },
    numberOfSharedItems: 0,
    folderShares: [],
    shareMessage: "",
    sharePermissionId: 0,
    shareFolderName: ""
};

export const sharedFoldersSlice = createSlice({
    name: "sharedFolders",
    initialState: initialState,
    reducers: {
        loadShared: (state) => {
            state.loaded = true;
        },
        unloadShared: () => initialState,
        loadSharedFolders: (state, action) => {
            state.sharedFolders = action.payload;
        },
        getSharedItems: (state, action) => {
            state.numberOfSharedItems = action.payload;
        },
        loadFolderShares: (state, action) => {
            state.folderShares = action.payload;
        },
        clearFolderShares: (state) => {
            state.folderShares = [];
        },
        addShareToFolder: (state, action) => {
            state.folderShares = [...state.folderShares, action.payload];
        },
        deleteShareToFolder: (state, action) => {
            state.folderShares = deleteShare(state, action.payload);
        },
        updateShareToFolder: (state, action) => {
            state.folderShares = updateShare(state, action.payload);
        },
        acceptedShareToFolder: (state, action) => {
            state.folderShares = acceptedShare(state, action.payload);
        },
        getShareMessage: (state, action) => {
            state.shareMessage = action.payload;
        },
        getSharePermission: (state, action) => {
            state.sharePermissionId = action.payload;
        },
        getShareFolderName: (state, action) => {
            state.shareFolderName = action.payload;
        },
        acceptShareToFolderIn: (state, action) => {
            state.sharedFolders = acceptShareIn(state, action.payload);
        },
        acceptShareToFolderOut: (state, action) => {
            state.sharedFolders = acceptShareOut(state, action.payload);
        }
    }
});

const acceptShareIn = (state, share) => {
    const { sharedFolders } = state;
    const { sharerequestid, shareid } = share;
    const incomingShares = sharedFolders.shares.incoming;

    if (sharerequestid) {
        const filteredShares = incomingShares.filter(share => share.sharerequestid !== sharerequestid);
        delete share.sharerequestid;

        sharedFolders.shares.incoming = [...filteredShares, share];

        return sharedFolders;
    } else if (shareid) {
        const filteredShares = incomingShares.filter(share => share.shareid !== shareid);

        sharedFolders.shares.incoming = [...filteredShares, share];

        return sharedFolders;
    } else {
        return sharedFolders;
    }
};

const acceptShareOut = (state, share) => {
    const { sharedFolders } = state;
    const { sharerequestid, shareid } = share;
    const outgoingShares = sharedFolders.shares.outgoing;

    if (sharerequestid) {
        const filteredShares = outgoingShares.filter(share => share.sharerequestid !== sharerequestid);
        delete share.sharerequestid;

        sharedFolders.shares.outgoing = [...filteredShares, share];

        return sharedFolders;
    } else if (shareid) {
        const filteredShares = outgoingShares.filter(share => share.shareid !== shareid);

        sharedFolders.shares.outgoing = [...filteredShares, share];

        return sharedFolders;
    } else {
        return sharedFolders;
    }
};

const deleteShare = (state, share) => {
    const { folderShares } = state;
    const { shareid, sharerequestid } = share;

    if (shareid) {
        return folderShares.filter((share) => share.shareid !== shareid);
    } else if (sharerequestid) {
        return folderShares.filter(
            (share) => share.sharerequestid !== sharerequestid
        );
    } else {
        return folderShares;
    }
};

const updateShare = (state, share) => {
    const { folderShares } = state;
    const { shareid, sharerequestid } = share;

    if (shareid) {
        const filteredShares = [...folderShares];
        const changedIndex = filteredShares.filter(
          (share) => share.shareid === shareid
        );
        filteredShares[changedIndex] = share;

        return filteredShares;
    } else if (sharerequestid) {
        const filteredShares = [...folderShares];
        const changedIndex = filteredShares.filter(
          (share) => share.sharerequestid === sharerequestid
        );
        filteredShares[changedIndex] = share;

        return filteredShares;
    } else {
        return folderShares;
    }
};

const acceptedShare = (state, share) => {
    const { folderShares } = state;
    const { sharerequestid } = share;

    if (sharerequestid) {
        const filteredShares = folderShares.filter(
            (share) => share.sharerequestid !== sharerequestid
        );
        delete share.sharerequestid;
        return [...filteredShares, share];
    } else {
        return folderShares;
    }
};

export const {
    loadShared,
    unloadShared,
    loadSharedFolders,
    getSharedItems,
    loadFolderShares,
    clearFolderShares,
    addShareToFolder,
    deleteShareToFolder,
    updateShareToFolder,
    acceptedShareToFolder,
    getShareMessage,
    getSharePermission,
    getShareFolderName,
    acceptShareToFolderIn,
    acceptShareToFolderOut
} = sharedFoldersSlice.actions;

export default sharedFoldersSlice.reducer;
