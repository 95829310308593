import React, { useEffect, useState } from "react";
import { MenuProvider } from "../../context/MenuContextProvider";
import useWindowDimensions from "../../../../lib/hooks/useWindowDimensions";
import MobileLayout from "./MobileLayout";
import DesktopLayout from "./DesktopLayout";
import { MOBILE_WIDTH_BREAKPOINT } from "../../../../config/constants";
import { PAGES_EMAIL_REDIRECTS } from "../../../../containers/EmailRedirectPagesContainer/constants";
import { getHashPage, toggleClassForPage } from "../../../../lib/utils";
import hashManager from "../../../../lib/hashManager";

const TopBar = () => {
    const { bodyWidth } = useWindowDimensions();
    const isMobile = bodyWidth < MOBILE_WIDTH_BREAKPOINT;
    const [searchBarExpanded, setSearchBarExpanded] = useState(false);
    const [page, setPage] = useState(getHashPage());

    // Hide top bar for certain pages
    useEffect(() => {
        const handleHashChange = () => {
            setPage(getHashPage());

            toggleClassForPage(PAGES_EMAIL_REDIRECTS, "no-topbar");
        }
        handleHashChange();
        window.addEventListener("hashchange", handleHashChange);

        return () => {
            window.removeEventListener("hashchange", handleHashChange);
        };
    }, []);

    useEffect(() => {
        HFN.showRibbon();
    }, [isMobile]);

    if (page === 'puplink') {
        return null;
    }

    return (
        <MenuProvider>
            {isMobile && (
                <MobileLayout
                    searchBarExpanded={searchBarExpanded}
                    setSearchBarExpanded={setSearchBarExpanded}
                    page={page}
                />
            )}

            {!isMobile && (
                <DesktopLayout
                    searchBarExpanded={searchBarExpanded}
                    setSearchBarExpanded={setSearchBarExpanded}
                    page={page}
                />
            )}
        </MenuProvider>
    );
};

export default TopBar;
