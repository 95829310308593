import styled from "styled-components";

import { SORT_DIRECTION_ASC } from "../../../config/constants";

export const ListGridCointainer = styled.div`
  height: 100%;
  position: relative;
  animation: fadeIn 0.2s normal forwards ease-in;
  outline: none;

  #selection-container {
    height: 100%;
    width: 100%;
    outline: none;

    &.actions-line {
      height: calc(100% - 60px);
    }
  }

  .sticky-list-wrapper {
    overflow-x: hidden !important;
    outline: none;
    overscroll-behavior: none;
  }

  .invisibleOnHover {
    visibility: visible;
    opacity: 1;
    transition: all 150ms ease-in-out;
  }

  .visibleOnHover, .visibleOnHoverOnly {
    visibility: hidden;
    opacity: 0;
  }

  .displayOnHover {
    display: none;
  }

  .visible {
    visibility: visible;
    opacity: 1;
  }

  .clickable {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .selected {
    .visibleOnHover {
      visibility: visible;
      opacity: 1;
    }

    .displayOnHover {
      display: block;
    }
  }

  .selecto {
    border: 1px solid var(--color-primary-500);
    border-radius: var(--radius-minimum);
    background-color: var(--color-primary-50);
    opacity: 0.5;
  }

  .listGridWindow {
    outline: none;
  }

  .audioSideviewWrapper {
    height: 100%;
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
	gap: var(--spacing-xs);
	align-items: center;
`;

export const OptionsWrapper = styled(FlexWrapper)`
  padding: var(--spacing-sm) 0 0 0;
  box-sizing: border-box;
`;

export const SelectionOptions = styled(FlexWrapper)`
  gap: var(--spacing-sm);
  justify-content: start;
  height: 36px;
  font-size: var(--small-font-size);
  color: var(--text-primary);
  width: 100%;
  box-sizing: border-box;
  transition: all 150ms ease-in-out;
  transition-property: background, box-shadow;
  border-radius: var(--radius-2xl);

  &.hasSelection {
    padding: 0 var(--spacing-sm) 0 6px;
    background: var(--surface-secondary);
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.08);
    border: 1px solid var(--divider-secondary);

    .hideOnSelection {
      display: none;
    }
  }

  &.mobile {
    display: none;
  }

  &.hasMobileSelection {
    display: flex;
    gap: var(--spacing-sm);
    position: absolute;
    top: -56px;
    margin: 0 var(--spacing-sm);
    width: calc(100% - var(--spacing-sm)* 2);
    height: 40px;
    z-index: 1;
  }

  .menusWrapper {
    margin-left: auto;
    display: flex;
  }
`;

export const SharedLinksMenu = styled(FlexWrapper)`
    padding: 0 var(--spacing-md) var(--spacing-md) var(--spacing-md);
    width: calc(100% -(var(--spacing-md)* 2));

    .brandingButton {
      margin-left: auto;
    }
`;

export const OptionsButton = styled(FlexWrapper)`
  box-sizing: border-box;
  cursor: pointer;
  width: auto;
  min-width: 24px;
  height: 24px;
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: var(--radius-lg);
  gap: var(--spacing-sm);
  white-space: nowrap;
  overflow: hidden;
  justify-content: center;

  .icon {
    width: 20px;
    text-align: center;
    color: var(--color-icon-menu);
  }

  .label {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &:hover {
    background: var(--surface-hover);
  }
`;

export const OptionsClearButton = styled(OptionsButton)`
  border: 1px solid var(--color-primary-500);
  padding-right: var(--spacing-sm);
  min-width: fit-content;

  .icon {
    width: 16px;
    position: relative;
    top: 1px;
  }
`;

export const HoverMenuButton = styled.div`
  margin-left: var(--spacing-sm);
  padding: var(--spacing-sm);
  display: flex;
  align-items: center;
  cursor: pointer;

    &.no-margin {
        margin-left: 0;
    }

    &.no-padding {
        padding: 0;
    }

  &.negative-margin {
    margin-left: -4px;
  }
`;

export const MenuButtonAboveContent = styled(HoverMenuButton)`
  .toggle-button {
    position: relative;
    top: 1px;
    
    &.active i{
      color: var(--text-link);
    }
  }
`;

export const MenuIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  text-align: center;
  color: var(--color-icon-menu);
`;

export const MenuActiveIcon = styled(MenuIcon)`
  padding-left: var(--spacing-md);
`;

export const MenuLabel = styled.div`
  margin-left: var(--spacing-sm);
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

export const OptionIcon = styled.div`
  display: inline-block;
  color: var(--color-icon-menu);

  &.hoverMenuButton {
    margin-right: var(--spacing-sm);
    position: relative;
    top: 1px;
  }

  &.menuIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    text-align: center;
  }
`;

export const OptionTitle = styled.div`
  display: inline-block;
  margin-left: var(--spacing-md);
`;


export const ReusableMenuBackdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const GridOptions = styled(FlexWrapper)`
  flex-shrink: 1;
`;

export const Header = styled(FlexWrapper)`
  border-bottom: 1px solid var(--divider-secondary);
  height: 39px;
  box-sizing: border-box;

  &.mobile {
    height: 38px;
    position: absolute;
    width: 50%;
    left: 0;
    border-bottom: none;
    gap: 0;
  }
`;

export const Column = styled.div`
  flex-grow: ${({ $stretchWidth }) => $stretchWidth ? 1 : 0};
  transition: all 150ms ease-in-out;
	display: flex;
	align-items: center;
	gap: var(--spacing-sm);
  overflow: hidden;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;

	&.select-col {
		width: 27px;
		min-width: 27px;
    gap: 0;

    .listCheckbox:not(.mobile) {
      padding: 12px 6px;

      &.checked, &.intermediate {
        &::before {
          left: 12px;
        }
      }

      &.intermediate {
        &::before {
          left: 10px;
        }
      }

      &.lgList {
        padding: 20px 6px;

        &.checked, &.intermediate {
          &::before {
            top: 23px;
          }
        }
      }
    }

    &.mobile {
      width: 40px;
      min-width: 40px;
    }

    .hover-menu-button {
      margin: 0;
    }
	}

  &.typeIcon-col {
    width: 24px;
		min-width: 24px;  

    &.lgList {
      width: 40px;
      min-width: 40px;  
    }

    &.mobile {
      width: 28px;
      min-width: 28px;

      &.lgList {
        width: 44px;
        min-width: 44px;  
      }
    }

    .hover-menu-button {
      position: relative;
      left: -4px;
      margin-left: 0;
    }

    .thumbready {
      .iconwrap {
        img {
          border: 1px solid var(--surface-hover-dark) !important;
          box-sizing: border-box;
          border-radius: var(--radius-xs);
        }
      }

      &.playButton {
        .iconwrap::before {
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1;
          content: "\\f04b";
          font-family: "Font Awesome 6 Pro";
          font-weight: 900;
          font-size: 12px;
          color: #ffffff;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.08);
          border-radius: var(--radius-xs);
        }
      }
    }
  }

  &.name-col {
    height: 100%;
    padding-left: var(--spacing-xs);

    &.hasInnerIcon {
      padding-left: 0;
    }

    &.mobile.hasInnerIcon {
      padding-left: 0;
      gap: var(--spacing-xs);
    }

    &.noSelectCol {
      padding-left: var(--spacing-sm) !important;
    }

    .nameSub-col {
      .hasRightIcon {
        position: relative;
        line-height: var(--right-icon-full-width);
        padding-right: calc(var(--spacing-sm) + var(--right-icon-full-width));

        &.fa-2-icons {
          padding-right: calc(var(--spacing-sm) + (var(--right-icon-full-width) * 2));
        }
        &.fa-3-icons {
          padding-right: calc(var(--spacing-sm) + (var(--right-icon-full-width) * 3));
        }
        &.fa-4-icons {
          padding-right: calc(var(--spacing-sm) + (var(--right-icon-full-width) * 4));
        }
        &.fa-5-icons {
          padding-right: calc(var(--spacing-sm) + (var(--right-icon-full-width) * 5));
        }
        &.fa-6-icons {
          padding-right: calc(var(--spacing-sm) + (var(--right-icon-full-width) * 6));
        }

        &:has(.rightIconsWrapper:hover) {
          text-decoration: none;
        }

        .rightIconsWrapper {
          position: absolute;
          right: 0;

          .rightIcon {
            width: var(--right-icon-full-width);
            height: var(--right-icon-full-width);
            display: inline-block;
            text-align: center;

            &.defaultCursor {
              cursor: default;
            }

            .listRow {
              display: inline;
              i {
                width: 16px;
                height: 16px;
              }

              &.mobile {
                background: none;
              }
            }
          }
        }
      }

      &.mobile {
        .hasRightIcon {
          padding-right: var(--right-icon-full-width);

          &.fa-2-icons {
            padding-right: calc(var(--right-icon-full-width) * 2);
          }
          &.fa-3-icons {
            padding-right: calc(var(--right-icon-full-width) * 3);
          }
          &.fa-4-icons {
            padding-right: calc(var(--right-icon-full-width) * 4);
          }
          &.fa-5-icons {
            padding-right: calc(var(--right-icon-full-width) * 5);
          }
          &.fa-6-icons {
            padding-right: calc(var(--right-icon-full-width) * 6);
          }
        }
      }

      --right-icon-full-width: 28px;

      .hasRightIcon {
        position: relative;
        line-height: var(--right-icon-full-width);
        padding-right: calc(var(--spacing-sm) + var(--right-icon-full-width));

        &.fa-2-icons {
          padding-right: calc(var(--spacing-sm) + (var(--right-icon-full-width) * 2));
        }
        &.fa-3-icons {
          padding-right: calc(var(--spacing-sm) + (var(--right-icon-full-width) * 3));
        }
        &.fa-4-icons {
          padding-right: calc(var(--spacing-sm) + (var(--right-icon-full-width) * 4));
        }
        &.fa-5-icons {
          padding-right: calc(var(--spacing-sm) + (var(--right-icon-full-width) * 5));
        }
        &.fa-6-icons {
          padding-right: calc(var(--spacing-sm) + (var(--right-icon-full-width) * 6));
        }

        &:has(.rightIconsWrapper:hover) {
          text-decoration: none;
        }

        .rightIconsWrapper {
          position: absolute;
          right: 0;

          .rightIcon {
            width: var(--right-icon-full-width);
            height: var(--right-icon-full-width);
            display: inline-block;
            text-align: center;

            &.defaultCursor {
              cursor: default;
            }

            .listRow {
              display: inline;
              i {
                width: 16px;
                height: 16px;
              }

              &.mobile {
                background: none;
              }
            }
          }
        }
      }

      &.mobile {
        .hasRightIcon {
          padding-right: var(--right-icon-full-width);

          &.fa-2-icons {
            padding-right: calc(var(--right-icon-full-width) * 2);
          }
          &.fa-3-icons {
            padding-right: calc(var(--right-icon-full-width) * 3);
          }
          &.fa-4-icons {
            padding-right: calc(var(--right-icon-full-width) * 4);
          }
          &.fa-5-icons {
            padding-right: calc(var(--right-icon-full-width) * 5);
          }
          &.fa-6-icons {
            padding-right: calc(var(--right-icon-full-width) * 6);
          }
        }
      }

      .subLine {
        display: block;
        font-size: var(--small-font-size);
        color: var(--color-grey-600);
        position: absolute;
        bottom: 10px;

        .rightIcon {
          padding-left: var(--spacing-sm);

          .smallIcon {
            color: var(--icon-color-lighter) !important;
            font-size: var(--font-size-11) !important;
          }
        }
      }

      &.hasSecLine.mobile {
        height: 100%;
        position: relative;
        flex-direction: column;

        .truncate {
          position: relative;
          top: 9px;
          width: 100%;

          &.subLine {
            position: absolute;
            top: auto;
            bottom: 9px;
          }
        }
      }

      &.mobile.lgList {
        .subLine {
          bottom: 14px;
        }
      }
    }
  }

	&.modified-col, &.deleted-col, &.albums_count-col, &.songs_count-col, &.items-col {
		width: 100px;
		min-width: 100px;

    &:not(.bookmarks) {
      @media only screen and (min-width: 1024px) {
        width: 15%;
        min-width: 15%;
      }
    }
	}

  &.created-col {
		width: 100px;
		min-width: 100px;
  }

  &.description-col, &.comment-col {
		width: calc(35% - 100px);
		min-width: calc(35% - 100px);
  }

	&.size-col, &.traffic-col, &.downloads-col {
		width: 110px;
		min-width: 110px;
		padding-right: var(--spacing-xs);
    justify-content: flex-end;

    @media only screen and (min-width: 1024px) {
      width: 15%;
		  min-width: 15%;
    }
	}

  &.traffic-col, &.downloads-col {
    width: 120px;
		min-width: 120px;

    @media only screen and (max-width: 1280px) {
      width: 90px;
		  min-width: 90px;
    }
  }

  &.permissions-col {
    width: 160px;
		min-width: 160px;

    span::first-letter {
      text-transform: capitalize;
    }
  }

  &.type-col {
    width: 180px;
		min-width: 180px;
    padding-right: var(--spacing-sm);
    justify-content: flex-end;

    &:hover {
      text-decoration: none;
    }

    
    @media only screen and (max-width: 1150px) {
      width: 70px;
		  min-width: 70px;

      .acceptButton {
        width: 30px;
        padding: 0;

        i {
          display: block;
          padding: 8px;
        }

        span {
          display: none;
        }
      }
    }
  }

  &.ownername-col {
    width: 200px;
		min-width: 200px;
    padding-right: var(--spacing-sm);

    @media only screen and (max-width: 1400px) {
      width: 140px;
		  min-width: 140px;
    }
  }

  &.created-col-right, &.shares_count-col {
    width: 150px;
		min-width: 150px;
    padding-right: var(--spacing-xs);
    justify-content: flex-end;

    @media only screen and (max-width: 1280px) {
      width: 100px;
		  min-width: 100px;
    }
  }

  &.created-col-right-small {
    width: 90px;
		min-width: 90px;
    padding-right: var(--spacing-xs);
    justify-content: flex-end;
  }

  &.created-col-with-time {
      width: 175px;
      min-width: 175px;
  }

  &.shares_count-col {
    padding-right: 100px;
  }

  &.album-col, &.genre-col {
		max-width: 15%;
		min-width: 15%;
    padding: 0 var(--spacing-xs);
	}

  &.artist-col {
		max-width: 35%;
		min-width: 35%;
    padding: 0 var(--spacing-xs);
	}
  
  &.actionsColumn-col {
    min-width: min-content;
    padding-right: var(--spacing-sm);
  }

	.iconwrap {
		margin: 0;
		min-width: 24px;
	}

  .thumbready div.iconwrap {
    margin: 0;
  }

  .truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const HeaderColumn = styled(Column)`
  font-size: var(--small-font-size);
  line-height: 16px;
  text-align: left;
  color: ${({ $isSortKey }) => {
    return $isSortKey ? "var(--text-primary)" : "var(--text-secondary)"
  }};
  transition: all 150ms ease-in-out;

  &.sortable {
    cursor: pointer;
    &:hover {
      color: var(--text-primary);

      ${SortIcon}: {
        border-color: var(--text-primary);
      }
    }
  }

  &.name-col {
    &.noSelectCol {
      padding-left: var(--spacing-sm) !important;
    }
  }
`;

export const SortIcon = styled.div`
  display: inline-block;
  width: 4px;
  height: 4px;
  border-right: 1px solid var(--text-secondary);
  border-bottom: 1px solid var(--text-secondary);
  position: relative;
  top: ${({ $sortDirection }) => $sortDirection === SORT_DIRECTION_ASC ? "-1px" : "0px"};
  transform: ${({ $sortDirection }) => $sortDirection === SORT_DIRECTION_ASC ? "rotate(-135deg)" : "rotate(45deg)"};
  transform-origin: center;
  transition: all 200ms ease-in-out;
  opacity: ${({ $isSortKey }) => $isSortKey ? 1 : 0};

  &:after {
    content: ' ';
    width: 2.9px;
    height: 8px;
    border-left: 1px solid var(--text-secondary);
    transform: rotate(-225deg);
    display: block;
    top: -1px;
    position: relative;
    left: -1px;
  }
`;

export const Content = styled.div`
  height: 100%;
  position: relative;
  overflow: hidden;

  &.audioSideviewContainer {
    padding-left: 196px;

    &.mobile {
      padding-left: 0;
      padding-top: calc(100px + var(--spacing-sm));
      height: calc(100% - 100px - var(--spacing-sm));
    }
  }
`;

export const StickyRowWrapperList = styled.div`
	position: absolute;
  z-index: 2;
	display: flex;
	align-items: center;
  background: var(--color-base-white);
  flex-direction: column;

	&.sticky {
		top: 0 !important;
		position: sticky !important;


    &.mobile {
      background: transparent;

      .menusWrapper {
        background: var(--color-base-white);
        width: 50%;
        justify-content: flex-end;
        height: 48px;
        align-items: center;
      }

      &.fullWidth {
        .menusWrapper {
          width: 100%;
        }
      }
    }
	}

  .optionsRow {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    width: 100%;
    height: 48px;

    &.mobile {
      border-bottom: 1px solid var(--divider-secondary);
    }
  }
`;

export const StickyRowWrapperGrid = styled(StickyRowWrapperList)`
  flex-direction: row;

  .gridTitle {
    padding-left: 0 !important;
    margin-left: -4px;
  }

  .menusWrapper {
    display: flex;
    margin-left: auto;
  }
`;

export const StickyLabel = styled.div`
  position: absolute;
  z-index: 1;
  background: white;
  display: none;
  align-items: center;

  .gridTitle {
    bottom: unset;
  }
`;

export const ActionButton = styled.div`
  cursor: pointer;
	border-radius: var(--radius-full);
  color: var(--text-positive);
  border: 1px solid var(--text-positive);
	text-align: center;
  padding: var(--spacing-sm) var(--spacing-lg);
  background: var(--button-positive-background-default);
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  margin-right: var(--spacing-sm);
`;

export const AcceptButton = styled.div`
  cursor: pointer;
	border-radius: var(--radius-full);
  color: var(--text-link);
  border: 1px solid var(--text-link);
	text-align: center;
  background: var(--button-positive-background-default);
  font-weight: 500;
  line-height: 20px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--spacing-md);
  box-sizing: border-box;

  i {
    display: none;
  }
`;

export const DeclineButton = styled.div`
  cursor: pointer;
	border-radius: var(--radius-full);
  color: var(--text-brand);
	text-align: center;
  background: var(--color-grey-300);
  font-weight: 500;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 150ms ease-in-out;
`;

export const IconButton = styled.div`
  cursor: pointer;
	border-radius: 50%;
	text-align: center;
  width: 20px;
  height: 20px;
  padding: 2px;
  color: var(--text-primary);

  &:hover {
    background: var(--color-grey-400);
  }

  &.listRow {
    width: 16px;
    height: 16px;
    padding: 6px;
  }

  &.menuButton {
    padding: var(--spacing-sm);
  }

  &.mobile {
    padding: 8px;
  }
`;

export const CheckboxButton = styled.div`
  cursor: pointer;
  width: 15px;
  height: 15px;
  padding: 12px;
  position: relative;
  transition: all 200ms ease-in-out;

  &::after {
    content: " ";
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 2px;
    border: 1px solid var(--color-grey-400);
    background: white;
    box-sizing: border-box;
  }

  &.checked, &.intermediate {
    &::after {
      border-color: var(--icon-color-link);
      background: var(--icon-color-link);
    }
    &::before {
      content: " ";
      position: absolute;
      top: 15px;
      left: 18px;
      height: 7px;
      width: 3px;
      border: 1px solid white;
      border-width: 0 1px 1px 0;
      transform: rotate(40deg);
      box-sizing: border-box;
    }

    &:hover {
      &::after {
        border-color: var(--icon-color-link);
      }
    }
  }

  &.intermediate {
    &::before {
      top: 19px;
      left: 16px;
      height: 1px;
      width: 7px;
      border: 1px solid white;
      border-width: 0 0 1px 0;
      transform: none;
    }
  }

  &:hover {
    &::after {
      border-color: var(--color-grey-500);
    }
  }
`;

export const Row = styled(FlexWrapper)`
  border-bottom: 1px solid var(--divider-secondary);
  padding: 0;
  box-sizing: border-box;
  height: 40px;
  transition: all 200ms ease-in-out;
  background-color: transparent;
  font-size: var(--base-font-size);
  text-align: left;
  color: var(--text-primary);
	align-items: center;

  &:hover {
		background: var(--surface-hover);

    .visibleOnHover, .visibleOnHoverOnly {
      visibility: visible;
      opacity: 1;
    }

    .displayOnHover {
      display: block;
    }

    .clickable {
      /* text-decoration: underline; */
      /* font-weight: 500; */
      /* letter-spacing: -0.065px; */
    }
  }

  &:hover, &.selected {
  .declineButton {
      background: var(--color-grey-400);
    }
  }

  &.mobile {
    gap: 0;

    &:hover {
      background: inherit;
    }
  }

	&.selected, &.hovered {
		background: var(--surface-selected) !important;
	}

  &.credits {
    font-size: var(--small-font-size);
    border: 0;
    justify-content: center;

    &:hover {
      background: none;
    }
  }

  .albums_count-col, .songs_count-col, .items-col {
    text-transform: lowercase;
  }
`;

export const GridItemNameMobileWrapper = styled.div`
  &.mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - var(--spacing-sm));
    padding: 0 0 0 var(--spacing-sm);
    gap: var(--spacing-sm);
  }

  &.album {
    width: 100%;
    padding: 0;
  }
`;

export const GridItemAlbumNameMobileWrapper = styled.div`
  &:hover {
    cursor: pointer;

    .left {
		  text-decoration: underline !important;
    }
  }

  &.mobile {
    width: calc(100% - 44px);

    ${GridItemNameWrapper} {
      padding-right: 0;
    }
  }
`;

export const GridItemNameWrapper = styled.div`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	flex: 1;

  &.left {
    padding: var(--spacing-xs) var(--spacing-sm) 0 var(--spacing-sm);

    &.second-line {
      color: var(--text-secondary);
      font-size: 12px !important;
      padding-top: 0;
      padding-bottom: var(--spacing-xs);
    }
  }
`;

export const GridItemName = styled.div`
	display: inline;
	font-size: var(--base-font-size);
	font-weight: 400;
	line-height: 24px;
	cursor: pointer;
	line-height: 40px;

  &.left {
    line-height: 24px;
    &.second-line {
        line-height: 20px;
    }
  }

	&:hover {
		text-decoration: underline;
		&:after {
			text-decoration: underline;
		}
	}
`;

export const GridTitle = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
	font-family: 'Inter', sans-serif;
	font-size: var(--medium-font-size);
	font-style: normal;
	font-weight: 500;
	line-height: 22px;
	width: 100%;
	color: var(--text-secondary);
	padding-left: var(--spacing-sm);
	position: absolute;
	bottom: var(--spacing-sm);

  &.filesTitle {
    padding-left: 4px;
  }

  &.mobile {
    padding-left: 0;
    margin-left: 0;
  }
`;

export const GridCellWrapper = styled(FlexWrapper)`
  box-sizing: border-box;
	padding: 0 var(--spacing-sm);

	.cellContent {
		display: flex;
		align-items: center;
		overflow: hidden;
		width: 100%;
		transition: background 200ms ease-in-out;
		background: var(--surface-primary);
		border: 1px solid var(--surface-primary);
		border-radius: var(--radius-xs);
		position: relative;
		box-sizing: border-box;

		&:hover:not(.mobile) {
			background: var(--surface-hover-dark) !important;
			border: 1px solid var(--surface-hover-dark);

			.visibleOnHover, .visibleOnHoverOnly {
				visibility: visible;
				opacity: 1;
			}

      .invisibleOnHover {
				visibility: hidden;
				opacity: 0;
        transition: none;
			}

      .displayOnHover {
        display: block;
      }
		}

    &.mobile:hover {
      /* background: inherit !important; */
    }

	}

	&.itemFolder, &.itemFile {
		.cellContent.selected {
			background: var(--surface-selected) !important;
			border-color: var(--surface-selected);

      &:not(.noCrop) {
        .fileIconWrapper.thumbready::before {
          display: block;
        }
      }

      &.noCrop {
        .fileIconWrapper {
          &.thumbready {
            img {
              transition: border 200ms ease-in-out;
              border-color: var(--surface-selected) !important;
            }
          }
          &:not(.thumbready) .iconwrap {
            background: none !important;
          }
        }
      }

      .invisibleOnHover {
				visibility: hidden;
				opacity: 0;
			}
		}
	}

  &.itemFolder, &.itemFile {
		.cellContent.hovered {
			background: var(--surface-selected) !important;
			border-color: var(--surface-selected);
		}
	}

	&.itemFolder {
  	height: 56px;

		.cellContent {
			padding: 0 var(--spacing-sm) 0 var(--spacing-sm);
			gap: var(--spacing-xs);
      height: 40px;

			.checkBox {
				z-index: 1;
        position: absolute;
        left: 0;

        &.mobile {
          padding: 0;
          z-index: auto;
          position: relative;
        }
			}

			.iconwrap {
				min-width: 24px;
				margin: 0;

				img {
					height: 24px;
    			width: 24px;
				}
			}

      &.mobile {
        padding: 0;
      }

			${GridItemNameWrapper} {
				padding-left: var(--spacing-xs);
			}
		}
	}

	&.itemFile {
		.cellContent {
			position: absolute;
			width: calc(100% - var(--spacing-md));
			height: calc(100% - var(--spacing-md));
			display: flex;
			align-items: center;
			justify-content: space-between;
			overflow: hidden;
			flex-direction: column;
			background: var(--surface-primary);

			.checkBox {
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
			}

      .counter {
        position: absolute;
        top: 8px;
        right: 8px;
        height: 24px;
        color: var(--color-base-white);
        font-size: 12px;
        line-height: 24px;
        background: rgb(73 79 85 / 80%);
        border-radius: var(--radius-xs);
        padding: 0 var(--spacing-sm);
        z-index: 1;
      }

			.fileIconWrapper {
				height: calc(100% - 40px);
				overflow: hidden;
				display: flex;
				align-items: center;
				width: 100%;
				justify-content: center;

				.iconwrap {
					margin: 0;
					width: 30%;

					img {
						height: 100% !important;
						width: 100% !important;
					}
				}

        &.album {
				  height: calc(100% - 52px);
          .iconwrap {
					  width: 40%;
          }
        }

        &.playlist {
          .iconwrap {
					  width: 40%;
          }
        }

				&.thumbready {
					&::before {
						display: none;
						z-index: 1;
						content: " ";
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						height: 24%;
						background: linear-gradient(0deg, rgba(115, 115, 115, 0) 0%, rgba(115, 115, 115, 0.24) 100%);
					}

					.iconwrap {
						height: 100%;
						width: 100%;

						img {
							width: 100% !important;
							height: 100% !important;
							object-fit: cover;
							border-radius: 0 2px 2px;
						}
					}

          &.playButton {
            .iconwrap::before {
              display: flex;
              align-items: center;
              justify-content: center;
              z-index: 1;
              content: "\\f04b";
              font-family: "Font Awesome 6 Pro";
              font-weight: 900;
              font-size: 32px;
              color: #ffffff;
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background: rgba(0, 0, 0, 0.08);
              border-radius: var(--radius-xs);
            }
          }
				}
			}

      &.noCrop {
        background: none;
        border: none;

        .fileIconWrapper {

          &:not(.thumbready) .iconwrap {
            border-radius: var(--radius-minimum);
            padding: 20% 24%;
            transition: background 200ms ease-in-out;
          }

          &.thumbready {
            img {
              object-fit: unset;
              border-radius: var(--radius-xs);
              width: auto !important;
              height: auto !important;
              max-width: 100% !important;
              max-height: 100% !important;
              position: absolute;
              bottom: 0;
              transform: translateX(-50%);
              margin: 0 auto;
              border: 1px solid var(--surface-hover-dark) !important;
              box-sizing: border-box;
            }

            &.playButton {
              justify-content: flex-end;
              flex-direction: column;
              align-items: center;

              .iconwrap {
                height: auto;
                width: auto;
                max-width: 100%;
                max-height: 100%;

                img {
                  position: unset;
                  transform: none;
                }
              }
            }
          }
        }

        &.mobile {
          .fileIconWrapper {
            background: var(--surface-primary);
            border-radius: var(--radius-minimum);
          }

          &.selected {
            .fileIconWrapper {
              background: var(--surface-selected) !important;
            }
          }
        }

        &:hover {
          .fileIconWrapper {
            &:not(.thumbready) .iconwrap {
              background: var(--surface-hover-dark);
            }
          }
          &.mobile {
            .fileIconWrapper {
              background: var(--surface-primary);
              &:not(.thumbready) .iconwrap {
                background: var(--surface-primary);
              }
            }
          }
        }
      }

      &:hover:not(.noCrop) {
        .fileIconWrapper.thumbready::before {
          display: block;
        }
      }
		}
	}
`;
