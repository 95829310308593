import { useState, useEffect, useRef } from "react";
import { apiMethod } from "../../../api/index";
import { searchAndReplaceMentions } from "../utils/utils";
import { useSelector } from "react-redux";

export const FETCH_COMMENTS_PORTION_LIMIT = 10;
export const DELAY_COMMENTS_EVENTSUBSCRIBE_CHECK = 3000;
export const DELAY_ON_XHR_ERROR_COMMENTS_EVENTSUBSCRIBE_CHECK = 10000;

const useManageCommentsListData = ({
  contentItemData,
  commentsListIsVisible
}) => {
  const [comments, setComments] = useState({data: new Map(), totalCount: 0});
  const [newCommentEventData, setNewCommentEventData] = useState(null);
  const [postedComment, setPostedComment] = useState(null);
  const [loadingMoreComments, setLoadingMoreComments] = useState(false);
  const [loadingInitialComments, setLoadingInitialComments] = useState(true);
  const [usersForMentions, setUsersForMentions] = useState([]);
  const [postCommentInProgress, setPostCommentInProgress] = useState(false);

  const loggedUserId = useSelector(({ pCloudUser }) => pCloudUser.userinfo.userid);

  const loadCommentsXHR = useRef();
  
  const subscribeCommentChangesXHR = useRef({xhr: null, params: null});
  const delayEventSubscribeCheck = useRef(DELAY_COMMENTS_EVENTSUBSCRIBE_CHECK);
  const eventSubscribeTimeoutHandler = useRef(null);
  
  const lastContentItemDataId = useRef(null);
  const lastCommentId = useRef();

  // Array which holds all received new comments while postCommentInProgress === true
  const postponedNewComments = useRef([]);


  useEffect(() => {
    return () => {
      // Stop add/remove comment event listeners.
      stopCommentChangeListeners();
    }
  }, []);

  useEffect(() => {
    if (!commentsListIsVisible) {
      // Stop add/remove comment event listeners.
      stopCommentChangeListeners();
      lastContentItemDataId.current = null;
    }
  }, [commentsListIsVisible]);

  useEffect(() => {
    if (!contentItemData || lastContentItemDataId.current === contentItemData.id) {
      // contentItemData is required param. || we're not on a different item comments thread
      return;
    }

    lastContentItemDataId.current = contentItemData.id;

    loadInitialComments();
  }, [contentItemData]);

  /**
   * On new comment fired by "addcomment" event as response to "eventsubscribe"
   */
  useEffect(() => {
    if (!newCommentEventData) {
      return;
    }

    addNewComment(newCommentEventData);
  }, [newCommentEventData]);

  /**
   * On posted new comment from PostComment.js
   */
  useEffect(() => {
    if (!postedComment) {
      return;
    }

    addNewComment(postedComment);
  }, [postedComment]);

  /** Handle with postponedNewComments if there are any. */
  useEffect(() => {
    if (postCommentInProgress) {
      return;
    }

    // Posting finished...
    if (postponedNewComments.current.length > 0) {
      const postponedNewCommentsCopy = postponedNewComments.current;
      // Cleanup added comments.
      postponedNewComments.current = [];
      postponedNewCommentsCopy.forEach((postponedNewComment) => {
        if (comments.data.get(postponedNewComment.comment.id)) {
          delete postponedNewComment.comment.isnew;
        }

        // Add those coming from "addcomment" subscription.
        addNewComment(postponedNewComment);
      });
    }
  }, [postCommentInProgress]);

  /**  Set posting as finished after the new posted comment with iscommentpostresponse === true was added. */
  useEffect(() => {
    const lastComment = (comments && comments.data && lastCommentId && lastCommentId.current && comments.data.get(lastCommentId.current)) || null;
    
    if (lastComment && lastComment.iscommentpostresponse) {
      // Posted comment is here. This will allow postponedNewComments to be added.
      setPostCommentInProgress(false);
      // Remove this property to prevent next changes in comments (for example loadMore) to be here.
      delete lastComment.iscommentpostresponse;
    }
  }, [comments]);

  useEffect(() => {
    // Loading initial comments and we have new comments to be added.
    if (!loadingInitialComments && postponedNewComments.current.length > 0) {
      setPostCommentInProgress(false);
    }
  }, [loadingInitialComments])

  const loadInitialComments = () => {
    // Stop previous add/remove comment event listeners.
    stopCommentChangeListeners();
    setLoadingInitialComments(true);
    loadComments(0, FETCH_COMMENTS_PORTION_LIMIT).finally(() => {
      setLoadingInitialComments(false);
    });
  };

  const loadMoreComments = () => {
    if (loadingMoreComments || comments.totalCount <= comments.data.size) {
      return;
    }

    setLoadingMoreComments(true);

    const offset = comments.data.size;

    loadComments(offset, FETCH_COMMENTS_PORTION_LIMIT).finally(() => {
      setLoadingMoreComments(false);
    });
  };

  const onPostedComment = (comment, {folderid, fileid}) => {
    // Hook postedComment will be triggered.
    setPostedComment({comment, folderid, fileid})
  };

  const addNewComment = (newComment) => {
    if (!newComment) {
      return;
    }
    
    const { comment, folderid, fileid } = newComment;

    if (!(
      (contentItemData.isfolder && contentItemData.folderid === folderid) ||
      (!contentItemData.isfolder && contentItemData.fileid === fileid))
    ) {
      // We're no longer viewing the same item comments thread.
      return;
    }

    // If post new comment in progress
    // && coming from "addcomment" event
    // && comment is from the logged user 
    if (postCommentInProgress && !comment.iscommentpostresponse && comment.usermeta.id === loggedUserId) {
      // Postpone in queue to be updated after sending in progress is finished, because it'll be added after the postcomment request is finished.
      postponedNewComments.current.push(newComment);
      return;
    }

    if (comment.iscommentpostresponse && loadingInitialComments) {
      // Postpone in queue to be added after initial load is finished.
      postponedNewComments.current.push(newComment);
      return;
    }
  
    const { newComments } = prepareCommentDataForOutput([comment], usersForMentions);

    if (newComments.size === 1 && newComments.get(comment.id)) {
      setComments((prevComments) => {
        if (lastCommentId.current) {
          const lastComment = prevComments.data.get(lastCommentId.current);
          if (lastComment && Date.parse(lastComment.dt) <= Date.parse(comment.dt)) {
            lastCommentId.current = comment.id;
            const thisCommentExists = prevComments.data.get(comment.id) || null;
            
            if (thisCommentExists && comment.isnew) {
              delete comment.isnew;
            }

            const prevCommentsSize = prevComments.data.size;
            
            return { data: prevComments.data.set(comment.id, newComments.get(comment.id)), totalCount: prevComments.totalCount + (prevComments.data.size - prevCommentsSize) }
          }
        }

        // Find the place for the new comment in the Map
        const prevCommentsSize = prevComments.data.size;
        const newCommentsArr = Array.from(prevComments.data.values());
        newCommentsArr.push(comment);
        const { newComments: newCommentsMap, lastId } = prepareCommentDataForOutput(newCommentsArr, usersForMentions);
        lastCommentId.current = lastId;
        if (newCommentsMap.size > prevComments.data.size) {
          return { data: newCommentsMap, totalCount: prevComments.totalCount + (newCommentsMap.size - prevCommentsSize) };
        }

        return prevComments;
      });
    }
  };

  const onDeletedComment = (comment) => {
    setComments((prevComments) => {
      if (prevComments.data.delete(comment.id)) {
        // Successfully deleted.
        if (lastCommentId.current === comment.id) {
          // Set new lastCommentId
          lastCommentId.current = Array.from(prevComments.data.keys()).pop();
        }

        if (prevComments.data.size === 0 && prevComments.totalCount - 1 > 0) {
          // We have more comments and no comments are displayed let's load more.
          loadMoreComments();
        }

        return { ...prevComments, totalCount: prevComments.totalCount - 1 };
      }
      
      // Already removed.
      return prevComments;  
    });
  };

  const loadComments = (offset, limit = 10) => {
    // Cancel previous requests
    if (loadCommentsXHR && typeof loadCommentsXHR.current !== "undefined" && loadCommentsXHR.current !== null && loadCommentsXHR.current.abort) {
      loadCommentsXHR.current.abort();
    }

    const loadCommentsParams = {
      offset,
      limit,
      // 1 -> asc, 2 -> desc
      sort: 2,
      withavatars: 1
    };

    if (offset === 0) {
      loadCommentsParams.showinfo = 1;
    }

    if (contentItemData.isfolder) {
      loadCommentsParams.folderid = contentItemData.folderid;
    } else {
      loadCommentsParams.fileid = contentItemData.fileid;
    }
  
    // We need them to check if user's profile can be viewed and also for mentions.
    const accountUsersPromise = offset === 0 ? new Promise((resolve, reject) => {
      apiMethod("account_users", { withavatars: 1 }, resolve, {
          errorCallback: reject,
          onXhrError: (xhr, status, error) => {
            reject({ reasoncode: "xhr_error", xhrError: { error: xhr, status, error } });
        }
      })
    }) : Promise.resolve();

    const loadCommentsPromise = new Promise((resolve, reject) => {
      loadCommentsXHR.current = apiMethod("commentlist", loadCommentsParams, resolve, {
          forceFresh: true,
          errorCallback: reject,
          onXhrError: (xhr, status, error) => {
            reject({ reasoncode: "xhr_error", xhrError: { error: xhr, status, error } });
          }
        }
      );
    });

    return Promise.all([accountUsersPromise, loadCommentsPromise]).then((values) => {
      const accountUsersRes = values[0];
      const commentsRes = values[1];

      if (accountUsersRes) {
        setUsersForMentions(accountUsersRes.users);
      }

      const {newComments, lastId} = prepareCommentDataForOutput(commentsRes.comments, accountUsersRes ? accountUsersRes.users : usersForMentions);
      lastCommentId.current = lastId;
      
      if (offset === 0) {
        // Loading initial comments
        setComments({data: newComments, totalCount: commentsRes.totalcomments || 0});
        
        // Start comment change listeners.
        const subscribeCommentChangeParams = {
          withavatars: 1,
          commentid: commentsRes.comments.length > 0 ? commentsRes.comments[0].id : 0,
          commentdeletetime: commentsRes.lastdeletetime
        };

        if (loadCommentsParams.folderid) {
          subscribeCommentChangeParams.folderid = loadCommentsParams.folderid;
        } else {
          subscribeCommentChangeParams.fileid = loadCommentsParams.fileid;
        }

        startCommentChangeListeners(subscribeCommentChangeParams);
        return;
      }
      
      if (newComments.size > 0) {
        setComments((prevComments) => {
          // Concat with prevComments Map.
          prevComments.data.forEach((value, key) => newComments.set(key, value));
          return { ...prevComments, data: newComments }
        });
      } else {
        // No comments returned for this offset. They could be deleted so accept this as end.
        setComments((prevComments) => {
          return { ...prevComments, totalCount: prevComments.data.size }
        });
      }
    }).catch((err) => {
      if (err && err.reasoncode && err.reasoncode === "xhr_error") {
        setComments({data: new Map(), totalCount: 0});
        HFN.message(__("Please check your Internet connection."), "error");
      }
    });
  };

  const prepareCommentDataForOutput = (commentsResArr, accUsersForMentions) => {
    let lastId = null;
    // Sort by date in DESC order, because API response doesn't guarantee the returned response is sorted by date.
    commentsResArr.sort((a, b) => Date.parse(b.dt) - Date.parse(a.dt));

    // Reverse map without changing the cached response.
    const newComments = commentsResArr.reduce((newComments, comment, index, resComments) => {
      const newComment = { ...resComments[resComments.length - 1 - index] };

      if (newComment.prepared) {
        // Already prepared
        lastId = newComment.id;
        return newComments.set(newComment.id, newComment);
      }

      // TODO We won't receive deleted after API method is changed, so remove the following check.
      if (newComment.deleted) {
        return newComments;
      }

      // Skip "N new revisions" row.
      if (newComment.revcount) {
        return newComments;
      }
      
      // Can show View profile link?
      newComment.usermeta.link = accUsersForMentions.some(({id}) => newComment.usermeta.id === id) ? `#page=b_user&id=${newComment.usermeta.id}` : null;
      
      if (contentItemData.isfolder) {
        // Hide revision id link.
        newComment.revisionid = null;
      }

      if (accUsersForMentions.length > 0) {
        newComment.comment = searchAndReplaceMentions(newComment.comment, accUsersForMentions);
      }
      newComment.prepared = true;
      lastId = newComment.id;
      return newComments.set(newComment.id, newComment);
    }, new Map());

    return { newComments, lastId };
  };

  const stopCommentChangeListeners = () => {
    if (subscribeCommentChangesXHR && typeof subscribeCommentChangesXHR.current.xhr !== "undefined" && subscribeCommentChangesXHR.current.xhr !== null && subscribeCommentChangesXHR.current.xhr.abort) {
      subscribeCommentChangesXHR.current.xhr.abort();
      subscribeCommentChangesXHR.current.params = null;
    }

    if (eventSubscribeTimeoutHandler.current) {
      clearTimeout(eventSubscribeTimeoutHandler.current);
      eventSubscribeTimeoutHandler.current = null;
    }

    delayEventSubscribeCheck.current = DELAY_COMMENTS_EVENTSUBSCRIBE_CHECK;
  }

  const startCommentChangeListeners = (params) => {
    // Stop previous
    stopCommentChangeListeners();

    params.events = "addcomment,deletecomment";

    subscribeCommentChangesXHR.current.params = params;
    subscribeCommentChangesXHR.current.xhr = apiMethod("eventsubscribe", params, 
    (res) => {
      if (res.events && res.events.length > 0) {
        for (const eventData of res.events) {
          if (eventData.event === "addcomment") {
            // New comment.
            eventData.comment.isnew = true;
            // Hook newCommentEventData will be triggered.
            setNewCommentEventData({comment: eventData.comment, folderid: params.folderid || undefined, fileid: params.fileid || undefined});

            if (parseInt(eventData.comment.id) > params.commentid) {
              params.commentid = parseInt(eventData.comment.id);
            }
          } else if (eventData.event === "deletecomment") {
            // Removed comment.
            onDeletedComment(eventData.comment);
            
            if (parseInt(eventData.comment.deletetime) > params.commentdeletetime) {
              params.commentdeletetime = parseInt(eventData.comment.deletetime);
            }
          }
        }
      }

      // Restart listening for changes with the same target.
      delayEventSubscribeCheck.current = DELAY_COMMENTS_EVENTSUBSCRIBE_CHECK;
      startCommentChangeListeners(params);
    },
    {
      errorCallback: () => {
        // eventsubscribe API Error
        stopCommentChangeListeners();
      },
      onXhrError: (xhr, status, error) => {
        if (status !== "timeout") {
          delayEventSubscribeCheck.current =  DELAY_ON_XHR_ERROR_COMMENTS_EVENTSUBSCRIBE_CHECK;
          checkCommentChangeListenersAreRunning();
        }
      }
    });

    checkCommentChangeListenersAreRunning();
  };

  const checkCommentChangeListenersAreRunning = () => {
    if (eventSubscribeTimeoutHandler.current) {
      clearTimeout(eventSubscribeTimeoutHandler.current);
    }
    
    eventSubscribeTimeoutHandler.current = setTimeout(() => {
      if (subscribeCommentChangesXHR.current.xhr && subscribeCommentChangesXHR.current.xhr.readyState > 0 && subscribeCommentChangesXHR.current.xhr.readyState < 4) {
        // The request is running...
        delayEventSubscribeCheck.current = DELAY_COMMENTS_EVENTSUBSCRIBE_CHECK;
        checkCommentChangeListenersAreRunning();
        return;
      }

      if (subscribeCommentChangesXHR.current.xhr && subscribeCommentChangesXHR.current.params) {
        startCommentChangeListeners(subscribeCommentChangesXHR.current.params);
      } else {
        checkCommentChangeListenersAreRunning();
      }
    }, delayEventSubscribeCheck.current);
  };

  return {
    comments,
    loadMoreComments,
    loadingInitialComments,
    loadingMoreComments,
    usersForMentions,
    onPostedComment,
    onDeletedComment,
    postCommentInProgress,
    setPostCommentInProgress,
    FETCH_COMMENTS_PORTION_LIMIT
  }
};

export default useManageCommentsListData;