// @flow

import * as React from "react";
import $ from "jquery";
import { getIconInfo } from "../lib/icons";
import debounce from "lodash/debounce";

import type { Metadata } from "../types/filesystem";

type FileIconProps = {
  item: number | string,
  type: number,
  metadata?: Metadata,
  thumbOptions?: { code?: string, linkpassword?: String, crop?: Number },
  loadingItem?: number | string,
  loadingType?: number
};

export default class FileIcon extends React.Component<FileIconProps> {
  ref: ?HTMLElement;

  constructor(props: FileIconProps) {
    super(props);

    const { item, type, metadata, thumbOptions, isScrolling = false } = props;
    const { src, width, height } = getIconInfo(item, type, false);

    this.state = {
      iconSrc: src,
      iconWidth: width,
      iconHeight: height
    };

    if (metadata && metadata.thumb) {
      const crop = thumbOptions ? (thumbOptions.crop ?? 1) : 1;
      const thumb = HFN.iconTypes[type].thumb;
      const cacheid = HFN.cache.cacheid('thumb-' + crop + '-', thumb.size, metadata.hash, metadata.revisionid || 0);

      this.setupThumbHandler = (HFN.cache.has(cacheid) || !isScrolling) ? this.setupThumb : debounce(this.setupThumb, 400);
    } else {
      this.setupThumbHandler = false;
    }
  }

  componentDidMount() {
    const { metadata, type, thumbOptions, loadingItem,  loadingType} = this.props;

    if (metadata && metadata.thumb && !(metadata.isdeleted ?? false) && typeof this.setupThumbHandler === "function") {
      this.setupThumbHandler($(this.ref), metadata, type, loadingItem && loadingType ? { ...thumbOptions, srcOnError: this.state.iconSrc } : thumbOptions);
    }
  }

  componentWillUnmount() {
    if (this.setupThumbHandler && typeof this.setupThumbHandler.cancel === "function") {
      this.setupThumbHandler.cancel()
    };
  }

  setupThumb = (ref, metadata, type, thumbOptions) => {
    HFN.thumbManager.setupThumb(ref, metadata, type, thumbOptions);
  };

  onError = () => {
    $(this.ref).prop("src", this.state.iconSrc);
    $(this.ref).prop("width", this.state.iconWidth);
    $(this.ref).prop("height", this.state.iconHeight);
  };

  render() {
    const { item, type, loadingItem, loadingType, metadata } = this.props;

    const hasThumb = metadata && metadata.thumb && !(metadata.isdeleted ?? false);
    const loadLoadingIcon = hasThumb && loadingItem && loadingType;
    const { src, width, height } = !loadLoadingIcon ? getIconInfo(item, type, false) : getIconInfo(loadingItem, loadingType, false);
    return (
      <div className={`iconwrap${loadLoadingIcon ? " loading-spin-animation" : ""}${!hasThumb ? " no-thumb" : ""}`}>
        <img
          ref={r => (this.ref = r)}
          src={src}
          width={width}
          height={height}
          draggable="false"
          loading="lazy"
          onError={this.onError}
        />
      </div>
    );
  }
}
