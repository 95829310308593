import { createSlice } from "@reduxjs/toolkit";
import { setAutoFreeze } from 'immer';

setAutoFreeze(false);

const initialState = {
    loaded: false,
    bookmarksList: []
};

export const bookmarksSlice = createSlice({
    name: "bookmarks",
    initialState: initialState,
    reducers: {
        loadBookmarks: (state, action) => {
            state.bookmarksList = action.payload;
        },
        editBookmark: (state, action) => {
            state.bookmarksList = state.bookmarksList.map((item) =>
                item.id === action.payload.id ? action.payload : item
            );
        },
        deleteBookmark: (state, action) => {
            state.bookmarksList = state.bookmarksList.filter(
                ({ id }) => id !== action.payload
            );
        }
    }
});

export const {
    loadBookmarks,
    editBookmark,
    deleteBookmark
} = bookmarksSlice.actions;

export default bookmarksSlice.reducer;
