// flow
import { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch } from "react-redux";
import { __ } from "../../../lib/translate";
import { EXIT_FULLSCREEN_VIDEO_FROM_ACTIVE_SLIDESHOW } from "../../../components/HighlightOnboardingComponent/constants";
import { removeItem, addItem } from "../../../lib/state/reducers/onboardingSlice";

const useSlideshow = ({
  slideshowActive,
  setSlideshowActive,
  slideshowPaused,
  setSlideshowPaused,
  goToNextMedia,
  shownLastMedia,
  mediaContentWrapperEl,
  shownItemData,
  media,
  fullscreenVideoFromSlideshow,
  setFullscreenVideoFromSlideshow,
  showErrorMediaPreview = { show: false, options: { showDownloadButton: true } },
  resetZoom,
  onExitSlideshowFullscreen = null
}) => {
  const [slideshowNotification, setSlideshowNotification] = useState({ show: false, msg: null });
  const [shouldRepeat, setShouldRepeat] = useState(false);
  const [slideSeconds, setSlideSeconds] = useState(5000); // miliseconds
  const [onSlideshowTimeout, setOnSlideshowTimeout] = useState(null);
  const [keyboardSpaceClicked, setKeyboardSpaceClicked] = useState(null);
  const [keyboardArrowsClicked, setKeyboardArrowsClicked] = useState(null);
  const [showSlideshowControls, setShowSlideshowControls] = useState(false);
  const [screenLock, setScreenLock] = useState(null);

  const slideshowTimeout = useRef(null);
  const hideSlideshowControlsTimeout = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      onDeactivateSlideshow();
    }
  }, []);

  // Activate / Deactivate slideshow actions.
  useEffect(() => {
    if (slideshowActive) {
      onActivateSlideshow();
      resetZoom();
    } else {
      onDeactivateSlideshow();
    }
  }, [slideshowActive]);

  // Pause / Play actions or changed slide seconds or Arrows are cliked (restart to keep the timer).
  useEffect(() => {
    if (!slideshowActive || slideSeconds <= 0) {
      return;
    }

    if (shownItemData && shownItemData.metaData && shownItemData.metaData.category === HFN.CATEGORY.VIDEO && !showErrorMediaPreview.show) {
      // We don't want to change the slideshow state. It's already handeled in another hook.
      return;
    }

    if (slideshowPaused || (!media && !showErrorMediaPreview.show)) {
      // Pause slideshow
      clearSlideshowTimeout();
    } else {
      // Play slideshow
      setSlideshowTimeout();
    }
  }, [slideshowPaused, slideSeconds, keyboardArrowsClicked]);

  // Playing actions. setTimeout triggers this hook.
  useEffect(() => {
    if (onSlideshowTimeout === null || !slideshowActive || slideshowPaused || (shownItemData && shownItemData.metaData && shownItemData.metaData.category === HFN.CATEGORY.VIDEO && !showErrorMediaPreview.show)) {
      return;
    }

    playSlideshowActions();
  }, [onSlideshowTimeout]);

  // Handle Keyboard "Space" clicked -> toggle Play / Pause
  useEffect(() => {
    if (keyboardSpaceClicked === null || !slideshowActive) {
      return;
    }

    if (shownItemData && shownItemData.metaData && shownItemData.metaData.category === HFN.CATEGORY.VIDEO && !showErrorMediaPreview.show) {
      // Video player keyboard shortcuts will handle it.
      // We don't want to change the slideshow state.
      return;
    }

    setSlideshowNotification({show: true, msg: slideshowPaused ? __("Play", "Play") : __("Pause", "Pause")});
    setSlideshowPaused(prevState => !prevState);

    if (typeof gtag === "function") {
      gtag("event", "media_preview_click", {
        action: "slideshow",
        eventValue: slideshowPaused ? "play" : "paused",
        category: shownItemData && shownItemData.metaData && shownItemData.metaData.category === HFN.CATEGORY.VIDEO ? "video" : "image"
      });
    }
    
  }, [keyboardSpaceClicked]);

  useEffect(() => {
    if (!slideshowActive) {
      return;
    }

    if (media && media.category === HFN.CATEGORY.VIDEO && !showErrorMediaPreview.show) {
      // Prevent going to next item from setTimeout until the video is watched.
      clearSlideshowTimeout();
    } else if (!slideshowPaused && (media || showErrorMediaPreview.show)) {
      // Play slideshow
      setSlideshowTimeout();
    }
  }, [media, slideshowActive, showErrorMediaPreview]);

  useEffect(() => {
    if (!slideshowActive && screenLock) {
      releaseScreenLock();
    }
  }, [screenLock]);

  const isScreenLockSupported = () => "wakeLock" in navigator;

  const activateScreenLock = async () => {
    if (isScreenLockSupported()) {
      try {
        const lock = await navigator.wakeLock.request("screen");
        // Screen lock finished successfully.
        setScreenLock(lock);
      } catch (err) {
        setScreenLock(null);
        // console.log(err.name, err.message);
      }
    }
  };

  const releaseScreenLock = () => {
    if (screenLock) {
      screenLock.release().then(() => {
        setScreenLock(null);
      });
    }
  };

  const playSlideshowActions = () => {
    if (!shouldRepeat && shownLastMedia()) {
      // This will trigger the hook which watches for slideshowPaused changes and slideshow will pause.
      setSlideshowNotification({show: true, msg: __("End", "End")});
      // Deactivate slideshow.
      setSlideshowActive(false);
      return;
    }

    goToNextMedia();
  };

  const onActivateSlideshow = () => {
    addSlideshowListeners();

    if (!document.fullscreenElement) {
      enterFullscreenAndSetScreenLock();
    }

    // This will trigger the hook which watches for slideshowPaused changes and slideshow will run.
    setSlideshowPaused(false);

    // Hide onboarding popup near Slideshow toggle if shown.
    dispatch(removeItem(EXIT_FULLSCREEN_VIDEO_FROM_ACTIVE_SLIDESHOW));
  };

  const onDeactivateSlideshow = () => {
    setSlideshowPaused(true);
    removeSlideshowListeners();
    releaseScreenLock();

    exitFullscreen();
    clearSlideshowTimeout();
    clearHideSlideshowControlsTimeout();

    if (fullscreenVideoFromSlideshow) {
      // Show popup near Slideshow toggle.
      dispatch(addItem(EXIT_FULLSCREEN_VIDEO_FROM_ACTIVE_SLIDESHOW));
    } else {
      // Hide onboarding popup near Slideshow toggle if shown.
      dispatch(removeItem(EXIT_FULLSCREEN_VIDEO_FROM_ACTIVE_SLIDESHOW));
    }

    setFullscreenVideoFromSlideshow(false);
  };

  const triggerDeactivateSlideshow = () => {
    if (onExitSlideshowFullscreen !== null) {
      onExitSlideshowFullscreen();
    } else {
      // Deactivate slideshow.
      setSlideshowActive(false);
    }
  };

  const handleKeyDown = useCallback((event) => {
    switch (event.key) {
      case " " :
        event.preventDefault();
        setKeyboardSpaceClicked({ key: event.key });
        break;
      case "ArrowLeft": 
      case "ArrowRight": 
        setKeyboardArrowsClicked({ key: event.key });
        break;
      case "Escape":
        triggerDeactivateSlideshow();
        break;
      default: 
        return false;
    }
  }, []);

  const handleFullscreenChange = useCallback(async (event) => {
    if (!document.fullscreenElement) {
      // Escape key wasn't fired.
      triggerDeactivateSlideshow();
    }
  }, []);

  const handleMouseMoveOnImgContainer = useCallback(() => {
    clearHideSlideshowControlsTimeout();

    setShowSlideshowControls(true);

    hideSlideshowControlsTimeout.current = setTimeout(() => {
      setShowSlideshowControls(false);
    }, 2000);
  }, []);

  const enterFullscreenAndSetScreenLock = () => {
    const slideshowElement = document.querySelector(".gallery-inner-wrapper-modal");
    if (slideshowElement && !document.fullscreenElement) {
      slideshowElement.requestFullscreen({ navigationUI: "hide" });
      activateScreenLock();

      // if (slideshowElement.requestFullscreen) {
      //   slideshowElement.requestFullscreen({ navigationUI: "hide" });
      // } else if (slideshowElement.mozRequestFullScreen) {
      //   slideshowElement.mozRequestFullScreen({ navigationUI: "hide" });
      // } else if (slideshowElement.webkitRequestFullScreen) {
      //   slideshowElement.webkitRequestFullScreen();
      // } else if (slideshowElement.msRequestFullscreen) {
      //   slideshowElement.msRequestFullscreen();
      // }
    }
  };

  const exitFullscreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
    // if (document.exitFullscreen) {
    //   document.exitFullscreen();
    // } else if (document.mozCancelFullScreen) {
    //   document.mozCancelFullScreen();
    // } else if (document.webkitCancelFullScreen) {
    //   document.webkitCancelFullScreen();
    // } else if (document.msExitFullscreen) {
    //   document.msExitFullscreen();
    // }
  };

  const setSlideshowTimeout = () => {
    // Clear previous timeout if exists.
    clearSlideshowTimeout();

    slideshowTimeout.current = setTimeout(() => {
      // Pass new object to trigger the hook.
      // Otherwise we cannot read the last state variable values from here.
      setOnSlideshowTimeout({goToNextMedia: true});
    }, slideSeconds);
  }

  const clearSlideshowTimeout = () => {
    if (slideshowTimeout.current) {
      clearTimeout(slideshowTimeout.current);
      slideshowTimeout.current = null;
    }
  };

  const clearHideSlideshowControlsTimeout = () => {
    if (hideSlideshowControlsTimeout.current) {
      clearTimeout(hideSlideshowControlsTimeout.current);
      hideSlideshowControlsTimeout.current = null;
    }
  };

  const addSlideshowListeners = () => {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    if (mediaContentWrapperEl.current) {
      mediaContentWrapperEl.current.addEventListener("mousemove", handleMouseMoveOnImgContainer);
    }
  };

  const removeSlideshowListeners = () => {
    document.removeEventListener("keydown", handleKeyDown);
    document.removeEventListener("fullscreenchange", handleFullscreenChange);
    if (mediaContentWrapperEl.current) {
      mediaContentWrapperEl.current.removeEventListener("mousemove", handleMouseMoveOnImgContainer);
    }
  };

  const onEnterFullscreenRequestForVideo = () => {
    if (!slideshowActive) {
      return;
    }

    // Fix for Safari and similar cases where second layer fullscreen cannot be applied.
    if (!fullscreenVideoFromSlideshow) {
      setFullscreenVideoFromSlideshow(true);
    } else {
      // Exit fullscreen = deactivate slideshow.
      setSlideshowActive(false);
    }
  };

  const onVideoEnded = (videoDataForPlayer) => {
    if (!slideshowActive) {
      return;
    }

    if (videoDataForPlayer && shownItemData && shownItemData.metaData && videoDataForPlayer.id !== shownItemData.metaData.id) {
      return;
    }

    if (fullscreenVideoFromSlideshow) {
      // Deactivate slideshow
      setSlideshowActive(false);
    } else {
      // Go to next slideshow.
      if (!slideshowPaused) {
        playSlideshowActions();
      }
    }
  };

  return {
    slideshowActive,
    setSlideshowActive,
    slideshowPaused,
    slideSeconds,
    setSlideSeconds,
    shouldRepeat,
    setShouldRepeat,
    slideshowNotification,
    setSlideshowNotification,
    showSlideshowControls,
    onVideoEnded,
    onEnterFullscreenRequestForVideo
  };
};

export default useSlideshow;
