import React, { PureComponent } from "react";
import styled, { keyframes } from "styled-components";

import Modal, { OvalModalWrap, OvalModalClose, OvalModalBody } from "./Modal";
import { ButtonCentered } from "./ButtonDefault";
import { __ } from "../lib/translate";
import { hiDPI } from "polished";

type State = {
  isOpen: boolean
};

type Props = {};

export default class DownloadLinkModal extends PureComponent<Props, State> {
  state = {
    isOpen: false
  };

  componentDidMount() {
    this.open();
  }

  open = () => {
    this.setState({ isOpen: true });
    gaAll("event", "download-link-modal", "show");
  };

  close = () => {
    this.setState({ isOpen: false });
  };

  onClick = () => {
    gaAll("event", "download-link-modal", "click");
  }

  render() {
    const { isOpen } = this.state;

    const modalStyle = {
      padding: "15px",
      maxWidth: "656px"
    };

    return (
      <Modal align="top" onClose={this.close} opened={isOpen}>
        <OvalModalWrap style={modalStyle}>
          <OvalModalClose onClick={this.close} />
          <Title>{__("downloadlink_modal_headline", "Save all your files in one secure place")}</Title>
          <Description>{__("downloadlink_modal_description", "Access, share and work with your files, anywhere you go from every device. Start with up to 10 GB for free!")}</Description>
          <Image />
          <ButtonCentered
            href={HFN.siteUrl()}
            style={{ minWidth: "220px", marginBottom: "15px", width: 'auto', display: 'inline-block' }}
            onClick={this.onClick}
            workLikeLink={true}
          >
            {__("downloadlink_modal_button", "Join pCloud")}
          </ButtonCentered>
        </OvalModalWrap>
      </Modal>
    );
  }
}

const Title = styled.h1`
  display: inline-block;
  max-width: 535px;
  margin: 15px auto 5px auto;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: #212121;
  font-family: "Roboto", sans-serif;
  line-height: 1.21;
  letter-spacing: -0.6px;
`;

const Description = styled.p`
  display: inline-block;
  max-width: 535px;
  margin: 0 auto;
  font-size: 17px;
  line-height: 1.18;
  letter-spacing: -0.26px;
  text-align: center;
  font-family: "Roboto", sans-serif;
  color: #999999;
`;

const Image = styled.div`
  width: 100%;
  max-width: 460px;
  height: 228px;
  margin: 26px auto 30px auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${require("../../root/img/dlink/img.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img/dlink/img@2x.png")});
  }
`;
