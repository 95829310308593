import React, { useMemo, useEffect, useRef } from 'react';
import { ControlledMenu, MenuItem, SubMenu, MenuDivider } from '@szhsin/react-menu';
import { useMenu } from '../context/MenuContextProvider';
import '@szhsin/react-menu/dist/index.css';
import { MenuIcon, MenuLabel, ReusableMenuBackdrop } from "./Style";
import debounce from "lodash/debounce";

const ReusableMenu = ({ listGridContainerRef, ignoreHideOnResize = useRef(false) }) => {
	const { menuItems, anchorPoint, anchorRef, isOpen, hideMenu } = useMenu();
	const ignoreResize = useRef(false);

	const renderMenuItems = useMemo(() => {
		return (items) => {
			return items.map((item, index) => {
				if (item.divider) {
					return (
						<MenuDivider key={index} />
					);
				} else if (item.submenu) {
					const SubMenuComponent = (
						<React.Fragment>
							{item.iconLight &&
								<MenuIcon>
									<i className={"smallIcon lightColorIcon " + item.iconLight} />
								</MenuIcon>
							}
							<MenuLabel>
								{__(...item.label)}
							</MenuLabel>
						</React.Fragment>
					);
					return (
						<SubMenu key={index} label={SubMenuComponent}>
							{renderMenuItems(item.submenu)}
						</SubMenu>
					);
				} else {
					return (
						<MenuItem key={index} onClick={() => item.onClick(item.params || {}, null, "context menu")}>
							{item.iconLight && (
								<MenuIcon>
									<i className={"smallIcon lightColorIcon " + item.iconLight} />
								</MenuIcon>
							)}
							<MenuLabel>{__(...item.label)}</MenuLabel>
						</MenuItem>
					);
				}
			});
		}
	}, [menuItems]);

	useEffect(() => {
		if (!listGridContainerRef?.current) return;

		const element = listGridContainerRef.current;

		if (!(element instanceof Element)) {
			return;
		}

		const resizeObserver = new ResizeObserver(debounce(() => {
			if (isOpen && !ignoreResize.current && !ignoreHideOnResize.current) {
				hideMenu();
			}
		}, 50));

		resizeObserver.observe(element);

		if (isOpen) {
			ignoreResize.current = true;
			setTimeout(() => {
				ignoreResize.current = false;
			}, 100);
		}

		return () => {
			resizeObserver.disconnect();
		};
	}, [listGridContainerRef, isOpen, hideMenu]);

	return (
		<>
			{isOpen && <ReusableMenuBackdrop />}
			<ControlledMenu
				anchorPoint={anchorPoint}
				anchorRef={anchorRef}
				portal={true}
				viewScroll="close"
				state={isOpen ? "open" : "closed"}
				onClose={hideMenu}
				position="auto"
				boundingBoxPadding="16"
			>
				{renderMenuItems(menuItems)}
			</ControlledMenu>
		</>
	);

};

export default ReusableMenu;
