// flow
import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { apiMethod } from "../../../api";
import { fileext } from "../../../lib/utils";
import { fetchMediaSuccess, deleteMediaItem } from "../../../lib/state/reducers/galleryPreviewSlice";
import { getProperThumbnailSize, getVideoUrlsAndMaxVideoSize } from "../utils";

const useGalleryData = ({
  rawContentData,
  initialMediaId,
  revisionId,
  opts = {}, 
  onClosePreview, 
  resetZoom, 
  fullscreenVideoFromSlideshow, 
  disableArrowKeys,
  slideshowActive, 
  slideshowPaused,
  vidstackMediaPlayer
}) => {
  const dispatch = useDispatch();
  const token = useSelector(({ pCloudUser }) => pCloudUser.token);

  let contentData = useSelector(
    ({ content }) => ({ items: content.itemData.items, itemKeys: content.itemKeys }),
    (oldValue, newValue) => oldValue.items === newValue.items && oldValue.itemKeys === newValue.itemKeys
  ); // {id: {metadata}}

  if (!contentData.itemKeys.length) {
    contentData = rawContentData;
  }

  const [onMediaFetched, setOnMediaFetched] = useState(null);
  const [onMediaFetchedError, setOnMediaFetchedError] = useState(null);
  const [onShownItemAction, setOnShownItemAction] = useState(null);

  const initialItemKeys = useRef([...contentData.itemKeys]); // [0: 'f166656565', 1: "d02212121"] sorted item keys
  const initialContentData = useRef({ ...contentData.items });

  const cachedMediaData = useSelector(
    ({ galleryPreview }) => galleryPreview.mediaItems, 
    (oldValue, newValue) => oldValue === newValue
  );

  const [contentDataGalleryIds, setContentDataGalleryIds] = useState([]); // 0: { id: 'f166656565', missing: true || false }

  const [media, setMedia] = useState(null);
  const [loading, setLoading] = useState(true);
  const [videoLoading, setVideoLoading] = useState(false);

  const [keyboardArrowClicked, setKeyboardArrowClicked] = useState(null);
  const [onScreenOrientationChange, setOnScreenOrientationChange] = useState(null);

  const [shownItemData, setShownItemData] = useState({ place: -1, metaData: null, autoPlay: false, seek: 0 });
  const [showErrorMediaPreview, setShowErrorMediaPreview] = useState({ show: false, options: {showDownloadButton: true} });

  const isDlink = HFN.config.isDlink();

  const getNext = n => (n === contentDataGalleryIds.length - 1 ? 0 : n + 1);

  const getPrevious = n => (n === 0 ? contentDataGalleryIds.length - 1 : n - 1);

  const fetchedMediaRetries = useRef({});

  const handleKeyUp = useCallback(event => {
    if (event.key !== "ArrowLeft" && event.key !== "ArrowRight") {
      return false;
    }

    // Is video shown and playing?
    if (vidstackMediaPlayer && vidstackMediaPlayer.current && (vidstackMediaPlayer.current?.state.seeking || vidstackMediaPlayer.current?.state.playing || vidstackMediaPlayer.current?.state.waiting)) {
      return false;
    }

    const isAnotherModalOpen = document.documentElement.classList.contains("g-modal-open");
    const isClickInsideInputZoom = event.target.classList.contains("zoom-input");

    if (isAnotherModalOpen || isClickInsideInputZoom) {
      return false;
    }

    setKeyboardArrowClicked({ key: event.key });
  }, []);

  const onShownMediaError = ({tryToRefresh = true, autoPlay = true, mediaType = "image", lastPlayingTime = 0}) => {
    if (!tryToRefresh || shownItemData.metaData === null || (fetchedMediaRetries.current[shownItemData.metaData.id] && fetchedMediaRetries.current[shownItemData.metaData.id][mediaType] > 0)) {
      if (mediaType !== "videoThumb") {
        setLoading(false);
        setVideoLoading(false);
        // Clear the previously shown media if exists. 
        // Otherwise it'll be shown for a tiny moment while changing to another media on going next/prev.
        setMedia(null);
        setShowErrorMediaPreview({ show: true, options: {showDownloadButton: true} });
      }
      
      return;
    }

    // Try to refresh it once. Probably the links are expired.
    forceRefreshShownItem(mediaType, autoPlay, lastPlayingTime);
  };

  const forceRefreshShownItem = (mediaType, autoPlay = false, seek = 0) => {
    if (shownItemData.metaData === null) {
      return;
    }

    setLoading(true);

    // Handle the broken item: remove it from cache to refetch new URL-s from the API.
    dispatch(deleteMediaItem(shownItemData.metaData.id));

    // Increase retry counter.
    if (!fetchedMediaRetries.current[shownItemData.metaData.id]) {
      fetchedMediaRetries.current[shownItemData.metaData.id] = {};
    }

    fetchedMediaRetries.current[shownItemData.metaData.id][mediaType] = (fetchedMediaRetries.current[shownItemData.metaData.id][mediaType] || 0) + 1;

    // Refresh the item.
    showItem(shownItemData.place, autoPlay, seek);
  }

  const deleteById = (obj, id) => {
    for (const key in obj) {
      if (obj[key].id === id) {
        delete obj[key];
        break; // Exit the loop after deleting the property
      }
    }
  };

  const handleActionOnItemListener = e => {
    if (e && e.detail) {
      setOnShownItemAction(e.detail);
    }
  };

  useEffect(() => {
    if (!onShownItemAction) {
      return;
    }
      
    const { action, data } = onShownItemAction;
    const { id, name } = data;

    if (action === "rename") {
      initialContentData.current[id].name = name;
    } else if (action === "delete" || action === "move") {
      if (Object.keys(initialContentData.current[id]).length) {
        deleteById(initialContentData.current, id);
      }
      const index = initialItemKeys.current.findIndex(element => element === id);
      if (index > -1) {
        initialItemKeys.current.splice(index, 1);
      }

      const updatedArray = contentDataGalleryIds.filter(item => item.id !== id);
      setContentDataGalleryIds(updatedArray);
    }
  }, [onShownItemAction]);

  const onShownMediaSuccess = (mediaType = "image") => {
    if (fetchedMediaRetries.current[shownItemData.metaData.id] && fetchedMediaRetries.current[shownItemData.metaData.id][mediaType]) {
      delete fetchedMediaRetries.current[shownItemData.metaData.id][mediaType];
    }
  }

  const cleanupPreviousFetchMediaRetriesData = () => {
    if (shownItemData.metaData === null) {
      return;
    }

    if (fetchedMediaRetries.current[shownItemData.metaData.id]) {
      const fetchedMediaRetriesCopy = fetchedMediaRetries.current[shownItemData.metaData.id];
      fetchedMediaRetries.current = {};
      fetchedMediaRetries.current[shownItemData.metaData.id] = fetchedMediaRetriesCopy;
    } else {
      fetchedMediaRetries.current = {};
    }
  }

  const handleScreenOrientationChange = (event) => {
    // const type = event.target.type;
    // const angle = event.target.angle;
    setOnScreenOrientationChange(event);
  }

  useEffect(() => {
    if (!onScreenOrientationChange) {
      return;
    }

    if (typeof gtag === "function") {
      gtag("event", "media_preview_view", {
        action: "orientationchange",
        category: shownItemData && shownItemData.metaData && shownItemData.metaData.category === HFN.CATEGORY.VIDEO ? "video" : "image"
      });
    }
  }, [onScreenOrientationChange]);

  useEffect(() => {
    document.addEventListener("keyup", handleKeyUp);
    document.addEventListener("more-options-click", handleActionOnItemListener);
    window.addEventListener('popstate', onClosePreview);
    
    if (typeof screen !== "undefined" && typeof screen.orientation !== "undefined") {
      screen.orientation.addEventListener("change", handleScreenOrientationChange);
    }

    return () => {
      document.removeEventListener("keyup", handleKeyUp);
      document.removeEventListener("more-options-click", handleActionOnItemListener);
      window.removeEventListener('popstate', onClosePreview);
      
      if (typeof screen !== "undefined" && typeof screen.orientation !== "undefined") {
        screen.orientation.removeEventListener("change", handleScreenOrientationChange);
      }
    };
  }, []);

  useEffect(() => {
    if (contentData.itemKeys.length === 0) {
      // Missing content data for preview.
      return;
    }

    if (initialItemKeys.current.length) {
      const galleryIds = initialItemKeys.current.reduce((acc, fileId) => {
        const item = contentData.items[fileId];

        if (item) {
          if (item.category && ((item.category === HFN.CATEGORY.IMAGE && item.thumb) || item.category === HFN.CATEGORY.VIDEO)) {
            acc.push({ id: fileId, missing: false });

            // Possible rename of the item from another tab/browser should be updataed.
            if (initialContentData?.current[fileId]) {
              initialContentData.current[fileId].name = item.name;
            }
          }
        } else {
          // deleted or moved item
          acc.push({ id: fileId, missing: true });
        }

        return acc;
      }, []);

      if (Object.keys(galleryIds).length === 0) {
        // Missing content data for preview.
        return;
      }

      setContentDataGalleryIds(galleryIds); // id
    }
  }, [contentData]);

  useEffect(() => {
    if (contentDataGalleryIds.length === 0) {
      return;
    }

    // Initially load clicked item.
    if (shownItemData.metaData === null) {
      if (initialMediaId) {
        const currentIndex = contentDataGalleryIds.findIndex(({ id }) => id === initialMediaId);

        if (currentIndex !== -1) {

          gtag("event", "media_preview_view", {
            action: "open",
            category: initialContentData?.current[initialMediaId] && initialContentData.current[initialMediaId].category === HFN.CATEGORY.VIDEO ? "video" : "image"
          });
          
          showItem(currentIndex, true);
        }
      } else {
        // Missing required input param.
        onClosePreview();
      }
    } else if (!initialContentData?.current[shownItemData.metaData.id]) {
      // Deleted or moved item.
      // Show next item which now equals to the old index.
      if (shownItemData.place <= contentDataGalleryIds.length - 1) {
        // Show next item.
        showItem(shownItemData.place);
      } else {
        // Show first item.
        showItem(0);
      }
    }
  }, [contentDataGalleryIds]);

  useEffect(() => {
    if (shownItemData.metaData === null) {
      return;
    }

    if (showErrorMediaPreview.show) {
      setShowErrorMediaPreview({ show: false, options: {showDownloadButton: true} });
    }

    cleanupPreviousFetchMediaRetriesData();
    
    fetchMedia(shownItemData.metaData, 
      fetchedMedia => {
        setOnMediaFetched(fetchedMedia);
      }, 
      (id, error) => {
        setOnMediaFetchedError({id, error});
      }
    );
  }, [shownItemData]);

  useEffect(() => {
    if (!onMediaFetched) {
      return;
    }

    const fetchedMedia = onMediaFetched;

    if (shownItemData.metaData.id === fetchedMedia.id) {
      setMedia(fetchedMedia);
      setLoading(false);

      preloadItemsBeforeAndAfter(shownItemData.place);

      if (shownItemData.metaData.category === HFN.CATEGORY.IMAGE) {
        // Reset previous zoom if !== 100%
        resetZoom();
      }
    }
  }, [onMediaFetched]);

  useEffect(() => {
    if (!onMediaFetchedError) {
      return;
    }

    if (shownItemData.metaData.id === onMediaFetchedError.id) {
      setLoading(false);
      setVideoLoading(false);
      // Clear the previously shown media if exists. 
      // Otherwise it'll be shown for a tiny moment while changing to another media on going next/prev.
      setMedia(null);

      let showDownloadButton = true;

      if (onMediaFetchedError.error && onMediaFetchedError.error.result) {
        switch (onMediaFetchedError.error.result) {
          case 2002: // "A component of parent directory does not exist."
          case 2003: // "Access denied. You do not have permissions to perform this operation."
          case 2009: // "File not found."
          case 2075: // "You are not a member of a business account."
          // TODO: we can implement "Try again" button.
          case 5002: // "Internal error, no servers available. Try again later."
          case 4008: // "This link is temporarily unavailable. Try again later."
          case 7002: // "This link is deleted by the owner."
          case 7003: // "This file is no longer available due to a copyright claim."
          case 7004: // "This link has expired."
          case 7005: // "This link has reached its traffic limit."
          case 7006: // "This link has reached maximum downloads."
          case 7010: // "Invalid link referer."
            showDownloadButton = false;
            break;
        }
      }

      setShowErrorMediaPreview({ show: true, options: {showDownloadButton} });
      
      // Preload before and after items.
      preloadItemsBeforeAndAfter(shownItemData.place);
    }
  }, [onMediaFetchedError]);

  useEffect(() => {
    if (keyboardArrowClicked === null || fullscreenVideoFromSlideshow || disableArrowKeys || contentDataGalleryIds.length <= 1) {
      return;
    }

    if (keyboardArrowClicked.key === "ArrowLeft" || keyboardArrowClicked.key === "ArrowRight") {
      if (typeof gtag === "function") {
        gtag("event", "media_preview_click", {
          action: keyboardArrowClicked.key === "ArrowLeft" ? "previous" : "next",
          category: shownItemData && shownItemData.metaData && shownItemData.metaData.category === HFN.CATEGORY.VIDEO ? "video" : "image"
        });
      }

      if (keyboardArrowClicked.key === "ArrowLeft") {
        showItem(getPrevious(shownItemData.place));
      } else {
        showItem(getNext(shownItemData.place));
      }
    }
  }, [keyboardArrowClicked]);

  const showItem = (n, autoPlay = false, seek = 0) => {
    if (!(n in contentDataGalleryIds)) {
      return;
    }

    // find metadata by id
    const meta = initialContentData?.current[contentDataGalleryIds[n].id];

    if (!meta) {
      return;
    }

    // Check if it's already fetched.
    if (!fetchMediaFromCache(meta.id)) {
      setLoading(true);
    }

    // For autoPlay we should wait until videoStarted event is triggered before hiding the loading.
    setVideoLoading((autoPlay || (slideshowActive && !slideshowPaused)) && meta.category === HFN.CATEGORY.VIDEO);

    // This will trigger the usEffect hook which will load the new item.
    setShownItemData({ place: n, metaData: meta, autoPlay, seek });
  };

  const mediaExistsInCache = id => (cachedMediaData && cachedMediaData[id] ? cachedMediaData[id] : false);

  const fetchMediaFromCache = (id) => {
    const mediaDataCache = mediaExistsInCache(id);
    if (mediaDataCache && !(mediaDataCache.forceFetch && (mediaDataCache.forceFetch.image || mediaDataCache.forceFetch.video))) {
      return mediaDataCache;
    }

    return false;
  }

  const fetchImg = (meta, onSuccess = () => {}, onError = () => {}, {forceFresh = false, }) => {
    const scrWidth = window.screen.availWidth;
    const scrHeight = window.screen.availHeight;

    let availableHeight = scrHeight;
    let availableWidth = scrWidth;
    availableHeight = availableHeight && Math.min(availableHeight - (availableHeight % 4), 2048);
    availableWidth = availableWidth && Math.min(availableWidth - (availableWidth % 4), 2048);

    const size =
      meta.width && meta.height
        ? HFN.calcImageSize(meta.width, meta.height, scrWidth, scrHeight, 620, 360)
        : [availableWidth, availableHeight];

    const imgData = {
      id: meta.id,
      category: meta.category
      // url -> will be set later.
    };

    const getThumb = () => {
      const params = { fileid: meta.fileid, size: `${size[0]}x${size[1]}`, hashCache: meta.hash, crop: 0 };
      const method = opts.code ? "getpubthumblink" : "getthumblink";

      // Prevent showing notification messages from ERROR_MESSAGE_KEYS (web-utilities/src/api/errors.js),
      // because we have an error view.
      const apiMethodOpts = { showErrorMessage: false };

      if (forceFresh) {
        apiMethodOpts.forceFresh = true;
      }

      if (opts.code) {
        params.code = opts.code;
        if (opts.linkpassword) {
          params.linkpassword = opts.linkpassword;
        }

        if (token) {
          params.auth = token;
        }
      }

      if (meta.revisionid) {
        params.revisionid = meta.revisionid;
      }

      if (fileext(meta.name) === "png") {
        params.type = "png";
      }

      apiMethod(
        method,
        params,
        ret => {
          const url = HFN.prepUrl(ret);
          const img = new Image();
          img.src = url;
          img.onload = () => {
            imgData.url = url;
            // Cache image data.
            dispatch(fetchMediaSuccess(imgData));
            onSuccess(imgData);
          };

          img.onerror = (err) => onError(imgData.id, err);
        },
        {
          ...apiMethodOpts,
          errorCallback(res) {
            console.log("ERROR 3001");
            // if (res.result === 3001) {
            //  // Set fake Image object without src and trigger the success callback to setup some actions like Download.
            // }

            onError(imgData.id, res);
          },
          onXhrError: (xhr, status, error) => {
            onError(imgData.id, { reasoncode: "xhr_error", xhrError: { error: xhr, status, error } });
          }
        }
      );
    };

    const candownload = typeof opts.candownload !== "undefined" ? opts.candownload : true;
    const getFileLink = () => {
      // Prevent showing notification messages from ERROR_MESSAGE_KEYS (web-utilities/src/api/errors.js),
      // because we have an error view.
      const apiMethodOpts = { showErrorMessage: false };

      if (forceFresh) {
        apiMethodOpts.forceFresh = true;
      }

      HFN.getFileLinkBack(
        meta,
        ret => {
          const img = new Image();
          img.src = ret;
          img.onload = () => {
            imgData.url = ret;
            // Cache image data.
            dispatch(fetchMediaSuccess(imgData));
            onSuccess(imgData);
          };

          img.onerror = (err) => onError(imgData.id, err);
        },
        {
          ...apiMethodOpts,
          code: opts.code || false,
          candownload,
          errorCallback(res) {
            onError(imgData.id, res);
          },
          onXhrError: (xhr, status, error) => {
            onError(imgData.id, { reasoncode: "xhr_error", xhrError: { error: xhr, status, error } });
          }
        }
      );
    };

    const cantShowInBrowser = ["tif", "nex"];
    const hasSize = size[0] && size[1];
    const dlinkImgNoMetaSize = isDlink && meta.thumb;

    if (
      (meta.thumb && hasSize) ||
      dlinkImgNoMetaSize ||
      cantShowInBrowser.indexOf(fileext(meta.name)) !== -1 ||
      (meta.thumb && meta.size > 1048576 * 2)
    ) {
      console.log("GET ------- THUMB");
      getThumb();
    } else {
      console.log("GET ------- FILE LINK");
      getFileLink();
    }
  };

  const fetchVideo = (meta, onSuccess = () => {}, onError = () => {}, {videoForceFresh = false, thumbForceFresh = false}) => {
    const videoParams = { fileid: meta.fileid, contenttype: "video/mp4" };
    let videoMethod = "getvideolinks";
    
    const thumbParams = { fileid: meta.fileid };
    let thumbMethod = "getthumblink";

    // Prevent showing notification messages from ERROR_MESSAGE_KEYS (web-utilities/src/api/errors.js),
    // because we have an error view.
    const videoApiMethodOpts = { showErrorMessage: false };
    const thumbApiMethodOpts = { showErrorMessage: false };

    if (videoForceFresh) {
      videoApiMethodOpts.forceFresh = true;
    }

    if (thumbForceFresh) {
      thumbApiMethodOpts.forceFresh = true;
    }

    if (opts.code && location.host != "pcloud") {
      videoMethod = "getpubvideolinks";
      thumbMethod = "getpubthumblink";
      videoParams.code = opts.code;
      thumbParams.code = opts.code;

      if (opts.linkpassword) {
        videoParams.linkpassword = opts.linkpassword;
        thumbParams.linkpassword = opts.linkpassword;
      }

      if (HFN.config.auth) {
        videoParams.auth = HFN.config.auth;
        thumbParams.auth = HFN.config.auth;
      }
    }

    if (meta.revisionid) {
      videoParams.revisionid = meta.revisionid;
      thumbParams.revisionid = meta.revisionid;
    }

    const videoData = {
      id: meta.id,
      name: meta.name,
      category: meta.category,
      videoUrls: [],
      thumbUrl: "",
      thumbType: "image/jpeg",
      maxWidth: 0,
      maxHeight: 0
    };

    // Fetch videoUrl data.
    apiMethod(
      videoMethod,
      videoParams,
      res => {
        // If we'are on download link -> disallow "Original" variant.
        const {videoUrls, videoMaxWidth, videoMaxHeight} = getVideoUrlsAndMaxVideoSize(res.variants, !isDlink);
        
        if (videoUrls.length === 0) {
          onError(videoData.id, { reasoncode: "missing_video_urls" });
          return;
        }

        videoData.videoUrls = videoUrls;
        videoData.maxWidth = videoMaxWidth;
        videoData.maxHeight = videoMaxHeight;

        // Set thumb size 1:1 with the video size.
        const thumbWidth = getProperThumbnailSize(Math.min(videoData.maxWidth, Math.max(window.screen.availWidth, window.screen.availHeight)));
	      const thumbHeight = getProperThumbnailSize(Math.min(videoData.maxHeight, Math.max(window.screen.availWidth, window.screen.availHeight)));
        thumbParams.size = `${thumbWidth}x${thumbHeight}`;

        const onVideoThumbError = () => {
          // Even without thumb video should be shown to the user.
          videoData.thumbUrl = "";
          videoData.forceFetch = {image: true};

          // Cache video data.
          dispatch(fetchMediaSuccess(videoData));

          // Output videoData
          onSuccess(videoData);
        };

        // Fetch thumbUrl data.
        apiMethod(
          thumbMethod,
          thumbParams,
          res => {

            // if (!mediaExistsInCache(meta.id)) {
            // Break the thumb....
            // res.path = "/xyz";
            //}

            // Set videoUrls.
            videoData.thumbUrl = HFN.prepUrl(res);
            videoData.thumbSize = thumbParams.size;

            // Force browser to download and cache the thumb.
            const img = new Image();
            img.src = videoData.thumbUrl;
            img.onload = () => {
              videoData.thumbSize = img.width > 0 && img.height > 0 ? `${img.width}x${img.height}` : videoData.thumbSize;
              
              // Break the thumb....
              // videoData.thumbUrl = videoData.thumbUrl.substring(0, 30);

              // Cache video data.
              dispatch(fetchMediaSuccess(videoData));

              // Output videoData
              onSuccess(videoData);
            };

            img.onerror = onVideoThumbError;
          },
          {
            ...thumbApiMethodOpts,
            errorCallback(res) {
              onVideoThumbError();
            },
            onXhrError: (xhr, status, error) => {
              onVideoThumbError();
            }
          }
        );
      },
      {
        ...videoApiMethodOpts,
        errorCallback(res) {
          onError(videoData.id, res);
        },
        onXhrError: (xhr, status, error) => {
          onError(videoData.id, { reasoncode: "xhr_error", xhrError: { error: xhr, status, error } });
        }
      }
    );
  };

  const preloadItemsBeforeAndAfter = n => {
    if (!(n in contentDataGalleryIds)) {
      // N is not part of contentDataGalleryIds.
      return;
    }

    const idsToPreload = {};

    // Preload numItemsAfterToPreload after open img
    const numItemsAfterToPreload = 2;
    const startAfterIndex = n + 1;
    for (let i = startAfterIndex; i < startAfterIndex + numItemsAfterToPreload; i++) {
      if (i > contentDataGalleryIds.length - 1) {
        // After last -> get from beginning.
        const newIndex = i - (contentDataGalleryIds.length - 1) - 1;
        if (newIndex < n) {
          if (newIndex in contentDataGalleryIds) {
            idsToPreload[contentDataGalleryIds[newIndex].id] = newIndex;
          }
        } else {
          // We have reached the initial n item.
          break;
        }
      } else if (i in contentDataGalleryIds) {
        idsToPreload[contentDataGalleryIds[i].id] = i;
      }
    }

    // Preload numItemsAfterToPreload after open img
    const numItemsBeforeToPreload = 2;
    const startBeforeIndex = n - 1;
    for (let i = startBeforeIndex; i > startBeforeIndex - numItemsBeforeToPreload; i--) {
      if (i < 0) {
        // Before first -> get from last.
        const newIndex = contentDataGalleryIds.length + i;
        if (newIndex > n) {
          if (newIndex in contentDataGalleryIds) {
            idsToPreload[contentDataGalleryIds[newIndex].id] = newIndex;
          }
        } else {
          // We have reached the initial n item.
          break;
        }
      } else if (i in contentDataGalleryIds) {
        idsToPreload[contentDataGalleryIds[i].id] = i;
      }
    }

    for (const fileId in idsToPreload) {
      const meta = initialContentData?.current[fileId];
      if (meta) {
        fetchMedia(meta, () => {}, () => {});
      }
    }
  };

  const fetchMedia = (meta, onFetched = () => {}, onError = () => {}) => {
    // Check if it's already fetched.
    const mediaDataCache = fetchMediaFromCache(meta.id);
    if (mediaDataCache) {
      onFetched({...mediaDataCache});
      return;
    }

    const forceFetch = {image: false, video: false};
    
    switch (meta.category) {
      case HFN.CATEGORY.IMAGE:
        forceFetch.image = forceFetch.image || (fetchedMediaRetries.current[shownItemData.metaData.id] && fetchedMediaRetries.current[shownItemData.metaData.id]["image"] > 0);
        fetchImg(meta, onFetched, onError, {forceFresh: forceFetch.image});
        break;
      case HFN.CATEGORY.VIDEO:
        forceFetch.video = forceFetch.video || (fetchedMediaRetries.current[shownItemData.metaData.id] && (fetchedMediaRetries.current[shownItemData.metaData.id]["video"] > 0 || fetchedMediaRetries.current[shownItemData.metaData.id]["videoThumb"] > 0));
        forceFetch.image = forceFetch.image || forceFetch.video;
        fetchVideo(meta, onFetched, onError, {videoForceFresh: forceFetch.video, thumbForceFresh: forceFetch.image});
        break;
    }
  };

  return {
    media,
    loading,
    contentDataGalleryIds,
    shownItemData,
    showItem,
    getNext,
    getPrevious,
    videoLoading,
    setVideoLoading,
    onShownMediaError,
    onShownMediaSuccess,
    showErrorMediaPreview,
  };
};

export default useGalleryData;
