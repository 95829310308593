// @flow

import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";

import Modal, { OvalModalWrap, CloseModalIcon } from "../Modal";
import Button from "../ButtonDefault";
import * as Style from "./styledComponents";

import FileIcon from "../FileIcon";

import { __ } from "../../lib/translate";
import { errorKeys } from "../../lib/errors";
import apiMethod from "../../api/apiMethod";
import { ICO, ICONS } from "../../lib/icons";
import hashManager from "../../lib/hashManager";
import { useSelector } from "react-redux";

type Props = {
  data: object,
  revisionName: string
};

const RestoreRevisionAsNewFileModal = ({ data, revisionName }: Props) => {
  const [isOpened, setIsOpened] = useState(true);
  const [loading, setLoading] = useState(false);
  const [restoreRevisionTo, setRestoreRevisionTo] = useState("");
  const breadcrumbData = useSelector(({ content }) => content.breadcrumb);

  const { fileid, name, revisionid, parentfolderid, icon } = data;

  useEffect(() => {
    const parent = breadcrumbData["d" + parentfolderid];
    const parentName = parent ? HFN.metaName(parent) : "";

    setRestoreRevisionTo(parentName);
  }, [parentfolderid]); //[parentfolderid]

  useEffect(() => {
    if (isOpened) {
      console.log("ADD event on keyup RevertRevisionModal");
      document.addEventListener("keyup", onEnter);
    } else {
      console.log("REMOVE event on keyup RevertRevisionModal");
      document.removeEventListener("keyup", onEnter);
    }
  }, [isOpened, onEnter]); //[isOpened, onEnter]

  const onEnter = useCallback(
    (e: any) => {
      if (e.keyCode === 13) {
        onRestoreRevisionClick();
      }
    },
    [onRestoreRevisionClick]
  );

  const onCloseClick = () => {
    if (loading) {
      return;
    }
    setIsOpened(false);
  };

  const onRestoreRevisionClick = useCallback(() => {
    if (loading) {
      return;
    }
    setLoading(true);

    apiMethod(
      "copyfile",
      {
        fileid: fileid,
        revisionid: revisionid,
        tofolderid: parentfolderid,
        toname: revisionName
      },
      ret => {
        HFN.cache.expire("api-listrevisions-fileid:" + fileid);
        if (hashManager.getState("page") == "revisions") hashManager.pushState({ refresh: uniqueNum.get() });

        HFN.message(
            __('revision_restored_as', 'File <u>%file%</u> restored as <u>%revfile%</u>', { file: name, revfile: revisionName }),
            null,
            null,
            null,
            null,
            null,
            null,
            {
              linkConverter: false,
              linkConverterV2: false
            }
        );
        setIsOpened(false);
      },
      {
        errorCallback: ({ result, error }) => {
          if (errorKeys[result]) {
            HFN.message(__(errorKeys[result]), "error");
          } else {
            console.log("ERROR", { result, error });
            HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
          }
          setLoading(false);
        },
        onXhrError: (xhr, status, error) => {
          HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
          setLoading(false);
        }
      }
    );
  });

  const renderContent = () => {
    return (
      <React.Fragment>
        <Body>
          <Header>{__("Restore Revision", "Restore revision")}</Header>
          <Container>
            <Style.Label>{__("save_files", "Save file")}</Style.Label>
            <BoxRevision>
              <Style.Row key="0">
                <FileIcon item={icon} type={ICONS.SVG_LIST} metadata={data} />
                <Style.NameEllipsis>{name}</Style.NameEllipsis>
              </Style.Row>
            </BoxRevision>
            <Style.NameEllipsis>{data.renderItems}</Style.NameEllipsis>
            <Style.Label>{__("save_file_to", "Save file to")}</Style.Label>
            <BoxRevision>
              <Style.Row key="1">
                <FileIcon item={ICO.FOLDER} type={ICONS.SVG_LIST} />
                <Style.NameEllipsis>{restoreRevisionTo}</Style.NameEllipsis>
              </Style.Row>
            </BoxRevision>
          </Container>
        </Body>
        <Style.Footer>
          <Button style={{ marginRight: "5px" }} color="lightgray4" onClick={onCloseClick}>
            {__("Cancel")}
          </Button>
          <Button
            style={{ marginLeft: "5px" }}
            color="cyan"
            disabled={loading}
            loading={loading}
            onClick={onRestoreRevisionClick}
          >
            {__("Restore Revision")}
          </Button>
        </Style.Footer>
      </React.Fragment>
    );
  };

  return (
    <Modal align="top" opened={isOpened} onClose={onCloseClick}>
      <OvalModalWrap className="responsive-modal">
        <CloseModalIcon onClick={onCloseClick} />
        <Content>{renderContent()}</Content>
      </OvalModalWrap>
    </Modal>
  );
};

export default RestoreRevisionAsNewFileModal;

const Content = styled.div`
  padding: 30px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  min-height: 220px;
`;

const Container = styled.div`
    width: 480px;
    border-radius: 21px;
    background-color: #fff;
    font-size: 18px;
    font-weight: bold;
    padding: 30px 0 10px;
    box-sizing: border-box;
    transition: all 250ms ease-in-out;
    
    @media (max-width: 800px) {
        width: 100%;
    }
`;

const Body = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const BoxRevision = styled(Style.Box)`
  line-height: 24px;
  padding: 10px;
  font-size: 15px;
  font-weight: normal;
  text-align: left;
  padding: 10px;
  justify-content: flex-start;
  flex-direction: column;
  max-height: 115px;
  overflow: auto;
  overflow-x: hidden;

  & > div:only-child {
    padding-bottom: 0px;
  }
`;

const Header = styled(Style.Header)`
  margin-bottom: 0;
`;

