import React, { useState, useRef, useEffect, useMemo } from "react";
import debounce from "lodash/debounce";
import styled from 'styled-components';
import { useSelector } from "react-redux";
import { MenuProvider, useMenu } from "../ContentView/context/MenuContextProvider";
import { OPTIONS } from "../../config/options";
import {
  OptionsButton,
  OptionIcon
} from "../ContentView/UI/Style";
import { transormOptionsToList, getContentOptions } from "../../lib/utils";
import ReusableMenu from "../ContentView/UI/ReusableMenu";
import { RIGHT_SIDE_PANEL } from "../../config/constants";

const ItemsOptionsInner = ({
  selectedFolders,
  selectedFiles,
  overwriteOptionsOnClick = {},
  contentType = "browse",
  forceOptionsAsSecondary = new Set()
}) => {

  const isBusiness = useSelector(({ pCloudUser }) => pCloudUser.userinfo.business);
  const cryptoV2IsActive = useSelector(({ pCloudUser }) => pCloudUser.userinfo.cryptov2isactive);
  const isCollaborationEnabled = useSelector(({ pCloudUser }) => pCloudUser.collaboration || false);

  const [wrapperWidth, setWrapperWidth] = useState();
  const [optionsCount, setOptionsCount] = useState(100);

  const ref = useRef(null);
  const isFirstResize = useRef(true);
  const optionsWrapperRef = useRef();

  const { showMenu } = useMenu();
  const currentSelection = useMemo(() => [...selectedFolders.values(), ...selectedFiles.values()], [selectedFolders, selectedFiles]);

  useEffect(() => {
    const handleResize = debounce((width) => {
      setWrapperWidth(width);
      isFirstResize.current = false;
    }, 150);

    const observer = new ResizeObserver((entries) => {
      const width = entries[0].contentRect.width;
      if (isFirstResize.current) {
        setWrapperWidth(width);
      } else {
        handleResize(width);
      }
    });

    if (optionsWrapperRef.current) {
      observer.observe(optionsWrapperRef.current);
    }

    return () => {
      if (optionsWrapperRef.current) {
        observer.unobserve(optionsWrapperRef.current);
      }
    };
  }, []);

  useEffect(() => {
    let optionsCnt = 100;
    if (wrapperWidth) {
      for (let i = 1; i < optionsCnt; ++i) {
        if (wrapperWidth < ((wrapperWidth / 2) * (i + 1))) {
          optionsCnt = i;
          setOptionsCount(optionsCnt);
          break;
        }
      }
    }
  }, [wrapperWidth]);

  const availableOptions = useMemo(() => {
    return (wrapperWidth && currentSelection.length > 0) ? getContentOptions({
      content: currentSelection,
      isBusiness: isBusiness,
      cryptoV2IsActive: cryptoV2IsActive,
      isCollaborationEnabled: isCollaborationEnabled,
      maxMainOptionsCnt: optionsCount,
      contentType: contentType,
      forceOptionsAsSecondary: forceOptionsAsSecondary,
      placement: RIGHT_SIDE_PANEL
    }) : {};
  }, [currentSelection, isBusiness, cryptoV2IsActive, isCollaborationEnabled, optionsCount]);

  const items = useMemo(() => transormOptionsToList(availableOptions.secondary, currentSelection, overwriteOptionsOnClick), [availableOptions.secondary, currentSelection]);

  const renderMainOptions = () => {
    if (!availableOptions.main) {
      return null;
    }

    return availableOptions.main.map(option => {
      const optionData = OPTIONS[option] || {};
      const { iconLight, optionTitle } = optionData;
      const onClick = overwriteOptionsOnClick[option] ?? optionData.onClick;

      return (
        <OptionsButton
          className="options-btn"
          key={option}
          onClick={() => onClick(currentSelection, null, "items info menu")}
        >
          <OptionIcon>
            <i className={"smallIcon lightColorIcon " + iconLight} />
          </OptionIcon>
          <span className="label">
            {__(...optionTitle)}
          </span>
        </OptionsButton>
      );
    });
  };

  const renderSecondaryOptions = () => {
    if (!availableOptions.secondary) {
      return null;
    }

    return (
      Object.keys(availableOptions.secondary).length ? (
        <OptionsButton
          className="options-btn secondary-options-btn"
          ref={ref}
          key="options"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();

            showMenu(items, ref);
          }}
        >
          <OptionIcon>
            <i className="baseIcon fa-regular fa-ellipsis-vertical" />
          </OptionIcon>
        </OptionsButton>
      ) : null);
  };

  return currentSelection.length > 0 ? (
      <OptionsWrapper ref={optionsWrapperRef}>
        {renderMainOptions()}
        {renderSecondaryOptions()}

        <ReusableMenu listGridContainerRef={optionsWrapperRef} />
      </OptionsWrapper>
  ) : null;
};

const ItemsOptions = (props) => {
  return (
    <MenuProvider>
      <ItemsOptionsInner { ...props } />
    </MenuProvider>
  );
};

export default ItemsOptions;

const OptionsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: var(--spacing-md);
  align-items: center;
  justify-content: flex-end
`;