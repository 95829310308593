// @flow

import PropTypes from "prop-types";
import React, { Component } from "react";
import styled from "styled-components";
import { hiDPI } from "polished";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { __ } from "../lib/translate";
import { renderQuota } from "@pcloud/web-utilities/dist/plans/utils.plans";
import { TSpan, HtmlTString } from "../components/intl";
import CyanLink from "../components/CyanLink";
import ProgressSmart from "../components/ProgressSmart";
import UserQuotaTooltip from "./UserQuotaTooltip";
import { URLSITE } from "@pcloud/web-utilities/dist/config";

import type { Userinfo } from "../types/user";

type UserDropDownPlanSummaryProps = {
  userinfo: Userinfo,
  onUnverifiedStatusClick: () => void
};

class UserDropdownPlanSummary extends React.Component<
  UserDropDownPlanSummaryProps,
  {}
> {
  static defaultProps = {
    userinfo: {},
    onUnverifiedStatusClick: () => {}
  };

  getTotalQuota() {
    const { freequota, quota } = this.props.userinfo;
    return freequota ? (freequota < quota ? quota : freequota) : quota;
  }

  calculateProgress() {
    const { usedquota, freequota, quota } = this.props.userinfo;

    const totalQuota = this.getTotalQuota();

    let usedFracrion = Math.min(1, Math.max(0, usedquota / totalQuota));
    let freeFraction = Math.min(
      1,
      Math.max(0, (quota - usedquota) / totalQuota)
    );

    if (usedFracrion > 0 && usedFracrion < 0.02) {
      usedFracrion = 0.02;
    }
    if (freeFraction > 0 && freeFraction < 0.02) {
      freeFraction = 0.02;
    }

    let fractions = [
      {
        percent: usedFracrion.toFixed(2),
        color: "#5cb900",
        title: __("used_space"),
        size: renderQuota(usedquota)
      },
      {
        percent: freeFraction.toFixed(2),
        color: "#c0e699",
        title: __("free_space"),
        size: renderQuota(Math.max(0, quota - usedquota))
      }
    ];

    if (freequota) {
      //free user
      const lockedFraction = Math.min(
        1,
        Math.max(0, (freequota - quota) / freequota)
      );
      if (lockedFraction != 0) {
        fractions = [
          ...fractions,
          {
            percent: lockedFraction.toFixed(2),
            color: "#e6e6e6",
            title: __("locked_space"),
            size: renderQuota(Math.max(0, freequota - quota))
          }
        ];
      }
    }
    return fractions;
  }

  renderManage() {
    return (
      <div style={{ marginTop: "10px" }}>
        <CyanLink href={URLSITE + "manage-subscriptions.html"}>
          <TSpan id="manage_subscription" text="Manage subscription" />
        </CyanLink>
      </div>
    );
  }

  renderAddStorage() {
    return (
      <div style={{ marginTop: "10px" }}>
        <CyanLink href={URLSITE + "cloud-storage-pricing-plans.html"}>
          <TSpan id="add_storage" text="add_storage" />
        </CyanLink>
      </div>
    );
  }

  renderUpgrade() {
    return (
      <div style={{ marginTop: "10px" }}>
        <CyanLink href={URLSITE + "cloud-storage-pricing-plans.html"}>
          <TSpan id="upgrade_to_premium" text="Upgrade to premium" />
        </CyanLink>
      </div>
    );
  }

  render() {
    const {
      email,
      usedquota,
      quota,
      freequota,
      premium,
      business,
      emailverified,
      lastsubscription,
      premiumlifetime,
      plan,
      planparams
    } = this.props.userinfo;
    const { onUnverifiedStatusClick } = this.props;

    const percent = Math.min(100, Math.round((usedquota / quota) * 100));
    const calcProgress = this.calculateProgress();
    const separator = { width: "1px", backgroundColor: "#fff" };
    const userHasActiveSubscription = lastsubscription && (lastsubscription.status === "active" || lastsubscription.status === "past_due");
    const userCanAddStorage = !business && premiumlifetime && (plan == 1 || plan == 3 || (plan == 13 && planparams && planparams.storage < 2) || (plan == 17 && planparams && planparams.storage < 8));
    const userCanUpgrade = !business && !premium && (!lastsubscription || lastsubscription.status !== "active");

    return (
      <Wrap>
        <EmailWrap>
          {!emailverified && (
            <Unverified
              title="Unverified mail"
              onClick={onUnverifiedStatusClick}
            />
          )}
          <EmailStringWrap>
            <Email title={email}>{email}</Email>
          </EmailStringWrap>
        </EmailWrap>
        <UsedSpace percent={percent}>
          <TSpan
            id="used_quota"
            replace={{
              used: renderQuota(usedquota),
              total: renderQuota(quota)
            }}
          >
            %used% of %total% used
          </TSpan>
        </UsedSpace>
        <div data-tooltip-id="quota">
          <ProgressSmart
            fractions={calcProgress}
            separator={separator}
            progressHeight="8px"
          />
        </div>
        <ReactTooltip id="quota" className="cv-tooltip" noArrow={true}>
          <UserQuotaTooltip quota={calcProgress} />
        </ReactTooltip>

        {!business && userHasActiveSubscription && this.renderManage()}
        {!userHasActiveSubscription && userCanAddStorage && this.renderAddStorage()}
        {userCanUpgrade && this.renderUpgrade()}
      </Wrap>
    );
  }
}

export default UserDropdownPlanSummary;

const Wrap = styled.div`
  text-align: left;
  font-size: 13px;
  border-bottom: 1px solid #f0f0f0;
  padding: 10px 20px 10px 20px;
  min-width: 160px;
  max-width: 220px;
  .tooltip-wrapper {
    opacity: 1;
    padding: 12px;
    border-radius: 12px;
    box-shadow: 0px 1px 2.9px 0.1px;
  }
`;

const UsedSpace = styled.div`
  color: ${props => (props.percent >= 100 ? "#de4e4f" : "#9f9f9f")};
  font-size: 11px;
  margin: 10px 0 10px 0;
`;

const ProgressWrap = styled.div`
  background: #e4e4e4;
  height: 2px;
`;

const Progress = styled.div`
  background: ${props => (props.percent >= 100 ? "#de4e4f" : "#2b2b2d")};
  height: 100%;
`;

const EmailWrap = styled.div``;

const EmailStringWrap = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  white-space: nowrap;
`;

const Email = styled.span`
  height: 20px;
  line-height: 22px;
`;

const Unverified = styled.div`
  cursor: pointer;
  margin-left: 6px;
  float: right;
  width: 20px;
  height: 20px;
  background-size: 20px 20px;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(${require("../../root/img/header/unverified.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img/header/unverified@2x.png")});
  }
`;
