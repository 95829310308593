// @flow

import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import { __ } from "../lib/translate";
import UserPassDropdown from "./UserPassDropdown";
import Notifications from "./Notifications";

import type { UserState } from "../types/user";

type UserPassHeaderProps = {
  user: UserState,
  onLoginRequest: () => void
};

const UserPassHeader = ({ user: { logged }, onLoginRequest, ...restProps }) => (
  <Wrap>
    {logged ? (
      <LoggedWrap>
        <NotificationsWrap>
          <Notifications {...restProps} />
        </NotificationsWrap>
        <UserPassDropdown {...restProps} />
      </LoggedWrap>
    ) : (
      <LogoutWrap>
        <Sign data-testid="signin-header-button" onClick={onLoginRequest}>{__("signin", "Sign in")}</Sign>
      </LogoutWrap>
    )}
  </Wrap>
);

export default connect(state => ({ user: state.pCloudUser }))(UserPassHeader);

const Wrap = styled.div`
  width: 147px;
  height: 60px;
  border: 1px solid transparent;
`;

const LoggedWrap = styled.div`
  height: 60px;

  & > div {
    display: inline-block;
    vertical-align: middle;
  }
`;

const NotificationsWrap = styled.div`
  width: 48px;
  height: 60px;
  margin: 0px 26px 0 26px;

  & > div {
    margin-top: 6px;
    display: inline-block;
    vertical-align: middle;
  }
`;

const UserDropdownWrap = styled.div`
  height: 60px;
  & > div {
    vertical-align: middle;
  }
`;

const LogoutWrap = styled.div``;

const Sign = styled.a`
  text-decoration: none;
  display: block;
  color: #17bed0;
  font-size: 14px;
  font-weight: 700;
  margin: 22px 0 0 37px;
  padding: 0 0 0 33px;
  border-left: 1px solid #ccc;
  cursor: pointer;
`; 
