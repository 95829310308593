import { createSlice } from "@reduxjs/toolkit";
import { setAutoFreeze } from 'immer';

setAutoFreeze(false);

const initialState = {
    loadedSongs: {}
};

export const audioPlayerSlice = createSlice({
    name: "audioPlayer",
    initialState: initialState,
    reducers: {
        addLoadedSong: (state, action) => {
            state.loadedSongs[action.payload.id] = action.payload.song;
        }
    }
});

export const { addLoadedSong } = audioPlayerSlice.actions;

export default audioPlayerSlice.reducer;
