import React, { useState } from "react";
import styled from "styled-components";
import Componentify from "react-componentify";
import { Tooltip as ReactTooltip } from 'react-tooltip';

import Modal, { OvalModalWrap, OvalModalCloseV2 } from "../Modal";
import Button from "../ButtonDefault";
import { boldConverter } from "../../lib/componentifyConverters";
import { FILE_LIST_HEIGHT_FAILED_FOLDER, FILE_HEIGHT_FAILED_FOLDER, FILE_LIST_WIDTH_FAILED_FOLDER } from "./constants";
import { FixedSizeList } from 'react-window';
import UploadFailedFolderItem from "./UploadFailedFolderItem";
import useUploadContext from './hooks/useUploadContext';
import { Content, Body, ButtonWrapper, Text, Title } from "./sharedStyles";
import { __ } from "../../lib/translate"
import FailedSvg from "../../../root/img/svg/upload-manager/failed.svg";

const UploadFailedFolderItemsModal = () => {
  const {
    failedFolderModalItemId,
    handleFailedFolderModalOpenClose,
    handleFailedFolderModalOpenYesClick,
    failedItems,
    failedFolderError
  } = useUploadContext();
  if (!failedFolderModalItemId) {
    return null;
  }

  let failedItemsToRender = [];
  const failedItem = failedItems.find((item) => item.id === failedFolderModalItemId);
  failedItemsToRender = [...failedItem.files, ...failedItem.uniqueFolders];

  return (
    <Modal align="top" animate shouldCloseOnOverlayClick={false} onClose={handleFailedFolderModalOpenClose} opened={failedFolderModalItemId}>
      <OvalModalWrap>
        <OvalModalCloseV2 onClick={handleFailedFolderModalOpenClose} />
        <StyledContent>
          <React.Fragment>
            <Body>
              <Title>{__('upload_manager_failed_items_title', 'Upload problems')}</Title>
              <StyledText>
                <FailedSvg />
                <Componentify
                  text={__("upload_manager_failed_items_description", "There were problems uploading the following files.")}
                  converters={[boldConverter]}
                />
              </StyledText>
              <FixedSizeList
                className="files-container"
                height={FILE_LIST_HEIGHT_FAILED_FOLDER}
                itemCount={failedItemsToRender.length}
                itemSize={FILE_HEIGHT_FAILED_FOLDER}
                width={FILE_LIST_WIDTH_FAILED_FOLDER}
              >
                {({ index, style }) => (
                  <UploadFailedFolderItem item={failedItemsToRender[index]} style={style} failedFolderError={failedFolderError}></UploadFailedFolderItem>
                )}
              </FixedSizeList>
              <StyledReactTooltip className="cv-tooltip" noArrow={true} id="failed-item-tooltip" place="top" />
            </Body>
            <StyledButtonWrapper>
              <Button inline color="lightgray4" onClick={handleFailedFolderModalOpenClose}>
                {__('Cancel', 'Cancel')}
              </Button>
              <Button inline color="cyan" onClick={handleFailedFolderModalOpenYesClick}>
                {__('upload_manager_failed_items_action_success', 'Retry all')}
              </Button>
            </StyledButtonWrapper>
          </React.Fragment>
        </StyledContent>
      </OvalModalWrap>
    </Modal>
  );
};

export default UploadFailedFolderItemsModal;

const StyledContent = styled(Content)`

  @media (max-width: 500px) {
    padding: 15px;
  }

  .files-container {
    /* For WebKit (Safari, Chrome, Edge) */
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: #CACACEA6;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #A2A2AA;
    }

    /* For Firefox */
    scrollbar-color: #CACACEA6 #ffffff; /* thumb and track color */
    scrollbar-width: thin; /* 'auto' or 'thin' (Firefox does not support custom widths) */
  }
`;

const StyledReactTooltip = styled(ReactTooltip)`
  padding: 15px !important;
`

const StyledButtonWrapper = styled(ButtonWrapper)`
  margin-top: 15px;
`

const StyledText = styled(Text)`
  display: flex;
  align-items: center;
  gap: 8px;
`
