import {
  ADD_STORAGE_12COUNT_PLAN_ID,
  ADD_STORAGE_1COUNT_PLAN_ID,
  ADD_STORAGE_20COUNT_PLAN_ID,
  ADD_STORAGE_4COUNT_PLAN_ID,
  ADD_STORAGE_8COUNT_PLAN_ID,
  CRYPTO_PLAN_ID,
  CUSTOM4TB_PLAN_ID,
  CUSTOM_20COUNT_PLAN_ID,
  CUSTOM_8COUNT_PLAN_ID,
  CUSTOM_PLAN_ID,
  EFH_PLAN_ID,
  FAMILY2TB_PLAN_ID,
  FAMILY_ADD_1COUNT_PLAN_ID,
  FAMILY_ADD_2COUNT_PLAN_ID,
  FAMILY_ADD_5COUNT_PLAN_ID,
  FAMILY_CUSTOM_2COUNT_PLAN_ID,
  FAMILY_CUSTOM_5COUNT_PLAN_ID,
  PASS_FAMILY_PLAN_ID,
  PASS_PREMIUM_PLAN_ID,
  PREMIUM_PLAN_ID,
  PREMIUM_PLUS_PLAN_ID,
  ADDITIONAL_TRAFFIC_ID
} from "@pcloud/web-utilities/dist/plans/constants.plans";
import { __ } from "./translate";

export const planNames = {
  [PREMIUM_PLAN_ID]: "Premium 500 GB",
  [PREMIUM_PLUS_PLAN_ID]: "Premium Plus 2 TB",
  [FAMILY2TB_PLAN_ID]: "Family 2 TB",
  [FAMILY_CUSTOM_2COUNT_PLAN_ID]: "Family 4 TB",
  [FAMILY_CUSTOM_5COUNT_PLAN_ID]: "Family 10 TB",
  [FAMILY_ADD_1COUNT_PLAN_ID]: "Family Add 2 TB",
  [FAMILY_ADD_2COUNT_PLAN_ID]: "Family Add 4 TB",
  [FAMILY_ADD_5COUNT_PLAN_ID]: "Family Add 10 TB",
  [CUSTOM_PLAN_ID]: "Custom",
  [CUSTOM4TB_PLAN_ID]: "Custom 4 TB",
  [CUSTOM_8COUNT_PLAN_ID]: "Custom 4 TB",
  [CUSTOM_20COUNT_PLAN_ID]: "Custom 10 TB",
  [ADD_STORAGE_1COUNT_PLAN_ID]: "Add 500 GB",
  [ADD_STORAGE_4COUNT_PLAN_ID]: "Add 2 TB",
  [ADD_STORAGE_8COUNT_PLAN_ID]: "Add 4 TB",
  [ADD_STORAGE_12COUNT_PLAN_ID]: "Add 6 TB",
  [ADD_STORAGE_20COUNT_PLAN_ID]: "Add 10 TB",
  [CRYPTO_PLAN_ID]: "Encryption",
  [EFH_PLAN_ID]: "Extended History",
  [ADDITIONAL_TRAFFIC_ID]: "Additional Traffic",
  [PASS_PREMIUM_PLAN_ID]: "Pass Premium",
  [PASS_FAMILY_PLAN_ID]: "Pass Family"
};

export const testPeriods = {
  trial: "Trial",
  free: "Free",
  month: "Monthly",
  year: "Annual",
  lifetime: "Lifetime"
};

export const plansQuota = {
  [PREMIUM_PLAN_ID]: "500 GB",
  [PREMIUM_PLUS_PLAN_ID]: "2 TB",
  [FAMILY2TB_PLAN_ID]: "2 TB",
  [FAMILY_CUSTOM_2COUNT_PLAN_ID]: "4 TB",
  [FAMILY_CUSTOM_5COUNT_PLAN_ID]: "10 TB",
  [FAMILY_ADD_1COUNT_PLAN_ID]: "2 TB",
  [FAMILY_ADD_2COUNT_PLAN_ID]: "4 TB",
  [FAMILY_ADD_5COUNT_PLAN_ID]: "10 TB",
  [CUSTOM_PLAN_ID]: null,
  [CUSTOM4TB_PLAN_ID]: "4 TB",
  [CUSTOM_8COUNT_PLAN_ID]: "4 TB",
  [CUSTOM_20COUNT_PLAN_ID]: "10 TB",
  [ADD_STORAGE_1COUNT_PLAN_ID]: "500 GB",
  [ADD_STORAGE_4COUNT_PLAN_ID]: "2 TB",
  [ADD_STORAGE_8COUNT_PLAN_ID]: "4 TB",
  [ADD_STORAGE_12COUNT_PLAN_ID]: "6 TB",
  [ADD_STORAGE_20COUNT_PLAN_ID]: "10 TB",
  [CRYPTO_PLAN_ID]: null,
  [EFH_PLAN_ID]: null,
  [ADDITIONAL_TRAFFIC_ID]: null,
  [PASS_PREMIUM_PLAN_ID]: null,
  [PASS_FAMILY_PLAN_ID]: null
};

export const getBusinessPlanName = (planId) => {
  if (planId === 1) {
    return __("plan_name_business");
  } else if (planId === 2) {
    return __("plan_name_business_pro");
  }
}
