//@flow

import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { SwitchTransition, CSSTransition } from "react-transition-group";

import {
  SettingsWrapper,
  SettingsHeader,
  SettingsTitle,
  SettingsDescription,
  SettingOptionsContainer,
  Error,
  LinkWrapper,
  LinkInput,
  CopyLink,
  FadeAnimationContainer
} from "./SharedStyledComponents";
import TopBar from "./TopBar";
import Button from "../../components/ButtonDefault";
import { changePublinkApiCall } from "./utils";

import { __ } from "../../lib/translate";
import { copyTextToClipboard } from "../../lib/utils";
import { setShortLinkUrl } from "../../lib/state/reducers/sharedLinksSlice";

type Props = {
  allSettingsBack: any,
  bodyHeight: number,
  parentRef: any
};

const ShortLinkSetting = ({ allSettingsBack, bodyHeight, parentRef, currentSettingId }: Props) => {
  const linkData = useSelector(({ sharedLinks }) => sharedLinks.linkData);
  const { linkId, shortLinkUrl } = linkData;
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useDispatch();

  const handleSubmit = () => {
    errorMessage && setErrorMessage("");
    setLoading(true);
    changePublink();
  };

  const changePublink = () => {
    const params = {
      linkid: linkId,
      iconformat: "id"
    };

    if (shortLinkUrl) {
      params.deleteshortlink = true;
    } else {
      params.shortlink = true;
    }

    changePublinkApiCall(
      params,
      result => {
        if (typeof gtag === "function") {
          gtag("event", "shared_link_settings_click", {
            action: "short link create",
            category: "setting",
            location: "settings modal"
          })
        }
        setLoading(false);
        dispatch(setShortLinkUrl(result.shortlink || ""));
      },
      ({ error }) => {
        setLoading(false);
        setErrorMessage(error);
      }
    );
  };

  const copyLink = useCallback(() => {
    if (!shortLinkUrl) {
      return;
    }
    copyTextToClipboard(shortLinkUrl, __("Link copied."));
  }, [shortLinkUrl]);

  const renderHeader = () => {
    return (
      <SettingsHeader>
        <SettingsTitle>{__("dl_shortlink")}</SettingsTitle>
        <SettingsDescription>{__("shortlinks_explain")}</SettingsDescription>
      </SettingsHeader>
    );
  };

  const renderBody = () => {
    return (
      <SettingOptionsContainer>
        <form>
          <LinkWrapper disabled={!shortLinkUrl}>
            <LinkInput value={shortLinkUrl || ""} disabled />
            <CopyLink onClick={copyLink} disabled={!shortLinkUrl}>
              {__("shared_links_settings_copy_link")}
            </CopyLink>
          </LinkWrapper>
          <FadeAnimationContainer>
            <SwitchTransition>
              <CSSTransition
                key={errorMessage}
                addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
                classNames="fade"
              >
                {errorMessage ? <Error>{errorMessage}</Error> : <Error />}
              </CSSTransition>
            </SwitchTransition>
          </FadeAnimationContainer>
          <ButtonWrapper>
            <Button
              loading={loading}
              borderRadius="3"
              color={shortLinkUrl ? "red" : "cyan"}
              onClick={handleSubmit}
            >
              <FadeAnimationContainer>
                <SwitchTransition>
                  <CSSTransition
                    key={!!shortLinkUrl}
                    addEndListener={(node, done) =>
                      node.addEventListener("transitionend", done, false)
                    }
                    classNames="fade"
                  >
                    {shortLinkUrl ? <span>{__("Delete")}</span> : <span>{__("Create")}</span>}
                  </CSSTransition>
                </SwitchTransition>
              </FadeAnimationContainer>
            </Button>
          </ButtonWrapper>
        </form>
      </SettingOptionsContainer>
    );
  };

  return (
    <SettingsWrapper bodyHeight={bodyHeight} ref={parentRef}>
      <div>
        <TopBar allSettingsBack={allSettingsBack} settingId={currentSettingId} />
        {renderHeader()}
        {renderBody()}
      </div>
    </SettingsWrapper>
  );
};

export default ShortLinkSetting;

const ButtonWrapper = styled.div`
  max-width: 140px;
  margin: 0 auto;

  a[disabled] {
    background-color: #dddddd;
    color: #a2a2a2;
    opacity: 1;
  }
`;
