// @flow
import React, { useState } from "react";
import { useSelector } from "react-redux";

import * as Style from "./preferences.style";
import { __ } from "../../lib/translate";
import EnterPass from "./changecryptopass/EnterPass";
import SetNewPass from "./changecryptopass/SetNewPass";
import FlowManager from "../../containers/TwoFactorAuth/FlowManager";
import { OvalModalClose } from "../Modal";

const ChangeCryptoPassSetting = () => {
  const cryptoSetup = useSelector(({ pCloudUser }) => pCloudUser.userinfo.cryptosetup)
  const [currentFlow, setCurrentFlow] = useState([]);
  const DEFAULT_FLOW = [
    EnterPass,
    SetNewPass
  ]

  const onCloseClick = () => {
    setCurrentFlow([]);
  };

  const onChangeCryptoPassClick = () => {
    setCurrentFlow(DEFAULT_FLOW);
  }

  if (!cryptoSetup) {
    return null;
  }

  return (
    <>
      <Style.Row>
        <Style.Title>{__("heading_change_crypto_pass")}</Style.Title>
        <Style.Button onClick={onChangeCryptoPassClick}>{__("Change")}</Style.Button>
      </Style.Row>
      <FlowManager
        flow={currentFlow}
        onClose={onCloseClick}
        onSuccess={onCloseClick}
        closeButton={OvalModalClose}
        style={{ marginTop: 0 }}
      />
    </>
  )
};

export default ChangeCryptoPassSetting;
