import { FILEMANAGER_PAGE, UPLOAD_STATUS, UPLOAD_TAB, errorsCodeForShown } from "./constants";
import { errorKeys } from "../../lib/errors";
import { __ } from "../../lib/translate";
import hashManager from "../../lib/hashManager";

export const countFailedItems = (files) => files.filter((file) => file.status === UPLOAD_STATUS.FAILED || file.status === UPLOAD_STATUS.FADING_ACTIVE_FAILED).length;

export const checkIfFailedItemExists = (files) => files.some(file => file.status === UPLOAD_STATUS.FAILED);

export const filterFilesByTab = (files, activeTab) => {
  const tabItemsLength = {
    [UPLOAD_TAB.ALL]: files.length,
    [UPLOAD_TAB.ACTIVE]: 0,
    [UPLOAD_TAB.COMPLETED]: 0,
    [UPLOAD_TAB.FAILED]: 0,
  }
  const filteredItems = files.filter((file) => {
    const isActive = file.status === file.status === UPLOAD_STATUS.FADING_ACTIVE_FAILED || file.status === UPLOAD_STATUS.FADING_ACTIVE_COMPLETED || file.status === UPLOAD_STATUS.PENDING;
    const isCompleted = file.status === UPLOAD_STATUS.COMPLETED;
    const isFailed = file.status === UPLOAD_STATUS.FAILED || file.status === UPLOAD_STATUS.FADING_FAILED_RETRY;

    if (isActive) {
      ++tabItemsLength[UPLOAD_TAB.ACTIVE];
    } else if (isCompleted) {
      ++tabItemsLength[UPLOAD_TAB.COMPLETED];
    } else if (isFailed) {
      ++tabItemsLength[UPLOAD_TAB.FAILED];
    }

    switch (activeTab) {
      case UPLOAD_TAB.ALL:
        return file;
        break;
      case UPLOAD_TAB.ACTIVE:
        return isActive;
        break;
      case UPLOAD_TAB.COMPLETED:
        return isCompleted;
        break;
      case UPLOAD_TAB.FAILED:
        return isFailed;
        break;
      default:
        break;
    }
  })

  return { filteredItems, tabItemsLength };
}

export const fileItemShouldFadeOut = (status, activeTab) => (activeTab === UPLOAD_TAB.ACTIVE && (status === UPLOAD_STATUS.FADING_ACTIVE_FAILED || status === UPLOAD_STATUS.FADING_ACTIVE_COMPLETED)) || activeTab === UPLOAD_TAB.FAILED && status === UPLOAD_STATUS.FADING_FAILED_RETRY;

export const routeToFileLocation = (folderid, fileid) => {
  const timestamp = (new Date()).getTime();
  if (HFN.config.isDlink()) {
    return `${location.origin + location.pathname + location.search}#folder=${folderid}${fileid ? '&file=' + fileid : ''}&_=${timestamp}`;
  }
  return `${location.origin}#page=filemanager&folder=${folderid}${fileid ? '&file=' + fileid : ''}&tpl=folderlist&_=${timestamp}`;
};

export const getParentFolderPathFromArrayOfPaths = (pathsArray) => pathsArray.slice(0, -1).join("/");

export const getUniqueFolders = (items) => {
  const uniqueFolders = new Set();
  Array.from(items).forEach(file => {
    file.webkitRelativePath.split('/').slice(0, -1).reduce((acc, cur) => {
      const path = acc ? `${acc}/${cur}` : cur;
      uniqueFolders.add(path);
      return path;
    }, '');
  });

  return uniqueFolders;
}

export const fileExistsCache = (folderid, filename, isFolder) => {
  const isDlink = HFN.config.isDlink();
  let c = HFN.cache, cid = c.cacheid('listfolder', 'list', folderid, isDlink ? 'public' : 'default');
  const ref = c.get(cid);

  if (typeof filename === 'object') {
    filename = HFN.metaName(filename);
  }

  if (ref) {
    for (let n = 0; n < ref.contents.length; ++n) {
      const contentFileName = HFN.metaName(ref.contents[n])
      if (filename === contentFileName && isFolder === ref.contents[n].isfolder) {
        return ref.contents[n];
      }
    }
  }
  return false;
}

export const uniqFilename = (name, folderid, isFolder) => {
  const { baseName, extension } = getFileDetails(name, !isFolder);
  let n = 1;

  while (fileExistsCache(folderid, name, isFolder)) {
    ++n;
    name = baseName + ' (' + n + ')' + (extension || '');
  }
  return name;
}

export const isUploadDisabled = () => {
    const { auth, isDlink, hasQuota, isDlinkOwner, emailVerified } = {
        auth: HFN.config.auth,
        isDlink: HFN.config.isDlink(),
        hasQuota: HFN.hasQuota(),
        isDlinkOwner: HFN.config.isDlinkOwner(),
        emailVerified: HFN.config.user.emailverified
    };
    if (
        !auth ||
        (isDlink && !emailVerified) ||
        (!isDlink && !hasQuota) ||
        (isDlink && isDlinkOwner && !hasQuota) ||
        !canUpload() ||
        !isFileManagerPage()
    ) {
        return true;
    }
    return false;
};

export const handleDisabledInfoModals = (successCallback) => {
  const { auth, isDlink, hasQuota, userAccount, isDlinkOwner, emailVerified, canUploadSetting } = {
    auth: HFN.config.auth,
    isDlink: HFN.config.isDlink(),
    hasQuota: HFN.hasQuota() || (
        HFN.config.isDlink() &&
        !HFN.config.isDlinkOwner()
    ),
    userAccount: HFN.config.user.account,
    isDlinkOwner: HFN.config.isDlinkOwner(),
    emailVerified: HFN.config.user.emailverified,
    canUploadSetting: !HFN.config.isDlink() || HFN.dLink.data.usercanupload
  };

  if (!auth) {
    HFN.initLoginRegModal("loginorsignup", function () { }, { subTitle: __("shared_link_login_prompt") });
  } else if (!isFileManagerPage() && !isDlink) {
    HFN.openInfoModal("warning", "", __('upload_manager_droparea_unavailable', 'Upload currently unavailable.'));
  } else if (
      userAccount &&
      !userAccount.owner &&
      hashManager.getState('crypto') &&
      !hashManager.getState('folder')
  ) {
    HFN.message(__("upload_manager_droparea_unavailable", "Upload currently unavailable."), 'error');
  } else if (!canUpload() || !canUploadSetting) {
    HFN.openInfoModal("warning", "", __("shared_link_restricted_alert", 'You don\'t have the necessary permissions to upload or create folders from this link. Request \"Upload\" rights from the link owner.'));
  } else if (isDlink && !emailVerified) {
    HFN.message(__("shared_link_verify_message", "You need to verify the email address for your pCloud account in order to perform this action."), 'error');
  } else if ((!isDlink && !hasQuota) || (isDlink && isDlinkOwner && !hasQuota)) {
    HFN.openModalFullQuota();
  } else {
    successCallback();
  }
}

export const isUploadDisabledRedesign = () => {
  const { auth, isDlink, hasQuota, userAccount, isDlinkOwner, emailVerified } = {
    auth: HFN.config.auth,
    isDlink: HFN.config.isDlink(),
    hasQuota: HFN.hasQuota(),
    userAccount: HFN.config.user.account,
    isDlinkOwner: HFN.config.isDlinkOwner(),
    emailVerified: HFN.config.user.emailverified,
  };

  if (
    hashManager.getState('q') ||
    !auth ||
    (!isFileManagerPage() && !isDlink) ||
    (isDlink && !emailVerified) ||
    (
        userAccount &&
        !userAccount.owner &&
        hashManager.getState('crypto') &&
        !hashManager.getState('folder')
    ) ||
    ((!isDlink && !hasQuota) || (isDlink && isDlinkOwner && !hasQuota))
  ) {
    return true;
  }

  return false;
}

const canUpload = () => {
  const currentPageFolderId = hashManager.getState('folder') || currentFolder || 0;
  const meta = HFN.data.fflookup[`d${currentPageFolderId}`];

  const isRestrictedMobileBackup = meta?.virtualfolder && meta?.folderid === 0 && !meta?.ecnrypted;
  const isRestrictedDesktopBackup = (meta?.virtualfolder && meta?.backupdesktop) || meta?.isbackupdevicelist || meta?.isbackupdevice;

  return (meta && (meta.ismine || meta.cancreate) && !(meta.virtualfolder && meta.encrypted) && !isRestrictedMobileBackup && !isRestrictedDesktopBackup) || (HFN.dLink?.data?.canupload && HFN.dLink?.data?.usercanupload) ? true : false;
}

export const isFileManagerPage = () => {
  const pageParam = hashManager.getState('page')
  const backupmobileParam = hashManager.getState('backupmobile');
  const backupdesktopParam = hashManager.getState('backupdesktop');
  const folderParam = hashManager.getState('folder');

  if ((backupmobileParam || backupdesktopParam) && !folderParam) {
    return false;
  }

  return pageParam === FILEMANAGER_PAGE || !pageParam;
}

export const shouldNotRetryForError = (errorCode) => errorsCodeForShown.includes(errorCode);

export const getFormattedPasteName = () => {
  const now = new Date();

  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');

  return `screenshot_${year}${month}${day}_${hours}${minutes}${seconds}.png`;
}

export const getFileDetails = (name, isFile) => {
  const extensionIndex = name?.lastIndexOf('.');
  let extension = isFile && extensionIndex >= 0 ? name?.substring(extensionIndex) : "";
  let baseName = isFile && extensionIndex >= 0 ? name?.substring(0, extensionIndex) : name;
  if (!baseName && extension) {
    baseName = extension;
    extension = undefined;
  }
  return { baseName, extension };
}