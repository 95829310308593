import { createSlice } from "@reduxjs/toolkit";
import { setAutoFreeze } from 'immer';
import hashManager from "../../hashManager";
import { DEFAULT_SELECTED_ITEMS_INFO_TAB } from "../../../config/constants";
import { LOCAL_STORAGE_TOGGLED } from "../../../components/LeftMenu/constants";

setAutoFreeze(false);

const initialState = {
    loaded: false,
    breadcrumb: [],
    itemData: {
        comments: 0,
        contents: [],
        created: "",
        folderid: "",
        icon: 0,
        id: "",
        isfolder: false,
        ismine: false,
        isshared: false,
        modified: "",
        name: "",
        parentfolderid: 0,
        thumb: false,
        items: {}
    },
    itemKeys: [],
    defaultBranding: {},
    leftMenuContent: {
        activeItem: "",
        toggled: localStorage.getItem(LOCAL_STORAGE_TOGGLED) === "true",
        activeItemIndex: -1,
        isProfileOpened: false,
        previousMainActiveItem: "",
        previousMainActiveItemIndex: -1,
        isMobileMenuOpened: false
    },
    activeExploreCardIndex: 1,
    pageInfo: {
        explorecards: [],
        explorecardsleft: false,
        leftmenuitems: [],
        leftprofilemenuitems: []
    },
    gridHeaderHeight: 0,
    itemsInfoPanelData: {
        show: !!hashManager.getState('comments') || !!hashManager.getState('info'),
        tab: !!hashManager.getState('comments') ? "comments" : (!!hashManager.getState('info') ? "info" : DEFAULT_SELECTED_ITEMS_INFO_TAB),
        item: null,
        selectedFiles: new Map(),
        selectedFolders: new Map(),
        autoFocus: {
            value: false
        }
    }
};

export const contentSlice = createSlice({
    name: "content",
    initialState: initialState,
    reducers: {
        loadContent: (state, action) => {
            state.loaded = action.payload;
        },
        deleteContentItems: (state) => {
            state.itemData = {};
            state.itemKeys = [];
        },
        setContentItems: (state, action) => {
            state.itemData = buildContentItems(action.payload);
        },
        addContentItem: (state, action) => {
            state.itemData = addItem(state, action.payload);
        },
        deleteContentItem: (state, action) => {
            state.itemData = deleteItem(state, action.payload);
        },
        modifyContentItem: (state, action) => {
            state.itemData = modifyItem(state, action.payload);
            state.breadcrumb = modifyBreadcrumb(state, action.payload);
        },
        setContentItemKeys: (state, action) => {
            state.itemKeys = [...action.payload];
        },
        loadBreadcrumb: (state, action) => {
            const tmpBreadcrumb = {};
            action.payload.forEach((element) => {
                tmpBreadcrumb[element.id] = element;
            });
            state.breadcrumb = tmpBreadcrumb;
        },
        deleteBreadcrumb: (state) => {
            state.breadcrumb = [];
        },
        setLeftMenuContent: (state, action) => {
            state.leftMenuContent = action.payload;
        },
        setPageInfo: (state, action) => {
            state.pageInfo = action.payload;
        },
        setActiveExploreCard: (state, action) => {
            state.activeExploreCardIndex = action.payload;
        },
        incrementActiveExploreCard: (state) => {
            state.activeExploreCardIndex = state.activeExploreCardIndex + 1;
        },
        decrementActiveExploreCard: (state) => {
            state.activeExploreCardIndex = state.activeExploreCardIndex - 1;
        },
        setGridHeaderHeight: (state, action) => {
            state.gridHeaderHeight = action.payload;
        },
        setItemsInfoPanelData: (state, action) => {
            state.itemsInfoPanelData = action.payload;
        },
        setDefaultBranding: (state, action) => {
            state.defaultBranding = action.payload;
            state.defaultBranding.skipCloseOnInitialLoad = action.payload.skipCloseOnInitialLoad ?? false;
            state.defaultBranding.skipZoomAnimation = action.payload.skipZoomAnimation ?? false;
        }
    }
});

const buildContentItems = (metadata) => {
    const {
        hash = "",
        comments = 0,
        contents = [],
        created = "",
        folderid = "",
        fileid = "",
        icon = 0,
        id = "",
        isfolder = false,
        ismine = false,
        isshared = false,
        isalbum = false,
        isplaylist = false,
        modified = "",
        name = "",
        artistname = "",
        parentfolderid = 0,
        thumb = false
    } = metadata;

    const itemData = {
        comments: comments,
        created: created,
        folderid: folderid,
        fileid: fileid,
        icon: icon,
        hash: hash,
        id: id,
        isfolder: isfolder,
        ismine: ismine,
        isshared: isshared,
        isalbum: isalbum,
        isplaylist: isplaylist,
        modified: modified,
        name: name,
        artistname: artistname,
        parentfolderid: parentfolderid,
        thumb: thumb,
        items: {}
    };

    if (metadata.hasOwnProperty("encrypted")) {
        itemData.encrypted = metadata.encrypted;
    }
    if (metadata.hasOwnProperty("time")) {
        itemData.time = metadata.time;
    }
    if (metadata.hasOwnProperty("tm_key")) {
        itemData.tm_key = metadata.tm_key;
    }

    if (contents.length) {
        contents.forEach((element) => {
            itemData.items[element.id] = element;
        });
    }

    return itemData;
};

const addItem = (state, metadata) => {
    let data = { ...metadata };
    const { itemData } = state;
    const { parentfolderid = 0, isfolder = false, contents = [] } = data;

    if (itemData.id === "d" + parentfolderid) {
        itemData.items[metadata.id] = data;
    } else if (itemData.items && itemData.items["d" + parentfolderid]) {
        if (!itemData.items["d" + parentfolderid].contents) {
            itemData.items["d" + parentfolderid].contents = [];
        }
        itemData.items["d" + parentfolderid].contents.push(data.id);
    }

    return { ...itemData };
};

const deleteItem = (state, metadata) => {
    const { itemData } = state;
    const { parentfolderid, id } = metadata;

    if (itemData.items && itemData.items[id]) {
        delete itemData.items[id];
    }

    if (
        itemData.items &&
        itemData.items["d" + parentfolderid] &&
        itemData.items["d" + parentfolderid].contents
    ) {
        itemData.items["d" + parentfolderid].contents = itemData.items[
            "d" + parentfolderid
        ].contents.filter((elementId) => elementId !== id);
    }

    return { ...itemData };
};

const modifyItem = (state, metadata) => {
    let data = { ...metadata };
    const { itemData } = state;
    const { id, parentfolderid } = metadata;

    if (metadata.isplaylist) {
        if (itemData.items[id] && itemData.items[id].id === id) {
            itemData.items[id].name = metadata.name;
        } else if (itemData.id === id) {
            itemData.name = metadata.name;
        }

        return { ...itemData };
    } else if (metadata.isfilerequest) {
        if (itemData.items[id] && itemData.items[id].id === id) {
            itemData.items[id].comment = metadata.comment;
            if (metadata.maxspace) {
                itemData.items[id].maxspace = metadata.maxspace;
            } else {
                delete itemData.items[id].maxspace;
            }
            if (metadata.expires) {
                itemData.items[id].expires = metadata.expires;
            } else {
                delete itemData.items[id].expires;
            }
        }

        return { ...itemData };
    } else if (metadata.issharedlink) {
        if (itemData.items[id] && itemData.items[id].id === id) {
            itemData.items[id] = metadata;
        }

        return { ...itemData };
    }

    if (itemData.folderid !== parentfolderid) {
        if (itemData.items && itemData.items[id]) {
            if (itemData.items[id].id === id && itemData.items[id].issharedbyme) {
                itemData.items[id].name = metadata.name;
            } else {
                // Content moved
                delete itemData.items[id];
            }
        };
    } else {
        if (itemData.items && itemData.items[id]) {
            if (metadata.category === HFN.CATEGORY.IMAGE && metadata.thumb) {
                // thumb-1--40x40-15747698833754946000-0
                const cacheid = itemData.items[id].hash + '-' + (itemData.items[id].revisionid || 0);
                HFN.cache.expireMatch('thumb-0--[^-]+-' + cacheid);
                HFN.cache.expireMatch('thumb-1--[^-]+-' + cacheid);
            }
            itemData.items[id] = metadata;
        } else if (itemData.id === id) {
            itemData.icon = metadata.icon;
            itemData.ismine = metadata.ismine;
            itemData.isshared = metadata.isshared;
            itemData.modified = metadata.modified;
            itemData.name = metadata.name;
            itemData.parentfolderid = metadata.parentfolderid;
            itemData.thumb = metadata.thumb;
        }
    }

    return { ...itemData };
};

const modifyBreadcrumb = (state, metadata) => {
    const { breadcrumb } = state;
    const { id } = metadata;

    if (breadcrumb[id]) {
        breadcrumb[id] = metadata;
    }

    return { ...breadcrumb };
};

export const {
    loadContent,
    deleteContentItems,
    setContentItems,
    addContentItem,
    deleteContentItem,
    modifyContentItem,
    setContentItemKeys,
    loadBreadcrumb,
    deleteBreadcrumb,
    setLeftMenuContent,
    setPageInfo,
    setActiveExploreCard,
    incrementActiveExploreCard,
    decrementActiveExploreCard,
    setGridHeaderHeight,
    setDefaultBranding,
    setItemsInfoPanelData
} = contentSlice.actions;

export default contentSlice.reducer;
