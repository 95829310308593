// @flow

import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import $ from "jquery";

import { __ } from "../../../../../lib/translate";
import { getHash, getHashPage } from "../../../../../lib/utils";

import SearchFilterDropdown from "./SearchFilterDropdown";
import { MOBILE_WIDTH_BREAKPOINT } from "../../../../../config/constants";
import { Tooltip } from "react-tooltip";
import { useSelector } from "react-redux";
import hashManager from "../../../../../lib/hashManager";

let timeout;

const specialPages = {
    publinks: { placeholder: { key: "search_links", default: "Search Links" } },
    filerequests: {
        placeholder: { key: "search_links", default: "Search Links" }
    },
    bookmarks: {
        placeholder: { key: "search_bookmarks", default: "Search Bookmarks" }
    },
    "tab-songs": {
        placeholder: { key: "Search Songs", default: "Search Songs" }
    },
    "tab-artists": {
        placeholder: { key: "Search Artists", default: "Search Artists" }
    },
    "tab-albums": {
        placeholder: { key: "Search Albums", default: "Search Albums" }
    },
    "tab-playlists": {
        placeholder: { key: "search_playlists", default: "Search Playlists" }
    },
    "publinks-tab": {
        placeholder: { key: "search_links", default: "Search Links" }
    },
    "myshares-tab": {
        placeholder: { key: "search_my_shares", default: "Search My Shares" }
    },
    "requests-tab": {
        placeholder: { key: "search_shared_with_me", default: "Search Shared with me" }
    }
};

const SearchBar = ({
    folderId = 0,
    isDisabled = false,
    searchBarExpanded = false,
    setSearchBarExpanded = () => {}
}) => {
    const [page, setPage] = useState(getHashPage());
    const [disableTransition, setDisableTransition] = useState(false);
    const [isExpandFinish, setIsExpandFinish] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [selectedFilterId, setSelectedFilterId] = useState(0);
    const [isDropdownOpened, setIsDropdownOpened] = useState(false);

    // Do not delete - userLang is used for language change render
    const userLang = useSelector(({ pCloudUser }) => pCloudUser.userinfo.language);

    const wrapperRef = useRef(null);
    const inputRef = useRef(null);
    const isSpecial = specialPages.hasOwnProperty(page);

    useEffect(() => {
        const queryQ = getHash("q") || "";
        const queryFilter = getHash("filter") || 0;

        const paramSearchText = queryQ.split(":")[1] || "";
        const paramFilter =
            queryFilter === "all"
                ? HFN.CATEGORY.UNKNOWN
                : parseInt(queryFilter, 10);
        const shouldFocus = Boolean(queryQ);

        setSearchBarExpanded(shouldFocus);

        if (shouldFocus) {
            setDisableTransition(true);
            setIsExpandFinish(true);
        }

        setSearchText(paramSearchText.split(/\+/g).join(" "));
        setSelectedFilterId(paramFilter);
    }, []);

    useEffect(() => {
        if (selectedFilterId !== 0 || searchText !== "") {
            search();
        }
    }, [selectedFilterId]);

    useEffect(() => {
        window.addEventListener("hashchange", handleHashChange);

        return () => {
            window.removeEventListener("hashchange", handleHashChange);
        };
    }, []);

    useEffect(() => {
        if (!wrapperRef.current) {
            return;
        }

        const transitions = {
            transition: "transitionend",
            OTransition: "oTransitionEnd",
            MozTransition: "transitionend",
            WebkitTransition: "webkitTransitionEnd"
        };
        let transitionEvent = "";

        let t;
        for (t in transitions) {
            if (
                wrapperRef.current &&
                wrapperRef.current.style[t] !== undefined
            ) {
                transitionEvent = transitions[t];
            }
        }

        if (transitionEvent) {
            wrapperRef.current.addEventListener(
                transitionEvent,
                handleTransition
            );
        }

        if (timeout) {
            clearTimeout(timeout);
        }
        if (searchBarExpanded && !isDropdownOpened) {
            timeout = setTimeout(() => {
                if (searchText.trim() === "") {
                    setSearchBarExpanded(false);
                }
            }, 10000);

            setDisableTransition(false);
        }

        return () => {
            if (wrapperRef.current) {
                wrapperRef.current.removeEventListener(
                    transitionEvent,
                    handleTransition
                );
            }
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [searchBarExpanded, isDropdownOpened]);

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [searchBarExpanded, isExpandFinish, isDropdownOpened, inputRef.current]);

    function handleClickOutside(event) {
        event.stopPropagation();
        if (
            document.body.offsetWidth < MOBILE_WIDTH_BREAKPOINT &&
            !isDropdownOpened &&
            searchBarExpanded &&
            isExpandFinish &&
            inputRef.current?.value === "" &&
            !hashManager.getState('q') &&
            wrapperRef.current &&
            !wrapperRef.current.contains(event.target)
        ) {
            onClose(event);
        }
    }

    const handleHashChange = () => {
        const hashPage = getHashPage();
        setPage(hashPage);

        if (hashPage !== "filemanager") {
            setIsExpandFinish(false);
            setSearchText("");
            setSearchBarExpanded(false);
        }
    };

    const onWrapperClick = () => {
        if (isDisabled) {
            return;
        }
        setSearchBarExpanded(true);
    };

    const handleTransition = () => {
        setIsExpandFinish(searchBarExpanded);
        if (searchBarExpanded) {
            setDisableTransition(false);
        }
    };

    const onClose = (e) => {
        e.stopPropagation();
        setSearchText("");

        if (isSpecial) {
            inputRef.current.value = "";
            $(inputRef.current).trigger("keyup");
            window.dispatchEvent(new Event("keyup"));
        } else {
            hashManager.removeState("q");
            hashManager.removeState("filter");
        }

        setSelectedFilterId(0);
        setIsExpandFinish(false);
        setSearchBarExpanded(false);
    };

    const onKeyDown = (e) => {
        if (e.keyCode == 13) {
            search();
        }
    };

    const getSelectedFilterId = (id) => {
        setSelectedFilterId(id);
    };

    const onInputChange = (e) => {
        if (e.target.value !== "") {
            clearTimeout(timeout);
        }
        setSearchText(e.target.value);
    };

    const search = () => {
        if (isSpecial) {
            return;
        }

        const encoder = new TextEncoder("ASCII");
        const bytes = encoder.encode(searchText);
        if (searchText.length > 0 && bytes.length < 3) {
            HFN.message(
                __("search_character_error", "You need to enter at least 3 characters."),
                "error"
            );
            return;
        }

        hashManager.pushState(
            Object.assign(
                {},
                {
                    page: "filemanager",
                    q: "name:" + searchText,
                    folderid: folderId,
                    filter: selectedFilterId === 0 ? "all" : selectedFilterId
                },
                {}
            ),
            2
        );
    };

    const onDropdownOpen = () => {
        setIsDropdownOpened(true);
    };

    const onDropdownClose = () => {
        setIsDropdownOpened(false);
    };

    const renderIcons = () => {
        return (
            <>
                {!isSpecial && (
                    <SearchFilterDropdown
                        onOpen={onDropdownOpen}
                        onClose={onDropdownClose}
                        selectedItemId={selectedFilterId}
                        onItemSelect={getSelectedFilterId}
                    />
                )}
                <CloseButton onClick={onClose}>
                    <i className="baseIcon fa-light fa-close" />
                </CloseButton>
            </>
        );
    };

    return (
        <>
            <Wrapper
                $disableTransition={disableTransition}
                $isExpanded={searchBarExpanded}
                $isDisabled={isDisabled}
                ref={wrapperRef}
                onKeyDown={onKeyDown}
                onClick={onWrapperClick}
            >
                {searchBarExpanded && (
                    <>
                        <InputIcon onClick={search}>
                            <i className="mediumIcon fa-regular fa-search" />
                        </InputIcon>
                        <SearchInput
                            $isExpanded={searchBarExpanded}
                            ref={inputRef}
                            name="search"
                            id={"search-bar"}
                            type="text"
                            autoComplete="off"
                            value={searchText}
                            placeholder={
                                isSpecial
                                    ? __(
                                        specialPages[page].placeholder.key,
                                        specialPages[page].placeholder.default
                                    )
                                    : __(
                                        "search_bar_placeholder_my",
                                        "Search in My pCloud"
                                    )
                            }
                            onChange={onInputChange}
                            autoFocus
                        />
                        {searchBarExpanded && isExpandFinish && renderIcons()}
                    </>
                )}
                {!searchBarExpanded && (
                    <SearchIcon data-tooltip-id="searchbar_tooltip">
                        <i className="mediumIcon fa-regular fa-search" />
                    </SearchIcon>
                )}
            </Wrapper>
            <Tooltip
                className="cv-tooltip"
                id="searchbar_tooltip"
                noArrow
                place="bottom"
                offset={20}
            >
                {__("tooltip_search", "Search")}
            </Tooltip>
        </>
    );
};

export default SearchBar;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    overflow: hidden;
    float: right;
    height: 40px;
    padding: 2px 4px;
    transition: ${({ $disableTransition }) =>
        $disableTransition ? "" : "width 0.6s, height 0.6s ease-in-out"};
    box-sizing: border-box;
    border-radius: var(--radius-full, 9999px);
    width: 40px;
    background: #f0f4f5;
    cursor: ${({ $isDisabled }) => ($isDisabled ? "auto" : "pointer")};
    opacity: ${({ $isDisabled }) => ($isDisabled ? "0.4" : "1")};

    &,
    & * {
        -webkit-tap-highlight-color: transparent;
    }

    @media (max-width: 1023px) {
        position: absolute;
        right: var(--spacing-md);
    }

    ${({ $isExpanded }) =>
        $isExpanded &&
        css`
            background: var(--button-secondary-background-default);
            width: 500px;
            z-index: 1;
            @media (max-width: 1440px) {
                width: 400px;
            }
            @media (max-width: 1023px) {
                z-index: 0;
                width: calc(100% - var(--spacing-md) * 2 + 1px);
            }
            background: var(--surface-secondary);
            border: 1px solid var(--color-primary-500);
            border-radius: 88px;
            position: relative;
        `}
`;

const SearchInput = styled.input`
    flex: 1;
    flex-shrink: 0;
    height: 40px;
    width: 100%;
    padding-left: 8px;
    padding-right: 10px;
    border-radius: 24px;
    outline: none !important;
    border: none !important;
    box-sizing: border-box;
    background: var(--surface-secondary);

    color: var(--text-primary);
    font-size: var(--base-font-size);
    font-weight: 400;
    line-height: 24px;

    &::-ms-clear {
        display: none;
        height: 0;
        width: 0;
    }
`;

const SearchIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    margin-left: 3px;
`;

const InputIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-left: 4px;

    & i {
        color: var(--text-secondary);
    }
`;

const CloseButton = styled(SearchIcon)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    cursor: pointer;

    & i {
        color: var(--text-secondary);
    }
`;
