// @flow

import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import Popover from "../../../../Popover";
import NotificationsList from "./NotificationsList";
import NotificationsPopoverWrap from "./NotificationsPopoverWrap";
import NotificationsBell from "./NotificationsBell";
import NotificationsNew from "./NotificationsNew";

import type { NotificationsState } from "../../../../../lib/notifications/types";
import type { Notification } from "../../../../../lib/notifications/types";
import type { UserState } from "../../../../../types/user";
import { read, show } from "@pcloud/web-utilities/dist/lib/state/reducers/pcloud/notificationSlice";

import apiMethod from "../../../../../api/apiMethod";
import { Tooltip as ReactTooltip } from "react-tooltip";

type NotificationsProps = {
    notifications: NotificationsState,
    user: UserState,
    storeSubscription: any,
    readNotifications: () => void,
    showNotifications: () => void
};
type State = { isOpen: boolean, isMobile: boolean };

class Notifications extends React.Component<NotificationsProps, State> {
    constructor(props: NotificationsProps) {
        super(props);

        this.state = {
            isOpen: false
        };

        (this: any).onOpen = this.onOpen.bind(this);
        (this: any).onClose = this.onClose.bind(this);
    }

    onOpen() {
        this.setState({ isOpen: true });
        const {showNotifications} = this.props;
        showNotifications();

        const { user, notifications } = this.props;
        if (user.logged && notifications.loaded) {
            const lastUnreadNotificationId = getLastUnreadNotificationId(
                notifications.notifications
            );

            if (lastUnreadNotificationId) {
                apiMethod(
                    "readnotifications",
                    {
                        auth: user.token,
                        notificationid: lastUnreadNotificationId
                    },
                    () => {}
                );
            }
        }
    }

    onClose() {
        this.setState({ isOpen: false });
    }

    render() {
        const { notifications, isMobile = false } = this.props;
        const { isOpen } = this.state;
        const badge = notifications.loaded
            ? notifications.notifications.filter((n) => n.isnew).length
            : 0;

        const NotificationsPopover = (
            <NotificationsPopoverWrap isMobile={isMobile}>
                {notifications.loaded ? (
                    <NotificationsList
                        list={notifications.showNotifications}
                        isMobile={isMobile}
                    />
                ) : (
                    <NotificationsLoading>
                        <div
                            style={{
                                lineHeight: "42px",
                                textAlign: "center",
                                fontSize: "12px"
                            }}
                        >
                            Loading ...
                        </div>
                    </NotificationsLoading>
                )}
            </NotificationsPopoverWrap>
        );

        return (
            <Popover
                mobileOpen={isMobile}
                trigger="click"
                render={NotificationsPopover}
                onOpen={this.onOpen}
                onClose={this.onClose}
                cssPosition="absolute"
                position="bottom"
                align="right"
            >
                <>
                    <ReactTooltip
                        className="cv-tooltip"
                        id="notifications-header-tooltip"
                        noArrow
                        offset={12}
                        opacity={1}
                        place="bottom-start"
                    >
                        {__(
                            "tooltip_notifications_header",
                            "Notifications"
                        )}
                    </ReactTooltip>
                    <NotificationsBell
                        data-tooltip-id={isMobile ? null : "notifications-header-tooltip"}
                        $isOpen={isOpen}
                        $hasNew={badge > 0}
                    >
                        <i className="notification-bell mediumIcon fa-regular fa-bell" />
                        {badge > 0 && <NotificationsNew />}
                    </NotificationsBell>
                </>
            </Popover>
        );
    }
}

export default connect(
    (state) => ({ notifications: state.pCloudNotifications, user: state.pCloudUser }),
    (dispatch) => ({
        showNotifications: () => dispatch(show())
    })
)(Notifications);

const NotificationsLoading = styled.div`
    height: 42px;
    background: url("/ZCDNURL/img/loading_button.png") no-repeat center center;
`;

function getLastUnreadNotificationId(
    notifications: Array<Notification>
): number | false {
    if (notifications.length) {
        return notifications[0].notificationid;
    }
    return false;
}
