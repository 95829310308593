// @flow

import React, { useState, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { __ } from "../../lib/translate";
import apiMethod from "../../api/apiMethod";
import * as Style from "./preferences.style";
import Popover from "../Popover";
import { LanguageFontAwesomeIcon, MenuContainer, MenuItem } from "../LeftMenu/LeftMenuContainer";
import { getLanguages } from "../../lib/pCloudIntl";

const ChangeLanguage = () => {
  const [isLanguagesMenuOpened, setIsLanguagesMenuOpened] = useState(false);
  const languageTabRef = useRef(null);
  const languageContainerRef = useRef(null);

  const onLanguageClick = useCallback((code) => {
    languageTabRef.current.click();
    pCloudIntl.setUserLang(code);
  }, []);

  return (
    <Style.Row>
      <div>
        <Style.Title>{__("Language")}</Style.Title>
        <Style.Description>{__("Choose Your Interface Language")}</Style.Description>
      </div>
      <Popover
        trigger="click"
        render={
          <MenuContainer ref={languageContainerRef} $opened={isLanguagesMenuOpened}>
            {getLanguages().map(({ code, title }) => (
              <MenuItem key={code} onClick={() => onLanguageClick(code)}>
                {__(title)}
                {code === pCloudIntl.getLang() && <LanguageFontAwesomeIcon className="smallIcon fa-regular fa-check" />}
              </MenuItem>
            ))}
          </MenuContainer>
        }
        cssPosition="absolute"
        position="top"
        align="right"
        valign='top'
        flyin="bottom"
      >
        <div>
          <Style.Button style={{ lineHeight: "15px", paddingLeft: "10px" }} ref={languageTabRef}>
            {pCloudIntl.getLangObj().title}
            <i className="fa-light fa-globe" />
          </Style.Button>
        </div>
      </Popover >
    </Style.Row>
  )
};

export default ChangeLanguage;
