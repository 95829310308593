// @flow

import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";

import Modal, { OvalModalWrap, CloseModalIcon } from "../../components/Modal";
import Button from "../../components/ButtonDefault";
import { Loader } from "../TwoFactorAuth/SharedComponents";
import * as Style from "../../components/Modals/styledComponents";

import { __ } from "../../lib/translate";

type Props = {
  list: any
};

const ExportBookmark = ({ list }: Props) => {
  const [isOpened, setIsOpened] = useState(true);
  const [loadingModal, setLoadingModal] = useState(true);

  useEffect(() => {
    setLoadingModal(false);
  }, []);

  const onCloseClick = () => {
    setIsOpened(false);
  };

  const onCopyToClipboard = () => {
    window.getSelection().selectAllChildren(document.getElementById("link"));

    document.execCommand("copy");
    HFN.message(__("Copied to clipboard."));
  };

  const renderBookmarkItem = (data, index) => {
    const { link } = data;

    return <div id={index}>{link}</div>;
  };

  const renderContent = () => {
    const title = __("export_links", "Export Links");

    return (
      <React.Fragment>
        <Body>
          <HeaderCustom>{title}</HeaderCustom>
          <ContainerCopy>
            <Style.Label>{__("Links", "Link(s)")}</Style.Label>
            <div id="link" className="export-bookmark-div">
              {list.map(renderBookmarkItem) /*.join("\n")*/}
            </div>
          </ContainerCopy>
        </Body>
        <Style.Footer style={{ marginTop: "0px" }}>
          <Button style={{ marginRight: "5px" }} color="lightgray4" onClick={onCloseClick}>
            {__("Close")}
          </Button>
          <Button style={{ marginLeft: "5px" }} color="cyan" onClick={onCopyToClipboard}>
            {__("Copy Link(s)", "Copy Link(s)")}
          </Button>
        </Style.Footer>
      </React.Fragment>
    );
  };

  return (
    <Modal
      align="top"
      animate
      shouldCloseOnOverlayClick={false}
      onClose={() => setIsOpened(false)}
      opened={isOpened}
    >
      <OvalModalWrap>
        <CloseModalIcon onClick={() => setIsOpened(false)} />
        <Content>{loadingModal ? <Loader /> : renderContent()}</Content>
      </OvalModalWrap>
    </Modal>
  );
};

export default ExportBookmark;

const HeaderCustom = styled(Style.Header)`
  margin-bottom: 0;
`;

const Content = styled.div`
  padding: 30px;
  width: 480px;
  box-sizing: border-box;
  text-align: center;
  min-height: 220px;

  @media (max-width: 500px) {
    width: 90vw;
  }
`;

const Body = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const ContainerCopy = styled(Style.Container)`
  .export-bookmark-div {
      width: 100%;
      box-sizing: border-box;
      border: 1px solid #ccc;
      border-radius: 5px;

      overflow: hidden;
    
      height: auto;
      padding: 10px;
      font-size: 15px;
      font-weight: normal;
      text-align: left;
      padding: 8px;
      justify-content: flex-start;
      flex-direction: column;
      outline: none;
    
      max-height: 200px;
      overflow: auto;
      overflow-x: hidden;    
  }
  div.export-bookmark-div > div {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
  }

    /* width */
  div.export-bookmark-div::-webkit-scrollbar {
      width: 5px;
      border-radius: 3px;
  }

  /* Track */
  div.export-bookmark-div::-webkit-scrollbar-track {
      background: #eaeaea;
      border-radius: 3px;
  }

  /* Handle */
  div.export-bookmark-div::-webkit-scrollbar-thumb {
      background: #aaa;
      border-radius: 3px;
      transition: background 200ms ease-in-out;
  }

  /* Handle on hover */
  div.export-bookmark-div::-webkit-scrollbar-thumb:hover {
      background: #555;
  }
`;
