// @flow

import React, { useState, useEffect } from "react";

import Modal, { OvalModalWrap, CloseModalIcon } from "../Modal";
import Button from "../ButtonDefault";
import * as Style from "./styledComponents";

import { __ } from "../../lib/translate";
import { copyTextToClipboard } from "../../lib/utils";
import { CATEGORY } from "@pcloud/web-utilities/dist/config/constants";

const GetPublickLinkModal = ({
  directLink,
  metadata
}: {
  directLink: string,
  metadata: Metadata
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [linkForWeb, setLinkForWeb] = useState("");
  const [linkForForums, setLinkForForums] = useState("");
  const [linkEmbedImg, setLinkEmbedImg] = useState("");

  useEffect(() => {
    setLinkForWeb(htmlLink(directLink, metadata));
    setLinkForForums(urlBBCode(directLink, metadata));
    isImage(metadata) && setLinkEmbedImg(imageBBCode(directLink));
  }, [directLink, metadata]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const isImage = (metadata: Metadata): boolean => {
    return !metadata.isfolder && metadata.category === CATEGORY.IMAGE;
  };

  const htmlLink = (link: string, meta: Metadata): string => {
    return '<a href="' + link + '">' + meta.name + "</a>";
  };

  const imageBBCode = (link: string): string => {
    return "[img]" + link + "[/img]";
  };

  const urlBBCode = (link: string, meta: Metadata): string => {
    return "[url=" + link + "]" + meta.name + "[/url]";
  };

  const copyLink = (link: string) => {
    copyTextToClipboard(link, __("Link copied."));
  };

  return (
    <Modal align="top" animate onClose={handleClose} opened={isOpen}>
      <OvalModalWrap>
        <CloseModalIcon onClick={handleClose} />
        <Style.Container>
          <Style.Header>{__("Get Link")}</Style.Header>
          <Style.Label>{__("direct_link", "Direct link")}</Style.Label>
          <Style.LinkWrapper>
            <Style.LinkInput defaultValue={directLink} disabled />
            <Style.CopyLink onClick={() => copyLink(directLink)}>
              {__("shared_links_settings_copy_link")}
            </Style.CopyLink>
          </Style.LinkWrapper>

          <Style.Label>{__("link_for_websites", "Link for Websites")}</Style.Label>
          <Style.LinkWrapper>
            <Style.LinkInput defaultValue={linkForWeb} disabled />
            <Style.CopyLink onClick={() => copyLink(linkForWeb)}>
              {__("shared_links_settings_copy_link")}
            </Style.CopyLink>
          </Style.LinkWrapper>

          <Style.Label>{__("link_for_forums", "Link for Forums")}</Style.Label>
          <Style.LinkWrapper>
            <Style.LinkInput defaultValue={linkForForums} disabled />
            <Style.CopyLink onClick={() => copyLink(linkForForums)}>
              {__("shared_links_settings_copy_link")}
            </Style.CopyLink>
          </Style.LinkWrapper>
          {isImage(metadata) ? (
            <Style.LinkWrapper>
              <Style.LinkInput defaultValue={linkEmbedImg} disabled />
              <Style.CopyLink onClick={() => copyLink(linkEmbedImg)}>
                {__("shared_links_settings_copy_link")}
              </Style.CopyLink>
            </Style.LinkWrapper>
          ) : null}
          <Style.Footer>
            <Button color="lightgray4" onClick={handleClose}>
              {__("Close")}
            </Button>
          </Style.Footer>
        </Style.Container>
      </OvalModalWrap>
    </Modal>
  );
};

export default GetPublickLinkModal;
