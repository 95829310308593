// @flow

import * as React from "react";
import styled from "styled-components";
import { clearFix } from "polished";
import { connect } from "react-redux";

import { each, range } from "../../lib/utils";
import Step from "./Step";
import { stepTitles } from "./StepContent";
import StepPoint from "./StepPoint";
import { __ } from "../../lib/translate";
import { completeJourney } from "@pcloud/web-utilities/dist/lib/state/reducers/pcloud/userSlice";

import type { UserState } from "../../types/user";
import type { BonusStep } from "./types";
import {
  userJourneyMask,
  boolsJourneyStepsToMask,
  firstStepNotDone,
  nextStepNotDone,
  stepsMap,
  StepArr
} from "./misc";

//const ALL_DONE = 31;
const ALL_DONE = 63;

type BonusStepsProps = {
  user: UserState,
  onUploadFile: () => void,
  onVerifyMail: () => void,
  onResize: () => void
};

type BonusStepsState = {
  selected: BonusStep,
  expanded: boolean,
  //claimingreward: boolean,
  //openModal: boolean
};

class BonusSteps extends React.Component<BonusStepsProps, BonusStepsState> {
  constructor(props: BonusStepsProps) {
    super(props);

    this.state = {
      selected: 1,
      expanded: false,
      //claimingreward: false,
      //openModal: false
    };

    (this: any)._onExpandToggle = this._onExpandToggle.bind(this);
    //(this: any)._claimReward = this._claimReward.bind(this);
  }

  _onExpandToggle(nextStep?: BonusStep) {
    this.setState({ expanded: !this.state.expanded });
  }

  componentDidUpdate() {
    this.props.onResize();
  }

  componentDidMount() {
    if (this.props.user.logged) {
      const mask = userJourneyMask(this.props.user.userinfo);
      if ((1 & mask) === 0) {
        this.setState({ expanded: true });
      }
    }
  }

  /*_claimReward() {
    console.log("claim reward");

    if (this.props.user.logged) {
      const { token, userinfo } = this.props.user;
      const premium = userinfo.premium || userinfo.business || userinfo.vivapcloud;

      this.setState({ claimingreward: true });

      console.log("claiming");
      apiMethod("completejourney", { auth: token }, ret => {
        this.setState({ claimingreward: false });
        if (!premium) {
          this.setState({ openModal: true });
        }
      });
    }
  }*/

  /*_modal() {
    return <JourneyModal {...this.props} />;
  }*/

  render() {
    const { user, ...restProps } = this.props;

    if (!user.logged) {
      return null;
    }/* else if (this.state.openModal) {
      return this._modal();
    } else if (user.userinfo.journey.claimed) {
      return null;
    } */

    const mask = user && userJourneyMask(user.userinfo);
    const premium = user.userinfo.premium || user.userinfo.business || user.userinfo.vivapcloud;

    if (mask == ALL_DONE) {
      return null;
    } else if (
      new Date(user.userinfo.registered).getTime() <
      new Date("2017-10-30 11:00:00 GMT")
    ) {
      return null;
    } else if (premium) {
      return null;
    } else if (!user.userinfo.premium && user.userinfo.freequota == user.userinfo.quota) {
      return null;
    }

    //a

    //const mask = userJourneyMask(user.userinfo);
    const completed = mask === ALL_DONE;
    const noStepsCompleted = mask === 0 && 1;
    //const premium = user.userinfo.premium || user.userinfo.business || user.userinfo.vivapcloud;
    const { selected, expanded/*, claimingreward*/ } = this.state;
    const nextStep = firstStepNotDone(mask);
    const nextTitle = nextStep ? stepTitles[nextStep] : "";
    console.log("USER:", user);
    const nextStepNotCurrent = nextStep
      ? nextStepNotDone(mask, selected)
      : false;

    // selection to be showed
    const selection = completed ? "final" : expanded ? selected : false;

    return (
      <Wrap>
        <Holder>
          <Header>
            {mask != /*31*/ ALL_DONE && (
              <Toggle onClick={this._onExpandToggle}>
                {expanded ? __("action_hide") : __("action_show")}
              </Toggle>
            )}
            <StepsWrap>
              {StepArr.map((step: BonusStep) => (
                <StepPoint
                  key={step}
                  step={step}
                  completed={(Math.pow(2, step - 1) & mask) !== 0}
                  selected={!completed && expanded && step === selected}
                  onClick={() => {
                    if ((Math.pow(2, step - 1) & mask) !== 0 && 0) {
                      return;
                    }

                    this.setState({ selected: step });
                    //onStepClick(step);
                    if (!expanded) {
                      this.setState({ expanded: true });
                      //this._onExpandToggle();
                    }
                  }}
                  active={!completed}
                  expanded={expanded}
                />
              ))}
              {!premium}
            </StepsWrap>
            <TitleWrap>
              {(expanded || noStepsCompleted) &&
                !completed && (
                  <Title alone={true /* || expanded || completed*/}>
                    {premium
                      ? __(
                          "get_to_know_premium",
                          "Get to know pCloud by completing these 6 steps."
                        )
                      : __(
                          "complete_these_steps_unlock_space",
                          "Find more about pCloud! Complete these steps and unlock more space."
                        )}
                  </Title>
                )}
              {!expanded &&
                !completed &&
                !noStepsCompleted &&
                nextStep && (
                  <Subtitle
                    onClick={() =>
                      this.setState({ selected: nextStep, expanded: true })
                    }
                  >
                    <b>{__("next_step", "Next Step:")}</b> {__(nextTitle)}
                  </Subtitle>
                )}
            </TitleWrap>
          </Header>
          {/*completed && (
            <FinalStep
              step="final"
              claimingreward={claimingreward}
              premium={premium}
              onRewardClaim={this._claimReward}
              {...restProps}
            />
          )*/}
          {}
          {!completed &&
            expanded && (
              <Step
                step={selected}
                premium={premium}
                completed={(Math.pow(2, selected - 1) & mask) !== 0}
                onNextStep={
                  nextStepNotCurrent
                    ? () => this.setState({ selected: nextStepNotCurrent })
                    : undefined
                }
                {...restProps}
              />
            )}
        </Holder>
      </Wrap>
    );
  }
}

export default connect(
  state => ({ user: state.pCloudUser }),
  dispatch => ({ completeJourney: () => dispatch(completeJourney()) })
)(BonusSteps);

/*
export default connect(state => ({
  premium: state.user.userinfo.premium,
  token: state.user.token,
  mask: boolsJourneyStepsToMask(state.user.userinfo.journey.steps)
}))(BonusSteps);
*/

export { BonusSteps as Steps };

const Wrap = styled.div`
  background-repeat: repeat;
  background-image: url(${require("../../../root/img/journey/bg.png")});
  padding: 0;
  ${clearFix()};
`;

const Holder = styled.div`
  width: 900px;
  margin: 0 auto 0 auto;
`;

const Header = styled.div`
  width: 900px;
  height: 55px;
`;

const TitleWrap = styled.div`
  padding: 0;
`;

const Title = styled.div`
  color: #000;
  font-size: 13px;
  /*font-weight: bold;*/
  line-height: ${props => (props.alone ? 35 : 20)}px;
  line-height: 55px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const Subtitle = styled.div`
  line-height: 55px;
  color: #8d8d94;
  font-size: 13px;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  & > b {
    color: #000;
  }
`;

const StepsWrap = styled.div`
  margin-top: 10px;
  float: right;
  height: 35px;

  & > div {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 5px;
  }
`;

/*const FourG = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 100%;
  background: #61c100;
  color: #fff;
  font-weight: bold;
  text-align: center;
  line-height: 35px;
  font-size: 13px;
  margin: 0 0 0 10px !important;
`;*/

const Toggle = styled.div`
  color: #8d8d94;
  font-size: 11px;
  width: 35px;
  text-align: right;
  line-height: 55px;
  float: right;
  padding-left: 10px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
