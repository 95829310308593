// @flow

import React, { useState, useCallback, useRef, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { hiDPI } from "polished";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import MessageIcon from "../../../root/img/svg/message-icon.svg";
import UploadLimitIcon from "../../../root/img/svg/upload-limit-icon.svg";
import ExpirationIcon from "../../../root/img/svg/time-icon.svg";

import Modal, { OvalModalWrap, OvalModalCloseV2 } from "../../components/Modal";
import FileRequestModalHeader from "./FileRequestModalHeader";
import FileRequestMessage from "./FileRequestMessage";
import FileRequestUploadLimit from "./FileRequestUploadLimit";
import FileRequestExpirationDate from "./FileRequestExpirationDate";
import FileRequestDeleteLink from "./FileRequestDeleteLink";
import FileRequestStats from "./FileRequestStats";
import { SlideAnimationWrapper } from "./styledComponents";

import { __ } from "../../lib/translate";
import { formatDt } from "../../lib/utils";

import {
  defaultBoddyHeight,
  defaultSettingHeight,
  defaultHeaderHeight,
  defaultFooterHeight
} from "../ShareLinkSettings/DefaultValues";
import useWindowDimensions from "../../lib/hooks/useWindowDimensions";

const modalStyle = {
  width: "620px",
  overflow: "hidden",
  transition: "height 500ms ease-in-out",
  height: 0
};

type SettingsItem = {
  id: string,
  icon: Class<React$Component<any, any>>,
  title: string,
  description: string,
  status: string
};

type FolderType = {
  comments: number,
  created: string,
  folderid: number,
  icon: number,
  id: string,
  isfolder: boolean,
  ismine: boolean,
  isshared: boolean,
  modified: string,
  name: string,
  parentfolderid: number,
  thumb: boolean
};

type LinkDataType = {
  code: string,
  comment: string,
  expires: string,
  created: string,
  files: number,
  link: string,
  mail: string,
  modified: string,
  space: number,
  maxspace?: number,
  uploadlinkid: number,
  metadata: FolderType
};

type Props = {
  linkData: LinkDataType,
  onClose: () => void
};

const FileRequestModal = ({ onClose = () => {} }: Props) => {
  const linkData = useSelector(({ fileRequests }) => fileRequests.linkData);
  const { link: linkUrl, expires, maxspace, metadata } = linkData;
  const { name: linkName } = metadata;
  const [isOpen, setIsOpen] = useState(true);
  const [currentSettingId, setCurrentSettingId] = useState(null);

  const [bodyHeight, setBodyHeight] = useState(0);
  const [modalHeight, setModalHeight] = useState(0);

  const modalContainer = useRef(null);
  const settingElement = useRef(null);
  const { bodyWidth } = useWindowDimensions();

  useLayoutEffect(() => {
    let height = 0;
    let settingHeight = 0;

    if (settingElement.current) {
      settingHeight = settingElement.current.offsetHeight;
    }

    if (currentSettingId == null) {
      height = (bodyHeight || defaultBoddyHeight) + defaultHeaderHeight + defaultFooterHeight;
    } else {
      height = (settingHeight || defaultSettingHeight) + defaultHeaderHeight;
    }

    setModalHeight(height);
  }, [bodyHeight, currentSettingId]);

  const measuredBodyHeightRef = useCallback(node => {
    if (node !== null) {
      const newBodyHeight = node.getBoundingClientRect().height;
      setBodyHeight(newBodyHeight);
      setModalHeight(newBodyHeight + defaultHeaderHeight + defaultFooterHeight);
    }
  }, []);

  const handleClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }
    setIsOpen(false);
  };

  const getSettingsComponents = () => {
    const settingsComponents = {
      message: FileRequestMessage,
      uploadLimit: FileRequestUploadLimit,
      expdate: FileRequestExpirationDate,
      delete: FileRequestDeleteLink,
      stats: FileRequestStats
    };

    return settingsComponents;
  };

  const getSettingsItems = (): Array<SettingsItem> => {
    const settings = [
      {
        id: "message",
        icon: MessageIcon,
        title: __("Message"),
        description: __(
          "file_request_settings_modal_add_message_desc",
          "Add a message, which others will see, when they open the file request."
        ),
        status: ""
      },
      {
        id: "uploadLimit",
        icon: UploadLimitIcon,
        title: __("file_request_settings_modal_limit_title", "Upload limit"),
        description: __(
          "file_request_settings_modal_limit_desc",
          "Restrict how much users can upload from the file request."
        ),
        status: maxspace ? __("on", "On").toLowerCase() : __("off", "Off").toLowerCase()
      },
      {
        id: "expdate",
        icon: ExpirationIcon,
        title: __("link_settings_exp_date", "Expiration date"),
        description: __(
          "file_request_settings_modal_expdate_desc",
          "Once the file request expires, it will no longer be accessible."
        ),
        status: expires ? formatDt(expires) : __("off", "Off").toLowerCase()
      }
    ];

    return settings;
  };

  const onBackClick = () => {
    setCurrentSettingId(null);
  };

  const onRowClick = (e: any, id: number) => {
    setCurrentSettingId(id);
  };

  const allSettings = (
    <AllSettingsWraper onClick={onBackClick}>{__("shared_links_all_settings")}</AllSettingsWraper>
  );

  const setOverflow = value => {
    modalContainer.current.style.overflow = value;
  };

  const rednerRow = ({ icon, title, description, status, id }: SettingsItem) => {
    const Icon = icon;

    return (
      <Row key={title} onClick={e => onRowClick(e, id)}>
        <Icon />
        <TextWrapper>
          <RowTitle>{title}</RowTitle>
          <RowDescription>{description}</RowDescription>
        </TextWrapper>
        <Status>
          <StatusText>{status}</StatusText>
          <Arrow />
        </Status>
      </Row>
    );
  };

  const renderBody = () => {
    const settingsItems = getSettingsItems();
    return <div ref={measuredBodyHeightRef}>{settingsItems.map(rednerRow)}</div>;
  };

  const renderFooter = () => {
    return (
      <Footer>
        <DeleteLink
          onClick={() => {
            setCurrentSettingId("delete");
          }}
        >
          {__("file_request_settings_modal_delete", "Delete file request")}
        </DeleteLink>
        <RightWrapper>
          <LinkStats
            onClick={() => {
              setCurrentSettingId("stats");
            }}
          >
            {__("file_request_settings_modal_stats", "Stats")}
          </LinkStats>
        </RightWrapper>
      </Footer>
    );
  };

  const renderCurrentSetting = () => {
    const settings = getSettingsComponents();
    const CurrentSettingComponent = settings[currentSettingId];
    const params = {
      allSettingsBack: allSettings,
      bodyHeight: bodyHeight,
      parentRef: settingElement,
      setModalHeight: setModalHeight
    };

    return <CurrentSettingComponent {...params} />;
  };

  if (bodyWidth < 800) {
    modalStyle.width = '95%';
  }

  return (
    <Modal align="top" onClose={handleClose} opened={isOpen}>
      <OvalModalWrap
        style={{ ...modalStyle, height: modalHeight + "px" }}
        ref={modalContainer}
      >
        <OvalModalCloseV2 onClick={handleClose} />
        <FileRequestModalHeader linkUrl={linkUrl} linkName={linkName} />
        <SlideAnimationWrapper>
          <TransitionGroup>
            <CSSTransition
              key={currentSettingId}
              addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
              classNames="slide"
              onEnter={() => setOverflow("hidden")}
              onExited={() => setOverflow("visible")}
            >
              {currentSettingId !== null ? renderCurrentSetting() : <div />}
            </CSSTransition>
          </TransitionGroup>
        </SlideAnimationWrapper>
        {renderBody()}
        {renderFooter()}
      </OvalModalWrap>
    </Modal>
  );
};

export default FileRequestModal;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 73px;
  padding: 20px;
  box-sizing: border-box;
  border-top: 1px solid #f2f2f2;
  cursor: pointer;

  svg {
    margin-right: 20px;
    
    @media only screen and (max-width: 450px) {
      display: none;
    }
  }

  &:last-child {
    border-bottom: 1px solid #f2f2f2;
  }

  &:hover {
    background-color: #fafafa;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: left;
`;

const RowTitle = styled.div`
  font-size: 15px;
  font-weight: 500;
`;

const RowDescription = styled.div`
  font-size: 13px;
  color: #888888;
  font-weight: normal;
  margin-top: 3px;
`;

const Status = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const StatusText = styled.div`
  font-size: 15px;
  line-height: 30px;
  color: #888888;
  text-transform: capitalize;
`;

const Arrow = styled.div`
  display: inline-block;
  margin-left: 10px;
  width: 6px;
  height: 10px;
  cursor: pointer;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  background-image: url(${require("../../../root/img/share-link/next.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/share-link/next@2x.png")});
  }
`;

const Footer = styled.div`
  display: flex;
  height: 47px;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`;

const LinkFooter = styled.div`
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const DeleteLink = styled(LinkFooter)`
  color: #ff4c4c;
`;

const LinkStats = styled(LinkFooter)`
  color: #17bed0;
`;

const RightWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const AllSettingsWraper = styled.div`
  margin-top: 20px;
  margin-left: 20px;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  line-height: 1.2;
  letter-spacing: -0.23px;
  text-align: left;
  color: #000000;
  cursor: pointer;

  &::before {
    content: "";
    display: inline-block;
    width: 6px;
    height: 6px;
    border-bottom: 1.3px solid #3b3b3b;
    border-left: 1.3px solid #3b3b3b;
    transform: rotate(45deg);
    margin-right: 8px;
    position: relative;
    top: -2px;
  }
`;
