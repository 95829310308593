import styled, { keyframes, css } from "styled-components";
import { hiDPI } from "polished";
import loadingBlue from "../../root/img/loading_blue.png";
import familyImg from "../../root/img/data-location/family.png";
import familyImg2x from "../../root/img/data-location/family@2x.png";
import businessImg from "../../root/img/data-location/business.png";
import businessImg2x from "../../root/img/data-location/business@2x.png";
import inviteToImg from "../../root/img/data-location/invite-to.png";
import inviteToImg2x from "../../root/img/data-location/invite-to@2x.png";
import attentionImg from "../../root/img/data-location/attention.png";
import attentionImg2x from "../../root/img/data-location/attention@2x.png";
import closeImg from "../../root/img/2fa-login/close.png";
import closeImg2x from "../../root/img/2fa-login/close@2x.png";

const shake = keyframes`
  10%, 90% { transform: translate3d(-1px, 0, 0); }
  20%, 80% { transform: translate3d(2px, 0, 0); }
  30%, 50%, 70% { transform: translate3d(-4px, 0, 0); }
  40%, 60% { transform: translate3d(4px, 0, 0); }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const HeaderLogoContainer = styled.div`
  margin-bottom: var(--spacing-2xl);
  height: 36px;
`;

export const WrapperFolderInvite = styled.div`
  width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Title = styled.div`
  margin-bottom: var(--spacing-md);
  font-size: 26px;
  font-weight: bold;
  line-height: 36px;
  width: 360px;
  text-align: left;
  color: var(--color-base-black);

  &.modal {
    margin: 30px 0;
  }

  &.before-continue {
    margin: 30px auto;
    text-align: center;
  }

  @media (max-width: 500px) {
    width: 100%;
    margin: 20px 0 var(--spacing-md) 0;
    box-sizing: border-box;
  }
`;

export const Container = styled.div`
  margin: auto;
  padding: var(--spacing-md) var(--spacing-lg);
  
  @media screen and (max-width: 910px) {
    min-width: 0px;
    max-width: 400px;
    padding: var(--spacing-xl) var(--spacing-md);
  }
`;

export const Footer = styled.div`
  display: flex;
  margin-top: 30px;
  padding: 0;
  width: 360px;

  &.single {
    display: block;
  }

  a {
    display: flex;
    width: 100%;
    max-width: 100%;
    font-size: 15px;
    font-weight: normal;
    justify-content: center;
  }

  @media (max-width: 480px) {
    width: 100%;
    flex-direction: column;

    a {
      margin-left: 0 !important;
      margin-right: 0;
    }

    a:first-child {
      margin-bottom: 10px;
    }
  }
`;

export const FormLabel = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 32px;
  width: 360px;
  text-align: left;
  color: var(--color-base-black);
  margin-bottom: var(--spacing-md);
`;

export const Message = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  max-width: 360px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 8px;
  border: solid 1px var(--color-primary-300);
  background-color: var(--color-primary-50);
  font-size: 15px;
  line-height: 1.2;
  text-align: left;
  color: rgba(0, 0, 0, 0.75);
  word-break: break-word;
  margin: 0 auto 15px auto;

  svg {
    margin-right: 20px;
    flex-shrink: 0;
  }

  & > span {
    display: block;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
  }

  &.modal {
    margin: 0 40px 30px;
    border-radius: 0;
  }

  @media (max-width: 768px) {
    padding: 20px;
    &.modal {
      margin: 0 10px 30px;
    }
  }
`;

export const WarningMessage = styled(Message)`
  border: solid 1px var(--color-functional-warning200);
  background-color: var(--color-functional-warning100);
`;

export const Description = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 1.23;
  text-align: center;
  margin-top: 35px;
`;

export const FamilyIcon = styled.div`
  display: block;
  min-width: 64px;
  min-height: 64px;
  margin-right: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${familyImg});
  
  ${hiDPI(1.5)} {
    background-image: url(${familyImg2x});
  }
`;

export const BusinessIcon = styled(FamilyIcon)`
  background-image: url(${businessImg});
  
  ${hiDPI(1.5)} {
    background-image: url(${businessImg2x});
  }
`;

export const FolderInviteIcon = styled(FamilyIcon)`
  background-image: url(${inviteToImg});
  
  ${hiDPI(1.5)} {
    background-image: url(${inviteToImg2x});
  }
`;

export const WarnIcon = styled(FamilyIcon)`
  background-image: url(${attentionImg});
  
  ${hiDPI(1.5)} {
    background-image: url(${attentionImg2x});
  }

  &.modal {
    margin: 0;
  }
`;

export const ButtonContainerInline = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  a {
    width: 40%;
    max-width: 180px;
    display: inline-block;
    margin: 0px 5px;
    color: var(--color-base-white);
    text-decoration: none;
  }

  &.modal {
    margin: 0 0 30px 0;
  }
`;

export const Link = styled.div`
  max-width: 640px;
  margin-top: var(--spacing-lg);
  font-size: 18px;
  line-height: 1.23;
  color: var(--color-primary-500);
  cursor: pointer;
  text-decoration: none;
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  width: 50%;
  height: 50%;
  background-color: var(--color-base-white);
  z-index: 1;

  div {
    display: inline-block;
    height: 48px;
    width: 48px;
    background-image: url(${loadingBlue});
    background-position: center;
    background-size: 48px 48px;
    position: relative;
    top: 50%;
    margin-top: -24px;
    left: 50%;
    margin-left: -24px;
    animation: ${spin} 500ms linear infinite;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;

  form {
    margin-top: 15px;
  }

  .responsive-wrap {
    width: 450px;
  }

  &.family-invitation-reg-wrapper,
  &.business-invitation-reg-wrapper {
    .input-pass-wrapper {
      margin-bottom: 15px;

      input.input-pass {
        margin-bottom: 0;
      }
    }
  }
`;

export const ContainerCol = styled.div`
  align-items: center;
`;

export const ContainerColIE = styled.div`
  align-items: center;
`;

export const ButtonBlue = styled.div`
  display: inline-block;
  min-width: 100px;
  max-width: 140px;
  margin: 0px 5px;
  background-color: var(--color-primary-500);
  padding: 0 20px;
  box-sizing: border-box;
  font-weight: 600;
  font-size: 13px;
  text-align: center;
  cursor: pointer;
  line-height: 40px;
  border-radius: 5px;
  height: 40px;
  color: var(--color-base-white);
`;

export const ButtonGrey = styled(ButtonBlue)`
  background-color: var(--color-grey-600);
`;

export const ComponentifyWrapper = styled.div`

  &.modal {
    margin-top: 20px;
    text-align: center;
  }
  
  & span {
    word-wrap: break-word;
  }
  
  @media screen and (max-width: 910px) {
    max-width: 300px;
  }

  a {
    color: var(--color-primary-500);
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  width: 26px;
  height: 26px;
  margin: 12px;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${closeImg});

  ${hiDPI(1.5)} {
    background-image: url(${closeImg2x});
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
`