import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled, { createGlobalStyle, css, keyframes } from 'styled-components';
import { __ } from '../../lib/translate';

import useRightSectionSlideAnimationManager from '../ContentView/hooks/useRightSectionSlideAnimationManager';
import ItemsOptions from './ItemsOptions';
import CommentsContainer from "../CommentsContainer/CommentsContainer";
import { getIconType } from "../../lib/utils";
import { getThumbInfo, getIconInfo } from "../../lib/icons";
import FileIcon from "../FileIcon";
import { getItemClickActionAndLabel } from "../ContentView/ContentData"; 
import useWindowDimensions from '../../lib/hooks/useWindowDimensions';
import ItemsInfo from './ItemsInfo';
import { useSelector } from "react-redux";
import { OPTIONS } from "../../config/options";
import { getContentOptions } from "../../lib/utils";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled as MUIStyled } from '@mui/material/styles';

import { CSSTransition, TransitionGroup } from "react-transition-group";
import { PLAY } from '../../config/constants';

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  minHeight: "40px !important",
  '& .MuiTabs-flexContainer': {
    gap: "10px"
  },
  '& .MuiTabs-indicator': {
    backgroundColor: "var(--color-primary-500)",
    borderRadius: "8px"
  },
});

const StyledTab = MUIStyled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    fontWeight: "500",
    fontSize: "13px",
    minHeight: "40px",

    color: "var(--color-grey-800, #494F55FF)",
    fontFamily: 'Roboto',
    padding: "var(--spacing-none, 0px) var(--spacing-md, 16px)",

    '&.Mui-selected': {
      color: "var(--text-primary, #282C2F)",
      fontWeight: "500",
      cursor: "default",
    },
    '&.Mui-focusVisible': {
      backgroundColor: "transparent",
    },
  }),
);

type Props = {
  isOpened: boolean,
  item: {
    id: string,
    fileid: number,
    name: string,
    category: number,
    thumb: boolean,
    width: number,
    height: number,
    contenttype: string,
    size: number,
    created: string,
    modified: string,
    exifdatetime: number,
    hash: number,
    comments: number,
    isshared: boolean,
    ismine: boolean,
    icon: number,
    parentfolderid: number,
  } | {
    id: string,
    folderid: number,
    name: string,
    ismine: boolean,
    thumb: string,
    created: string,
    modified: string,
    comments: number,
    isshared: boolean,
    icon: number,
    parentfolderid: number
  },
  selectedFiles: Map,
  selectedFolders: Map,
  onCloseItemsInfo: () => void,
  onOpenFolderPreview: () => void,
  selectedTab: "info" | "comments",
  onTabChange: (tab: "info" | "comments") => void,
  autoFocusAddComment: {value: boolean},
  isMobileView: boolean,
  isBusiness: boolean,
  animateItemIconZoomInOut: boolean,
  setAnimateItemIconZoomInOut: (animateItemIconZoomInOut: boolean) => void
}

const ItemsInfoContainer = ({
  containerId = "items-info",
  isOpened = true,
  item = null,
  selectedFiles = new Map(),
  selectedFolders = new Map(),
  onCloseItemsInfo = () => {},
  onOpenFolderPreview = () => {},
  selectedTab = "info",
  onTabChange = () => {},
  autoFocusAddComment = false,
  isMobileView = false,
  isBusiness = false,
  contentType = "browse",
  animateItemIconZoomInOut = false,
  setAnimateItemIconZoomInOut = () => {}
}: Props) => {

  const ALLOWED_ICONS_ROTATE_DEGREES = [0, -10, 10, 18];
  const TOGGLE_TAB_ANIMATION_DURATION = 500;
  const [disableToggleTabAnimation, setDisableToggleTabAnimation] = useState(false);
  const disableToggleTabTimeout = useRef();

  const [onAnimationItemIconZoomInOutEnd, setOnAnimationItemIconZoomInOutEnd] = useState({value: false});

  const cryptoV2IsActive = useSelector(({ pCloudUser }) => pCloudUser.userinfo.cryptov2isactive);
  const isCollaborationEnabled = useSelector(({ pCloudUser }) => pCloudUser.collaboration || false);

  const { bodyHeight } = useWindowDimensions();
  const MOBILE_HEIGHT_BREAKPOINT = 545;
  const isMobileHeightView = bodyHeight <= MOBILE_HEIGHT_BREAKPOINT;

  const isDlink = HFN.config.isDlink();

  const containerRef = useRef();
  const itemsInfoTabContentRef = useRef();
  const commentsTabContentRef = useRef();

  const otherRightSectionId = "upload-section";

  const stickyHeight = useMemo(() => document.querySelector('.trialinfo-bar-holder')?.offsetHeight || 0, []);

  const currentSelectionRotateDegrees = useRef(new Map());

  useRightSectionSlideAnimationManager({
    containerRef,
    currentRightSectionId: containerId,
    otherRightSectionId,
    isOpened,
    isToggled: false,
    closeRightSection: onCloseItemsInfo
  });

  const { itemIconType, itemsInfoPreviewData, itemName } = useMemo(() => { 
    return {
      itemsInfoPreviewData: item ? getItemClickActionAndLabel(item, "item info main action", {[HFN.CATEGORY.ARCHIVE]: true}) : null,
      itemIconType: item ? getIconType(item) : null,
      // Decrypt item.name if we are in crypto folder.
      itemName: item ? (HFN.metaName(item) || "") : ""
    }
  }, [item]);

  const fileIconData = useMemo(() => {
    return {
      thumbInfo: getThumbInfo(HFN.ICONS.SVG_ITEM_PREVIEW),
      iconInfo: getIconInfo(undefined, HFN.ICONS.SVG_ITEM_PREVIEW),
      thumbInfoM: getThumbInfo(HFN.ICONS.SVG_LIST),
      iconInfoM: getIconInfo(undefined, HFN.ICONS.SVG_LIST),
      loadingIcon: {
        item: HFN.ICO.LOADING,
        type: HFN.ICONS.SVG_LIST
      } 
    }
  }, []);

  const currentSelection = useMemo(() => {
    if (item) {
      // Single selection -> cleanup previous selection rotate degrees.
      currentSelectionRotateDegrees.current = new Map();
      return [];
    }

    const newSelection = [...selectedFiles.values(), ...selectedFolders.values()];
    
    if (newSelection.length === 0) {
      // Cleanup previous selection rotate degrees.
      currentSelectionRotateDegrees.current = new Map();
    }

    if (selectedFiles.size > 0 && selectedFolders.size > 0) {
      // We need to sort them by selectionIndex property, because it gives the right selection sequence.
      newSelection.sort((a, b) => typeof a.selectionIndex !== "undefined" && typeof b.selectionIndex !== "undefined" ? (a.selectionIndex > b.selectionIndex) - (a.selectionIndex < b.selectionIndex) : 0);
    }

    return newSelection;
  }, [selectedFolders, selectedFiles]);

  const multipleSelectionMainAction = useMemo(() => {
    return currentSelection.length > 1 ? (getContentOptions({
      content: [...selectedFolders.values(), ...selectedFiles.values()],
      isBusiness: isBusiness,
      cryptoV2IsActive: cryptoV2IsActive,
      isCollaborationEnabled: isCollaborationEnabled,
      maxMainOptionsCnt: 1,
      maxSecondaryOptionsCnt: 0,
      contentType: contentType
    }))?.main?.[0] : {};
  }, [currentSelection, isBusiness, cryptoV2IsActive, isCollaborationEnabled]);

  useEffect(() => {
    return () => {
      if (disableToggleTabTimeout.current) {
        clearTimeout(disableToggleTabTimeout.current);
      }
    }
  }, []);

  useEffect(() => {
    if (!isOpened) {
      // On closed right panel.
      setDisableToggleTabAnimation(true);
    } else {
      // Add 500ms delay before toggle tab animation to be enabled, 
      // because we don't want to see such animation while the righ panel is opening.
      if (disableToggleTabTimeout.current) {
        clearTimeout(disableToggleTabTimeout.current);
      }
      disableToggleTabTimeout.current = setTimeout(() => setDisableToggleTabAnimation(false), 500);//#content-right-section-inner -> transition: width 0.5s ease-in-out;
    }
  }, [isOpened]);

  useEffect(() => {
    setAnimateItemIconZoomInOut(false);
  }, [onAnimationItemIconZoomInOutEnd]);

  /**
   * Single selection only. 
   * */
  const onPreviewButtonClick = (event) => {
    if (item.isfolder) {
      if (isMobileView) {
        onCloseItemsInfo();
      } else {
        onOpenFolderPreview();
      }
    } else if (isMobileView && item.category === HFN.CATEGORY.AUDIO && itemsInfoPreviewData.type === PLAY) {
      onCloseItemsInfo();
    }
    // const hashParams = !isMobileView ? { details: "open" } : null;
    itemsInfoPreviewData.onClick(event, item, null, itemsInfoPreviewData.googleAnalyticsAction);
  }

  const renderFileIconsOnMultipleSelection = () => {
    if (currentSelection.length <= 1) {
      return null;
    }

    
    const itemsForOut = [];
    let countdownIter = ALLOWED_ICONS_ROTATE_DEGREES.length;

    const freeRotateDegrees = new Set(ALLOWED_ICONS_ROTATE_DEGREES);

    for (let i = currentSelection.length - 1; i >= 0; i--) {
      if (countdownIter === 0) {
        break;
      }
      countdownIter--;

      if (typeof currentSelectionRotateDegrees.current.get(currentSelection[i].id) !== "undefined") {
        // Remove this reserved rotate degree.
        freeRotateDegrees.delete(currentSelectionRotateDegrees.current.get(currentSelection[i].id));
      }

      itemsForOut[countdownIter] = currentSelection[i];
    }

    const fileIconCompsForOut = [];
    const newSelectionRotateDegrees = new Map();
    let currentZIndex = 1;

    itemsForOut.forEach((itemData) => {
      if (typeof currentSelectionRotateDegrees.current.get(itemData.id) === "undefined") {
        for (let rotateDegree of freeRotateDegrees) {
          currentSelectionRotateDegrees.current.set(itemData.id, rotateDegree);
          freeRotateDegrees.delete(rotateDegree);
          break;
        }
      }

      const itemDataIconType = getIconType(itemData);

      newSelectionRotateDegrees.set(itemData.id, currentSelectionRotateDegrees.current.get(itemData.id));
      
      fileIconCompsForOut.push(<FileIconWrapper 
          className="file-icon-wrapper multiple" 
          $mobileHeightBreakpoint={MOBILE_HEIGHT_BREAKPOINT + "px"} 
          key={itemData.id + "_multiple_" + (isMobileHeightView ? "mobile" : "")} 
          $thumbWidth={fileIconData.thumbInfo.width} 
          $thumbHeight={fileIconData.thumbInfo.height} 
          $iconWidth={fileIconData.iconInfo.width} 
          $iconHeight={fileIconData.iconInfo.height} 
          $thumbWidthM={fileIconData.thumbInfoM.width} 
          $thumbHeightM={fileIconData.thumbInfoM.height} 
          $iconWidthM={fileIconData.iconInfoM.width} 
          $iconHeightM={fileIconData.iconInfoM.height}
          style={{transform: `rotate(${currentSelectionRotateDegrees.current.get(itemData.id) || 0}deg)`, zIndex: currentZIndex++}}
        >
          <FileIcon 
            item={itemDataIconType} 
            type={!isMobileHeightView ? HFN.ICONS.SVG_ITEM_PREVIEW : HFN.ICONS.SVG_LIST} 
            metadata={itemData} 
            thumbOptions={{crop: !isMobileHeightView ? 0 : 1}}
            loadingItem={fileIconData.loadingIcon.item}
            loadingType={fileIconData.loadingIcon.type}
           />
        </FileIconWrapper>
      );
    });

    // Update currentSelectionRotateDegrees to remove those who were removed from the 4 items set.
    currentSelectionRotateDegrees.current = newSelectionRotateDegrees;

    return fileIconCompsForOut;
  }

  const renderContent = () => {
    return (
      <ItemsInfoWrapper
        ref={containerRef}
        className="items-info-wrapper fixed-content"
        $isClosed={!isOpened}
      >
        <TopContainer>
          <HeaderText $mobileHeightBreakpoint={MOBILE_HEIGHT_BREAKPOINT + "px"}>
            <span>{(selectedFiles.size + selectedFolders.size < 1 || isMobileView) && item && itemName !== "" ? itemName : (selectedFiles.size + selectedFolders.size > 0 ? (selectedFiles.size + selectedFolders.size) + " " + __((selectedFiles.size + selectedFolders.size) === 1 ? "selected_one" : "selected_multiple") : null)}</span>
            <span className="mobile-height-only">
              {selectedFiles.size + selectedFolders.size > 0 ? ((selectedFiles.size + selectedFolders.size) + " " + __((selectedFiles.size + selectedFolders.size) === 1 ? "selected_one" : "selected_multiple")) : null}
            </span>
          </HeaderText>
          <IconsContainer onClick={onCloseItemsInfo}>
            <i className="mediumIcon lightColorIcon fa-regular fa-chevron-right"/>
          </IconsContainer>
        </TopContainer>

        <MainContainer $mobileHeightBreakpoint={MOBILE_HEIGHT_BREAKPOINT + "px"}>
                   
          <ItemPreviewWrapper $mobileHeightBreakpoint={MOBILE_HEIGHT_BREAKPOINT + "px"}>
            {
              // Single selection
              item ? 
                <React.Fragment>
                  <FileIconContainer 
                    $mobileHeightBreakpoint={MOBILE_HEIGHT_BREAKPOINT + "px"} 
                    $thumbHeight={fileIconData.thumbInfo.height} 
                    $iconHeight={fileIconData.iconInfo.height} 
                    $iconWidthM={fileIconData.iconInfoM.width} 
                    $iconHeightM={fileIconData.iconInfoM.height}
                    $itemNameVisible={itemName && !isMobileView}
                  >
                    <FileIconWrapper className="file-icon-wrapper" 
                      $mobileHeightBreakpoint={MOBILE_HEIGHT_BREAKPOINT + "px"} 
                      key={item.id + (animateItemIconZoomInOut ? "-animated" : "") + "_" + (isMobileHeightView ? "mobile" : "")} 
                      $thumbWidth={fileIconData.thumbInfo.width} 
                      $thumbHeight={fileIconData.thumbInfo.height} 
                      $iconWidth={fileIconData.iconInfo.width} 
                      $iconHeight={fileIconData.iconInfo.height} 
                      $thumbWidthM={fileIconData.thumbInfoM.width} 
                      $thumbHeightM={fileIconData.thumbInfoM.height} 
                      $iconWidthM={fileIconData.iconInfoM.width} 
                      $iconHeightM={fileIconData.iconInfoM.height}
                      $animateItemIconZoomInOut={animateItemIconZoomInOut}
                      onAnimationEnd={(e) => setOnAnimationItemIconZoomInOutEnd({value: true})}
                    >
                      <FileIcon 
                        item={itemIconType} 
                        type={!isMobileHeightView ? HFN.ICONS.SVG_ITEM_PREVIEW : HFN.ICONS.SVG_LIST} 
                        metadata={item} 
                        thumbOptions={{crop: !isMobileHeightView ? 0 : 1}}
                      />
                    </FileIconWrapper>
                  </FileIconContainer>
                  
                  {selectedFiles.size + selectedFolders.size == 1 && itemName !== "" ?
                    <ItemNameLabel $mobileHeightBreakpoint={MOBILE_HEIGHT_BREAKPOINT + "px"}>{itemName}</ItemNameLabel>
                  : null}
                  
                  <ItemPreviewActionButton $mobileHeightBreakpoint={MOBILE_HEIGHT_BREAKPOINT + "px"} onClick={onPreviewButtonClick}>{__(itemsInfoPreviewData.label)}</ItemPreviewActionButton>
                </React.Fragment>
              : (
                // Multiple selection
                selectedFiles.size + selectedFolders.size > 1 ? 
                  <React.Fragment>
                    <FileIconContainer className="multiple" 
                      $mobileHeightBreakpoint={MOBILE_HEIGHT_BREAKPOINT + "px"} 
                      $thumbHeight={fileIconData.thumbInfo.height} 
                      $iconHeight={fileIconData.iconInfo.height} 
                      $iconWidthM={fileIconData.iconInfoM.width} 
                      $iconHeightM={fileIconData.iconInfoM.height}
                      $itemNameVisible={true}
                    >
                      {renderFileIconsOnMultipleSelection()}
                    </FileIconContainer>

                    <ItemNameLabel className="multiple" $mobileHeightBreakpoint={MOBILE_HEIGHT_BREAKPOINT + "px"}>{__("multiple_selection", "Multiple selection")}</ItemNameLabel>

                    {multipleSelectionMainAction ? 
                      <ItemPreviewActionButton 
                        $mobileHeightBreakpoint={MOBILE_HEIGHT_BREAKPOINT + "px"} 
                        onClick={() => OPTIONS[multipleSelectionMainAction].onClick(currentSelection, null, "items info multiple selection main action")}>
                          {__(...OPTIONS[multipleSelectionMainAction].optionTitle)}
                      </ItemPreviewActionButton>
                    : null}
                  </React.Fragment>
                : null)
            }
          </ItemPreviewWrapper>

          {!isMobileView && isBusiness ? 
            <TabsWrapper>
              <StyledTabs
                value={selectedTab}
                onChange={(e, clickedTab) => onTabChange(clickedTab)}
              >
                  <StyledTab label={__("Information", "Information")} value="info" />
                  <StyledTab label={__("Comments", "Comments")} value="comments" />
              </StyledTabs>
            </TabsWrapper>
          : null}
              
          <SlideAnimationWrapper $animationDuration={TOGGLE_TAB_ANIMATION_DURATION + "ms"} $disableToggleTabAnimation={disableToggleTabAnimation}>
            <TransitionGroup component={null}>
                
              {selectedTab === "info" ? 
                <CSSTransition
                  key="info-tab"
                  timeout={TOGGLE_TAB_ANIMATION_DURATION}
                  classNames="slide-left"
                  nodeRef={itemsInfoTabContentRef}
                >
                  <ItemsInfoTabContent className="tab-content" ref={itemsInfoTabContentRef}>
                    <ItemsInfo item={item} />

                    {selectedFiles.size > 0 || selectedFolders.size > 0 || item ?
                      <ItemOptionsWrapper>
                        <ItemsOptions 
                          selectedFolders={selectedFolders.size === 0 && item && item.isfolder ? new Map([[item.id, item]]) : selectedFolders}
                          selectedFiles={selectedFiles.size === 0 && item && !item.isfolder ? new Map([[item.id, item]]) : selectedFiles}
                          forceOptionsAsSecondary={item && itemsInfoPreviewData.type ? new Set([itemsInfoPreviewData.type]) : (selectedFiles.size + selectedFolders.size > 1 && multipleSelectionMainAction ? new Set([multipleSelectionMainAction]) : null)}
                        />
                      </ItemOptionsWrapper>
                    : null}
                  </ItemsInfoTabContent>
                </CSSTransition>
              : null}

              {selectedTab === "comments" ? 
                <CSSTransition
                  key="comments-tab"
                  timeout={TOGGLE_TAB_ANIMATION_DURATION}
                  classNames="slide-right"
                  nodeRef={commentsTabContentRef}
                >
                  <CommentsTabContent className="tab-content" ref={commentsTabContentRef}>
                    <CommentsContainer
                      contentItemData={item}
                      isVisible={isOpened}
                      autoFocusAddComment={autoFocusAddComment}
                    />
                  </CommentsTabContent>
                </CSSTransition>
              : null}
                
              
            </TransitionGroup>
          </SlideAnimationWrapper>
          
        </MainContainer>
      </ItemsInfoWrapper>
    );
  };


  if (!isDlink) {  
    return (
      <React.Fragment>
        <GlobalStyle $isClosed={!isOpened} $otherRightSectionId={otherRightSectionId} $stickyHeight={stickyHeight} />
        {renderContent()}
      </React.Fragment>
    )
  }

  return null;
};

export default ItemsInfoContainer;

const GlobalStyle = createGlobalStyle`
  ${({ $isClosed, $otherRightSectionId, $stickyHeight }) =>
        css`
            html {
              --items-info-content-width: ${$isClosed ? "0px !important" : "var(--right-content-width, 400px) !important"};
              #content-right-section-inner.opened-${$otherRightSectionId} {
                .items-info-wrapper {
                  transition: right 0.5s ease-in-out;
                  right: ${$isClosed ? 'calc(0px - var(--right-content-width, 400px))' : '0'};
                }
              }

              @media (max-width: 1023px) {
                --items-info-content-right-mobile: ${$isClosed ? '-100%!important' : '0 !important'};
                #content-right-section-inner {
                  width: ${$isClosed ? '0px !important' : 'inherit !important'}
                }

                &.sticky-business {
                  #content-right-section {
                    height: calc(100dvh - ${(64 + $stickyHeight) + "px"}) !important;
                  }
                }
              }
            }
        `}
`;

const ItemsInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  overflow: hidden;
  position: relative;
  height: 100%;
  width: var(--right-content-width, 400px) - var(--right-content-border-width, 1px));
  border-radius: var(--radius-rounded, 16px) 0px 0px 0px;
  border-top: 1px solid var(--divider-primary);
  border-left: ${({ $isClosed }) => ($isClosed ? "none" : "var(--right-content-border-width, 1px) solid var(--divider-primary)")};
  background: #fff;
  box-sizing: border-box;

  @media (max-width: 1023px) {
    width: 100%;
    border: none;
    border-radius: 0;
  }
`;


const TopContainer = styled.div`
    background: var(--surface-primary, #F4F5F6);
    padding: var(--spacing-xs, 4px) var(--spacing-none, 0px) var(--spacing-xs, 4px) 16px;
    display: flex;
    justify-content: space-between;
    height: 49px;
    align-items: center;
    box-sizing: border-box;
    width: calc(var(--right-content-width, 400px) - var(--right-content-border-width, 1px));
    gap: 8px;
    @media (max-width: 1023px) {
      width: 100%;
      align-self: stretch;
    }
`;

const HeaderText = styled.p`
    color: var(--text-primary, #282C2F);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: inline;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    
    .mobile-height-only {
      display: none;
    }

    @media (max-height: ${({$mobileHeightBreakpoint}) => $mobileHeightBreakpoint ? $mobileHeightBreakpoint : "620px"}) {
      span:not(.mobile-height-only) {
        display: none;
      }
      
      .mobile-height-only {
        display: block;
      }
    }
`;

const IconsContainer = styled.div`
    display: flex;
    align-items: center;
    width: 40px;
    height: 40px;
    justify-content: center;
    cursor: pointer;
    flex-shrink: 0;
`;

const MainContainer = styled.div`
  display: flex;
  height: 100%;
  padding: var(--spacing-md, 16px) var(--spacing-none, 0px);
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  overflow-x: auto;
  overflow-y: hidden;
  width: calc(var(--right-content-width, 400px) - var(--right-content-border-width, 1px));
  
  @media (max-width: 1023px) {
    width: 100%;
  }

  @media (max-height: ${({$mobileHeightBreakpoint}) => $mobileHeightBreakpoint ? $mobileHeightBreakpoint : "620px"}) {
    padding: var(--spacing-sm, 8px) var(--spacing-none, 0px) var(--spacing-md, 16px) var(--spacing-none, 0px);
  }
`;

const SlideAnimationWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;

  .tab-content {
    position: absolute;
    left: 0;
    min-width: 100%;
    width: 100%;
  }

  .slide-left-enter {
    left: -100%;
  }
  .slide-left-exit {
    left: 0;
  }
  .slide-left-enter-active {
    left: 0;
  }
  .slide-left-exit-active {
    left: -100%;
  }
  
  .slide-right-enter {
    left: 100%;
  }
  .slide-right-exit {
    left: 0;
  }
  .slide-right-enter-active {
    left: 0;
  }
  .slide-right-exit-active {
    left: 100%;
  }

  .slide-left-enter-active,
  .slide-right-enter-active,
  .slide-left-exit-active,
  .slide-right-exit-active {
    transition: ${({$disableToggleTabAnimation, $animationDuration}) => $disableToggleTabAnimation ? "none" : `left ${$animationDuration ?? "250ms"} ease-in-out`};
  }
}
`;
const ItemPreviewWrapper = styled.div`
  display: flex;
  padding: var(--spacing-sm) 16px var(--spacing-sm, 8px) 16px;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-sm);
  align-self: stretch;
  min-height: 198px;
  box-sizing: border-box;
  justify-content: space-between;

  @media (max-height: ${({$mobileHeightBreakpoint}) => $mobileHeightBreakpoint ? $mobileHeightBreakpoint : "620px"}) {
    flex-direction: row;
    min-height: auto;
    justify-content: flex-start;
  }
`;

const FileIconContainer = styled.div`
  --thumb-border-width: 1px;
  min-height: calc(${({$thumbHeight}) => parseInt($thumbHeight) + "px" ?? "104px"} + (var(--thumb-border-width) * 2));  

  @media (max-height: ${({$mobileHeightBreakpoint}) => $mobileHeightBreakpoint ? $mobileHeightBreakpoint : "620px"}) {
    min-height: auto;
    flex-shrink: 0;
  }

  ${({$itemNameVisible}) => !$itemNameVisible ? css`
    .file-icon-wrapper {
      .iconwrap {
        display: flex;
        flex-direction: column;
        min-height: calc(${({$iconHeight}) => $iconHeight ?? "60px"} + (var(--spacing-sm) * 3));
        justify-content: end;
      }

      img {
        margin-bottom: var(--spacing-sm);
      }
    }
  ` : null}

  &.multiple {
    position: relative;
    z-index: 0;
    width: 100%;

    @media (max-height: ${({$mobileHeightBreakpoint}) => $mobileHeightBreakpoint ? $mobileHeightBreakpoint : "620px"}) {
      width: ${({$iconWidthM}) => $iconWidthM ?? "24px"} !important;
      height: ${({$iconHeightM}) => $iconHeightM ?? "24px"} !important;
    }
  }
`;

const zoomInOutAnimation = keyframes`
  0% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1);
  }
`;

const FileIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;

  .iconwrap {
    margin: 0!important;
    min-width: auto!important;
    float: none!important;

    @media (max-height: ${({$mobileHeightBreakpoint}) => $mobileHeightBreakpoint ? $mobileHeightBreakpoint : "620px"}) {
      min-height: auto !important;
      display: block !important;
    }
  }

  img {
    border: var(--thumb-border-width) solid transparent;
    width: ${({$iconWidth}) => $iconWidth ?? "auto"} !important;
    height: ${({$iconHeight}) => $iconHeight ?? "60px"} !important;
    max-width: 100%;
    
    @media (max-height: ${({$mobileHeightBreakpoint}) => $mobileHeightBreakpoint ? $mobileHeightBreakpoint : "620px"}) {
      margin-bottom: var(--spacing-none, 0) !important;
      width: ${({$iconWidthM}) => $iconWidthM ?? "24px"} !important;
      height: ${({$iconHeightM}) => $iconHeightM ?? "24px"} !important;
    }
  }

  &:not(.thumbready, .thumbfailed, .iconready) .loading-spin-animation {
    background: radial-gradient(50% 50% at 50% 50%, rgba(130, 130, 130, 0.2) 10%, rgba(0, 0, 0, 0) 100%);
    min-width: 35px !important;
    min-height: 35px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    
    img {
      width: 24px !important;
      height: 24px !important;
    }

    @media (max-height: ${({$mobileHeightBreakpoint}) => $mobileHeightBreakpoint ? $mobileHeightBreakpoint : "620px"}) {
      min-width: inherit !important;
      min-height: inherit !important;
      
      img {
        width: 16px !important;
        height: 16px !important;
      }
    }
  }

  &.thumbready {
    img {
      border-radius: var(--radius-minimum, 4px);
      border: var(--thumb-border-width) solid var(--border-secondary, #EFF0F1);
      width: auto !important;
      height: auto !important;
      max-width: ${({$thumbWidth}) => parseInt($thumbWidth) + "px" ?? "184px"};
      max-height: ${({$thumbHeight}) => parseInt($thumbHeight) + "px" ?? "104px"};
      min-height: unset !important;

      @media (max-height: ${({$mobileHeightBreakpoint}) => $mobileHeightBreakpoint ? $mobileHeightBreakpoint : "620px"}) {
        width: ${({$iconWidthM}) => $iconWidthM ?? "24px"} !important;
        height: ${({$iconHeightM}) => $iconHeightM ?? "24px"} !important;
        max-width: unset;
        max-height: unset;
      }
    }
  }

  ${({$animateItemIconZoomInOut}) => $animateItemIconZoomInOut ? css`
    img {
      animation: ${zoomInOutAnimation} 400ms ease-in-out forwards;
    }
  ` : null}

  &.multiple {
    position: absolute;
    left: 0;
    right: 0;
    transform-style: preserve-3d;

    .no-thumb, &.thumbfailed .iconwrap , &.iconready .iconwrap {
      min-width: ${({$thumbHeight}) => parseInt($thumbHeight) + "px" ?? "104px"} !important;
      min-height: ${({$thumbHeight}) => parseInt($thumbHeight) + "px" ?? "104px"} !important;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--surface-primary);
      border-radius:  var(--radius-minimum, 4px);
      border: 1px solid #fff;

      @media (max-height: ${({$mobileHeightBreakpoint}) => $mobileHeightBreakpoint ? $mobileHeightBreakpoint : "620px"}) {
        min-width: inherit !important;
        min-height: inherit !important;
        display: block;
        background: inherit;
        border-radius: 0;
        border: none;
      }
    }
  }
`;

const ItemNameLabel = styled.div`
  color: var(--text-primary, #282C2F);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  flex-shrink: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;

  &.multiple {
    display: none;
  }

  @media (max-width: 1023px) {
    display: none;
  }

  @media (max-height: ${({$mobileHeightBreakpoint}) => $mobileHeightBreakpoint ? $mobileHeightBreakpoint : "620px"}) {
    display: inline! important;
    flex: 1;
  }
`;

const ItemPreviewActionButton = styled.button`
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: var(--text-primary, #282C2F);
  padding: var(--spacing-sm, 8px);
  border-radius: var(--radius-full, 9999px);
  background: #F0F4F5;
  border: none;
  cursor: pointer;
  min-width: 120px;

  @media (max-height: ${({$mobileHeightBreakpoint}) => $mobileHeightBreakpoint ? $mobileHeightBreakpoint : "620px"}) {
    font-size: var(--base-font-size, 15px);
    padding: var(--spacing-xs, 4px);
    flex-shrink: 0;
  }
`;

const TabsWrapper = styled.div`
  border-bottom: 1px solid var(--divider-secondary, #EFF0F1);
  width: 100%;
  display: flex;
  justify-content: center;
  padding: var(--spacing-sm, 8px) var(--spacing-md, 16px) var(--spacing-none, 0px) var(--spacing-md, 16px);
  box-sizing: border-box;
`;

const ItemsInfoTabContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: 100%;
  gap: var(--spacing-sm, 8px);
  position: absolute;
  top: 0;
  left: 0;
`;

const CommentsTabContent = styled.div`
  height: 100%;
  width: 100%;
`;

const ItemOptionsWrapper = styled.div`
  display: flex;
  padding: var(--spacing-none, 0px) var(--spacing-md, 16px) var(--spacing-sm, 8px) var(--spacing-md, 16px);
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  font-size: var(--small-font-size);
  font-weight: 400;

  .options-btn {
    border-radius: var(--radius-full, 9999px);
    border: 1px solid var(--divider-primary, #E4E5E7);
  }
`;