import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { hiDPI } from "polished";

import Modal, { OvalModalClose, OvalModalWrap } from "../Modal";
import { __ } from "../../lib/translate";
import ButtonDefault from "../ButtonDefault";
import { setActive } from "../../lib/state/reducers/onboardingSlice";
import hashManager from "../../lib/hashManager";

const BetaModal = ({ opened = true, onClose = () => {} }) => {
  const dispatch = useDispatch();

  const onButtonClick = () => {
    window.localStorage.setItem("betamodal", "1")
    if (hashManager.getState('page') != 'filemanager') {
      hashManager.pushState({ page: "filemanager" });
    };
    
    dispatch(setActive(1));
    onClose();
  };

  return (
    <Modal align="top" opened={opened} onClose={onClose}>
      <ModalWrapper>
        {/* <CloseButton onClick={onClose} src={`/ZCDNURL/img/betamodal/white-close.svg`}/> */}
        <BetaInformationContainer>
          <BetaTexts>
            <BetaTitle>{__("beta_modal_title", "The wait is over: try the redesigned pCloud in beta")}</BetaTitle>
            <BetaDescription>{__("beta_modal_description", "You beta believe it - check out ..., in beta, before it officially launches later this month.")}</BetaDescription>
            <ButtonWrapper>
              <ButtonDefault borderRadius="20" onClick={onButtonClick}>{__("beta_modal_cta", "TAKE THE TOUR")}</ButtonDefault>
            </ButtonWrapper>
          </BetaTexts>
          <BetaImageWrapper>
            <BetaScreenshot
              src={`/ZCDNURL/img/betamodal/screenshots.png`}
              srcSet={`/ZCDNURL/img/betamodal/screenshots@2x.png 2x`}
              alt="Screenshots of the new beta interface of my.pcloud.com"
              maxwidth="347px"
            />
            <BetaLogo
              src={`/ZCDNURL/img/betamodal/betalogo.svg`}
              alt="pCloud logo with 'BETA' label"
              maxwidth="347px"
            />
          </BetaImageWrapper>
        </BetaInformationContainer>
      </ModalWrapper>
    </Modal>
  )
}

export default BetaModal;

const ModalWrapper = styled(OvalModalWrap)`
  border-radius: 34px;
  background: transparent;
  width: 100%;
  max-width: 755px;
`;

const BetaInformationContainer = styled.div`
  border-radius: 34px;
  background: var(--color-base-white, #fff);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media(max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

// const CloseButton = styled.img`
//   display: inline-block;
//   width: 24px;
//   height: 24px;
//   position: absolute;
//   top: 16px;
//   right: 16px;
//   z-index: 1;
//   cursor: pointer;
// `;

const BetaTexts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  box-sizing: border-box;
  padding: var(--spacing-2xl, 40px);
  flex-shrink: 0;

  @media(max-width: 768px) {
    width: 100%;
    padding: 40px 24px;
    z-index: 2;
    position: relative;
    background: #fff;
  }
`;

const BetaTitle = styled.div`
  color: var(--color-base-black);
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.66px;
  text-align: left;
  margin-bottom: var(--spacing-md, 16px);
`;

const BetaDescription = styled.div`
  color: var(--color-base-black, #000);
  font-size: var(--font-size-16, 16px);
  font-style: normal;
  font-weight: 300;
  line-height: 1.3
  letter-spacing: -0.48px;
  text-align: left;
  margin-bottom: var(--spacing-xl, 32px);
`;

const ButtonWrapper = styled.div`
  width: 100%;
`;

const BetaImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: var(--spacing-xl, 32px) 0 var(--spacing-xl, 32px) var(--spacing-xl, 32px);
  width: 50%;
  position: relative;
  background-image: url("/ZCDNURL/img/betamodal/starsbg.png");
  ${hiDPI(1.5)} {
    background-image: url("/ZCDNURL/img/betamodal/starsbg@2x.png");
  }
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 500px;

  @media(max-width: 768px) {
    width: 100%;
    height: 180px;
    gap: 16px;
    justify-content: space-between;
    padding: 44px 0;
  }
`;

const BetaScreenshot = styled.img`
  @media(max-width: 768px) {
    width: 50%;
    position: relative;
    z-index: 1;
  }
`;

const BetaLogo = styled.img`
  width: 260px;
  height: 142px;
  position: absolute;
  bottom: 0;
  right: 0;

  @media(max-width: 768px) {
    bottom: -30px;
  }
`;

