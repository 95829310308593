// @flow

import React, { Component } from "react";
import { connect } from "react-redux";

import { __ } from "../../lib/translate";

import { Container, Header, Message } from "./SharedComponents";

export const renderSentEmail = (email: string) => {
  return (
    <Container className="sent-email">
      <Header>{__("Email sent")}</Header>
      <Message className="email-message">
        <div>
          {__("tfa_setup_sent_email_message")} <b>{email}</b>
        </div>
      </Message>
    </Container>
  );
};

type Props = {
  email: string
};

class SentEmailStep extends Component<Props, {}> {
  static defaultProps = {
    email: ""
  };

  render() {
    const { email } = this.props;

    return renderSentEmail(email);
  }
}

export default connect(({ pCloudUser }) => {
  return { email: pCloudUser.userinfo.email };
})(SentEmailStep);
