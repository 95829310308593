// @flow

import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import Modal, { OvalModalWrap, OvalModalCloseV2 } from "../../Modal";
import Button from "../../ButtonDefault";
import InputStyledCheckbox from "../../InputStyledCheckbox";
import * as Style from "../styledComponents";
import ArrowGrey from "../../../../root/img/svg/arrow-grey.svg";

import { __ } from "../../../lib/translate";

const InviteUsersToTeamsModal = ({ user }: { user: any }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [list, setList] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);

  const userTeams = user.teams;
  const teams = useSelector(({ business }) => business.teams);
  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      const checkboxes = document.getElementById("checkboxes");
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        checkboxes.style.display = "none";
        setIsExpanded(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  useEffect(() => {
    const teamsList = teams.map(team => {
      const { name, id } = team;
      const selectedTeam = userTeams.find(({ team: { id: teamId } }) => id === teamId);
      return {
        title: name,
        href: false,
        extra: { id: id, isChecked: selectedTeam !== undefined }
      };
    });

    teamsList.sort(
      ({ extra: { isChecked: isAChecked } }, { extra: { isChecked: isBChecked } }) =>
        isBChecked - isAChecked
    );
    setList(teamsList);
  }, [teams, userTeams]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const onCheckboxChange = (e, index) => {
    const target = e.target;
    const updatedList = [...list];

    updatedList[index].extra.isChecked = target.checked;
    setList(updatedList);
  };

  const renderTeamOption = ({ title, extra: { id, isChecked } }, index) => {
    if (id === 0) {
      return null;
    }

    return (
      <CheckboxWrapper key={id}>
        <InputStyledCheckbox
          name={id}
          size="small"
          checked={isChecked}
          onChange={e => onCheckboxChange(e, index)}
        >
          <Team>{title}</Team>
        </InputStyledCheckbox>
      </CheckboxWrapper>
    );
  };

  const showCheckboxes = () => {
    const checkboxes = document.getElementById("checkboxes");
    if (!isExpanded) {
      checkboxes.style.display = "block";
      setIsExpanded(true);
    } else {
      checkboxes.style.display = "none";
      setIsExpanded(false);
    }
  };

  const addToTeams = () => {
    const { id: memberId } = user;

    if (isLoading) {
      return;
    }
    setIsLoading(true);
    var batch = new batchApiCall({
      continueOnError: true,
      errorCallback: function(ret) {
        setIsLoading(false);
        if (ret.result !== 2106) {
          setError(__("Error adding users."));
        }
      }
    });

    list.map(({ extra: { id: teamId, isChecked } }) => {
      const isUserInTeam = userTeams.find(({ team: { id } }) => id === teamId);

      if (isChecked && !isUserInTeam) {
        batch.addCall("account_teamlink", { userid: memberId, teamid: teamId });
      } else if (!isChecked && isUserInTeam) {
        batch.addCall("account_teamunlink", { userid: memberId, teamid: teamId });
      }
    });

    batch.execute(function() {
      if (error) {
        HFN.message(error, "error");
        setIsLoading(false);
      } else {
        HFN.message(__("User added to team(s)."));
        PCB.refreshAll();
      }
      handleClose();
    });
  };

  const { firstname: memberFirstName, lastname: memberLastName, email: memberEmail } = user;

  return (
    <Modal align="top" animate onClose={handleClose} opened={isOpen}>
      <OvalModalWrap>
        <OvalModalCloseV2 onClick={handleClose} />
        <Style.Container>
          <Style.Header>{__("modal_add_user_to_teams_header", "Add User to team(s)")}</Style.Header>
          <Style.Label>{__("User", "User")}</Style.Label>
          <BoxReset>
            <b>{`${memberFirstName} ${memberLastName}`}</b>
            {` (${memberEmail})`}
          </BoxReset>
          <Style.Label>{__("modal_add_user_to_teams_label_teams", "Team(s)")}</Style.Label>
          <MultiSelectWrapper>
            <MultiSelect ref={wrapperRef}>
              <SelectBox className="selectBox" onClick={showCheckboxes}>
                <Select isExpanded={isExpanded}>
                  <option>{__("Choose")}</option>
                </Select>
                <ArrowIconSelect position={isExpanded ? "DOWN" : "UP"} width="12.7" height="12.7" />
                <OverSelect />
              </SelectBox>
              <Options id="checkboxes">{list.map(renderTeamOption)}</Options>
            </MultiSelect>
          </MultiSelectWrapper>
          <Style.Footer>
            <Button
              color="lightgray4"
              style={{
                marginRight: "5px"
              }}
              onClick={handleClose}
            >
              {__("Close")}
            </Button>
            <Button
              color="cyan"
              style={{
                marginLeft: "5px"
              }}
              disabledColor="#dddddd"
              loading={isLoading}
              onClick={addToTeams}
            >
              {__("insert_invoice_info", "Add")}
            </Button>
          </Style.Footer>
        </Style.Container>
      </OvalModalWrap>
    </Modal>
  );
};

export default InviteUsersToTeamsModal;

const MultiSelectWrapper = styled.div`
  .overSelect {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
  }
`;

const MultiSelect = styled.div`
  width: 420px;
`;

const SelectBox = styled.div`
  position: relative;
`;

const OverSelect = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
`;

const Select = styled.select`
  width: 420px;
  height: 40px;
  font-size: 15px;
  font-weight: normal;
  box-sizing: border-box;
  padding: 10px 15px 10px 10px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  color: ${props => (props.isExpanded ? "#999999" : "#000000")};
  border-bottom-left-radius: ${props => (props.isExpanded ? "0" : "6px")};
  border-bottom-right-radius: ${props => (props.isExpanded ? "0" : "6px")};
  box-shadow: ${props => (props.isExpanded ? "0" : "0 1px 2px 0 rgba(0, 0, 0, 0.07)")};
  appearance: none;
`;

const Options = styled.div`
  display: none;
  position: fixed;
  width: 418px;
  z-index: 1000;
  border-radius: 6px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px #dddddd;
  background-color: #ffffff;
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

const Team = styled.div`
  margin-left: 10px;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  box-sizing: border-box;

  label {
    width: 100%;
    text-align: left;
    padding: 10px 10px 10px 20px;
  }

  &:hover {
    background-color: #eee;
  }

  span {
    margin: 10px;
  }
`;

const BoxReset = styled(Style.Box)`
  display: block;
  font-weight: normal;
  text-align: left;
  margin-bottom: 17px;
`;

const ArrowIconSelect = styled(ArrowGrey)`
  position: absolute;
  right: 10px;
  transform: ${props => (props.position === "UP" ? "rotate(180deg)" : "rotate(0deg)")};
  top: 15px;
`;
