// @flow
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Componentify from "react-componentify";
import styled from "styled-components";
import { hiDPI } from "polished";

import { __ } from "../../lib/translate";
import { styledSpanTagConverter } from "../../lib/componentifyConverters";
import getFolderShares from "../../api/getFolderShares";

import Modal, { OvalModalWrap, CloseModalIcon } from "../Modal";
import * as Style from "../Modals/styledComponents";

import TabsHolder from "../TabsHolder";
import InviteCollaboratorsTab from "./InviteCollaboratorsTab";
import ManageShareTab from "./ManageShareTab";
import InvitedWithoutCrypto from "./InvitedWithoutCrypto";
import InviteOtherRegion from "./InviteOtherRegion";
import InviteWithChangePermission from "./InviteWithChangePermission";
import useWindowDimensions from "../../lib/hooks/useWindowDimensions";
import { clearFolderShares, loadFolderShares } from "../../lib/state/reducers/sharedFoldersSlice";

const customConverter = Object.assign({}, styledSpanTagConverter, {
  props: {
    style: {
      display: "inline-block",
      color: "#17bed0",
      maxWidth: "300px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      lineHeight: 1.2
    }
  }
});

type Props = {
  folderName: string,
  folderId: number,
  isMine: boolean,
  isCryptoShare: boolean,
  tabId: number
};

const ShareModal = ({ folderName, folderId, isMine, isCryptoShare, tabId = 1 }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(tabId);
  const [showCreateCryptoPass, setShowCreateCryptoPass] = useState(false);
  const [showOtherRegion, setShowOtherRegion] = useState(false);
  const [showUsersWithChangePermission, setshowUsersWithChangePermission] = useState(false);
  const [invitationsNoCrypto, setInvitationsNoCrypto] = useState([]);
  const [invitationsOtherRegion, setInvitationsOtherRegion] = useState([]);
  const [invitationsWithChangePermission, setInvitationsWithChangePermission] = useState([]);
  const [shouldShowCancelShare, setShouldShowCancelShare] = useState(false);
  const { bodyWidth } = useWindowDimensions();
  const dispatch = useDispatch();

  (isOpen: boolean);
  (selectedTab: number);
  (showCreateCryptoPass: boolean);
  (invitationsNoCrypto: Array<any>);
  (shouldShowCancelShare: boolean);

  useEffect(() => {
    getFolderShares(folderId, shares => {
      dispatch(loadFolderShares(shares));
    });

    return () => {
      dispatch(clearFolderShares());
    };
  }, [dispatch, folderId]);

  useEffect(() => {
    onOpen();
  }, []);

  useEffect(() => {
    if (invitationsNoCrypto.length !== 0 && isCryptoShare) {
      setShowCreateCryptoPass(true);
    }
  }, [invitationsNoCrypto, isCryptoShare]);

  useEffect(() => {
    if (invitationsOtherRegion.length !== 0) {
      setShowOtherRegion(true);
    }
  }, [invitationsOtherRegion]);

  useEffect(() => {
    if (invitationsWithChangePermission.length !== 0) {
      setshowUsersWithChangePermission(true);
    }
  }, [invitationsWithChangePermission]);

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    if (!shouldShowCancelShare && invitationsNoCrypto.length !== 0 && isCryptoShare) {
      setShouldShowCancelShare(true);
    }
    setIsOpen(false);
  };

  const showCreateCryptoPassSucc = tabIndex => {
    setShowCreateCryptoPass(false);
    setShowOtherRegion(false);
    setSelectedTab(tabIndex);
  };

  const showManageShare = tabIndex => {
    setshowUsersWithChangePermission(false);
    setSelectedTab(tabIndex);
  };

  const onTabClick = tabIndex => {
    setSelectedTab(tabIndex);
  };

  const renderTabsHolder = () => {
    const title = __("share_folder_share_for").replace("%foldername%", folderName);

    return (
      <React.Fragment>
        <Style.Header>
          <Componentify text={title} converters={[customConverter]} />
        </Style.Header>
        <TabsHolder
          size="small"
          type={selectedTab}
          tabStyle={tabStyle}
          tabLineStyle={tabLineStyle}
          onTabClick={onTabClick}
        >
          <TabInner label={__("share_folder_modal_invite_to_folder", "Invite to folder")}>
            <InviteCollaboratorsTab
              folderId={folderId}
              folderNameShare={folderName}
              isCryptoShare={isCryptoShare}
              onTabClick={onTabClick}
              getUsersWithoutCrypto={setInvitationsNoCrypto}
              getUsersFromOtherRegion={setInvitationsOtherRegion}
              getUsersWithChangedPermission={setInvitationsWithChangePermission}
            />
          </TabInner>

          <TabInner label={__("share_folder_manage_share", "Manage share")}>
            <ManageShareTab isMine={isMine} onTabClick={onTabClick} />
          </TabInner>
        </TabsHolder>
      </React.Fragment>
    );
  };

  const renderBody = () => {
    if (showCreateCryptoPass) {
      return (
        <InvitedWithoutCrypto
          folderId={folderId}
          folderNameShare={folderName}
          usersWithoutCrypto={invitationsNoCrypto}
          setUsersWithoutCrypto={setInvitationsNoCrypto}
          showCancelShare={shouldShowCancelShare}
          setShowCancelShare={setShouldShowCancelShare}
          showManageTab={showCreateCryptoPassSucc}
          setIsOpenModal={setIsOpen}
        />
      );
    } else if (showOtherRegion) {
      return (
        <InviteOtherRegion
          folderId={folderId}
          folderNameShare={folderName}
          usersFromOtherRegion={invitationsOtherRegion}
          setIsOpenModal={setIsOpen}
          showComponent={setShowOtherRegion}
        />
      );
    } else if (showUsersWithChangePermission) {
      return (
        <InviteWithChangePermission
          usersWithChangePermission={invitationsWithChangePermission}
          showComponent={setshowUsersWithChangePermission}
          showManageTab={showManageShare}
        />
      );
    } else {
      return renderTabsHolder();
    }
  };

  const modalStyle = {}
  if (bodyWidth < 800) {
    modalStyle.width = '95vw';
  }

  return (
    <Modal
      align="top"
      onClose={onClose}
      opened={isOpen}
      animate
      shouldCloseOnOverlayClick={false}
    >
      <OvalModalWrap style={modalStyle}>
        <CloseModalIcon onClick={onClose} />
        <Style.Container className="invite-folder-modal">
          {renderBody()}
        </Style.Container>
      </OvalModalWrap>
    </Modal>
  );
};

export default ShareModal;

const Wrapper = styled.div`
  /* position: relative; */
  margin-top: 20px;
`;

const TabInner = styled.li`
  list-style-type: none;
  padding: 20px 0 0 0;
`;

const tabStyle = {
  fontSize: "13px",
  fontWeight: "bold",
  textTransform: "none"
};

const tabLineStyle = {
  background: "#17bed0",
  height: "3px"
};

const ModalHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CryptoIcon = styled.div`
  display: block;
  width: 9px;
  height: 11px;
  margin: 5px 10px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(${require("../../../root/img/share-icons/green-lock-title.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/share-icons/green-lock-title@2x.png")});
  }
`;
