// @flow

import React, { useState } from "react";

import Modal, { OvalModalWrap, OvalModalCloseSimple } from "../../components/Modal";
import EditBookmark from "./EditBookmark";

type Props = {
  code: string,
  locationId: number,
  bookmarkName: string,
  bookmarkDesc: string,
  onSuccess: () => void
};

const EditBookmarkModal = ({
  code,
  locationId,
  bookmarkName = "",
  bookmarkDesc = "",
  onSuccess = () => {}
}: Props) => {
  const [isOpen, setIsOpen] = useState(true);

  const onClose = () => {
    setIsOpen(false);
  };

  const onSuccessCb = () => {
    onSuccess();
    setIsOpen(false);
  };

  return (
    <Modal align="top" onClose={onClose} opened={isOpen}>
      <OvalModalWrap>
        <OvalModalCloseSimple onClick={onClose} />
        <EditBookmark
          isPinned
          type="modal"
          code={code}
          locationId={locationId}
          bookmarkName={bookmarkName}
          bookmarkDesc={bookmarkDesc}
          onSave={onSuccessCb}
        />
      </OvalModalWrap>
    </Modal>
  );
};

export default EditBookmarkModal;
