// @flow

import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";

import Modal, { OvalModalWrap, OvalModalCloseV2 } from "../../Modal";
import Button from "../../ButtonDefault";
import * as Style from "../styledComponents";

import apiMethod from "../../../api/apiMethod";
import { __ } from "../../../lib/translate";
import { is } from "immutable";

type Props = {
  teamName: string,
  teamId: number,
  active: boolean
};

const ChangeTeamStatusModal = ({ teamName = "", teamId = 0, active = true }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onEnter = useCallback((e: any) => {
    if (e.keyCode === 13) {
      changeStatus();
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("keyup", onEnter);
    } else {
      document.removeEventListener("keyup", onEnter);
    }
  }, [isOpen]);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };


  const changeStatus = () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    let message = __("Team Activated.");
    const params = {
      teamid: teamId,
      active: 1
    };

    if (active) {
      params.active = 0;
      message = __("Team Deactivated.");
    }

    apiMethod(
      "account_teammodify",
      params,
      () => {
        PCB.refreshAll();
        HFN.message(message);
        handleClose();
      },
      {
        errorCallback: ({ error, result }) => {
          setIsLoading(false);
          let errorMessage = __("something_went_wrong_refresh_and_try_again");

          if (result === 2076) {
            errorMessage = __("error_2076", "You do not have the permissions for this action.");
          }

          HFN.message(errorMessage, "error");
          throw new Error(error);
        }
      }
    );
  };

  return (
    <Modal align="top" animate onClose={handleClose} opened={isOpen}>
      <OvalModalWrap>
        <OvalModalCloseV2 onClick={handleClose} />
        <Style.Container>
          <Style.Header>
            {active
              ? __("modal_change_team_status_header", "Deactivate Team")
              : __("modal_change_team_status_header_activate", "Activate Team")}
          </Style.Header>
          <Style.Label>{__("Team", "Team")}</Style.Label>
          <BoxTeam>{teamName}</BoxTeam>
          <Style.Footer>
            <Button
              color="lightgray4"
              style={{
                marginRight: "5px"
              }}
              onClick={handleClose}
            >
              {__("Close")}
            </Button>
            <Button
              loading={isLoading}
              color={active ? "red" : "cyan"}
              style={{
                marginLeft: "5px"
              }}
              disabledColor="#dddddd"
              onClick={changeStatus}
            >
              {active ? __("Deactivate") : __("Activate")}
            </Button>
          </Style.Footer>
        </Style.Container>
      </OvalModalWrap>
    </Modal>
  );
};

export default ChangeTeamStatusModal;

const BoxTeam = styled(Style.Box)`
  height: auto;
  padding: 10px;
  font-size: 15px;
`;
