// @flow

import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { __ } from "../../lib/translate";
import * as Style from "./preferences.style";
import { apiMethod } from "../../api";

const DeleteAccountSetting = () => {
  const token = useSelector(({ pCloudUser }) => pCloudUser.token);
  const isBusiness = useSelector(({ pCloudUser }) => pCloudUser.userinfo.business);
  const isVerified = useSelector(({ pCloudUser }) => pCloudUser.userinfo.emailverified);
  const isFamily = useSelector(({ pCloudUser }) => pCloudUser.userinfo.family);
  const isFamilyOwner = useSelector(({ pCloudUser }) => pCloudUser.userinfo.family?.owner);
  const email = useSelector(({ pCloudUser }) => pCloudUser.userinfo.email);

  const deleteAccount = () => {
    apiMethod("senddeactivatemail", {}, ret => {
      HFN.message(__("delete_account_after_click", "We've sent you an email to <b>%email%</b>. Check your inbox and follow the instructions from the email. If you don't receive an email within few minutes, check your Junk/Spam folder.", { email: email }));
    }, {
        errorCallback: function (ret) {
          if (ret.result === 2255) {
            ret.error = __("delete_limit", "You requested to delete your account just recently. Please, try again later.");
          }

          if (ret.result === 2274) {
            ret.error = __("You have active subscription", "You have active subscription");
          }

          HFN.message(ret.error, "error");
        },
        type: 'POST'
      }
    );
  }

  const onDeleteAccountClick = () => {
    if (!isVerified) {
      HFN.message(__("delete_account_not_verified", "You need to verify your current email in order to delete your account."), "error");
      return;
    }

    if (isFamily && isFamilyOwner) {
      apiMethod("fm_getinfo", { auth: token }, ret => {
        if (ret.invites.length !== 0 || ret.members.length !== 1) {
          HFN.showDeleteAccount();
        } else {
          deleteAccount();
        }
      }, {
          errorCallback: function (ret) {
            HFN.message(
              __("something_went_wrong_refresh_and_try_again"),
              "error"
            );
            throw new Error(res.error);
          }
        });
    } else {
      deleteAccount();
    }
  };

  return (
    <Style.Row>
      <div>
        <Style.Title>{__("delete_account", "Delete your account")}</Style.Title>
        <Style.Description>{__("delete_account_explain", "To delete your pCloud account, click the button on the right and follow further instructions.")}</Style.Description>
      </div>
      <Style.RedButton onClick={onDeleteAccountClick}>
        {__("Delete account")}
      </Style.RedButton>
    </Style.Row>
  );
};

export default DeleteAccountSetting;

const Status = styled.div`
  display: inline-block;
`;
