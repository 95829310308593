// @flow

import React, { useState, useEffect, useCallback } from "react";

import Modal, { OvalModalWrap, OvalModalCloseV2 } from "../../Modal";
import Button from "../../ButtonDefault";
import * as Style from "../styledComponents";

import apiMethod from "../../../api/apiMethod";
import { __ } from "../../../lib/translate";

type Props = {
  userId: number,
  userEmail: string,
  fName: string,
  lName: string,
  teamId: number,
  teamName: string
};

const RemoveUserFromTeamModal = ({
  userId = 0,
  userEmail = "",
  fName = "",
  lName = "",
  teamId = 0,
  teamName = ""
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const onEnter = useCallback(
    (e: any) => {
      if (e.keyCode === 13) {
        removeUser();
      }
    },
    [removeUser]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("keyup", onEnter);
    } else {
      document.removeEventListener("keyup", onEnter);
    }
  }, [isOpen, onEnter]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const removeUser = useCallback(() => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    apiMethod(
      "account_teamunlink",
      { userid: userId, teamid: teamId },
      () => {
        PCB.refreshAll();
        HFN.message(__("User removed from team"));
        handleClose();
      },
      {
        errorCallback: ({ error, result }) => {
          setIsLoading(false);
          let errorMessage = __("something_went_wrong_refresh_and_try_again");

          if (result === 2076) {
            errorMessage = __("error_2076", "You do not have the permissions for this action.");
          }

          HFN.message(errorMessage, "error");
          throw new Error(error);
        }
      }
    );
  });

  return (
    <Modal align="top" animate onClose={handleClose} opened={isOpen}>
      <OvalModalWrap>
        <OvalModalCloseV2 onClick={handleClose} />
        <Style.Container>
          <Style.Header>
            {__("modal_remove_user_from_team_header", "Remove user from team")}
          </Style.Header>
          <Style.Label>{__("modal_remove_user_from_team_label", "Remove user")}</Style.Label>
          <Style.Box>
            <Style.WrapperElements>
            <Style.ElementBold>{__("Name")}</Style.ElementBold>
              <Style.ElementBold>{__("Email")}</Style.ElementBold>
              <Style.ElementBold>{__("Team")}</Style.ElementBold>
            </Style.WrapperElements>
            <Style.WrapperElements>
            <Style.ElementUnderLine><b>{`${fName} ${lName}`}</b></Style.ElementUnderLine>
              <Style.ElementUnderLine>{userEmail}</Style.ElementUnderLine>
              <Style.ElementUnderLine>{teamName}</Style.ElementUnderLine>
            </Style.WrapperElements>
          </Style.Box>
          <Style.Footer>
            <Button
              color="lightgray4"
              style={{
                marginRight: "5px"
              }}
              onClick={handleClose}
            >
              {__("Cancel")}
            </Button>
            <Button
              loading={isLoading}
              color="red"
              style={{
                marginLeft: "5px"
              }}
              disabledColor="#dddddd"
              onClick={removeUser}
            >
              {__("family_remove")}
            </Button>
          </Style.Footer>
        </Style.Container>
      </OvalModalWrap>
    </Modal>
  );
};

export default RemoveUserFromTeamModal;
