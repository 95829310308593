// @flow

import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Componentify from "react-componentify";

import { spanClassTagConverter } from "../../lib/componentifyConverters";
import apiMethod from "../../api/apiMethod";
import { __ } from "../../lib/translate";
import { makeTextFile } from "../../lib/utils";

import { ButtonCentered } from "../../components/ButtonDefault";
import { Container, Header, Message, Code, LoaderSecurityCodes } from "./SharedComponents";
import { TFA_TYPE } from "@pcloud/web-utilities/dist/config/constants";
import type { ActiveTfaType } from "../../types/user";

type securityCodesType = Array<{ code: string, used: boolean }>;

type Props = {
  token: string,
  password: string,
  msisdn: string,
  activeTfaType: ActiveTfaType,
  onSuccess: () => void
};

type State = {
  securityCodes: securityCodesType,
  isLoading: boolean
};

export const getSecurityCodesText = (securityCodes: securityCodesType) => {
  return securityCodes
    .filter(code => !code.used)
    .map(code => code.code)
    .join("\r\n");
};

export const renderCode = ({ code, used }: { code: string, used: boolean }, index: number) => {
  return (
    <Code key={index} used={used}>
      {code}
    </Code>
  );
};

class SecurityCodesStep extends Component<Props, State> {
  static defaultProps = {
    token: "",
    password: "",
    msisdn: "",
    activeTfaType: null,
    onSuccess: () => {}
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      securityCodes: [],
      isLoading: true
    };
  }

  componentDidMount() {
    const { token, password } = this.props;

    apiMethod(
      "tfa_getrecoverycodes",
      {
        auth: token,
        password
      },
      ({ codes }) => {
        this.setState({ securityCodes: codes, isLoading: false });
      },
      {
        errorCallback: ({ error }) => {
          this.setState({ isLoading: false });
          HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
          throw new Error(error);
        }
      }
    );
  }

  render() {
    const { securityCodes, isLoading } = this.state;
    const { msisdn, activeTfaType, onSuccess } = this.props;
    const textHref = makeTextFile(getSecurityCodesText(securityCodes));
    const phoneNumber = msisdn ? `+${msisdn}` : "";
    const subheading =
      activeTfaType === TFA_TYPE.MSISDN_TYPE ? (
        <p className="subheading-security-codes">
          <Componentify
            text={__("tfa_setup_recovery_codes_subheading", "", {
              phonenumberattr: `class='phone-number'`,
              phonenumber: phoneNumber
            })}
            converters={[spanClassTagConverter]}
          />
        </p>
      ) : (
        <p>{__("tfa_setup_authenticator_recovery_codes_subheading")}</p>
      );

    return (
      <Container className="security-container">
        <Header>{__("Almost done")}</Header>
        {subheading}
        <Message className="important-message security">
          <div className="security-text">
            <Componentify
              text={__("tfa_setup_view_recovery_codes_msg_n", "Write down or <a %url% %downloadattr% %linkattrsclass%>download</a> these one-time recovery codes and keep them safe. Use them should you have issues receiving security codes upon login.", {
                url: `${textHref}`,
                linkattrsclass: `class='download'`,
                downloadattr: `pcloudSecurityKeys.txt`
              })}
              converters={[customLinkConverterWithAttrs]}
            />
          </div>
          {isLoading ? (
            <LoaderSecurityCodes />
          ) : (
            <div>
              <Title>{__("backup_codes", "Backup Codes")}</Title>
              <div className="security-codes">{securityCodes.map(renderCode)}</div>
            </div>
          )}
        </Message>
        <ButtonCentered color="lightgray4" style={{ width: "100%" }} onClick={onSuccess}>
          {__("Close")}
        </ButtonCentered>
      </Container>
    );
  }
}

export default connect(({ pCloudUser }) => {
  const {
    token,
    userinfo: { password, msisdn } = {},
    userSettings: { security: { activeTfaType } = {} } = {}
  } = pCloudUser;

  return {
    token,
    password,
    msisdn,
    activeTfaType
  };
})(SecurityCodesStep);

const Title = styled.div`
  color: #000;
  font-size: 13px;
  text-align: center;
  font-weight: 500;
  margin: 17px 0;
`;

const customLinkConverterWithAttrs = {
  regex: /\<a((.*?)\>(.+?))\<\/a\>/g,
  component: "a",
  props: ([_, , attrs, classElement]) => {
    const attrsSplit = attrs.split(" ");

    return {
      className: classElement,
      href: attrsSplit[1],
      download: attrsSplit[2]
    }
  },
  innerText: matches => matches[3]
};
