// @flow

import React, { useState, useEffect, useCallback } from "react";

import Modal, { OvalModalWrap, CloseModalIcon } from "../../Modal";
import Button from "../../ButtonDefault";
import * as Style from "../../Modals/styledComponents";

import apiMethod from "../../../api/apiMethod";
import { __ } from "../../../lib/translate";
import { errorKeys } from "../../../lib/errors";

import store from "../../../lib/state/store";
import { deleteContentItem } from "../../../lib/state/reducers/contentSlice";
import hashManager from "../../../lib/hashManager";

type DeletePlaylistModalProps = {
    playlistobj: Array<any>
};

const DeletePlaylistModal = ({
    playlistobj = {}
}: DeletePlaylistModalProps) => {
    const [isOpen, setIsOpen] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const { id, name } = playlistobj;

    useEffect(() => {
        if (isOpen) {
            console.log("ADD event on keyup DeletePlaylistModal");
            document.addEventListener("keyup", onEnter);
        } else {
            console.log("REMOVE event on keyup DeletePlaylistModal");
            document.removeEventListener("keyup", onEnter);
        }
    }, [isOpen, onEnter]); //[isOpen, onEnter]

    const handleClose = () => {
        setIsOpen(false);
    };

    const onEnter = useCallback(
        (e: any) => {
            if (e.keyCode === 13) {
                onDeletePlaylistClick();
            }
        },
        [onDeletePlaylistClick]
    );

    const onDeletePlaylistClick = useCallback(() => {
        if (isLoading) {
            return;
        }
        setIsLoading(true);

        apiMethod(
            "collection_delete",
            { collectionid: id },
            () => {
                HFN.message(__("Playlist Deleted."));

                handleClose();

                if (
                    daGrid &&
                    daGrid.template.contentType == "browse_playlist" &&
                    daGrid.opts.playlistid == id
                ) {
                    hashManager.pushState(
                        { page: "audio", autab: "tab-playlists" },
                        2
                    );
                }

                HFN.cache.expireMatch("api-collection_list");
                HFN.cache.expireMatch("api-collection_list-showfiles:1");
                HFN.cache.expireMatch(
                    "api-collection_details-collectionid:" + id
                );

                if (daGrid && daGrid.template.contentType == "browse_playlists"){
                    store.dispatch(deleteContentItem({ id: id }));
                }
            },
            {
                errorCallback: ({ result, error }) => {
                    if (errorKeys[result]) {
                        HFN.message(__(errorKeys[result]), "error");
                    } else {
                        HFN.message(
                            __("something_went_wrong_refresh_and_try_again"),
                            "error"
                        );
                        throw new Error(error);
                    }
                    setIsLoading(false);
                }
            }
        );
    });

    return (
        <Modal align="top" animate onClose={handleClose} opened={isOpen}>
            <OvalModalWrap>
                <CloseModalIcon onClick={handleClose} />
                <Style.Container>
                    <Style.Header>{__("Delete Playlist")}</Style.Header>
                    <Style.Label>{__("Delete Playlist")}:</Style.Label>
                    <Style.Box>
                        <Style.WrapperElements>
                            <Style.ElementBold>
                                {__("Playlist")}
                            </Style.ElementBold>
                        </Style.WrapperElements>
                        <Style.WrapperElements>
                            <Style.ElementUnderLine>
                                {name}
                            </Style.ElementUnderLine>
                        </Style.WrapperElements>
                    </Style.Box>
                    <Style.Footer>
                        <Button
                            color="lightgray4"
                            style={{
                                marginRight: "5px"
                            }}
                            onClick={handleClose}
                        >
                            {__("Cancel")}
                        </Button>
                        <Button
                            loading={isLoading}
                            disabled={isLoading}
                            color="red"
                            style={{
                                marginLeft: "5px"
                            }}
                            onClick={onDeletePlaylistClick}
                        >
                            {__("Delete")}
                        </Button>
                    </Style.Footer>
                </Style.Container>
            </OvalModalWrap>
        </Modal>
    );
};

export default DeletePlaylistModal;
