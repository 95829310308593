// @flow
import React, { Component } from "react";
import styled from "styled-components";

import LoginOrSignup from "@pcloud/web-utilities/dist/authentication/components/UserAuthentication2/LoginOrSignup";
import LogopCloudIcon from "../../root/img/svg/pcloud-logo.svg";
import ConnectAppIcon from "../../root/img/svg/connect-app.svg";

type Props = {
  applicationName: string,
  application: any,
  response_type: any,
  redirect_uri: any,
  request_id: any,
  state: any,
  icon: any,
  onLoginSuccess: any
};
type State = {  };

class AuthorizeOAuth2 extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { applicationName, icon, onLoginSuccess } = this.props;

    return (
      <Wrapper>
        <Header>Connect {applicationName} to your account</Header>
        <Subheader>
          <b>{applicationName}</b> wants to have access to your pCloud account.
        </Subheader>
        <IconsWrapper>
          <Icon src={"//" + icon.hosts[0] + icon.path} />
          <ConnectAppIcon />
          <LogopCloudIcon />
        </IconsWrapper>

        <FormsWapper>
            <LoginOrSignup showTitle={false} onSuccess={onLoginSuccess} />
        </FormsWapper>
      </Wrapper>
    );
  }
}

export default AuthorizeOAuth2;

const Wrapper = styled.div`
  display: flex;
  margin-top: 20px;
  text-align: center;
  font-family: "Open sans", sans-serif;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span:after {
    left: 3px;
    height: 8px;
    width: 4px;
  }
`;

const Header = styled.div`
  margin-top: 20px;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
`;

const Icon = styled.img`
  display: inline-block;
  width: 64px;
  height: 64px;
`;

const Subheader = styled.div`
  font-size: 19px;
  font-weight: normal;
  font-stretch: normal;
  margin-bottom: 20px;
  margin-top: 5px;
`;

const IconsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 360px;
  padding: 30px;
  border-radius: 45px;
  border: solid 1px rgba(161, 161, 161, 0.01);
  background-color: #f8f8f8;
  align-items: center;
}
`;

const Tab = styled.div`
  width: 50%;
  display: inline-block;
  padding: 0px 0px 10px 0px;
  text-align: center;
  font-size: 15px;
  font-family: "Roboto", sans-serif;
  color: #222;
  cursor: pointer;
  transition: color 0.25s ease-in-out;
  @media (max-width: 768px) {
    padding: 0px 0px 10px 0px;
  }
`;

const FormsWapper = styled.div`
  width: 100%;
  margin-top: 30px;
    input[type="checkbox"]:checked ~ span {
        border: 1px solid #17bed0;
    }
`;
