import store from "../lib/state/store";

import {
  FILE_SECTION,
  SHARE_SUBSECTION,
  ADDTO_SUBSECTION,
  SHARE_SECTION,
  ARCHIVE_SECTION,
  DOWNLOAD_SECTION,
  EDIT_SECTION,
  INFO_SECTION,
  DELETE_SECTION,
  GRID_VIEW,
  GRID_ASPECT_RATIO_VIEW,
  LIST_VIEW,
  LARGE_LIST_VIEW,
  SORT,
  SORT_ASCENDING,
  SORT_DESCENDING,
  SORT_NEWEST_TOP,
  SORT_OLDEST_TOP,
  SORT_FOLDERS_FIRST,
  PREVIEW,
  PREVIEW_PUBLIC,
  OPEN,
  OPENDOC,
  EXTRACT,
  COMMENT,
  PLAY,
  PLAY_SELECTED,
  ADD_TO_PLAYER,
  ADD_TO_PLAYLIST,
  SHARE_LINK,
  INVITE_TO_FOLDER,
  MANAGE_SHARE,
  EDIT_SHARE,
  STOP_SHARE,
  REMOVE_SHARE,
  ACCEPT_SHARE,
  REJECT_SHARE,
  REQUEST_FILES,
  STOP_BACKUP,
  GET_LINK,
  DOWNLOAD,
  DOWNLOAD_RESIZED,
  DOWNLOAD_CONVERTED,
  DOWNLOAD_ARCHIVE,
  RESTORE,
  DELETE_FOREVER,
  DELETE,
  DELETE_LINK,
  MOVE,
  MOVE_PUBLIC,
  COPY,
  COPY_LINK,
  LINK_SETTINGS,
  RENAME,
  OPEN_LOCATION,
  FILE_INFO,
  FOLDER_INFO,
  REVISIONS,
  REVERT_REV,
  RESTORE_REV,
  EDIT_BOOKMARK,
  REMOVE_BOOKMARK,
  EXPORT_BOOKMARK,
  DEACTIVATE_PUBLIC_FOLDER,
  RESTORE_TIME, DOWNLOAD_TIME, EMPTY_TRASH, SHAREDLINK_SETTINGS, SHAREDLINK_BRANDING, SHOW_COMMENTS,
  SHAREDLINK_GENERAL_BRANDING, SHAREDLINK_STATS, SHAREDLINK_GET_TRAFFIC, DEFAULT_IEMS_INFO_PANEL_SETTING
} from "./constants";
import { CATEGORY } from "@pcloud/web-utilities/dist/config/constants";

import { setItemsInfoPanelData } from "../lib/state/reducers/contentSlice";
import hashManager from "../lib/hashManager";
import { copyTextToClipboard } from "../lib/utils";
import { getItemsInfoPanelSettingName } from "../../src/components/ItemsInfoPanel/helpers";

export const sendGAEvent = (action, location) => {
  if (typeof gtag === "function") {
    gtag("event", "content_options_click", {
      action: action,
      location: location || "unknown"
    })
  }
};

const moveOption = {
  icon: 'fa-regular fa-folder-tree',
  iconLight: 'fa-light fa-folder-tree',
  order: 3,
  optionTitle: ["Move"],
  sectionId: EDIT_SECTION,
  onClick: (list, type, location) => {
    sendGAEvent("move", location);

    FileSelection.reset();
    list.map(item => FileSelection.add(item));

    HFN.initMove(false);
  }
};

export const OPTIONS: OptionsDataType = {
  [PREVIEW]: {
    // done preview
    icon: 'fa-regular fa-image',
    iconLight: 'fa-light fa-image',
    order: 100,
    optionTitle: ["Preview"],
    sectionId: FILE_SECTION,
    onClick: (list, type, location) => {
      const data = list.length >= 1 ? list[0] : list;
      sendGAEvent("preview", location);
      HFN.previewFile(data);
    }
  },
  [PREVIEW_PUBLIC]: {
    // done previewdirectlink
    icon: 'fa-regular fa-image',
    iconLight: 'fa-light fa-image',
    order: 100,
    optionTitle: ["Preview"],
    sectionId: FILE_SECTION,
    onClick: (list, type, location) => {
      const data = list.length >= 1 ? list[0] : list;
      sendGAEvent("preview public link", location);
      window.open(HFN.getDirectLink(data));
    }
  },
  [OPEN]: {
    //done open
    icon: 'fa-regular fa-folder-magnifying-glass',
    iconLight: 'fa-light fa-folder-magnifying-glass',
    order: 5,
    optionTitle: ["Open"],
    sectionId: FILE_SECTION,
    onClick: (list, type, location) => {
      const data = list.length >= 1 ? list[0] : list;
      const { folderid } = data;
      sendGAEvent("open", location);
      HFN.openFolder(data.folderid);
    }
  },
  [OPENDOC]: {
    icon: 'fa-regular fa-file-magnifying-glass',
    iconLight: 'fa-light fa-file-magnifying-glass',
    order: 100,
    optionTitle: ["Open"],
    sectionId: FILE_SECTION,
    onClick: (list, type, location) => {
      const data = list.length >= 1 ? list[0] : list;
      sendGAEvent("open document", location);

      HFN.openCollaborationDocument(data, function (data, error) {
        HFN.message(error, 'error');
      });
    }
  },
  [EXTRACT]: {
    //done extract
    icon: 'fa-regular fa-file-zipper',
    iconLight: 'fa-light fa-file-zipper',
    order: 100,
    optionTitle: ["Extract"],
    sectionId: FILE_SECTION,
    onClick: (list, type, location) => {
      const data = list.length >= 1 ? list[0] : list;
      sendGAEvent("extract / copy link", location);

      if (type === "publinks" || type === "fileRequests") {
        HFN.initExportLinks(data);
      } else {
        HFN.initExtract(data);
      }
    }
  },
  [COMMENT]: {
    //done comment
    icon: 'fa-regular fa-message-plus',
    iconLight: 'fa-light fa-message-plus',
    order: 100,
    optionTitle: ["Add Comment"],
    sectionId: FILE_SECTION,
    onClick: (list, type, location) => {
      const data = list.length >= 1 ? list[0] : list;

      sendGAEvent("add comment", location);

      store.dispatch(setItemsInfoPanelData({
        show: true,
        // Check if we are on the same item, don't change the selected tab.
        tab: "comments", 
        // Copy to trigger reload initial data.
        item: {...data},
        autoFocus: { value: true }
      }));
    }
  },
  [SHOW_COMMENTS]: {
    //done comment
    icon: 'fa-regular fa-message',
    iconLight: 'fa-light fa-message',
    order: 100,
    optionTitle: ["View Comments"],
    sectionId: FILE_SECTION,
    onClick: (list, type, location) => {
      const data = list.length >= 1 ? list[0] : list;

      sendGAEvent("view comments", location);

      store.dispatch(setItemsInfoPanelData({
        show: true,
        // Check if we are on the same item, don't change the selected tab.
        tab: "comments", 
        // Copy to trigger reload initial data.
        item: {...data},
        autoFocus: { value: false }
      }));
    }
  },
  [PLAY]: {
    //done
    icon: 'fa-regular fa-play-circle',
    iconLight: 'fa-light fa-play-circle',
    order: 100,
    optionTitle: ["Play"],
    sectionId: FILE_SECTION,
    onClick: (list, type, location) => {
      const data = list.length >= 1 ? list[0] : list;
      sendGAEvent("play", location);

      if (hashManager.getState('autab') === "tab-artists") {
        HFN.audioPlayer.play(HFN.getArtistSongs(data));
      } else if (hashManager.getState('autab') === "tab-albums") {
        HFN.audioPlayer.play(HFN.getAlbumSongs(data));
      } else if (hashManager.getState('autab') === "tab-playlists") {
        HFN.getPlaylist(data.id, function (plist) {
          HFN.audioPlayer.loadPlaylist(plist);
        });
      } else {
        HFN.previewFile(data);
      }
    }
  },
  [PLAY_SELECTED]: {
    //done
    icon: 'fa-regular fa-play-circle',
    iconLight: 'fa-light fa-play-circle',
    order: 100,
    optionTitle: ["Play Selected"],
    sectionId: FILE_SECTION,
    onClick: (list, type, location) => {
      FileSelection.reset();
      list.map(item => FileSelection.add(item));
      sendGAEvent("play selected", location);

      if (hashManager.getState('autab') === "tab-albums") {
        HFN.audioPlayer.play(HFN.gatherFSAlbumSongs(FileSelection.getSelectionInfo()));
      } else if (FileSelection.getSelectionInfo()[0].albums) {
        HFN.audioPlayer.play(HFN.gatherFSArtistSongs(FileSelection.getSelectionInfo()));
      } else {
        HFN.audioPlayer.play(FileSelection.filter(FileSelection.getSelectionInfo(), { category: CATEGORY.AUDIO }));
      }
    }
  },
  [ADD_TO_PLAYER]: {
    //done playeradd
    icon: 'fa-regular fa-album-circle-plus',
    iconLight: 'fa-light fa-album-circle-plus',
    order: 100,
    optionTitle: ["Add to Player"],
    sectionId: FILE_SECTION,
    subSectionId: ADDTO_SUBSECTION,
    onClick: (list, type, location) => {
      const data = list.length >= 1 ? list[0] : list;
      sendGAEvent("add to player", location);

      if (hashManager.getState('autab') === "tab-artists") {
        HFN.audioPlayer.addToPlayer(HFN.getArtistSongs(data));
      } else if (hashManager.getState('autab') === "tab-playlists") {
        HFN.getPlaylist(data.id, function (plist) {
          HFN.audioPlayer.addToPlayer(plist.contents);
        });
      } else {
        HFN.audioPlayer.addToPlayer(list);
      }
    }
  },
  [ADD_TO_PLAYLIST]: {
    //done playlistadd
    icon: 'fa-regular fa-rectangle-history-circle-plus',
    iconLight: 'fa-light fa-rectangle-history-circle-plus',
    order: 100,
    optionTitle: ["Add to Playlist"],
    sectionId: FILE_SECTION,
    subSectionId: ADDTO_SUBSECTION,
    onClick: (list, type, location) => {
      sendGAEvent("add to playlist", location);

      if (hashManager.getState('autab') === "tab-artists") {
        const data = list.length > 1 ? HFN.gatherFSArtistSongs(list) : HFN.getArtistSongs(list[0]);
        HFN.playlistAdd(data);
      } else {
        HFN.playlistAdd(list);
      }
    }
  },
  [SHARE_LINK]: {
    //done getpublink
    icon: 'fa-regular fa-link',
    iconLight: 'fa-light fa-link',
    order: 1,
    optionTitle: ["Share Download Link"],
    sectionId: SHARE_SECTION,
    subSectionId: SHARE_SUBSECTION,
    onClick: (list, type, location) => {
      sendGAEvent("share link", location);

      if (list.length == 1) {
        HFN.getOrCreatePublink(
          list[0],
          linkData => {
            const { link } = linkData;
            const message = __("preview_toast_msg", "Share link copied to the clipboard.");
            const buttonText = __("preview_toast_msg_link", "SETTINGS");

            copyTextToClipboard(link, "");

            HFN.message(message, "custom", true, true, false, true, {}, false, "dark", buttonText, () => {
              HFN.sharePublink(linkData);
            });
          },
          {
            async: false,
            errorCallback(ret) {
              if (HFN.ERROR_MESSAGE[ret.result]) {
                HFN.message(__(HFN.ERROR_MESSAGE[ret.result]), "error");
              } else {
                HFN.message(ret.error, "error");
              }
            }
          }
        );
      } else {
        FileSelection.reset();
        list.map(item => FileSelection.add(item));
        HFN.initPublinkTree();
      }
    }
  },
  [INVITE_TO_FOLDER]: {
    //done shareall
    icon: 'fa-regular fa-user-plus',
    iconLight: 'fa-light fa-user-plus',
    order: 2,
    optionTitle: ["Share Folder"],
    sectionId: SHARE_SECTION,
    subSectionId: SHARE_SUBSECTION,
    onClick: (list, type, location) => {
      const data = list.length >= 1 ? list[0] : list;
      sendGAEvent("invite to folder", location);

      HFN.initShareFolder(data);
    }
  },
  [MANAGE_SHARE]: {
    icon: 'fa-regular fa-user-plus',
    iconLight: 'fa-light fa-user-plus',
    order: 2,
    optionTitle: ["Share Folder"],
    sectionId: EDIT_SECTION,
    onClick: (list, type, location) => {
      const data = list.length >= 1 ? list[0] : list;
      sendGAEvent("invite to folder", location);

      HFN.initShareFolder(data);
    }
  },
  [EDIT_SHARE]: {
    icon: 'fa-regular fa-user-cog',
    iconLight: 'fa-light fa-user-cog',
    order: 3,
    optionTitle: ["Edit Share"],
    sectionId: EDIT_SECTION,
    onClick: (list, type, location) => {
      sendGAEvent("edit share", location);

      HFN.initShareFolder(list[0], 2);
    }
  },
  [STOP_SHARE]: {
    icon: 'fa-regular fa-trash-alt',
    iconLight: 'fa-light fa-trash-alt',
    order: 4,
    optionTitle: ["Stop Share"],
    sectionId: EDIT_SECTION,
    onClick: (list, type, location) => {
      sendGAEvent("stop share", location);

      HFN.initStopAllShares(list[0]);
    }
  },
  [REMOVE_SHARE]: {
    icon: 'fa-regular fa-trash-alt',
    iconLight: 'fa-light fa-trash-alt',
    order: 4,
    optionTitle: ["Remove Share"],
    sectionId: EDIT_SECTION,
    onClick: (list, type, location) => {
      sendGAEvent("remove share", location);

      HFN.initRemoveShare(list[0]);
    }
  },
  [ACCEPT_SHARE]: {
    icon: 'smallIcon fa-solid fa-check',
    optionTitle: ["Accept"],
    onClick: (list, type, location) => {
      sendGAEvent("accept share", location);

      HFN.acceptShare(list[0]);
    }
  },
  [REJECT_SHARE]: {
    icon: 'smallIcon fa-regular fa-xmark',
    optionTitle: ["Decline"],
    onClick: (list, type, location) => {
      sendGAEvent("decline share", location);

      HFN.rejectShare(list[0]);
    }
  },
  [REQUEST_FILES]: {
    //done getpuplink
    icon: 'fa-regular fa-file-plus',
    iconLight: 'fa-light fa-file-plus',
    order: 100,
    optionTitle: ["create_file_request"],
    sectionId: SHARE_SECTION,
    onClick: (list, type, location) => {
      const data = list.length >= 1 ? list[0] : list;
      sendGAEvent("request files", location);

      HFN.getPuplink(data, function (link) {
        if (link) {
          HFN.viewPuplink(link);
        } else {
          HFN.createPuplink(data);
        }
      });
    }
  },
  [LINK_SETTINGS]: {
    icon: 'fa-regular fa-gear',
    iconLight: 'fa-light fa-gear',
    order: 100,
    optionTitle: ["linksettings", "Settings"],
    sectionId: EDIT_SECTION,
    onClick: (data, type, location) => {
      sendGAEvent("upload link settings", location);

      HFN.viewPuplink(data[0]);
    }
  },
  [STOP_BACKUP]: {
    icon: 'fa-regular fa-cloud-slash',
    iconLight: 'fa-light fa-cloud-slash',
    order: 100,
    optionTitle: ["Stop Backup"],
    sectionId: SHARE_SECTION,
    onClick: (list, type, location) => {
      const data = list.length >= 1 ? list[0] : list;
      sendGAEvent("stop backup", location);

      HFN.initStopDesktopBackup(data);
    }
  },
  [GET_LINK]: {
    // done embeddirectlink
    icon: 'fa-regular fa-link',
    iconLight: 'fa-light fa-link',
    order: 1,
    optionTitle: ["Get Link"],
    sectionId: SHARE_SECTION,
    onClick: (list, type, location) => {
      const data = list.length >= 1 ? list[0] : list;
      sendGAEvent("get link", location);

      HFN.initEmbedDirectLink(data);
    }
  },
  [DOWNLOAD_ARCHIVE]: {
    // done downloadzip
    icon: 'fa-regular fa-box-archive',
    iconLight: 'fa-light fa-box-archive',
    order: 2,
    optionTitle: ["Download Archive"],
    sectionId: ARCHIVE_SECTION,
    onClick: (list, type, location) => {
      FileSelection.reset();
      list.map(item => FileSelection.add(item));
      sendGAEvent("download archive", location);

      const tm_key = "";

      if (type === "sharedlinkView") {
        HFN.initDownloadArchivePublic();
      } else if (type === "revisionView") {

      } else {
        HFN.initDownloadArchive(false, tm_key);
      }
    }
  },
  [DOWNLOAD]: {
    //done
    icon: 'fa-regular fa-arrow-down-to-bracket',
    iconLight: 'fa-light fa-arrow-down-to-bracket',
    order: 2,
    optionTitle: ["Download"],
    sectionId: DOWNLOAD_SECTION,
    onClick: (list, type, location) => {
      const data = list.length >= 1 ? list[0] : list;
      const tm_key = ""; // TO DO
      sendGAEvent("download", location);

      if (type === "sharedlinkView") {
        HFN.initDownloadPublic();
      } else if (type === "revisionView") {
        if (list.length == 1 && list[0].encrypted) {
          pCloudCrypto.initFileDownload(list[0]);
        } else if (list.length == 1 && list[0].isfolder) {
          HFN.initDownloadArchive(false, tm_key);
        } else {
          HFN.previewGeneric(list[0], hashManager.getState('code'), false, tm_key);
        }
      } else {
        if (data.encrypted) {
          pCloudCrypto.initFileDownload(data);
        } else if ($.browser.msie && versionCompare($.browser.version, '10.0') == -1) {
          HFN.downloadFile(data.fileid);
        } else {
          var params = { fileid: data.fileid, forcedownload: 1 };
          if (data.revisionid) {
            params.revisionid = data.revisionid;
            params.name = data.name.replace(fileext(data.name))
            params.name = data.name.replace(/\.([^\.]+)$/g, "-r" + data.revisionid + ".$1");
          }
          HFN.downloadLinkFile(params);
        }
      }
    }
  },
  [DOWNLOAD_RESIZED]: {
    //done
    icon: 'fa-regular fa-expand-wide',
    iconLight: 'fa-light fa-expand-wide',
    order: 100,
    optionTitle: ["Download Resized"],
    sectionId: DOWNLOAD_SECTION,
    onClick: (list, type, location) => {
      const data = list.length >= 1 ? list[0] : list;
      sendGAEvent("download resized", location);
      HFN.initDownloadImageSizes(data);
    }
  },
  [DOWNLOAD_CONVERTED]: {
    icon: 'fa-regular fa-file-video',
    iconLight: 'fa-light fa-file-video',
    order: 100,
    optionTitle: ["Download Converted"],
    sectionId: DOWNLOAD_SECTION,
    onClick: (list, type, location) => {
      const data = list.length >= 1 ? list[0] : list;
      sendGAEvent("download converted", location);

      HFN.initDownloadConverts(data);
    }
  },
  // Trash
  [RESTORE]: {
    icon: 'fa-regular fa-trash-arrow-up',
    iconLight: 'fa-light fa-trash-arrow-up',
    order: 100,
    optionTitle: ["Restore"],
    sectionId: EDIT_SECTION,
    onClick: (list, type, location) => {
      sendGAEvent("restore", location);

      if (type === "revisionsView") {
        const tm_key = ""; // TO DO: get tm_key
        const time = "";// TO DO: tm_key
        HFN.initRestoreTime(tm_key, time);
      } else {
        FileSelection.reset();
        list.map(item => FileSelection.add(item));

        HFN.initTrashRestore();
      }
    }
  },
  // Trash
  [DELETE_FOREVER]: {
    icon: 'fa-regular fa-trash-xmark',
    iconLight: 'fa-light fa-trash-xmark',
    order: 100,
    optionTitle: ["Delete Forever"],
    sectionId: EDIT_SECTION,
    onClick: (list, type, location) => {
      FileSelection.reset();
      list.map(item => FileSelection.add(item));
      sendGAEvent("delete forever", location);

      HFN.initTrashDelete();
    }
  },
  // Empty  Trash
  [EMPTY_TRASH]: {
    icon: 'fa-regular fa-trash-xmark',
    iconLight: 'fa-light fa-trash-xmark',
    order: 100,
    optionTitle: ["Empty Trash"],
    sectionId: EDIT_SECTION,
    onClick: (list, type, location) => {
      sendGAEvent("empty trash", location);

      HFN.initEmptyTrashAll();
    }
  },
  [DELETE]: {
    //done
    icon: 'fa-regular fa-trash-alt',
    iconLight: 'fa-light fa-trash-alt',
    order: 100,
    optionTitle: ["Delete"],
    sectionId: EDIT_SECTION,
    onClick: (list, type, location) => {
      FileSelection.reset();
      list.map(item => FileSelection.add(item));
      sendGAEvent("delete", location);

      const data = list.length >= 1 ? list[0] : list;
      if (type === "publinks" || type === "fileRequests") {
        HFN.initDeleteLinks(false);
      } else if (hashManager.getState('autab') === "tab-playlist" || hashManager.getState('autab') === "tab-playlists") {
        HFN.initDeletePlaylist(data);
      } else {
        HFN.deleteItem(false, triggerOpenFolder.bind(null, null, false, true));
      }
    }
  },
  [DELETE_LINK]: {
    icon: 'fa-regular fa-trash-alt',
    iconLight: 'fa-light fa-trash-alt',
    order: 100,
    optionTitle: ["Remove link"],
    sectionId: EDIT_SECTION,
    onClick: (list, type, location) => {
      FileSelection.reset();
      list.map(item => FileSelection.add(item));

      sendGAEvent("delete upload link", location);

      HFN.initDeleteLinks();
    }
  },
  [MOVE]: {
    ...moveOption
  },
  [MOVE_PUBLIC]: {
    ...moveOption,
    order: 4
  },
  [COPY]: {
    //done copy
    icon: 'fa-regular fa-copy',
    iconLight: 'fa-light fa-copy',
    order: 100,
    optionTitle: ["Copy"],
    sectionId: EDIT_SECTION,
    onClick: (list, type, location) => {
      FileSelection.reset();
      list.map(item => FileSelection.add(item));
      sendGAEvent("copy", location);

      const data = list.length >= 1 ? list[0] : list;
      if (type === "sharedlinkView") {
        if (!HFN.config.auth) {
          HFN.initSaveFileLoginRegModal(function () {
            HFN.copyToCloud({
              code: hashManager.getState("code") || $.deparam.querystring().code,
              linkpassword: grid.opts.linkpassword,
              onDone: function (ret) {
                // HFN.message(__('File is copied.'));
              }
            });
          });
        } else {
          HFN.copyToCloud({
            code: hashManager.getState("code") || $.deparam.querystring().code,
            linkpassword: grid.opts.linkpassword,
            onDone: function (ret) {
              // HFN.message(__('File is copied.'));
            }
          });
        }
      } else {
        HFN.initCopy(false);
      }
    }
  },
  [COPY_LINK]: {
    icon: 'fa-regular fa-copy',
    iconLight: 'fa-light fa-copy',
    order: 100,
    optionTitle: ["shared_links_settings_copy_link", "Copy"],
    sectionId: EDIT_SECTION,
    onClick: (list, type, location) => {
      FileSelection.reset();
      list.map(item => FileSelection.add(item));

      sendGAEvent("copy link", location);

      HFN.initCopyLinks();
    }
  },
  [RENAME]: {
    //done rename
    icon: 'fa-regular fa-pen',
    iconLight: 'fa-light fa-pen',
    order: 100,
    optionTitle: ["Rename"],
    sectionId: EDIT_SECTION,
    onClick: (list, type, location) => {
      const data = list.length >= 1 ? list[0] : list;
      sendGAEvent("rename", location);

      if (hashManager.getState('autab') === "tab-playlist" || hashManager.getState('autab') === "tab-playlists") {
        HFN.initRenamePlaylist(data);
      } else {
        HFN.initRename(data);
      }
    }
  },
  [OPEN_LOCATION]: {
    // done openloc
    icon: 'fa-regular fa-folder',
    iconLight: 'fa-light fa-folder',
    order: 100,
    optionTitle: ["Open Location"],
    sectionId: INFO_SECTION,
    onClick: (list, type, location) => {
      const data = list.length >= 1 ? list[0] : list;
      sendGAEvent("open location", location);

      hashManager.pushState({ page: 'filemanager', folder: data.parentfolderid, file: data.id }, 2);
    }
  },
  [FILE_INFO]: {
    //done info
    icon: 'fa-regular fa-info-circle',
    iconLight: 'fa-light fa-info-circle',
    order: 100,
    optionTitle: ["File Info"],
    sectionId: INFO_SECTION,
    onClick: (list, type, location) => {
      const data = list.length >= 1 ? list[0] : list;
      sendGAEvent("file info", location);
      // HFN.itemInfo(data);
      store.dispatch(setItemsInfoPanelData({
        show: true,
        // Check if we are on the same item, don't change the selected tab.
        tab: "info", 
        item: data,
        autoFocus: { value: false },
        fromOptionsMenu: true,
        selectedFiles: store.getState()?.content?.itemData?.items[data.id] && (localStorage.getItem(getItemsInfoPanelSettingName(store.getState()?.user?.userinfo?.userid)) || DEFAULT_IEMS_INFO_PANEL_SETTING) === "1" ? new Map([[data.id, data]]) : new Map()
      }));
    }
  },
  [FOLDER_INFO]: {
    //done info
    icon: 'fa-regular fa-info-circle',
    iconLight: 'fa-light fa-info-circle',
    order: 100,
    optionTitle: ["Folder info"],
    sectionId: INFO_SECTION,
    onClick: (list, type, location) => {
      const data = list.length >= 1 ? list[0] : list;
      sendGAEvent("folder info", location);

      // HFN.itemInfo(data);
      store.dispatch(setItemsInfoPanelData({
        show: true,
        // Check if we are on the same item, don't change the selected tab.
        tab: "info", 
        item: data,
        autoFocus: { value: false },
        fromOptionsMenu: true,
        selectedFolders: store.getState()?.content?.itemData?.items[data.id] && (localStorage.getItem(getItemsInfoPanelSettingName(store.getState()?.user?.userinfo?.userid)) || DEFAULT_IEMS_INFO_PANEL_SETTING) === "1" ? new Map([[data.id, data]]) : new Map()
      }));
    }
  },
  [REVISIONS]: {
    // done revisions
    icon: 'fa-regular fa-calendar-clock',
    iconLight: 'fa-light fa-calendar-clock',
    order: 100,
    optionTitle: ["Revisions"],
    sectionId: INFO_SECTION,
    onClick: (list, type, location) => {
      const data = list.length >= 1 ? list[0] : list;
      sendGAEvent("revision", location);

      hashManager.pushState({ page: 'revisions', fileid: data.fileid }, 2);
    }
  },
  [REVERT_REV]: {
    //done revertrev
    icon: 'fa-regular fa-clock-rotate-left',
    iconLight: 'fa-light fa-clock-rotate-left',
    order: 100,
    optionTitle: ["Revert to this Revision"],
    sectionId: INFO_SECTION,
    onClick: (list, type, location) => {
      const data = list.length >= 1 ? list[0] : list;
      sendGAEvent("revert to this revesion", location);

      HFN.initRevertRev(data);
    }
  },
  [RESTORE_REV]: {
    //done revertrev
    icon: 'fa-regular fa-file-plus',
    iconLight: 'fa-light fa-file-plus',
    order: 100,
    optionTitle: ["Restore as new file"],
    sectionId: INFO_SECTION,
    onClick: (list, type, location) => {
      const data = list.length >= 1 ? list[0] : list;
      sendGAEvent("restore as new file", location);

      HFN.initRestoreRev(data);
    }
  },
  [RESTORE_TIME]: {
    icon: 'fa-regular fa-clock-rotate-left',
    iconLight: 'fa-light fa-clock-rotate-left',
    order: 100,
    optionTitle: ["Restore"],
    sectionId: INFO_SECTION,
    onClick: (list, type, location) => {
      const state = HFN.state.getState().content?.itemData;
      sendGAEvent("restore", location);

      if (
        state &&
        state.hasOwnProperty('time') &&
        state.hasOwnProperty('tm_key')
      ) {
        FileSelection.reset();
        list.map(item => FileSelection.add(item));
        HFN.initRestoreTime(state.tm_key, state.time, list);
      }
    }
  },
  [DOWNLOAD_TIME]: {
    icon: 'fa-regular fa-arrow-down-to-bracket',
    iconLight: 'fa-light fa-arrow-down-to-bracket',
    order: 2,
    optionTitle: ["Download"],
    sectionId: INFO_SECTION,
    onClick: (list, type, location) => {
      const state = HFN.state.getState().content?.itemData;
      sendGAEvent("download at time", location);

      if (
        state &&
        state.hasOwnProperty('time') &&
        state.hasOwnProperty('tm_key')
      ) {
        FileSelection.reset();
        list.map(item => FileSelection.add(item));
        if (list.length > 1 || list[0].isfolder) {
          HFN.initDownloadArchive(null, state.tm_key)
        } else {
          var params = {
            fileid: list[0].fileid,
            forcedownload: 1,
            tmkey: state.tm_key,
            date: state.time
          };

          HFN.apiMethod('tm_getfilelink', params, function (ret) {
            window.location.href = HFN.prepUrl(ret);
          });
        }
      }
    }
  },
  [EDIT_BOOKMARK]: {
    icon: 'fa-regular fa-pen',
    iconLight: 'fa-light fa-pen',
    order: 100,
    optionTitle: ["Edit"],
    sectionId: EDIT_SECTION,
    onClick: (list, type, location) => {
      const data = list.length >= 1 ? list[0] : list;
      sendGAEvent("edit", location);

      HFN.initEditBookmarks(data);
    }
  },
  [EXPORT_BOOKMARK]: {
    icon: 'fa-regular fa-link',
    iconLight: 'fa-light fa-link',
    order: 100,
    optionTitle: ["copy_link", "Copy link"],
    sectionId: EDIT_SECTION,
    onClick: (list, type, location) => {
      sendGAEvent("copy bookmark link", location);

      HFN.initExportLinks(list);
    }
  },
  [REMOVE_BOOKMARK]: {
    icon: 'fa-regular fa-trash-alt',
    iconLight: 'fa-light fa-trash-alt',
    order: 100,
    optionTitle: ["Remove bookmark"],
    sectionId: EDIT_SECTION,
    onClick: (list, type, location) => {
      sendGAEvent("remove bookmark", location);

      HFN.initDeleteBookmakrs(list);
    }
  },
  [DEACTIVATE_PUBLIC_FOLDER]: {
    icon: 'fa-regular fa-circle-stop',
    iconLight: 'fa-light fa-circle-stop',
    order: 3,
    optionTitle: ["deactivate_public_folder_action", "Deactivate Public Folder"],
    sectionId: SHARE_SECTION,
    onClick: (list, type, location) => {
      sendGAEvent("deactivate public folder", location);

      HFN.initDeactivatePublicFolder();
    }
  },
  [SHAREDLINK_SETTINGS]: {
    icon: 'fa-regular fa-gear',
    iconLight: 'fa-light fa-gear',
    order: 100,
    optionTitle: ["linksettings", "Settings"],
    sectionId: EDIT_SECTION,
    onClick: (data, type, location) => {
      sendGAEvent("share link settings", location);

      HFN.getPublinkById(data[0].linkid, function (link) {
        HFN.sharePublink(link, {
          openTab: ".linksettings"
        });
      });
    }
  },
  [SHAREDLINK_BRANDING]: {
    icon: 'fa-regular fa-palette',
    iconLight: 'fa-light fa-palette',
    order: 100,
    optionTitle: ["edit_branding", "Edit Branding"],
    sectionId: EDIT_SECTION,
    onClick: (data, type, location) => {
      const state = store.getState();
      const defaultBranding = state.content.defaultBranding || {};
      const sharedLinks = state.content.itemData.items;
      const customLinks = Object.values(sharedLinks).filter(link => link.custombranding) || 0;
      const customLinksLength = customLinks.length;
      const sharedLink = data[0];
      let brandingData;

      if (sharedLink.custombranding) {
        brandingData = {
          isActiveCL: true,
          isActiveGL: defaultBranding.isactive,
          brandingType: "custom"
        }

        if (typeof gtag === "function") {
          gtag("event", "shared_links_page_click", {
            action: "initiate branding setting change",
            category: "setting",
            type: (defaultBranding.isactive || HFN.config.user.premium || HFN.config.user.business) ? "premium" : "free",
            user_premium: (HFN.config.user.premium || HFN.config.user.business) ? 1 : 0,
            location: "shared links actions"
          })
        }

        HFN.openEditBrandingModal(sharedLink, brandingData, event);
      } else if (defaultBranding.isactive) {
        brandingData = {
          isActiveCL: false,
          isActiveGL: true,
          brandingType: "custom"
        }

        if (typeof gtag === "function") {
          gtag("event", "shared_links_page_click", {
            action: "initiate branding setting change",
            category: "setting",
            type: "premium",
            user_premium: (HFN.config.user.premium || HFN.config.user.business) ? 1 : 0,
            location: "shared links actions"
          })
        }

        HFN.openEditBrandingModal(sharedLink, brandingData, event);
      } else {
        if (!(HFN.config.user.premium || HFN.config.user.business) && customLinksLength > 0) {
          if (typeof gtag === "function") {
            gtag("event", "shared_links_page_click", {
              action: "initiate branding setting change",
              category: "setting",
              type: (customLinksLength > 0 || defaultBranding.isactive || HFN.config.user.premium || HFN.config.user.business) ? "premium" : "free",
              user_premium: (HFN.config.user.premium || HFN.config.user.business) ? 1 : 0,
              location: "shared links actions"
            })
          }

          HFN.message(__('branding_upgrade_to_premium') + "\n" + HFN.siteUrl("cloud-storage-pricing-plans.html"));
        } else {
          brandingData = {
            isActiveCL: false,
            isActiveGL: false,
            brandingType: "custom",
          }

          if (typeof gtag === "function") {
            gtag("event", "shared_links_page_click", {
              action: "initiate branding setting change",
              category: "setting",
              type: (customLinksLength > 0 || defaultBranding.isactive || HFN.config.user.premium || HFN.config.user.business) ? "premium" : "free",
              user_premium: (HFN.config.user.premium || HFN.config.user.business) ? 1 : 0,
              location: "shared links actions"
            })
          }

          HFN.openEditBrandingModal(sharedLink, brandingData, event);
        }
      }
    }
  },
  [SHAREDLINK_GENERAL_BRANDING]: {
    icon: 'baseIcon darkColorIcon fa-solid fa-palette',
    optionTitle: ["branding-left-menu-title", "Branding"],
    onClick: () => {
      if (typeof gtag === "function") {
        gtag("event", "shared_links_page_click", {
          action: "open brandlinks page",
          category: "page",
          location: "shared links table header"
        })
      }

      hashManager.pushState({ page: 'brandlinks' }, 2);
    }
  },
  [SHAREDLINK_STATS]: {
    icon: 'baseIcon darkColorIcon fa-regular fa-chart-column',
    optionTitle: ["Statistics"],
    onClick: () => {
      if (typeof gtag === "function") {
        gtag("event", "shared_links_page_click", {
          action: "open linkstats page",
          category: "page",
          location: "shared links table header"
        })
      }

      hashManager.pushState({ page: 'linkstats' }, 2);
    }
  },
  [SHAREDLINK_GET_TRAFFIC]: {
    optionTitle: ["get_traffic_button", "Get traffic"],
    onClick: () => {
      if (typeof gtag === "function") {
        gtag("event", "shared_links_page_click", {
          action: "buy traffic",
          category: "conversion",
          location: "shared links table header"
        })
      }

      window.open(HFN.config.URLSITE + "additional-traffic.html", "_blank");
    }
  },
  [ADDTO_SUBSECTION]: {
    icon: 'fa-regular fa-plus-circle',
    iconLight: 'fa-light fa-plus-circle',
    optionTitle: ["Add to ..."],
  },
  [SHARE_SUBSECTION]: {
    icon: 'fa-regular fa-link',
    iconLight: 'fa-light fa-link',
    optionTitle: ["Share"],
  },
  [GRID_VIEW]: {
    icon: 'fa-light fa-table-cells-large',
    iconSolid: 'fa-solid fa-table-cells-large',
    optionTitle: ["grid_view", "Grid view"],
    template: "foldergrid"
  },
  [GRID_ASPECT_RATIO_VIEW]: {
    icon: 'fa-light fa-expand-wide',
    iconSolid: 'fa-solid fa-expand-wide',
    optionTitle: ["aspect_ratio_grid_view", "Aspect ratio grid"],
    template: "foldergridar"
  },
  [LIST_VIEW]: {
    icon: 'fa-light fa-bars',
    iconSolid: 'fa-solid fa-bars',
    optionTitle: ["list_view", "List view"],
    template: "folderlist"
  },
  [LARGE_LIST_VIEW]: {
    icon: 'fa-light fa-line-height',
    iconSolid: 'fa-solid fa-line-height',
    optionTitle: ["large_list_view", "Large list view"],
    template: "folderlglist"
  },
  [SORT]: {
    icon: 'fa-solid fa-bars-sort',
    optionTitle: ["Sort by"],
  },
  [SORT_ASCENDING]: {
    icon: 'fa-light fa-arrow-down-a-z',
    optionTitle: ["Ascending"],
  },
  [SORT_DESCENDING]: {
    icon: 'fa-light fa-arrow-down-z-a',
    optionTitle: ["Descending"],
  },
  [SORT_NEWEST_TOP]: {
    icon: 'fa-light fa-arrow-down-short-wide',
    optionTitle: ["Newest Top"],
  },
  [SORT_OLDEST_TOP]: {
    icon: 'fa-light fa-arrow-down-wide-short',
    optionTitle: ["Oldest Top"],
  },
  [SORT_FOLDERS_FIRST]: {
    icon: 'fa-light fa-folder-bookmark',
    optionTitle: ["Folders First"],
  },
};
