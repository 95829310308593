// @flow

import * as React from "react";

import Button from "../components/ButtonDefault";
import GridDownloadLinkCountryStats from "../components/downloadlink/CountryStats";
import Modal, {
  ModalWrap,
  OvalModalWrap,
  OvalModalClose,
  OvalModalCloseSimple,
  OvalModalCloseV2,
  CloseModalIcon,
  OvalModalBody,
  ModalTop,
  ModalBody,
  ModalButtonArea,
  SharpModalClose
} from "@pcloud/web-utilities/dist/resources/components/Modal";

export default Modal;

class ExampleModal extends React.Component<
  { children: React.Element<any> },
  { opened: boolean }
> {
  constructor() {
    super();
    this.state = { opened: false };

    (this: any)._close = this._close.bind(this);
    (this: any)._open = this._open.bind(this);
  }
  _open() {
    this.setState({ opened: true });
  }
  _close() {
    this.setState({ opened: false });
    //this.setState(prevState => ({ opened: !prevState.opened }));
  }
  render() {
    return (
      <div>
        <a href="javascript:;" onClick={this._open}>
          Toggle Modal
        </a>
        <Modal align="top" opened={this.state.opened} onClose={this._close}>
          <ModalTop onClose={this._close}>Example Modal</ModalTop>
          <ModalBody style={{ minWidth: "400px" }}>
            <GridDownloadLinkCountryStats
              list={[
                {
                  country: "France",
                  views: 5,
                  traffic: 2500,
                  downloads: 3
                },
                {
                  country: "USA",
                  views: 7,
                  traffic: 2800,
                  downloads: 5
                },
                {
                  country: "Germany",
                  views: 6,
                  traffic: 3500,
                  downloads: 2
                },
                {
                  country: "Turkey",
                  views: 5,
                  traffic: 2500,
                  downloads: 3
                }
              ]}
            />
          </ModalBody>
          <ModalButtonArea>
            <Button color="black" onClick={this._close} inline>
              Close
            </Button>
          </ModalButtonArea>
        </Modal>
      </div>
    );
  }
}

export {
  ExampleModal,
  ModalTop,
  ModalBody,
  ModalButtonArea,
  ModalWrap,
  OvalModalWrap,
  OvalModalClose,
  OvalModalCloseSimple,
  OvalModalCloseV2,
  CloseModalIcon,
  OvalModalBody,
  SharpModalClose
};
