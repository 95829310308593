// @flow
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { hiDPI } from "polished";

import Componentify from "react-componentify";
import { __ } from "../../lib/translate";
import apiMethod from "../../api/apiMethod";
import { emailInitials, getNames, userInitials } from "../../lib/user-utils";
import { boldConverter } from "../../lib/componentifyConverters";

import Tooltip from "../../components/Tooltip";
import { AvatarWrap } from "../../components/UserAvatar";
import Modal, {
    OvalModalWrap,
    ModalBody, OvalModalBody, OvalModalCloseV2
} from "@pcloud/web-utilities/dist/resources/components/Modal";
import {
    LS_CRYPTO_TRANSITION_NOTIFIED_USERS_KEY,
    LS_CRYPTO_TRANSITION_STARTED
} from "@pcloud/web-utilities/dist/config/constants";
import { Content, Header, Wrapper, Button } from "./styles";

const getActiveUsersWithCryptoWithOutOwner = (user) => {
    return (
        user.cryptosetup === true &&
        user.active === true &&
        user.id != HFN.config.user.userid
    );
};

const UpdateCrypto = () => {
    const [userList, setUserList] = useState([]);
    const token = useSelector(({ pCloudUser }) => pCloudUser.token);
    const [ownerNotified, setOwnerNotified] = useState(false);
    const [notifiedUsers, setNotifiedUsers] = useState(
        JSON.parse(
            localStorage.getItem(LS_CRYPTO_TRANSITION_NOTIFIED_USERS_KEY)
        ) || []
    );
    const [sendingNotifications, setSendingNotifications] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [forceResetSent, setForceResetSent] = useState(false);
    const businessUsers = useSelector(({ business }) => business.users);

    useEffect(() => {
        setUserList(businessUsers.filter(getActiveUsersWithCryptoWithOutOwner));
        localStorage.setItem(LS_CRYPTO_TRANSITION_STARTED, true);
    }, []);

    const notifyAll = () => {
        const wait = [];
        userList.forEach((user) => {
            wait.push(
                apiMethod(
                    "account_cryptoresetmail",
                    { auth: token, userid: user.id },
                    (res) => {
                        res.userid = user.id;
                        return res;
                    }
                )
            );
        });
        setSendingNotifications(true);
        Promise.all(wait).then((results) => {
            let notifiedUsers = results
                .filter((res) => res && res.result == 0)
                .map((res) => res.userid);

            localStorage.setItem(
                LS_CRYPTO_TRANSITION_NOTIFIED_USERS_KEY,
                JSON.stringify(notifiedUsers)
            );

            setNotifiedUsers(notifiedUsers);
            if (notifiedUsers.length == wait.length) {
                HFN.message(__("crypto_reset_notifications_sent"));
            } else {
                HFN.message(
                    __("something_went_wrong_refresh_and_try_again"),
                    "error"
                );
            }
            setSendingNotifications(false);
        });
    };

    const notify = (userid) => {
        apiMethod(
            "account_cryptoresetmail",
            { auth: token, userid: userid },
            () => {
                const tmpNotifiedUsers = [...notifiedUsers]
                tmpNotifiedUsers.push(userid);
                localStorage.setItem(
                    LS_CRYPTO_TRANSITION_NOTIFIED_USERS_KEY,
                    JSON.stringify(tmpNotifiedUsers)
                );
                HFN.message(__("crypto_reset_notification_sent"));
                setNotifiedUsers(tmpNotifiedUsers);
            }
        );
    };

    const resetOwnerCrypto = () => {
        apiMethod("crypto_reset", { auth: token }, () => {
            setOwnerNotified(true);
        });
    };

    const forceReset = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const sendForceResetMail = () => {
        apiMethod("account_cryptoforceresetmail", { auth: token }, () => {
            setForceResetSent(true);
        });
    };

    return (
        <Wrap>
            <ContentTop>
                <Header>
                    <HeaderTitle>
                        {__("crypto_transition_reset_crypto_headline")}
                    </HeaderTitle>
                    <HeaderMessage>
                        {__(
                            "update_crypto_page_message",
                            "There are users in this Business account with an active Crypto folder. In order to enable pCloud Encryption sharing, your team needs to reset their Crypto folders."
                        )}
                    </HeaderMessage>
                </Header>
                <WarningWrapper>
                    <WarningMessage>
                        {__(
                            "update_crypto_warning_message",
                            "By resetting the Crypto folder, all of the data inside will be permanently deleted."
                        )}
                    </WarningMessage>
                </WarningWrapper>
            </ContentTop>
            <ContentBottom>
                <Row>
                    {!ownerNotified ? (
                        <>
                            <Col className="full-width">
                                <AvatarWrap
                                    size={32}
                                    textSize="13px"
                                    style={{ flexShrink: 0 }}
                                >
                                    {userInitials(HFN.config.user.account)}
                                </AvatarWrap>
                                <User>{getNames(HFN.config.user)}</User>
                            </Col>
                            <Col>
                                <Button
                                    onClick={resetOwnerCrypto}
                                    $danger={true}
                                    $outline={true}
                                >
                                    {__("reset_crypto")}
                                </Button>
                            </Col>
                        </>
                    ) : (
                        <RowText>
                            {__("An email with instructions has been sent to")}{" "}
                            <b>{HFN.config.user.email}</b>.{" "}
                            {__(
                                "Please, follow the steps to reset your Crypto"
                            )}
                        </RowText>
                    )}
                </Row>
                {userList.length ? (
                    <>
                        {userList.map((user, i) => {
                            return (
                                <Row
                                    key={user.id}
                                    bg={(i + 1) % 2 ? "#fafafa" : "#fff"}
                                    className="no-border condensed"
                                >
                                    <Col className="full-width">
                                        <AvatarWrap
                                            size={32}
                                            textSize="13px"
                                            style={{ flexShrink: 0 }}
                                        >
                                            {userInitials(user)}
                                        </AvatarWrap>
                                        <User>{getNames(user)}</User>
                                    </Col>
                                    <Col>
                                        {notifiedUsers.includes(user.id) ? (
                                            <Tooltip
                                                title={__(
                                                    "crypto_reset_member_notified"
                                                )}
                                            >
                                                <BellNotified>
                                                    <i
                                                        className={
                                                            "fa fa-regular fa-envelope"
                                                        }
                                                    />
                                                </BellNotified>
                                            </Tooltip>
                                        ) : (
                                            <Bell
                                                onClick={() => notify(user.id)}
                                            >
                                                <i
                                                    className={
                                                        "fa fa-regular fa-envelope"
                                                    }
                                                />
                                            </Bell>
                                        )}
                                    </Col>
                                </Row>
                            );
                        })}
                    </>
                ) : null}
            </ContentBottom>

            {userList.length ? (
                <ButtonWrapper>
                    <Button $black={true} $outline={true} onClick={notifyAll} disabled={sendingNotifications}>
                        {__("crypto_notify_member_to_reset_button")}
                    </Button>
                    <Button $danger={true} $outline={true} onClick={forceReset}>
                        {__("crypto_transition_force_reset_button")}
                    </Button>
                </ButtonWrapper>
            ) : null}

            <Modal align="top" opened={showModal}>
                <OvalModalWrap>
                    <OvalModalBodyCustom>
                        <OvalModalCloseV2 onClick={closeModal} />
                        <ModalTopCustom onClose={closeModal}>
                            <Title>{__("Force reset")}</Title>
                        </ModalTopCustom>
                        <ModalBodyCustom>
                            <div>
                                {forceResetSent ? (
                                    <Componentify
                                        text={__(
                                            "force_crypto_reset_modal_mail_sent",
                                            "",
                                            {
                                                email: HFN.config.user.email
                                            }
                                        )}
                                        converters={[boldConverter]}
                                    />
                                ) : (
                                    __("force_crypto_reset_modal_body")
                                )}
                            </div>
                            <ButtonWrapper>
                                {forceResetSent ? (
                                    <Button
                                        color="black"
                                        onClick={closeModal}
                                        inline
                                    >
                                        {__("Close")}
                                    </Button>
                                ) : (
                                    <>
                                        <Button
                                            $danger={true}
                                            onClick={sendForceResetMail}
                                            style={{ marginRight: "10px" }}
                                        >
                                            {__("Reset")}
                                        </Button>
                                        <Button
                                            $black={true}
                                            onClick={closeModal}
                                        >
                                            {__("Cancel")}
                                        </Button>
                                    </>
                                )}
                            </ButtonWrapper>
                        </ModalBodyCustom>
                    </OvalModalBodyCustom>
                </OvalModalWrap>
            </Modal>
        </Wrap>
    );
};

export default UpdateCrypto;

const ModalTopCustom = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
`;
const Title = styled.div`
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 25px;
    text-align: center;
    background: none;
    border: none;
    color: inherit;
    height: auto;
    line-height: normal;
    padding-left: 0;
`;
const ModalBodyCustom = styled(ModalBody)`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 16px;
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
    padding: 0;
`;
const OvalModalBodyCustom = styled(OvalModalBody)`
    padding: 30px;
`;
const Wrap = styled(Wrapper)`
    margin-top: 28px;
    gap: 24px;

    @media (max-width: 1023px) {
        margin-top: 0;
        margin-bottom: 40px;
    }
`;
const ContentTop = styled(Content)`
    max-width: 600px;
    gap: 32px;
`;
const WarningWrapper = styled.div`
    display: flex;
    padding: var(--spacing-md);
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: var(--radius-minimum);
    border: 2px solid var(--color-warning-500);
    background: var(--color-functional-warning100);
`;
const WarningMessage = styled.div`
    color: var(--text-secondary);
    font-size: 16px;
    font-weight: 400;
`;
const ContentBottom = styled(Content)`
    max-width: 600px;
    gap: 0;
`;
const HeaderTitle = styled.div`
    color: var(--text-primary, #282c2f);
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
`;
const HeaderMessage = styled.div`
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
`;
const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    width: 100%;
    max-width: 600px;

    @media (max-width: 400px) {
        flex-direction: column;
        button {
            margin: 0!important;
        }
    }
`;
const Row = styled.div`
    display: flex;
    width: 100%;
    padding: 14px 8px;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    border-bottom: var(--border-size) solid var(--divider-primary);

    @media (max-width: 600px) {
        flex-direction: column;
        align-items: start;
        & > div:last-child {
            align-self: end;
        }
    }
`;
const Col = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    min-width: 0;
    @media (max-width: 600px) {
        &.full-width {
            width: 100%;
        }
    }
`;
const User = styled.div`
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;

    color: var(--text-primary);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;
const RowText = styled.div`
    color: var(--text-primary);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const Bell = styled.div`
    cursor: pointer;
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    & i {
        font-size: 14px;
        color: var(--color-icon-menu);
    }
`;

const BellNotified = styled(Bell)`
    cursor: default;
    
    & i {
        color: var(--color-grey-500);
    }
`;
