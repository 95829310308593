import React, { useEffect } from "react";
import { __ } from "../../../../../../lib/translate";
import { Wrapper, Title, SubTitle, AppContainer } from "./styles";
import { useSelector } from "react-redux";
import { detectOS } from "@pcloud/web-utilities/dist/lib/utils";
import styled from "styled-components";
import { URLSITE } from "@pcloud/web-utilities/dist/config";

const DesktopEmptyContainer = () => {
    const isBusiness = useSelector(({ pCloudUser }) => pCloudUser.userinfo.business);
    const os = detectOS(false, true);

    useEffect(() => {
        localStorage.setItem("backupsLayersSeen", true);
    }, []);

    const pageTitle =
        os === "mac"
            ? __("desktop_backup_title_mac")
            : __("desktop_backup_title_pc");

    let downloadLink = "#";
    switch (os) {
        case "mac":
            downloadLink =
                URLSITE +
                "how-to-install-pcloud-drive-mac-os.html?download=mac";
            break;
        case "linux":
            downloadLink =
                URLSITE +
                "how-to-install-pcloud-drive-linux.html?download=electron-64";
            break;
        case "windows":
            downloadLink =
                URLSITE +
                "how-to-install-pcloud-drive-windows.html?download=windows-10-64bit";
            break;
    }

    return (
        <Wrapper>
            <Title>{pageTitle}</Title>
            <SubTitle>
                {__("desktop_backup_subtitle", false, {
                    period: isBusiness
                        ? __("desktop_backup_period_halfyear")
                        : __("desktop_backup_period_year")
                })}
            </SubTitle>
            <AppContainer>
                <Link href={downloadLink} target={"_blank"}>
                    {__("desktop_backup_link", "Download the App")}
                </Link>
            </AppContainer>
        </Wrapper>
    );
};

export default DesktopEmptyContainer;

const Link = styled.a`
    text-decoration: none;
    color: var(--color-primary-500);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
`;
