import styled, { css } from "styled-components";

export const EmptyStateWrapper = styled.div`
    margin-right: var(--spacing-md);
    height: 100%;
    overflow: auto;

    ${({ $isMobile, $exploreCardsAreVisible }) =>
        $isMobile && css`
            margin: 0 var(--spacing-md);
            padding-bottom: var(--spacing-2xl);
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: ${$exploreCardsAreVisible ? "flex-start" : "space-between"};
            align-items: center;
            gap: var(--spacing-lg);
        `
    }
`;

export const EmptyStateMessage = styled.div`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    gap: ${({$isMobile}) => $isMobile ? '24px' : '16px'};
    padding: ${({$isMobile}) => $isMobile ? 'var(--spacing-2xl) var(--spacing-none) 0' : 'var(--spacing-2xl) var(--spacing-xl)'};
`;

export const EmptyStateMessageTitle = styled.div`
    font-family: Inter, sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: var(--text-secondary);
`;

export const EmptyStateMessageSubTitle = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: var(--text-secondary);
`;

export const ImageContainer = styled.div`
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: -1;
    pointer-events: none;

    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ $isMobile }) =>
        !$isMobile && css`
            & svg {
                width:580px;
                height:517px;
                max-width: 50vw;
                max-height: 60dvh;
            }
        `
    }

    ${({ $isMobile }) =>
        $isMobile && css`
            position: relative;
            bottom: auto;
            right: auto;

            & svg {
                max-width: none;
                max-height: none;
                
                ${({ $exploreCardsAreVisible }) => (
                    $exploreCardsAreVisible && css`
                        width: 180px;
                        height: 180px;
                    `
                )}
            }

            transform: scale(0.9);
            max-width: 100%;
            max-height: 400px;

            @media (max-height: 490px) {
                display: none;
            }
        `
    }
`;

