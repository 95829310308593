// @flowstore

import {userSlice} from "@pcloud/web-utilities/dist/lib/state/reducers/pcloud/userSlice";
import {notificationSlice} from "@pcloud/web-utilities/dist/lib/state/reducers/pcloud/notificationSlice";
import { audioPlayerSlice } from "./reducers/audioPlayerSlice";
import { onboardingSlice } from "./reducers/onboardingSlice";
import { publiclinkSlice } from "./reducers/publiclinkSlice";
import { bookmarksSlice } from "./reducers/bookmarksSlice";
import { galleryPreviewSlice } from "./reducers/galleryPreviewSlice";
import { fileRequestsSlice } from "./reducers/fileRequestsSlice";
import { sharedFoldersSlice } from "./reducers/sharedFoldersSlice";
import { sharedLinksSlice } from "./reducers/sharedLinksSlice";
import { businessSlice } from "./reducers/businessSlice";
import { contentSlice } from "./reducers/contentSlice";

export default {
  pCloudUser: userSlice.actions,
  pCloudNotifications: notificationSlice.actions,
  audioPlayer: audioPlayerSlice.actions,
  onboarding: onboardingSlice.actions,
  publiclink: publiclinkSlice.actions,
  bookmarks: bookmarksSlice.actions,
  galleryPreview: galleryPreviewSlice.actions,
  fileRequests: fileRequestsSlice.actions,
  sharedFolders: sharedFoldersSlice.actions,
  sharedLinks: sharedLinksSlice.actions,
  business: businessSlice.actions,
  content: contentSlice.actions,
};
