import React from "react";
import styled from "styled-components";
import { hiDPI } from "polished";
import { __ } from "../lib/translate";
import { connect } from "react-redux";

class UnverifiedUsersAlert extends React.Component {
  constructor(props) {
    super(props);
    this.verifyEmail = this.verifyEmail.bind(this);
  }

  verifyEmail() {
    this.props.showVerifyMail();
  }

  render() {
    return (
      <WarningWrapper>
        <Content>
          <Icon></Icon>
          <Text>{__("verify_mail_firstwarning")}</Text>
          <VerifyButton onClick={this.verifyEmail}>{__("verify_restricted_button1")}</VerifyButton>
        </Content>
      </WarningWrapper>
    )
  }
}

export default connect(({ pCloudUser }) => ({ user: pCloudUser }))(UnverifiedUsersAlert);

const WarningWrapper = styled.div`
  background-color: #ff4d4d;
  text-align: center;
`

const Content = styled.div`
  display: inline-block;
  width: 100%;
  padding: 7px 0px;
  max-width: 1180px;
  margin: 0 auto;
  box-sizing: border-box;
`

const Icon = styled.div`
  display: inline-block;
  vertical-align: middle;
  border-radius: 100%;
  width: 18px;
  height: 18px;
  background-size: 18px 18px;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(${require("../../root/img/warning.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img/warning@2x.png")});
  }
`;

const Text = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin: 7px 13px;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  color: #fff;
`

const VerifyButton = styled.div`
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  padding: 7px 20px;
  background-color: rgba(0,0,0,0.15);
  color: #fff;
  border-radius: 4px;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  &:hover {
    background-color: rgba(0,0,0,0.20);
  }
  @media(max-width: 768px) {
    margin: 8px 0px;
  }
`
