// @flow
import React, { useState } from "react";
import styled from "styled-components";

import { useSelector } from "react-redux";

import { __ } from "../../lib/translate";
import apiMethod from "../../api/apiMethod";

import Button from "../ButtonDefault";
import PassWithHint from "./PassWithHint";

import { Row, Col } from "../tables/light";

type Props = {
  description: string,
  onPassOk: () => void,
};

const EnterPassBusiness = ({ description, onPassOk }: Props) => {
  const [errorMessage, setErrorMessage] = useState();
  const [password, onPassChange] = useState("");
  const [loading, setLoading] = useState(false);
  const token = useSelector(({ pCloudUser }) => pCloudUser.token);

  const checkPassword = e => {
    e.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);

    pCloudCrypto.unlockCrypto(password, ok => {
      if (ok) {
        onPassOk();
      } else {
        HFN.message(__("wrong_password"), "error");
        setLoading(false);
      }
    });
  };

  return (
    <Wrap className="clearfix" onSubmit={checkPassword}>
      <Row className="top-border bottom-border">
        <Col className="text">{description}</Col>
        <Col className="input">
          <PassWithHint onPassChange={onPassChange} password={password} />
        </Col>
      </Row>
      <ButtonWrap>
        <Button size="big" borderRadius="3" color="green" onClick={checkPassword} loading={loading}>
          {__("Continue")}
        </Button>
      </ButtonWrap>
    </Wrap>
  );
};

export default EnterPassBusiness;

const Wrap = styled.form`
  width: 720px;
`;

const ButtonWrap = styled.div`
  float: right;
  width: 240px;
  margin-top: 15px;
  margin-right: 11px;
`;

