// @flow

import React, { useState, useEffect } from "react";
import Componentify from "react-componentify";
import styled from "styled-components";
import { List } from "immutable";

import * as Style from "./styledComponents";
import InputText from "../InputText";
import { ButtonCentered } from "@pcloud/web-utilities/dist/resources/components/ButtonDefault";
import Modal, { OvalModalWrap, CloseModalIcon } from "../Modal";
import Picker from "../Picker/Picker";
import PickerSelectedItems from "../Picker/PickerSelectedItems";

import { __ } from "../../lib/translate";
import { errorKeys } from "../../lib/errors";
import { boldConverter } from "../../lib/componentifyConverters";
import apiMethod from "../../api/apiMethod";
import hashManager from "../../lib/hashManager";

type Props = {
  folder: any,
  fileList: Array<any>,
  opts: any
};

const CreateArchiveModal = ({
  folder = { folderid: 0, name: "/", contents: null },
  fileList = [],
  opts = {}
}: Props) => {
  const [isOpen, setIsOpen] = useState(true);
  const [archiveList, setArchivelist] = useState(List(fileList));
  const [showProgress, setShowProgress] = useState(false);
  const [archiveName, setArchiveName] = useState("");
  const [zipMeta, setZipMeta] = useState(null);
  const [finishedFiles, setFinishedFiles] = useState(0);
  const [totalFiles, setTotalFiles] = useState(0);
  const [bytesPercentage, setBytesPercentage] = useState(0);
  const [isReadyZip, setIsReadyZip] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let proponame = "";
    if (fileList.length > 1 && HFN.findOriginalMetadata({ id: "d" + fileList[0].parentfolderid })) {
      proponame = HFN.findOriginalMetadata({ id: "d" + fileList[0].parentfolderid }).name;
    } else if (fileList[0].isfolder) {
      proponame = fileList[0].name;
    } else {
      proponame = fileList[0].name.replace(/\.([^\.]+)$/i, "");
    }
    if (proponame == "/") {
      proponame = "Root";
    }

    if (!proponame.match(/\.zip$/i)) {
      proponame += ".zip";
    }
    if (proponame) {
      setArchiveName(proponame);
    }
  }, [fileList]); // fileList

  const handleClose = () => {
    setIsOpen(false);
  };

  const onLocateArchive = () => {
    handleClose();
    hashManager.pushState(
      {
        page: "filemanager",
        folder: zipMeta.parentfolderid,
        file: zipMeta.id
      },
      2
    );
  };

  const onArchiveNameChange = ({ target }) => {
    setArchiveName(target.value);
  };

  const goProgress = (host: string, hash) => {
    let zipprogressto = null;
    const goProgressCheck = () => {
      apiMethod(
        "savezipprogress",
        { progresshash: hash },
        ({ bytes, totalbytes, files, totalfiles }) => {
          console.log({ bytes, totalbytes, files, totalfiles });
          setShowProgress(true);
          setBytesPercentage((bytes / totalbytes) * 100);
          setFinishedFiles(files);
          setTotalFiles(totalfiles);

          if (zipprogressto) {
            clearTimeout(zipprogressto);
            zipprogressto = null;
          }

          if (bytes < totalbytes) {
            zipprogressto = setTimeout(goProgressCheck, 200);
          } else if (bytes >= totalbytes) {
            setIsReadyZip(true);
          }
        },
        {
          apiServer: host,
          errorCallback: ({ result, error }) => {
            if (result == 1901) {
              zipprogressto = setTimeout(goProgressCheck, 100);
            } else {
              throw new Error(error);
            }
          }
        }
      );
    };

    zipprogressto = setTimeout(goProgressCheck, 250);
  };

  const onPick = ({ data }) => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    const { itemId } = data;
    let zipName = archiveName.trim();
    const progresshash = uniqueNum.get("savezip");

    if (zipName.length < 1) {
      HFN.message(__('"Archive Name" is required.'), "error");
      setIsLoading(false);
      return;
    }

    const regex = new RegExp('[><"/]+');
    if (regex.test(zipName)) {
      HFN.message(__("share_folder_modal_error_folder_name_special_chars"), "error");
      setIsLoading(false);
      return;
    }

    if (!itemId) {
      HFN.message(__("Pick destination for archive."), "error");
      setIsLoading(false);
      return;
    }

    if (!zipName.match(/\.zip$/i)) {
      zipName += ".zip";
    }

    const params = fs.getAsParams();
    params.tofolderid = itemId;
    params.toname = zipName;
    params.progresshash = progresshash;

    const parentFolderMeta = HFN.findOriginalMetadata({ id: "d" + params.tofolderid });
    if (parentFolderMeta && HFN.config.isRestrictedDesktopBackup(parentFolderMeta)) {
      HFN.message(__("error_2346", "You cannot add files/folders to this backup folder."), "error");
      setIsLoading(false);
      return;
    }

    apiMethod("currentserver", {}, function(r) {
      let apiHost = r.hostname;

      console.log(apiHost);

      apiMethod(
        "savezip",
        params,
        ({ metadata }) => {
          setZipMeta(metadata);
        },
        {
          errorCallback: ({ result, error }) => {
            setIsLoading(false);
            if (errorKeys[result]) {
              HFN.message(__(errorKeys[result]), "error");
            } else {
              handleClose();
              HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
              throw new Error(error);
            }
          },
          apiServer: apiHost
        }
      );

      setTimeout(goProgress(apiHost, progresshash), 50);
    });
  };

  const onRemoveRow = index => {
    if (archiveList.size === 1) {
      handleClose();
    } else {
      setArchivelist(archiveList.delete(index));
    }
  };

  const renderProgressMessage = () => {
    const text = __("<b>%nfiles%</b> of <b>%totalfiles%</b> files archived")
      .replace("%nfiles%", finishedFiles)
      .replace("%totalfiles%", totalFiles);

    return (
      <React.Fragment>
        <b>{`${Math.round(bytesPercentage)}%`}</b>
        {", "}
        <Componentify text={text} converters={[boldConverter]} />
      </React.Fragment>
    );
  };

  const renderProgress = () => {
    return (
      <React.Fragment>
        <ProgressBar>{isReadyZip ? __("create_archive_modal_archive_is_ready", "Archive is ready") : renderProgressMessage()}</ProgressBar>
        {isReadyZip ? (
          <ButtonCentered style={{ minWidth: "100%" }} onClick={onLocateArchive}>
            {__("create_archive_modal_open_file_location", "Open file location")}
          </ButtonCentered>
        ) : (
          <React.Fragment>
            <ProgressText>
              {__(
                "Zipping is a background process. You can close this modal anytime without interrupting it."
              )}
            </ProgressText>
            <ButtonCentered style={{ minWidth: "100%" }} color="lightgray4" onClick={handleClose}>
              {__("Close")}
            </ButtonCentered>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  const renderPicker = () => {
    return (
      <React.Fragment>
        <LabelCopy>{__("Archive Name")}</LabelCopy>
        <InputText
          name="archivename"
          placeholder={__("Archive name")}
          onChange={onArchiveNameChange}
          value={archiveName}
          autoFocus
          style={{ marginBottom: "17px" }}
        />
        <LabelCopy>{__("selected_items", "Items")}</LabelCopy>
        <PickerSelectedItems list={archiveList} onRemoveItem={onRemoveRow} />
        <LabelCopy>{__("Save Archive in ...")}</LabelCopy>
        <Picker
          folder={folder}
          opts={Object.assign(opts, { buttonText: "Create" })}
          onPick={onPick}
          onCancel={handleClose}
        />
      </React.Fragment>
    );
  };

  return (
    <Modal align="top" animate onClose={handleClose} opened={isOpen}>
      <OvalModalWrap>
        <CloseModalIcon onClick={handleClose} />
        <Style.Container>
          <HeaderCopy>{__("Create Archive")}</HeaderCopy>
          {showProgress ? renderProgress() : renderPicker()}
        </Style.Container>
      </OvalModalWrap>
    </Modal>
  );
};

export default CreateArchiveModal;

const LabelCopy = styled(Style.Label)`
  height: 18px;
  margin-bottom: 5px;
`;

const HeaderCopy = styled(Style.Header)`
  height: 26px;
  margin-bottom: 16px;
`;

const ProgressBar = styled.div`
  font-size: 15px;
  font-weight: normal;
  padding: 11px;
  text-align: center;
  border-radius: 6px;
  border: solid 1px #eeeeee;
  margin-bottom: 25px;
`;

const ProgressText = styled.div`
  font-size: 13px;
  font-weight: normal;
  color: #8e8e95;
  margin-bottom: 25px;
`;
