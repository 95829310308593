// @flow
import React, { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { MIN_CRYPTO_PASS_STRENGTH } from "@pcloud/web-utilities/dist/config/constants";

import apiMethod from "../../../api/apiMethod";
import { __ } from "../../../lib/translate";

import Button from "../../ButtonDefault";
import PasswordStrength from "../../PasswordStrength";
import InputPassword from "../../InputPassword";

type Props = {
  onPassChanged: () => void,
  onSuccess: () => void,
};

const fieldsDefaultValue = { code: "", password: "", repeatPassword: "", hint: "" };

const SetNewPass = ({ onPassChanged = () => {}, onSuccess = () => {} }: Props) => {
  const token = useSelector(({ pCloudUser }) => pCloudUser.token);
  const [fields, setFields] = useState(fieldsDefaultValue);
  const [loading, setLoading] = useState(false);
  const [canResend, setCanResend] = useState(true);

  const onFieldChange = function(field, value) {
    const newFields = { ...fields };
    newFields[field] = value;
    setFields(newFields);
  };

  const onSetNewPassword = e => {
    e.preventDefault();
    const { code, password, repeatPassword, hint } = fields;

    if (loading || !password.length) {
      return;
    }

    if (code.length != 6 || !parseInt(code)) {
      HFN.message(__("invalid_verification_code"), "error");
      return;
    }

    const strength = pCloudCrypto.passwordStrength(password);

    if (password.length < 8 || strength < MIN_CRYPTO_PASS_STRENGTH) {
      HFN.message(__("Password is too weak!"), "error");
      return;
    }

    if (password != repeatPassword) {
      HFN.message(__("both passwords must be exact match."), "error");
      return;
    }

    const hintContainsPass = hint.trim().indexOf(password.trim()) != -1;
    if (hintContainsPass) {
      HFN.message(__("Your Hint must not contain the Passphrase."), "error");
      return;
    }

    setLoading(true);

    pCrypt.encryptPrivateKeyWithNewPass(password, 0, ({ privatekey, signature }) => {
      apiMethod(
        "crypto_changeuserprivate",
        { auth: token, code: code, hint: hint, privatekey: privatekey, signature: signature },
        res => {
          if (res.result == 0) {
            HFN.message(__("crypto_pass_changed"));
            onPassChanged();
          }
          setLoading(false);
          onSuccess();
        },
        {
          type: "post",
          errorCallback: error => {
            console.log(error);
            if (error.result == 2012) {
              HFN.message(__("invalid_verification_code"), "error");
            } else {
              HFN.message("Saving key failed", "error");
            }
            setLoading(false);
          }
        }
      );
    });
  };

  const resendCode = () => {
    if (!canResend) {
      return;
    }
    setCanResend(false);
    setTimeout(() => setCanResend(true), 15000);
    apiMethod(
      "crypto_sendchangeuserprivate",
      { auth: token },
      ({ result }) => {
        if (result == 0) {
          HFN.message("Code has been sent");
        }
      },
      { errorCallback: e => HFN.message(e, "error") }
    );
  };

  return (
    <ModalContainer className="clearfix" onSubmit={onSetNewPassword}>
      <ModalHeader key="title">{__("heading_change_crypto_pass")}</ModalHeader>
      <ModalDescription>{__("crypto_change_pass_verification_code_info")}</ModalDescription>
      <Form>
        <InputWrapper>
          <Input
            type="text"
            placeholder={__("verification_code_label")}
            className="error"
            name="code"
            onChange={e => {
              onFieldChange("code", e.target.value);
            }}
            value={fields.code}
          />
        </InputWrapper>


        <InputWrapper>
          <InputPassword
            type="password"
            name="cryptpass"
            autoComplete="new-password"
            placeholder={__("new_crypto_pass_label")}
            onChange={e => onFieldChange("password", e.target.value)}
            value={fields.password}
            revealPassword={true}
            InputComponent={Input}
          />
          <PassStrengWrap>
            <PasswordStrength password={fields.password} />
          </PassStrengWrap>
        </InputWrapper>

        <InputWrapper>
          <InputPassword
            type="password"
            placeholder={__("Repeat Crypto Pass")}
            autoComplete="new-password"
            name="cryptopassrepeat"
            onChange={e => onFieldChange("repeatPassword", e.target.value)}
            value={fields.repeatPassword}
            revealPassword={true}
            InputComponent={Input}
          />
        </InputWrapper>

        <InputWrapper>
          <Input
            type="text"
            name="hint"
            placeholder={`${__("Hint")} (${__("Optional")})`}
            onChange={e => onFieldChange("hint", e.target.value)}
            value={fields.hint}
          />
        </InputWrapper>

        <ButtonWrap>
          <Button
            size="big"
            onClick={onSetNewPassword}
            loading={loading}
            disabled={!fields.password.length}
          >
            {__("button_change_crypto_pass")}
          </Button>
          <ButtonLink
            className={classnames("lnk", { disabled: !canResend })}
            role="button"
            tabIndex="0"
            onKeyDown={e => e.keyCode == 13 && resendCode()}
            onClick={resendCode}
            canresend={canResend}
          >
            {__("resend_code_action")}
          </ButtonLink>
        </ButtonWrap>
      </Form>
    </ModalContainer>
  );
};

export default SetNewPass;

const ButtonWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-sm);
  margin-top: var(--spacing-md);
`;
const PassStrengWrap = styled.div`
  position: relative;
  top: 3px;
  left: 2px;
`;
const ResendWrap = styled.span`
  display: inline-flex;
  padding-left: 5px;
`;

const ModalContainer = styled.div`
  text-align: center;
  border-radius: var(--radius-lg);
  box-shadow: rgba(0, 0, 0, 0.25) 3px 3px 5px;
  padding: var(--spacing-xl);
  background: var(--color-base-white);
  max-width: 480px;
  width: 100%;
  min-height: auto;
  color: var(--color-base-black);
  box-sizing: border-box;
`;

const ModalHeader = styled.div`
  font-size: var(--font-size-18);
  color: var(--color-base-black);
  line-height: 23px;
  font-weight: bold;
  margin-top: -3px;
  margin-bottom: var(--spacing-lg);
`;

const ModalDescription = styled.div`
  font-size: 15px;
  color: var(--color-base-black)
  padding: 0px var(--spacing-md);
  text-align: center;
  margin-bottom: 16px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-sm);
  margin-top: var(--spacing-md);
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Input = styled.input`
  width: 100%;
  box-shadow: rgba(41, 38, 38, 0.05) 0px 3px 2px 0px inset;
  border-radius: var(--radius-xs);
  height: 38px;
  line-height: 26px;
  font-size: var(--font-size-14);
  color: var(--color-base-black);
  background: var(--color-base-white);
  padding-left: 10px;
  padding-right: var(--spacing-2xl);
  border: var(--border-size) solid var(--border-primary);
  box-sizing: border-box;
  outline: none;
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: var(--spacing-md);
`;

const ButtonLink = styled.div`
  font-size: var(--font-size-14);
  font-weight: normal;
  text-align: center;
  width: 100%;
  margin-top: var(--spacing-sm);
  color: ${({ canresend }) => canresend ? "var(--color-primary-500)" : "var(--color-grey-500)"};
  cursor: pointer;
  flex-shrink: 0;
`;