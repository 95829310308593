//  @flow

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import { getBillingInfoCall } from "../../../api/business";
import { __ } from "../../../lib/translate";
import { currencyToSign, loadScript } from "../../../lib/utils";
import { getBusinessPlanName } from "../../../lib/plansInfo";
import { SubscriptionUpdate } from "../../../lib/SubscriptionUpdate";
import { cardIconsComponents } from "../../PaymentForm/SharedStyledComponents";
import { PROVIDERS, PROVIDERS_IDS, SUBSC_PERIOD, SUBSC_STATUS } from "@pcloud/web-utilities/dist/config/constants";

import { HeaderTable, ButtonLink } from "./StyledComponents";
import { Loading } from "../../../components/ManageSubscription/styledComponents";
import useIsMount from "@pcloud/web-utilities/dist/hooks/useIsMount";
import PaypalIcon from "@pcloud/web-utilities/dist/resources/images/svg/paypal-logo.svg";
import Button from "../../../components/ButtonDefault";
import FlowManager from "../../TwoFactorAuth/FlowManager";
import PaymentFormStep from "./PaymentFormStep";
import StartSubscriptionStep from "./StartSubscriptionStep";
import SuccessMessageStep from "./SuccessMessageStep";
import ManageUsersQuotaStep from "./ManageUsersQuotaStep";
import ChangeCardStep from "./ChangeCardStep";
import CancelSubscriptionStep from "./CancelSubscriptionStep";
import { setProcessingProfile, updateBillingInfo } from "../../../lib/state/reducers/businessSlice";
import hashManager from "../../../lib/hashManager";

const modalFlows = {
  payNow: [StartSubscriptionStep, PaymentFormStep, SuccessMessageStep],
  upgrade: [ManageUsersQuotaStep, PaymentFormStep, SuccessMessageStep],
  updateUsers: [ManageUsersQuotaStep, PaymentFormStep, SuccessMessageStep],
  changeCard: [ChangeCardStep, SuccessMessageStep],
  cancelSubs: [CancelSubscriptionStep, SuccessMessageStep]
};

const SubscriptionDetails = () => {
  const dispatch = useDispatch();
  const billingInfo = useSelector(({ business }) => business.billingInfo);
  const canManage = useSelector(
    ({ pCloudUser }) => pCloudUser.userinfo.account && pCloudUser.userinfo.account.permissions.account_manage
  );
  const paymentSource = useSelector(({ pCloudUser }) => pCloudUser.paymentSource);
  const {
    billing = {},
    payer = {},
    subscriptions = [],
    is_trial,
    memberinfo = {},
    pending_members = {},
    processing = false
  } = billingInfo;
  const { 
    provider: billingProviderId,
    period: billingPeriod,
    currency: billingCurrency,
    until: expDate,
    businessplanid: planId
  } = billing;
  const {
    brand: payerBrand,
    card: payerCard,
    expmonth: payerExpmonth,
    expyear: payerExpyear,
    email: payerEmail
  } = payer;
  const { maximum: maximumMembers } = memberinfo;
  const {
    applying: applyingPendingMembers,
    count: countPendingMembers,
    price: pricePendingMembers
  } = pending_members;
  const notInTrial = billingProviderId != PROVIDERS_IDS.TRIAL && billingProviderId != PROVIDERS_IDS.PROMOCODE;
  const notInSalesTrial = billingProviderId != PROVIDERS_IDS.SALES && billingProviderId != PROVIDERS_IDS.RESELLER;
  const providerName = PROVIDERS[billingProviderId] || "";

  const [currentFlow, setCurrentFlow] = useState([]);
  const [currentFlowName, setCurrentFlowName] = useState("");
  const [currency, setCurrency] = useState("USD");
  const [price, setPrice] = useState("");
  const [period, setPeriod] = useState(billingPeriod);
  const [members, setMembers] = useState(maximumMembers);
  const [endTrial, setEndTrial] = useState(false);
  const [prorationtime, setProrationtimeCb] = useState(0);
  const [activeSubs, setActiveSubs] = useState([]);
  const [currentSubs, setCurrentSubs] = useState({});
  const [braintreeLoading, setBraintreeLoading] = useState(false);
  const [shouldRefreshPaypalButton, setShouldRefreshPayPalButton] = useState(false);
  const isMount = useIsMount();

  useEffect(() => {
    loadScript("https://js.braintreegateway.com/web/3.76.0/js/client.min.js", () => {});
    loadScript("https://js.braintreegateway.com/web/3.76.0/js/paypal-checkout.min.js", () => {});
    loadScript("https://js.braintreegateway.com/web/3.76.0/js/data-collector.min.js", () => {});
  }, []);

  useEffect(() => {
    if (currentFlowName !== "") {
      setShouldRefreshPayPalButton(false);
    }
  }, [currentFlowName]);


  useEffect(() => {
    if (payerEmail && isMount) {
      createBraintreeButton();
    } else {
      setBraintreeLoading(false);
    }
  }, [billingProviderId, payerEmail, isMount]);

  useEffect(() => {
    if (shouldRefreshPaypalButton) {
      createBraintreeButton();
    }
  }, [shouldRefreshPaypalButton]);


  useEffect(() => {
    if (subscriptions.length) {
      const activeSubscription = subscriptions.filter(subsc => subsc.status === 1);
      setActiveSubs(activeSubscription);
      setCurrentSubs(subscriptions[0] || {});
    }
  }, [subscriptions]);

  useEffect(() => {
    if (Object.keys(currentSubs).length) {
      const isPayPalSubs = currentSubs.billingprovider === PROVIDERS_IDS.PAYPAL;
      const isBraintreeSubs = currentSubs.billingprovider === PROVIDERS_IDS.BRAINTREE;
      const isActiveSubs = currentSubs.status !== SUBSC_STATUS.CANCELED;
      const diffBillingProvider = currentSubs.billingprovider !== billingProviderId;

      if ((isPayPalSubs || isBraintreeSubs) && diffBillingProvider && isActiveSubs) {
        dispatch(setProcessingProfile());
      }
    }
  }, [billingProviderId, currentSubs, dispatch]);

  useEffect(() => {
    const isPayPal = PROVIDERS_IDS.PAYPAL === billingProviderId;
    if (is_trial && !isPayPal) {
      if (hashManager.getState("pay")) {
        onActiveNowClick();
      }
      setTimeout(() => {
        $(".active-btn")
          .off("click")
          .on("click", onActiveNowClick);
      }, 0);
    } else {
      setTimeout(() => {
        HFN.removeTrialBusinessInfoBar();
      }, 0);
    }
  }, [billingProviderId, is_trial]);

  const createBraintreeButton = () => {
    if (billingProviderId !== PROVIDERS_IDS.BRAINTREE) {
      return;
    }

    const params = {
      provider: "braintree",
      period: period,
      storage: 0,
      traffic: 0,
      isBusiness: true,
      members: members,
      endTrial: endTrial,
    };

    if (planId) {
      params.businessplanid = planId;
    }

    const apiCall = new SubscriptionUpdate(params);
    setBraintreeLoading(true);

    apiCall.getTokenBraintree({
      refresh: false,
      paypalButtonId: "#paypal-button-table",
      label: "pay",
      callback: () => {},
      onReady: () => {
        setBraintreeLoading(false);
      },
      buttonHeight: 26
    });
  };

  const onActiveNowClick = () => {
    setCurrentFlow(modalFlows["payNow"]);
    setCurrentFlowName("payNow");
  };

  const onPayNowButtonClick = () => {
    setCurrentFlow(modalFlows["payNow"]);
    setCurrentFlowName("payNow");
  };

  const onUpdateUsersClick = () => {
    setCurrentFlow(modalFlows["updateUsers"]);
    setCurrentFlowName("updateUsers");
  };

  const onUpgradeClick = () => {
    setCurrentFlow(modalFlows["upgrade"]);
    setCurrentFlowName("upgrade");
  };

  const onCancelSubsClick = () => {
    setCurrentFlow(modalFlows["cancelSubs"]);
    setCurrentFlowName("cancelSubs");
  };

  const onCloseClick = () => {
    // UPDATE UI WITH NEW CARD
    // TO DO: add event for change card
    if (currentFlowName === "changeCard") {
      setTimeout(() => {
        getBillingInfoCall(ret => {
          delete ret.result;
          dispatch(updateBillingInfo(ret));
        });
      }, 1000);
    }

    setCurrentFlow([]);
    setShouldRefreshPayPalButton(true);
    setCurrentFlowName("");
    setPeriod(billingPeriod);
    setMembers(maximumMembers);
    setEndTrial(false);
    setProrationtimeCb(0);
    setPrice("");
    setCurrency("USD");
  };

  const onChangeCardClick = () => {
    setCurrentFlow(modalFlows["changeCard"]);
    setCurrentFlowName("changeCard");
  };

  const getData = () => {
    let data = [];

    // First ROW Status
    let firstRow = [];
    const isMonthly = billingPeriod == SUBSC_PERIOD.MONTHLY;
    const providers = [PROVIDERS_IDS.STRIPE, PROVIDERS_IDS.SAFECHARGE, PROVIDERS_IDS.BRAINTREE, PROVIDERS_IDS.PAYPAL];
    const hasProvider = providers.indexOf(currentSubs.billingprovider) !== -1;
    const isPayPal = PROVIDERS_IDS.PAYPAL === billingProviderId;
    const isBankTransfer = PROVIDERS_IDS.SALES === billingProviderId && !is_trial;
    const isSubsTrial = currentSubs.status === SUBSC_STATUS.PROCESSING;
    const period = __(`${isMonthly ? "Monthly" : "Yearly"} Subscription`);
    const isExpired = new Date(expDate).getTime() < new Date().getTime();
    let header = __("business_billing_status", "Status");
    let status = is_trial ? __("business_billing_status_trial", "Free trial") : period;
    let statusButton = "";

    console.log("BUISNESS_STATUS:", { payer, activeSubs, currentSubs, billingProviderId, processing });

    if (is_trial) {
      status = !activeSubs.length && hasProvider && !isSubsTrial ? __("Inactive") : status;
      statusButton = isPayPal || processing ? "" : renderPayNowButton();
    } else if (!activeSubs.length) {
      status = !isBankTransfer || isExpired ?  __("Inactive") : __("business_status_bank_transfer", "Bank transfer");
      statusButton = !isBankTransfer || isExpired ? renderPayNowButton() : "";
    } else if (isMonthly) {
      statusButton = (
        <ButtonLink onClick={onUpgradeClick}>{__("Upgrade to Annual and save 20%")}</ButtonLink>
      );
    }
    if (processing) {
      status = __("Processing profile");
      statusButton = "";
    }

    firstRow = [header, status, statusButton];
    firstRow.length && data.push(firstRow);

    // Secong ROW plan name
    let secondRow = [__("plan_name", "Plan"), getBusinessPlanName(planId), ""];
    secondRow.length && data.push(secondRow);

    // Third ROW Users
    let thirdRow = [__("Users"), maximumMembers, ""];

    if (canManage && !is_trial && activeSubs.length) {
      thirdRow[2] = (
        <ButtonLink onClick={onUpdateUsersClick}>
          {__("b_billing_manage_user_quota_header", "Manage user quota")}
        </ButtonLink>
      );
    }
    thirdRow.length && data.push(thirdRow);

    // Fourth ROW Billing Provider
    let fourthRow = [];
    if (notInTrial && notInSalesTrial) {
      let payerInfo = "";
      let paymentProveder = "";
      if (Object.keys(payer).length) {
        const isStripe = billingProviderId == PROVIDERS_IDS.STRIPE;
        const isSafecharge = billingProviderId == PROVIDERS_IDS.SAFECHARGE;
        const isBraintree = billingProviderId == PROVIDERS_IDS.BRAINTREE;

        if (isStripe || isSafecharge) {
          const brand = payerBrand ? payerBrand.replace(/\s+/g, "") : "";
          const CardIcon = cardIconsComponents[brand.toLowerCase()]
            ? cardIconsComponents[brand.toLowerCase()]
            : cardIconsComponents["default"];
          const hasExpired =
            new Date().getTime() > new Date(payerExpyear, payerExpmonth, 0).getTime();
          const expirationDate = `(${__("Expire: ")} ${payerExpmonth}/${payerExpyear})`;
          const expiredText = __("Expired");

          payerInfo = (
            <React.Fragment>
              <PayerInfo hasExpired={hasExpired}>
                <CardIcon className="billing-icon" />
                <CardInfo>
                  <span>{` **** **** **** ${payerCard} `}</span>
                  <ExpireCard hasExpired={hasExpired}>
                    {hasExpired ? `(${expiredText})` : expirationDate}
                  </ExpireCard>
                </CardInfo>
              </PayerInfo>
            </React.Fragment>
          );
          paymentProveder = (
            <ButtonLink onClick={onChangeCardClick}>
              {__("b_billing_change_method", "Change Method")}
            </ButtonLink>
          );
        } else if (isBraintree) {
          payerInfo = renderEmail(payerEmail);
          paymentProveder = renderChangeEmailBraintree();
        } else {
          payerInfo = renderEmail(payerEmail);
          paymentProveder = "";
        }
        fourthRow = [__("Billing Provider"), payerInfo, paymentProveder];
      }
    }

    fourthRow.length && data.push(fourthRow);

    // FifthRow ROW Pending users
    if (Object.keys(pending_members).length) {
      let fifthRow = [];

      if (applyingPendingMembers) {
        fifthRow = [__("Pending users"), __("Applying"), ""];
      } else {
        fifthRow = [
          __("Pending users"),
          `${countPendingMembers} ${__("members for")} ${currencyToSign(billingCurrency)} ${pricePendingMembers}`,
          ""
        ];
        // ????
        // if (canManage && !is_trial) {
        //   fifthRow[2] = <ButtonLink onClick={redeemUserQuota}>{__("Redeem Pending Users")}</ButtonLink>;
        // }
      }

      fifthRow.length && data.push(fifthRow);
    }

    // SixthRow - add start and next renewal date of the subscriprion
    let sixthRow = [];
    const startDate = (
      <span>
        {__("business_billing_start_date")}
        {": "}
        <span className="normal">
          {HFN.formatDt(currentSubs.startperiod) || "N/A"}
        </span>
      </span>
    );
    const nextDate = activeSubs.length || currentSubs.status === SUBSC_STATUS.PROCESSING ? (
      <span className="bold">
        {__("Next renewal date")}
        {": "}
        <span className="normal">
          {HFN.formatDt(currentSubs.endperiod) || "N/A"}
        </span>
      </span>
    ) : (
      <span className="bold">
        {__("business_billing_paid_until")}
        {": "}
        <span className="normal">
          {HFN.formatDt(expDate) || "N/A"}
        </span>
      </span>
    );

    const showCancelSubs = !processing && currentSubs.cancancel;
    const cancelButton = showCancelSubs ? (
      <ButtonLink onClick={onCancelSubsClick}>
        {__("cancel_subs_modal_bnt_cancel", "Cancel subscription")}
      </ButtonLink>
    ) : (
      ""
    );
    sixthRow = [startDate, nextDate, cancelButton];
    sixthRow.length && data.push(sixthRow);

    return data;
  };

  const renderCol = (value, index) => {
    return <Col key={index + "colKey"}>{value}</Col>;
  };

  const renderRow = (list, index) => {
    return <Row key={index + "rowKey"}>{list.map(renderCol)}</Row>;
  };

  const renderPayNowButton = () => {
    return (
      <Button
        inline
        minWidth="160px"
        color="green"
        size="big"
        style={{ margin: "10px 0" }}
        onClick={onPayNowButtonClick}
      >
        {__("pay_now_button", "Pay now")}
      </Button>
    );
  };

  const renderEmail = (email) => {
    return (
      <PayerInfo>
        <PaypalIcon style={{ marginRight: "10px" }} />
        {email}
      </PayerInfo>
    );
  };

  const renderChangeEmailBraintree = () => {
    return (
      <BraintreeChangeContainer>
        <ChangeLabel>{__("Change", "Change")}:</ChangeLabel>
        <BraintreeButtonContainer>
          {braintreeLoading ? (
            <BlueButtonLoading>
              <Loading />
            </BlueButtonLoading>
          ) : null}
          <div id="paypal-button-table" />
        </BraintreeButtonContainer>
      </BraintreeChangeContainer>
    );
  };

  return (
    <Table>
      <HeaderTable>{__("Billing Info:", "Subscription Details:")}</HeaderTable>
      <Body>{getData().map(renderRow)}</Body>
      <FlowManager
        flow={currentFlow}
        currentFlowName={currentFlowName}
        onClose={onCloseClick}
        provider={providerName.toLowerCase()}
        price={price}
        setPrice={setPrice}
        currency={currency}
        setCurrency={setCurrency}
        period={period}
        providerId={billingProviderId}
        members={members}
        setMembers={setMembers}
        setPeriod={setPeriod}
        endTrial={endTrial}
        setEndTrial={setEndTrial}
        prorationtime={prorationtime}
        setProrationtimeCb={setProrationtimeCb}
        expDate={HFN.formatDt(expDate) || "N/A"}
      />
    </Table>
  );
};

export default SubscriptionDetails;

const Table = styled.div`
  margin-top: 10px;
  .bold {
    font-weight: bold;
  }
  .normal {
    font-weight: normal;
  }
`;

const Body = styled.div`
  width: 780px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  line-height: 33px;
  border-bottom: 1px solid #e9e9e9;
  /* gap: 8px; */

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: 768px) {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    padding: 8px 20px;
  };
`;

const Col = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 33px;
  box-sizing: border-box;
  width: 33.3%;

  &:nth-child(1) {
    font-weight: bold;
    padding: 0 0 0 10px;
  }

  &:nth-child(3) {
    padding: 0 10px 0 0;
    text-align: right;
    justify-content: flex-end;
  }

  @media (max-width: 768px) {
    width: 100%;
    line-height: 1.1;

    &:nth-child(1) {
      grid-area: 1 / 1 / 2 / 2;
      padding-left: 0;
    }
    
    &:nth-child(2) {
      grid-area: 2 / 1 / 3 / 2;
      margin-top: 4px;
    }

    &:nth-child(3) {
      grid-area: 1 / 2 / 3 / 3;
      padding-right: 0;
    }
  }
`;

const CardInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0px;
  }
`;

const PayerInfo = styled.span`
  display: flex;
  align-items: center;
  opacity: ${props => (props.hasExpired ? 0.5 : 1)};
  line-height: 1.1;
  padding: 8px 0;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const ExpireCard = styled.span`
  color: ${props => (props.hasExpired ? "#f73c3c" : "#000")};
`;

const BraintreeChangeContainer = styled.div`
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ChangeLabel = styled.div`
  color: #888;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-right: 8px;
`;

const BlueButtonLoading = styled(Button)`
  background-color: #0070ba;
  height: 26px;
  cursor: default;
  pointer-events: none;
  margin-top: 0px;
  position: absolute;
  right: 0px;
  z-index: 1;
  float: none;
`;
export const BraintreeButtonContainer = styled.div`
  width: 90px;
  height: 26px;
  position: relative;

  #paypal-button-table {
    line-height: 1;
    z-index: 0;
    position: relative;

    .paypal-buttons {
      min-width: 0px !important;
    }
    .paypal-button-row {
      width: 25px !important;
    }
  }
`;
