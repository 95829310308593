// @flow

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Componentify from "react-componentify";

import styled from "styled-components";
import { Title } from "../tables/dark";

import apiMethod from "../../api/apiMethod";
import Tooltip from "../Tooltip";
import { __ } from "../../lib/translate";
import { getHash } from "../../lib/utils";
import { TFA_TYPE, SMS_TFA_ACCOUNT_RESTRICTION } from "@pcloud/web-utilities/dist/config/constants";
import type { ActiveTfaType } from "../../types/user";
import {
  setPhoneCountryCode,
  setVerifiedPhoneNumber,
  setUserCountry,
  deletePhoneNumber,
  deletePassword,
  setTwoFactorActiveType
} from "@pcloud/web-utilities/dist/lib/state/reducers/pcloud/userSlice";

import FlowManager from "../../containers/TwoFactorAuth/FlowManager";
import { ButtonCentered } from "../ButtonDefault";
import InputCheckbox from "../InputCheckbox";
import { CloseModalIcon } from "../Modal";
import * as Step from "../../containers/TwoFactorAuth";
import { spanClassTagConverter } from "../../lib/componentifyConverters";
import * as Style from "./preferences.style";

const modalFlows = {
  // Phone number flow
  sentEmail: [Step.SetupPassStep, Step.SentEmailStep],
  setupEmail: [Step.SentEmailStep],
  setupNewPass: [Step.SetupPassFormStep],
  verifyPhoneNumber: [
    Step.RequirePasswordStep,
    Step.ImportantMessageStep,
    Step.EnterPhoneNumberStep,
    Step.VerifyPhoneNumberStep,
    Step.ImportantVersionMessageStep,
    Step.SecurityCodesStep
  ],
  deactivate: [Step.RequirePasswordStep, Step.DeactivateTwoFactorAuthStep],
  changePhoneNumber: [
    Step.RequirePasswordStep,
    Step.EnterPhoneNumberStep,
    Step.VerifyPhoneNumberStep,
    Step.ChangePhoneNumberStep
  ],
  viewSecurityCodes: [Step.RequirePasswordStep, Step.SecurityCodesViewStep],

  // Google flow
  googleVerification: [
    Step.RequirePasswordStep,
    Step.ImportantMessageStep,
    Step.GoogleAuthenticatorStep,
    Step.ImportantVersionMessageStep,
    Step.SecurityCodesStep
  ]
};

type Props = {
  token: string,
  msisdn: string,
  verifiedPhoneNumber: string,
  hasPassword: boolean,
  isVerified: boolean,
  activeTfaType: ActiveTfaType,
  email: string,
  registered: string,
  setPhoneCountryCode: string => void,
  setVerifiedPhoneNumber: string => void,
  setUserCountry: string => void,
  deletePhoneNumber: () => void,
  deletePassword: () => void,
  setTwoFactorActiveType: number => void
};

type State = {
  msisdn: string,
  currentFlow: ?Array<Class<React$Component<any, any>>>,
  openSetPassword: boolean
};

export class TwoFactorAuthenticationSetting extends Component<Props, State> {
  static defaultProps = {
    token: "",
    msisdn: "",
    verifiedPhoneNumber: "",
    hasPassword: false,
    email: "",
    registered: "",
    activeTfaType: null,
    setPhoneCountryCode: () => {},
    setVerifiedPhoneNumber: () => {},
    setUserCountry: () => {},
    deletePhoneNumber: () => {},
    deletePassword: () => {},
    setTwoFactorActiveType: () => {}
  };

  constructor(props: Props) {
    super(props);

    (this: any).onToggleMsisdn = (this: any).onToggleMsisdn.bind(this);
    (this: any).onToggleAuthenticator = (this: any).onToggleAuthenticator.bind(this);
    (this: any).onOpenModal = (this: any).onOpenModal.bind(this);
    (this: any).onCloseModal = (this: any).onCloseModal.bind(this);
    (this: any).onChangeNumber = (this: any).onChangeNumber.bind(this);
    (this: any).onViewCodes = (this: any).onViewCodes.bind(this);
    (this: any).onSetupPass = (this: any).onSetupPass.bind(this);

    this.state = {
      currentFlow: [],
      msisdn: "",
      openSetPassword: false
    };
  }

  componentWillMount() {
    const { hasPassword } = this.props;

    if (getHash("opensetpassword") && !hasPassword) {
      this.onOpenModal(modalFlows["setupNewPass"]);
    }
  }

  componentDidMount() {
    this.fetchUserSettingsInfo();
  }

  fetchUserSettingsInfo() {
    const { token } = this.props;

    apiMethod(
      "usersettingsinfo",
      {
        auth: token
      },
      ({ twofacauth: { msisdn, countrycode, tfatype } = {} }) => {
        if (tfatype) {
          const { setTwoFactorActiveType } = this.props;
          setTwoFactorActiveType(tfatype);
        }

        if (countrycode && msisdn) {
          const { setPhoneCountryCode, setVerifiedPhoneNumber } = this.props;
          setPhoneCountryCode(countrycode);
          setVerifiedPhoneNumber(`${countrycode}${msisdn}`);
        }
      },
      {
        errorCallback: ({ error }) => {
          HFN.message(
            __(
              "something_went_wrong_refresh_and_try_again",
              "Something went wrong. Please reload the page and try again"
            ),
            "error"
          );
          throw new Error(error);
        }
      }
    );
  }

  fetchUserCountry() {
    const { setUserCountry } = this.props;

    apiMethod(
      "getip",
      {},
      ({ country }) => {
        setUserCountry(country);
      },
      {
        forseFresh: true,
        errorCallback: ({ error }) => {
          HFN.message(
            __(
              "something_went_wrong_refresh_and_try_again",
              "Something went wrong. Please reload the page and try again"
            ),
            "error"
          );
          throw new Error(error);
        }
      }
    );
  }

  onOpenModal(currentFlow?: any) {
    this.setState({ currentFlow });
    this.fetchUserCountry();
  }

  onCloseModal() {
    const { deletePassword, deletePhoneNumber } = this.props;

    deletePassword();
    deletePhoneNumber();
    this.setState({ currentFlow: [] });
  }

  onToggleMsisdn() {
    const { hasPassword, activeTfaType, isVerified } = this.props;

    if (!hasPassword) {
      this.onOpenModal(modalFlows["sentEmail"]);
    } else if (activeTfaType === TFA_TYPE.MSISDN_TYPE) {
      this.onOpenModal(modalFlows["deactivate"]);
    } else if (!isVerified && activeTfaType !== TFA_TYPE.AUTHENTICATOR_APP_TYPE) {
      HFN.message(
        __(
          "shared_link_verify_message",
          "You need to verify the email address for your pCloud account in order to perform this action."
        ),
        "error"
      );
    } else if (!activeTfaType) {
      this.onOpenModal(modalFlows["verifyPhoneNumber"]);
    }
  }

  onToggleAuthenticator() {
    const { hasPassword, activeTfaType, isVerified } = this.props;

    if (!hasPassword) {
      this.onOpenModal(modalFlows["sentEmail"]);
    } else if (activeTfaType === TFA_TYPE.AUTHENTICATOR_APP_TYPE) {
      this.onOpenModal(modalFlows["deactivate"]);
    } else if (!isVerified && activeTfaType !== TFA_TYPE.MSISDN_TYPE) {
      HFN.message(
        __(
          "shared_link_verify_message",
          "You need to verify the email address for your pCloud account in order to perform this action."
        ),
        "error"
      );
    } else if (!activeTfaType) {
      this.onOpenModal(modalFlows["googleVerification"]);
    }
  }

  onChangeNumber() {
    this.onOpenModal(modalFlows["changePhoneNumber"]);
  }

  onViewCodes() {
    this.onOpenModal(modalFlows["viewSecurityCodes"]);
  }

  onSetupPass() {
    const { token, hasPassword } = this.props;

    if (!hasPassword) {
      apiMethod(
        "sendsetpasswordlink",
        {
          auth: token,
          fromtwofacauth: true
        },
        res => {
          if (res.result === 0) {
            this.onOpenModal(modalFlows["setupEmail"]);
          } else {
            HFN.message(
              __(
                "something_went_wrong_refresh_and_try_again",
                "Something went wrong. Please reload the page and try again"
              ),
              "error"
            );
          }
        },
        {
          errorCallback: ({ error }) => {
            HFN.message(
              __(
                "something_went_wrong_refresh_and_try_again",
                "Something went wrong. Please reload the page and try again"
              ),
              "error"
            );
            throw new Error(error);
          },
          onXhrError: () => {
            HFN.message(
              __(
                "something_went_wrong_refresh_and_try_again",
                "Something went wrong. Please reload the page and try again"
              ),
              "error"
            );
          }
        }
      );
    }
  }

  renderTwoFactorActivationPhone() {
    const { activeTfaType, registered } = this.props;
    const registeredDate = new Date(registered).getTime();
    const timeNow = new Date().getTime();

    if (timeNow - registeredDate < SMS_TFA_ACCOUNT_RESTRICTION && activeTfaType != TFA_TYPE.MSISDN_TYPE) {
      return null;
    }

    return (
      <Style.Row key="byPhone" className="first-row">
        <div>
          <Style.Title className="text-wrap">
            {__("settings_security_tfa_sms", "Two-step verification (SMS / Notifications)")}
          </Style.Title>
          <Style.Description>
            {__("settings_security_tfa_sms_sub", "Require a security key sent via system notifications or SMS code in addition to your password.")}
          </Style.Description>
        </div>
        <Tooltip
          title={__(
            "tfa_setup_authenticator_tooltip_disable",
            "Disable your current Two-factor configuration, in order to switch."
          )}
          shouldRenderTooltip={activeTfaType === TFA_TYPE.AUTHENTICATOR_APP_TYPE}
        >
          <InputCheckbox
            size="small"
            onClick={this.onToggleMsisdn}
            checked={activeTfaType === TFA_TYPE.MSISDN_TYPE}
            disabled={activeTfaType === TFA_TYPE.AUTHENTICATOR_APP_TYPE}
          />
        </Tooltip>
      </Style.Row>
    );
  }

  renderGoogleActivation() {
    const { activeTfaType } = this.props;

    return (
      <Style.Row key="byGoogle" className="custom-row">
        <div>
          <Style.Title className="text-wrap">
            {__("settings_security_tfa_authenticator", "Two-step verification (Google Authenticator)")}
          </Style.Title>
          <Style.Description>
            {__("settings_security_tfa_authenticator_sub", "Require a security key generated by Authenticator app in addition to your password.")}
          </Style.Description>
        </div>

        <Tooltip
          title={__(
            "tfa_setup_authenticator_tooltip_disable",
            "Disable your current Two-factor configuration, in order to switch."
          )}
          shouldRenderTooltip={activeTfaType === TFA_TYPE.MSISDN_TYPE}
        >
          <InputCheckbox
            size="small"
            onClick={this.onToggleAuthenticator}
            checked={activeTfaType === TFA_TYPE.AUTHENTICATOR_APP_TYPE}
            disabled={activeTfaType === TFA_TYPE.MSISDN_TYPE}
          />
        </Tooltip>
      </Style.Row>
    );
  }

  renderPhoneNumber() {
    const { verifiedPhoneNumber } = this.props;

    if (!verifiedPhoneNumber) {
      return null;
    }

    return (
      <Style.Row key="phonenumber">
        <div>
          <Style.Title>
            {__("settings_security_tfa_phone_number", "Two-Factor Authentication With SMS")}
          </Style.Title>
          <Style.Description>
            <Componentify
              text={__("tfa_setup_change_phone_number", "", {
                phonenumberattr: `class='phone-number'`,
                phonenumber: `+${verifiedPhoneNumber}`
              })}
              converters={[spanClassTagConverter]}
            />
          </Style.Description>
        </div>

        <Style.Button onClick={this.onChangeNumber}>{__("Change")}</Style.Button>
      </Style.Row>
    );
  }

  renderRecoveryCodes() {
    return (
      <Style.Row key="securitycodes">
        <div>
          <Style.Title>{__("tfa_setup_view_recovery_codes", "Recovery codes")}</Style.Title>
          <Style.Description>{__("settings_security_tfa_recovery_codes", "You can sign in to your account using a backup code if you can't get a verification code from your authenticator app.")}</Style.Description>
        </div>
        <Style.Button onClick={this.onViewCodes}>{__("View")}</Style.Button>
      </Style.Row>
    );
  }

  renderSetupPass() {
    return (
      <Style.Row key="setpass">
        <div>
          <Style.Title>{__("Set up a password")}</Style.Title>
          <Style.Description>
            {__("tfa_setup_set_up_password", "Set up a password to protect your account.")}
          </Style.Description>
        </div>

        <Style.Button onClick={this.onSetupPass}>
          {__("tfa_setup_set_up_btn", "Set up")}
        </Style.Button>
      </Style.Row>
    );
  }

  render() {
    const { hasPassword, activeTfaType } = this.props;
    const { currentFlow } = this.state;

    return (
      <StyleWrap>
        {this.renderTwoFactorActivationPhone()}
        {activeTfaType === TFA_TYPE.MSISDN_TYPE
          ? [this.renderPhoneNumber(), this.renderRecoveryCodes()]
          : null}
        {[
          this.renderGoogleActivation(),
          activeTfaType === TFA_TYPE.AUTHENTICATOR_APP_TYPE ? this.renderRecoveryCodes() : null
        ]}
        {!hasPassword ? this.renderSetupPass() : null}
        <FlowManager
          key="flow"
          flow={currentFlow}
          onClose={this.onCloseModal}
          closeButton={CloseModalIcon}
        />
      </StyleWrap>
    );
  }
}

export default connect(
  ({ pCloudUser }) => {
    const {
      token,
      userinfo: { email, msisdn, verifiedPhoneNumber, haspassword, emailverified, registered } = {},
      userSettings: { security: { activeTfaType } = {} } = {}
    } = pCloudUser;

    return {
      token,
      email,
      msisdn,
      verifiedPhoneNumber,
      hasPassword: haspassword,
      activeTfaType,
      isVerified: emailverified,
      registered
    };
  },
  dispatch =>
    bindActionCreators(
      {
        setPhoneCountryCode,
        setVerifiedPhoneNumber,
        setUserCountry,
        deletePhoneNumber,
        deletePassword,
        setTwoFactorActiveType
      },
      dispatch
    )
)(TwoFactorAuthenticationSetting);

const StyleWrap = styled.div`
  a {
    padding: 0;
    height: 30px;
    line-height: 30px;
    font-weight: 400;
    font-size: 12px;
    width: 80px;
  }
`;
