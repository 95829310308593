import styled, { keyframes } from "styled-components";
import { hiDPI } from "polished";

import { COLORS } from "@pcloud/web-utilities/dist/config/constants";

const spin = keyframes`
  0% {
    transform:rotate(0deg);
  }
  100% {
    transform:rotate(360deg);
  }
`;

const fadein = keyframes`
  0% {
    opacity: 0;
    display: block;
  }
  100% {
    opacity: 1;
  }
`;

const fadeout = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
`;

export const PaymentLoader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);

  &.fadein {
    animation-name: ${fadein};
    animation-duration: 10ms;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }

  &.fadeout {
    animation-name: ${fadeout};
    animation-duration: 300ms;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }

  &::after {
    content: "";
    animation-name: ${spin};
    animation-duration: 600ms;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 48px;
    height: 48px;
    margin: -8px 0 0 -24px;
    z-index: 100;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-color: transparent;
    background-image: url(${require("../../../root/img/modal_loading.png")});
    ${hiDPI(1.5)} {
      background-image: url(${require("../../../root/img/modal_loading@2x.png")});
    }
    background-size: cover;
  }
`;

const Pulsate = keyframes`
  0% {transform: scale(0.1, 0.1); opacity: 0;}
  50% {opacity: 1;}
  100% {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1.2, 1.2);
    transform: translateZ(0);
    transform: scale(1.0, 1.0);
    opacity: 0;
    }
`;

export const Loader = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  border: ${(props) =>
    COLORS[props.color]
      ? "4px solid " + COLORS[props.color].mainColor
      : "4px solid " + COLORS["cyan"].mainColor};

  border-radius: 30px;
  animation: ${Pulsate} 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
`;

export const SmallLoader = styled(Loader)`
  position: relative;
  width: 14px;
  height: 14px;
  top: 0;
  left: 0;
  margin: 0 auto;
`;
