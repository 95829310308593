import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { IconButton } from "../ContentView/UI/Style";
import { CommentItemWrapper, CommentItem } from "./StyledComponents/Style";
import { formatDtTime } from "../../lib/utils";
import Componentify from "react-componentify";
import { linkConverterV2 } from "@pcloud/web-utilities/dist/lib/componentifyConverters";
import { isElementVisible } from "./utils/utils";
import { useSelector } from "react-redux";

export const newLine2Br = {
  regex: /\n/g,
  component: "br"
}

const CommentListItem = ({ 
    contentItemData,
    comment,
    scrollToBottomOnInit = false,
    onDeletedComment,
    onShowGoToNewCommentsPopover,
    isNewComment = false,
    scrollableListContainer,
    scrollingMaskHeight = 0
  },
) => {
  const loggedUserId = useSelector(({ pCloudUser }) => pCloudUser.userinfo.userid);

  const commentItemRef = useRef();
  const commentTextRef = useRef();

  useEffect(() => {
    if (scrollToBottomOnInit && scrollableListContainer) {
      scrollableListContainer.scrollTo({
        top: scrollableListContainer.scrollHeight,
        behavior: "instant"
      })
    }
  }, [scrollToBottomOnInit]);

  useEffect(() => {
    if (isNewComment && !isElementVisible(commentTextRef.current, scrollableListContainer, scrollingMaskHeight)) {
      onShowGoToNewCommentsPopover(comment, commentItemRef.current);
    }
  }, [isNewComment]);

  const downloadFileRevision = () => {
    var params = { 
      fileid: contentItemData.fileid, 
      forcedownload: 1 
    };

    if (comment.revisionid) {
      params.revisionid = comment.revisionid;
    }
    
    HFN.downloadLinkFile(params);
  }

  const deleteComment = () => {
    PCB.deleteComment(comment, contentItemData, onDeletedComment);
  }

  return (
    <CommentItemWrapper className="comment-list-item" ref={commentItemRef}>
      <CommentItem>
        <CommentHeader>
          <Avatar $backgroundImage={comment.usermeta.avatar && comment.usermeta.avatar.path ? HFN.prepUrl(comment.usermeta.avatar) : null}>
            {!comment.usermeta.avatar ? `${comment.usermeta.firstname[0]}${comment.usermeta.lastname[0]}` : null}
          </Avatar>
          <AuthorAndDateWrapper>
            <AuthorWrapper>
              <Author className={!comment.usermeta.link ? "disabled" : ""} href={comment.usermeta.link ? comment.usermeta.link : null} data-tooltip-hidden={!comment.usermeta.link} data-tooltip-id="view-profile-tooltip" data-tooltip-content={__("tooltip", "View profile")}>
                <span>
                  {comment.usermeta.firstname}{comment.usermeta.lastname ? ` ${comment.usermeta.lastname}` : null}
                </span>
              </Author>
            </AuthorWrapper>
            <DateAndTime>{formatDtTime(comment.dt)}</DateAndTime>
          </AuthorAndDateWrapper>
          {comment.usermeta.id === loggedUserId ? 
            <IconButton onClick={deleteComment} data-tooltip-id="delete-comment-tooltip" data-tooltip-content={__("Delete Comment", "Delete comment")} className="delete-comment">
              <i class="smallIcon fa-regular fa-trash-can"></i>
            </IconButton>
          : null}
        </CommentHeader>
        
        <Comment ref={commentTextRef}>
          <Componentify
            text={comment.comment}
            converters={[linkConverterV2, newLine2Br]}
          />
        </Comment>

        {comment.revisionid !== null ? 
          <CommentFooter>
            {__("Revision", "Revision")}: <RevLink onClick={downloadFileRevision}>{comment.revisionid ? comment.revisionid : __("Current", "Current")}</RevLink>
          </CommentFooter>
        : null}

      </CommentItem>
    </CommentItemWrapper>
  );
};

export default CommentListItem;

const CommentHeader = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-sm, 8px);
  align-self: stretch;

  .delete-comment {
    display: flex;
    visibility: hidden;
    opacity: 0;
    transition: all 150ms ease-in-out;
    width: 36px;
    height: 36px;
    justify-content: center;
    align-items: center;
    &:hover {
      border-radius: var(--radius-full, 9999px);
      background: var(--color-grey-300, #EFF0F1);
    }
  }
`;

const Avatar = styled.div`
  display: flex;
  min-width: var(--avatar-width, 32px);
  width: var(--avatar-width, 32px);
  height: var(--avatar-height, 32px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: var(--radius-full, 9999px);
  background-color: var(--surface-primary, #F4F5F6);
  background-image: ${({$backgroundImage}) => $backgroundImage ? `url("${$backgroundImage}")` : "unset"};
  background-repeat: no-repeat;
  background-position: center center;
  text-transform: uppercase;
  color: var(--text-secondary, #8C959D);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
`;

const AuthorAndDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`
const AuthorWrapper = styled.div``;

const Author = styled.a`
  color: var(--text-primary, #282C2F);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: none;
  display: inline-block;
  max-width: 100%;
  
  &:hover {
    text-decoration: underline;
  }

  &.disabled {
    cursor: default;
    &:hover {
      text-decoration: none;
    }
  }
`;

const DateAndTime = styled.div`
  color: var(--text-secondary, #8C959D);
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

const Comment = styled.div`
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
  max-width: 100%;

  a {
    color: var(--text-link, #282C2F);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const CommentFooter = styled.div`
  color: var(--text-secondary, #8C959D);
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

const RevLink = styled.a`
  cursor: pointer;
  color: var(--text-secondary, #8C959D);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
