import React from "react";
import styled from "styled-components";
import CommentsList from "./CommentsList";
import PostComment from "./PostComment";
import useManageCommentsListData from "./hooks/useManageCommentsListData";

const CommentsContainer = ({
  contentItemData,
  isVisible = true,
  autoFocusAddComment = {value: false}
}) => {
  
  const {
    comments,
    loadMoreComments,
    loadingInitialComments,
    loadingMoreComments,
    usersForMentions,
    onPostedComment,
    onDeletedComment,
    postCommentInProgress,
    setPostCommentInProgress,
    FETCH_COMMENTS_PORTION_LIMIT
  } = useManageCommentsListData({
    contentItemData,
    commentsListIsVisible: isVisible
  });

  return (
    <CommentsWrapper>
      <CommentsList 
        comments={comments.data}
        commentsTotalCount={comments.totalCount}
        contentItemData={contentItemData}
        isVisible={isVisible}
        onLoadMoreComments={loadMoreComments}
        loadMorePortionLimit={FETCH_COMMENTS_PORTION_LIMIT}
        loadingInitialComments={loadingInitialComments}
        loadingMoreComments={loadingMoreComments}
        onDeletedComment={onDeletedComment}
      />
      <PostComment
        contentItemData={contentItemData}
        usersForMentions={usersForMentions}
        onPostedComment={onPostedComment}
        autoFocus={autoFocusAddComment}
        isVisible={isVisible}
        postCommentInProgress={postCommentInProgress}
        setPostCommentInProgress={setPostCommentInProgress}
      />
    </CommentsWrapper>
  )
};

export default CommentsContainer;

const CommentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: 100%;
  min-width: 100%;
  gap: var(--spacing-md, 16px);
  position: relative;
`;