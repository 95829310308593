// @flow

import React, { PureComponent } from "react";
import styled from "styled-components";
import { findDOMNode } from "react-dom";

import BrandingLinkPreview from "./BrandingLinkPreview";
import type { PubLink } from "../../types/publiclinks";

type Props = {
  offsetX?: string,
  offsetY?: string,
  opened?: boolean,
  isPremium: boolean,
  isActiveGL: boolean,
  isActiveCL: boolean,
  brandingType: "custom" | "default",
  link?: PubLink,
  onClose?: () => void,
  clearBrandingData: () => void,
  updateGeneralBrandingStatus: () => void,
  updateCustomBrandingStatus: (string) => void
};

type State = {
  opened: boolean
};

class EditBrandingModal extends PureComponent<Props, State> {
  static defaultProps = {};
  modalContainer: ?HTMLElement;

  constructor(props: Props) {
    super(props);

    this.state = { opened: false };

    (this: any).closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    const { opened } = this.props;

    this.setState({ opened }, this.scaleInAnimation);

    document.getElementsByTagName( 'html' )[0].style.overflow = 'hidden';
    document.body.style.overflow = 'hidden';
  }

  componentDidUpdate({ opened: prevOpened }: Props) {
    const { opened } = this.props;

    console.log("opened", opened, prevOpened);

    if (opened !== prevOpened) {
      if (opened) {
        this.setState({ opened }, this.scaleInAnimation);
      } else {
        this.setState({ opened });
      }
    }
  }

  closeModal() {
    const { onClose } = this.props;

    if (onClose && typeof onClose === "function") {
      this.scaleOutAnimation(onClose);
    } else {
      this.scaleOutAnimation(() => {
        this.setState({ opened: false });
      });
    }

    document.getElementsByTagName( 'html' )[0].removeAttribute('style');
    document.body.removeAttribute('style');
  }

  scaleInAnimation() {
    const { opened } = this.props;
    console.log("scaleInAnimation", opened);

    if (opened) {
      setTimeout(() => {
        console.log("scaleInAnimation");
        const modalContainer = findDOMNode(this.modalContainer);

        modalContainer.classList.add("scalein-animation");
      }, 0);
    }
  }

  scaleOutAnimation(callBack: () => void) {
    const modalContainer = findDOMNode(this.modalContainer);
    const transitions = {
      transition: "transitionend",
      OTransition: "oTransitionEnd",
      MozTransition: "transitionend",
      WebkitTransition: "webkitTransitionEnd"
    };
    let transitionEvent;

    modalContainer.classList.remove("scalein-animation");

    for (let t in transitions) {
      if (modalContainer.style[t] !== undefined) {
        transitionEvent = transitions[t];
      }
    }

    transitionEvent &&
      modalContainer.addEventListener(transitionEvent, () => {
        if (callBack && typeof callBack === "function") {
          callBack();
        }
      });
  }

  render() {
    const {
      offsetX,
      offsetY,
      opened,
      link,
      brandingType,
      isPremium,
      isActiveCL,
      isActiveGL,
      updateGeneralBrandingStatus,
      updateCustomBrandingStatus
    } = this.props;
    const { opened: _opened } = this.state;

    const mouseOffset =
      offsetX && offsetY ? `${offsetX}px ${offsetY}px` : `center`;

    return _opened ? (
      <Modal align="top" ref={ref => (this.modalContainer = ref)}>
        <ModalContainer mouseOffset={mouseOffset}>
          <BrandingLinkPreview
            link={link}
            brandingType={brandingType}
            isPremium={isPremium}
            isActiveCL={isActiveCL}
            isActiveGL={isActiveGL}
            onClose={this.closeModal}
            updateGeneralBrandingStatus={updateGeneralBrandingStatus}
            updateCustomBrandingStatus={updateCustomBrandingStatus}
          />
        </ModalContainer>
      </Modal>
    ) : null;
  }
}

export default EditBrandingModal;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  text-align: center;
  z-index: 1999;
  opacity: 0;
  transition: opacity 350ms ease-in-out;
  padding: 15px;
  box-sizing: border-box;

  &.scalein-animation {
    opacity: 1;
    & > div {
      transform: scale(1);
    }
  }
`;

const ModalContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 4px;
  display: inline-block;
  transform-origin: ${props => props.mouseOffset};
  transform: scale(0);
  transition: transform 350ms ease-in-out;
  overflow: hidden;
`;
