import PropTypes from "prop-types";
import React, { useState, useCallback } from "react";
import apiMethod from "../api/apiMethod";
import { TSpan } from "../components/intl";
import { __ } from "../lib/translate";
import SubmitButton from "../components/SubmitButton";
import InputReadOnly from "../components/InputReadOnly";
import * as Style from "./SharedInvitationComponents";
import PcloudLogo from "@pcloud/web-utilities/dist/resources/images/svg/pcloudLogo.svg";
import WarnIcon from "../../root/img/svg/warning_invitation.svg";
import Componentify from "react-componentify";
import { SigninOptionsWrapper } from "@pcloud/web-utilities/dist/authentication/components/UserAuthentication2/MultiplatformComponents/WebStyles";
import CheckBox from "@pcloud/web-utilities/dist/authentication/components/UserAuthentication2/MultiplatformComponents/Elements/CheckBox";
import { boldConverter, linkConverter, linkConverterV2 } from "../lib/componentifyConverters";
import * as FormStyle from "../components/FormSharedComponents";
import InputPassword from "../components/InputPassword";
import hashManager from "../lib/hashManager";

const BusinessActivateContainer = ({ code, email }) => {
  const [newpassword, setNewPassword] = useState("");
  const [newpasswordRepeat, setNewPasswordRepeat] = useState("");
  const [terms, setTerms] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const businessActivate = useCallback(
    (e) => {
      e.preventDefault();

      if (!newpassword || !newpasswordRepeat) {
        setError(__("Passwords are required.", "Passwords are required."));
        return;
      }

      if (newpassword !== newpasswordRepeat) {
        setError(__("Both new passwords should match.", "Both new passwords should match."));
        return;
      }

      if (!terms) {
        setError(__("You must accept Terms of Service to continue", "You must accept Terms of Service to continue"));
        return;
      }

      setLoading(true);

      apiMethod(
        "account_setpassword",
        { code, newpassword, device: navigator.userAgent },
        (ret) => {
          if (ret.result === 0 && ret.auth) {
            apiMethod(
              "userinfo",
              { auth: ret.auth },
              (data) => {
                HFN.message("Success. Your account was activated.");
                HFN.userLoginAfter({ ...data, auth: ret.auth }, () => {
                  hashManager.pushState({ page: "b_account" }, 2);
                });
              },
              {
                errorCallback: (ret) => {
                  setError(ret.error);
                  setLoading(false);
                },
              }
            );
          }
        },
        {
          errorCallback: (ret) => {
            setError(ret.error);
            setLoading(false);
          },
        }
      );
    },
    [code, newpassword, newpasswordRepeat, terms]
  );

  const handleTermsChange = () => {
    setTerms((prev) => !prev);
  };

  const renderForm = () => (
    <>
      <Style.FormLabel>{__("B_ACTIVATE_ACCOUNT_DESCRIPTION")}</Style.FormLabel>
      <Style.Form method="post" onSubmit={businessActivate}>
        {error && <div className="error">{error}</div>}
        <InputReadOnly value={email} />
        <InputPassword
          name="newpassword"
          placeholder={__("New Password", "New Password")}
          value={newpassword}
          onChange={(e) => setNewPassword(e.target.value)}
          revealPassword={true}
        />
        <InputPassword
          name="repeatpassword"
          placeholder={__("Repeat Password", "Repeat Password")}
          value={newpasswordRepeat}
          onChange={(e) => setNewPasswordRepeat(e.target.value)}
          revealPassword={true}
        />
        <SigninOptionsWrapper className="terms-wrapper">
          <CheckBox
            name="terms"
            size="small"
            checked={terms}
            onChange={handleTermsChange}
            className="register-terms-checkbox"
          >
            <FormStyle.Terms className="registration-terms" onClick={handleTermsChange}>
              <Componentify
                className="terms-text"
                text={__(
                  "i_accept_pcloud_terms_mobile",
                  "I Accept pCloud's https://www.pcloud.com/policy/terms_conditions/[Terms & Conditions], https://www.pcloud.com/policy/privacy_policy/[Privacy Policy] and https://www.pcloud.com/policy/intellectual_privacy/[Intellectual Property Policy]"
                )}
                converters={[boldConverter, linkConverter, linkConverterV2]}
              />
            </FormStyle.Terms>
          </CheckBox>
        </SigninOptionsWrapper>
        <SubmitButton loading={loading} active={!loading}>
          {__("Continue")}
        </SubmitButton>
      </Style.Form>
    </>
  );

  const renderNoCode = () => (
    <Style.WarningMessage>
      <WarnIcon />
      <Style.ComponentifyWrapper>
        <Componentify text={__("invalid_data_arrived")} converters={[boldConverter]} />
      </Style.ComponentifyWrapper>
    </Style.WarningMessage>
  );

  return (
    <Style.Container>
      <Style.HeaderLogoContainer>
        <PcloudLogo />
      </Style.HeaderLogoContainer>
      <Style.Title>{__("welcome_to_pcloud_business", "Welcome to pCloud Business")}</Style.Title>
      {!code ? renderNoCode() : renderForm()}
    </Style.Container>
  );
};

BusinessActivateContainer.propTypes = {
  code: PropTypes.string,
  email: PropTypes.string,
};

export default BusinessActivateContainer;
