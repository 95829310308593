// @flow

import React, { PureComponent } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import apiMethod from "../../api/apiMethod";
import errors from "../../lib/errors";
import { __ } from "../../lib/translate";
import { MIN_USER_QUOTA, QUOTA_STEP, CHANGE_DATA_REGION_STATUS } from "@pcloud/web-utilities/dist/config/constants";

import InviteFamilyForm from "./InviteFamilyForm";
import MemnerList from "./MemberList";
import { LoaderFamily } from "./SharedFamilyComponents";

type Member = {
  userid: number,
  quota: number,
  email: string,
  isowner: boolean
};

type Invite = {
  email: string,
  invitationid: number,
  quota: number,
  invitelink: string
};

type Props = {
  token: string,
  quota: number,
  usedQuota: number
};

type State = {
  isLoadingMembers: boolean,
  members: Array<Member>,
  invites: Array<Invite>,
  maxMembers: number,
  relocationInProgress: boolean
};

class FamilyContainer extends PureComponent<Props, State> {
  static defaultProps = {};

  constructor(props: Props) {
    super(props);

    (this: any).state = {
      isLoadingMembers: false,
      members: [],
      invites: [],
      maxMembers: 0,
      relocationInProgress: false
    };

    (this: any).onSuccessInviteFamilyForm = this.onSuccessInviteFamilyForm.bind(this);
    (this: any).removeMember = this.removeMember.bind(this);
    (this: any).manageMember = this.manageMember.bind(this);
    (this: any).cancelInvite = this.cancelInvite.bind(this);

    (this: any).xhr = [];
  }

  componentDidMount() {
    this.fetchMembers();
    // Disable invitation form if owner activate relocation
    // this.getRelocationStatus(); // remove relocation restriction for new invites
  }

  // componentDidUpdate(prevProps) {
  //   const { quota: prevQuota } = prevProps;
  //   const { quota } = this.props;

  //   if (quota !== prevQuota) {
  //     this.fetchMembers();
  //   }
  // }

  componentWillUnmount() {
    (this: any).xhr.forEach(x => "abort" in x && x.abort());
  }

  onSuccessInviteFamilyForm(invite) {
    const { invites } = this.state;

    this.setState({
      invites: [...invites, invite]
    });
  }

  getRelocationStatus() {
    const { relocationStatus } = this.props;
    const status =
      relocationStatus === CHANGE_DATA_REGION_STATUS["IN_QUEUE"] ||
      relocationStatus === CHANGE_DATA_REGION_STATUS["IN_PROGRESS"];
    this.setState({ relocationInProgress: status });
  }

  fetchMembers() {
    const { token } = this.props;

    this.setState({ isLoadingMembers: true });

    (this: any).xhr.push(
      apiMethod(
        "fm_getinfo",
        {
          auth: token
        },
        res => {
          this.setState({
            isLoadingMembers: false,
            members: res.members,
            invites: res.invites,
            maxMembers: res.data.maxmembers
          });
        },
        {
          errorCallback: ({ result, error }) => {
            this.setState({
              isLoadingMembers: false
            });

            if (errors[result]) {
              HFN.message(errors[result], "error");
            } else {
              HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
              throw new Error(error);
            }
          }
        }
      )
    );
  }

  hasAvailableQuota() {
    const { usedQuota, quota } = this.props;
    const freeQuota = quota - usedQuota;
    const minUserQuota = (quota % QUOTA_STEP) + MIN_USER_QUOTA;

    return freeQuota >= MIN_USER_QUOTA && quota > minUserQuota;
  }

  canInvite() {
    const { members, invites, maxMembers } = this.state;
    const totalMembers = members.length + invites.length;

    return totalMembers < maxMembers;
  }

  removeMember(id: number) {
    const { members } = this.state;

    const updatedMembers = members.filter(({ userid }) => userid !== id);
    this.setState({ members: updatedMembers });
  }

  manageMember(id: number, quota: number) {
    const { members } = this.state;

    const updatedMembers = members.reduce((acc, member) => {
      if (member.userid === id) {
        member.quota = quota;
      }
      acc.push(member);
      return acc;
    }, []);

    this.setState({ members: updatedMembers });
  }

  cancelInvite(id: number) {
    const { invites } = this.state;

    const updatedInvites = invites.filter(({ invitationid }) => invitationid !== id);
    this.setState({ invites: updatedInvites });
  }

  renderHeader() {
    let header = "";
    let subHeader = "";

    if (!this.canInvite()) {
      header = __("family_no_invite_header", "You cannot invite more family members");
      subHeader = __(
        "family_no_invite_subheader_max_members",
        "You've reached the max. number of family members you can invite."
      );
    } else if (!this.hasAvailableQuota()) {
      header = __("family_no_invite_header", "You cannot invite more family members");
      subHeader = __(
        "family_no_invite_subheader_quota",
        "You don't have enough storage available to share with more family members."
      );
    } else {
      header = __("family_invite_header", "Invite your family members to join pCloud!");
      subHeader = __(
        "family_invite_subheader",
        "Enjoy all Premium features for you and up to five family members."
      );
    }

    return (
      <div>
        <Header>{header}</Header>
        <Subheader>{subHeader}</Subheader>
      </div>
    );
  }

  render() {
    const { members, invites, isLoadingMembers, relocationInProgress } = this.state;

    if (isLoadingMembers) {
      return <LoaderFamily />;
    }

    return (
      <Wrapper>
        {this.renderHeader()}
        {relocationInProgress ? (
          <DisableMessage>{__("owner_relocation_invite_restriction")}</DisableMessage>
        ) : null}
        {this.hasAvailableQuota() && this.canInvite() ? (
          <InviteFamilyForm
            disabled={relocationInProgress}
            onSuccess={this.onSuccessInviteFamilyForm}
          />
        ) : null}
        <MemnerList
          disabled={relocationInProgress}
          members={members}
          invites={invites}
          removeMember={this.removeMember}
          manageMember={this.manageMember}
          cancelInvite={this.cancelInvite}
        />
      </Wrapper>
    );
  }
}

export default connect(({ pCloudUser }) => {
const {
  token,
  userinfo: { usedquota, quota } = {},
  relocationInfo: { relocationStatus } = {}
} = pCloudUser;

return { token, usedQuota: usedquota, quota, relocationStatus: relocationStatus };

})(FamilyContainer);

const Wrapper = styled.div`
    padding: 0 var(--spacing-md) 0 var(--spacing-md);
    position: relative;
    padding-bottom: 100px;
  a {
    margin-top: 20px;
    min-width: 180px;
    height: 40px;
  }
`;

const Header = styled.div`
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
`;

const Subheader = styled.div`
  text-align: center;
  padding-top: 15px;
  font-size: 15px;
`;

const DisableMessage = styled.div`
  margin: 25px 0;
  padding: 19px 50px 17px 49px;
  border: solid 1px #fcf2d7;
  background-color: #fff8e2;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: -0.2px;
  text-align: center;
  color: #000000;
`;
