// @flow

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import apiMethod from "../../api/apiMethod";
import errors from "../../lib/errors";
import { __ } from "../../lib/translate";
import { renderSentEmail } from "../TwoFactorAuth/SentEmailStep";

import { ButtonCentered } from "../../components/ButtonDefault";
import { ErrorMessage } from "../TwoFactorAuth/SharedComponents";
import { CHANGE_DATA_REGION_STATUS } from "@pcloud/web-utilities/dist/config/constants";
import { setPassword, updateUserRelocationStatus } from "@pcloud/web-utilities/dist/lib/state/reducers/pcloud/userSlice";

import * as Style from "./SharedComponents";

type Props = {
  hasFree: boolean,
  hasPaidRelocation: boolean,
  onSuccess: () => void
};

const RequestReceivedStep = ({ hasFree, hasPaidRelocation, onSuccess }: Props) => {
  const email = useSelector(({ pCloudUser }) => pCloudUser.userinfo.email);

  const [pass, setPass] = useState("");
  const [hasForgottenPass, setHasForgottenPass] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const hasFreeRelocation = hasFree || hasPaidRelocation;

  const dispatch = useDispatch();

  const onPassChange = (e: any) => {
    setPass(e.target.value);
    setShowErrorMessage(false);
  };

  const onForgottenPassClick = () => {
    apiMethod(
      "lostpassword",
      {
        mail: email
      },
      res => {
        setHasForgottenPass(true);
      },
      {
        errorCallback: ({ error }) => {
          HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
          throw new Error(error);
        }
      }
    );
  };

  const onSubmit = () => {
    if (!pass) {
      setErrorMessage(__("Password is required"));
      setShowErrorMessage(true);
      return;
    }

    setIsLoading(true);
    setErrorMessage("");
    setShowErrorMessage(false);

    if (!hasFreeRelocation) {
      apiMethod(
        "verifypassword",
        {
          password: pass
        },
        res => {
          setIsLoading(false);
          dispatch(setPassword(pass));
          onSuccess();
        },
        {
          errorCallback: ({ result, error }) => {
            let errorMessage = "";
  
            if (errors[result]) {
              errorMessage = __(errors[result]);
            } else {
              errorMessage = __("something_went_wrong_refresh_and_try_again");
            }
            setIsLoading(false);
            setErrorMessage(errorMessage);
            setShowErrorMessage(true);
  
            if (!errors[result]) {
              throw new Error(error);
            }
          }
        }
      );
      return
    }

    apiMethod(
      "relocation/request",
      {
        password: pass
      },
      res => {
        setIsLoading(false);
        dispatch(setPassword(pass));
        dispatch(updateUserRelocationStatus(CHANGE_DATA_REGION_STATUS["IN_QUEUE"]));
        onSuccess();
      },
      {
        errorCallback: ({ result, error }) => {
          let errorMessage = "";

          if (errors[result]) {
            errorMessage = errors[result];
          } else {
            errorMessage = __("something_went_wrong_refresh_and_try_again");
          }
          setIsLoading(false);
          setErrorMessage(errorMessage);
          setShowErrorMessage(true);

          if (!errors[result]) {
            throw new Error(error);
          }
        }
      }
    );
  };

  const onEnter = (e: any) => {
    if (e.keyCode === 13) {
      onSubmit();
    }
  };

  const renderRequirePass = () => {
    return (
      <Style.Container key="relocate">
        <Style.DRHeader>{__("Password required")}</Style.DRHeader>
        <p>{__("tfa_setup_require_pass_subheading")}</p>
        <Style.DRMessage className="require-pass">
          <ErrorMessage show={showErrorMessage}>{errorMessage}</ErrorMessage>
          <Style.DRInputPass
            name="pass"
            type="password"
            value={pass}
            placeholder={__("Password")}
            autoFocus
            onChange={onPassChange}
            onKeyUp={onEnter}
            shouldRenderRedBorder={showErrorMessage}
            disabled={isLoading}
          />
        </Style.DRMessage>
        <ButtonCentered
          disabled={!pass}
          loading={isLoading}
          onClick={isLoading || !pass ? () => {} : onSubmit}
          minWidth="360px"
          style={{maxWidth: "360px"}}
          disabledColor="#dddddd"
        >
          {__("Continue")}
        </ButtonCentered>
        <Style.DRLink onClick={onForgottenPassClick} className="forgot-pass">
          {__("Forgot your password?")}
        </Style.DRLink>
      </Style.Container>
    );
  };

  return <div>{hasForgottenPass ? renderSentEmail(email) : renderRequirePass()}</div>;
};

export default RequestReceivedStep;
