import React from "react";
import styled, { keyframes } from "styled-components";

const SkeletonLoad = () => {
  return Array(5).fill(
    <ItemWrapper>
      <IconWrapper />
      <ItemName />
    </ItemWrapper>
  )

};

export default SkeletonLoad;

const skeletonLoading = keyframes`
    0% {
        background-color: hsla(210, 7%, 94%, .5);
    }
    100% {
        background-color: hsla(210, 7%, 94%, 1);
    }
`;
const ItemWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    justify-content: flex-start;
    padding: 0px 8px 0px 18px;
    z-index: 2;
    width: 100%;
`;

const ItemName = styled.div`
    width: 150px;
    height: 24px;
    margin-left: 8px;
    border-radius: 4px;
    background-color: var(--surface-skeleton);
    animation: ${skeletonLoading} 1s linear infinite alternate;
`;

const IconWrapper = styled.div`
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: var(--surface-skeleton);
    animation: ${skeletonLoading} 1s linear infinite alternate;
`;